export const latDMS = lat => {
  return `${dcToDeg(lat)}° ${dcToMin(lat)}' ${parseFloat(dcToSec(lat).toFixed(3))}" ${lat > 0 ? 'N' : 'S'}`
}

export const lngDMS = lng => {
  return `${dcToDeg(lng)}° ${dcToMin(lng)}' ${parseFloat(dcToSec(lng).toFixed(3))}" ${lng > 0 ? 'E' : 'W'}`
}

const dcToDeg = val => {
  if (val === 0) {
    return 0
  }
  return Math.floor(Math.abs(val))
}

const dcToMin = val => {
  if (val === 0) {
    return 0
  }
  return Math.floor((Math.abs(val) - Math.floor(Math.abs(val))) * 60)
}

const dcToSec = val => {
  if (val === 0) {
    return 0
  }
  return (Math.abs(val) - dcToDeg(val) - dcToMin(val) / 60) * 3600
}
