import { bezierSpline, center, length, lineOffset, lineString, point, transformTranslate } from '@turf/turf'

import { getUUIDv4 } from '@/utils/helper'

export function Arrow(_draw, createArrow, _feature = {}) {
  let _createOnDone = createArrow.done || createArrow
  let _createOnBuild = createArrow.build || createArrow
  this.draw_type = ''
  _draw.onDone = coordinates => {
    if (!coordinates || coordinates.length < 3) {
      return []
    }
    let features = _createOnDone(coordinates)
    let feature = features[0]
    feature.properties = {}
    feature.properties.draw_coordinates = coordinates
    feature.properties.id =
      _feature && _feature.properties
        ? _feature.id
          ? _feature.id
          : _feature.properties.id
        : `arrow-${getUUIDv4()}` || `arrow-${getUUIDv4()}`
    feature.properties.draw_type = this.draw_type
    feature.id = feature.properties.id
    if (!_feature || !_feature.properties || (!_feature.id && !_feature.properties.id)) {
      this.onCreate(features)
    } else {
      this.onUpdate(features)
    }
  }
  _draw.convertCoordinatesToFeatures = coordinates => {
    if (!coordinates || coordinates.length < 2) {
      return []
    }
    if (coordinates.length === 2) {
      return [lineString(coordinates)]
    }
    return _createOnBuild(coordinates)
  }

  this.onCreate = () => {}
  this.onUpdate = () => {}
  this.onDelete = () => {}
  this.destroy = () => {
    _draw.clear()
  }
  this.trash = () => {
    if (_feature) this.onDelete([_feature])
    _draw.clear()
    return [_feature]
  }

  this.start = () => {
    _draw.start()
  }
  if (_feature && _feature.properties && _feature.properties.draw_coordinates) {
    let draw_coordinates = JSON.parse(_feature.properties.draw_coordinates)
    _draw.init(draw_coordinates)
  }
}

export function createArrowHead(_point, _bearing, _length) {
  let _head_arrow_coordinates = transformTranslate(point(_point), _length, _bearing).geometry.coordinates
  let _wing_1_coordinates = transformTranslate(point(_point), _length, _bearing + 100).geometry.coordinates

  let _wing_2_coordinates = transformTranslate(point(_point), _length, _bearing - 100).geometry.coordinates
  return {
    _head_arrow_coordinates,
    _wing_1_coordinates,
    _wing_2_coordinates,
  }
}

export function createArrowHeadTail(_point, _before_point, _length) {
  let _line_offset_1 = lineOffset(lineString([_before_point, _point]), _length)
  let _line_offset_2 = lineOffset(lineString([_before_point, _point]), -_length)
  return {
    _tail_1_coordinates: _line_offset_1.geometry.coordinates.pop(),
    _tail_2_coordinates: _line_offset_2.geometry.coordinates.pop(),
  }
}

export function createArrowBody(coordinates, max_offset_len, ratio = 0.7) {
  coordinates = bezierSpline(lineString(coordinates), {
    sharpness: 0.25,
  }).geometry.coordinates

  let _part_length = 0
  let _line_length = length(lineString(coordinates))
  let _last_point_coordinate = coordinates[0]

  let _coordinates_1 = []
  let _coordinates_2 = []
  for (let index = 1; index < coordinates.length - 1; index++) {
    const _current_point_coordinate = coordinates[index]
    let _part_line = lineString([_last_point_coordinate, _current_point_coordinate])

    _part_length += length(_part_line)

    let _offset_len = max_offset_len * (1 - (_part_length / _line_length) * ratio)
    let _line_part_offset_1 = lineOffset(_part_line, _offset_len)
    let _line_part_offset_2 = lineOffset(_part_line, -_offset_len)

    let _part_point_1 = _line_part_offset_1.geometry.coordinates.pop()
    let _part_point_2 = _line_part_offset_2.geometry.coordinates.pop()
    _coordinates_1.push(_part_point_1)
    _coordinates_2.push(_part_point_2)
    _last_point_coordinate = _current_point_coordinate
  }
  return {
    _coordinates_1,
    _coordinates_2,
  }
}

export function convertArrowLineByBottom(coordinates, bottom_line) {
  let _bottom_center = center(bottom_line).geometry.coordinates

  // let _head_tail_coordinates = transformTranslate(
  //   point(_bottom_center),
  //   length(bottom_line),
  //   bearing(
  //     bottom_line.geometry.coordinates[0],
  //     bottom_line.geometry.coordinates[1]
  //   ) - 90
  // ).geometry.coordinates
  // coordinates.unshift(_head_tail_coordinates)
  coordinates.unshift(_bottom_center)

  return coordinates
}
