import api from '@/api/api'

class NotificationApi {
  async update(uuidNotification, data) {
    return await api.put(`/notifications/${uuidNotification}`, data)
  }
  async delete(uuidNotification) {
    return await api.delete(`/notifications/${uuidNotification}`, {}, { preventNotification: true })
  }
  async getNotification(uuidNotification) {
    return await api.get(`/notifications/${uuidNotification}`)
  }

  async getListNotifications(params = {}) {
    return await api.get('/notifications', params)
  }
  async markAllAsRead() {
    return await api.post(`/notifications/mark-all-as-read`, {}, { preventNotification: true })
  }
  async markAsRead(uuidNotification) {
    return await api.post(`/notifications/${uuidNotification}/mark-as-read`, {}, { preventNotification: true })
  }
  async markAsUnread(uuidNotification) {
    return await api.post(`/notifications/${uuidNotification}/mark-as-unread`, {}, { preventNotification: true })
  }
  async getUnreadNotificationsCount() {
    return await api.get('/notifications/unread-count')
  }
}

export default new NotificationApi()
