<template>
  <v-list-group ref="refVListGroup" class="vertical-nav-menu-group text--primary">
    <template #activator="{ props }">
      <v-list-item v-bind="props">
        <template #prepend>
          <v-icon class="mx-auto">
            {{ icon }}
          </v-icon>
        </template>
        <v-list-item-title style="font-size: 14px !important; font-weight: 650 !important">
          {{ title }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <slot></slot>
  </v-list-group>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss">
.app-navigation-menu {
  .v-list-item {
    padding-left: 22px;
    height: 44px;
    min-height: 44px;

    .v-list-item__icon {
      margin-top: 0;
      margin-bottom: 0;
      align-self: center;
    }
  }

  .vertical-nav-menu-group {
    & > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
      transform: rotate(-90deg);
    }

    &.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
      transform: none;
    }
  }
}
</style>
