import { getAOIs, registerAlert, unregisterAlert, createAOI, getGroupAOI } from '@/api/aoi-api'
import config from '@/config'

export default {
  namespaced: true,
  state: {
    listAOI: [],
    listAllAOI: [],
    AOIs: [],
    allAOIs: [],
    nullGroupAOIs: [],
    groups: [],
    groupsAOI: [],
    currentGroup: {},
    currentCreatedAOI: {},
    currentTypeCreateAOI: null,
    filters: undefined,
    nullGroupCount: 0,
    page: 1,
    queryData: undefined,
    allGroup: [],
  },
  getters: {
    listAOIs: state => state.listAOI,
    listAllAOIs: state => state.listAllAOI,
    AOIs: state => state.AOIs,
    allAOIs: state => state.allAOIs,
    groupsAOI: state => state.groupsAOI.sort((a, b) => a.name.localeCompare(b.name)),
    filters: state => state.filters,
    currentCreatedAOI: state => state.currentCreatedAOI,
    totalAOICount: state => {
      const groupCount = state.groupsAOI.reduce((total, group) => total + (group.count || 0), 0)
      return state.nullGroupCount + groupCount
    },
  },
  mutations: {
    SET_STATE_PROPERTY(state, payload) {
      state[payload.key] = payload.value
    },
    PROCESS_ITEMS(state, items) {
      items.forEach(val => {
        val.thumbnail_url = `${config.backend_pub}${val.thumbnail_url}`
        val.color = undefined
        val.display = false
        if (val.group) {
          let group = state.listAOI.find(item => item.name === val.group && item.items)
          if (group) {
            group.items.push(val)
          } else {
            state.listAOI.push({ name: val.group, items: [val] })
          }
        } else {
          state.listAOI.push(val)
        }
      })
    },
    APPEND_AOIS(state, data) {
      let currentGroup = state.groupsAOI.find(item => item.name === data.data.payload.group)
      if (currentGroup) {
        currentGroup.items = [...currentGroup.items, ...data.aois]
      } else {
        state.nullGroupAOIs = [...state.nullGroupAOIs, ...data.aois]
      }
    },
  },
  actions: {
    async getAllGroup({ state }, data) {
      const groupRes = await getGroupAOI({ projectId: data.projectId, payload: data.payload })
      state.allGroup = []
      groupRes.data.forEach(val => {
        if (val.group !== '') {
          state.allGroup.push(val.group)
        }
      })
    },
    async getListAOI({ state, commit }, data) {
      if (data.payload) {
        const { group, ...rest } = data.payload
        state.queryData = rest
      }

      try {
        if (!data.payload) data.payload = {}

        if (data.isLazy) {
          data.payload.per_page = 10
          const groupRes = await getGroupAOI({
            projectId: data.projectId,
            payload: state.queryData,
          })
          let nullGroup = groupRes.data.find(item => item.group === '')
          state.nullGroupCount = nullGroup ? nullGroup.count : 0
          state.groupsAOI = []
          state.nullGroupAOIs = []
          groupRes.data.forEach(val => {
            if (val.group !== '') {
              state.groupsAOI.push({ name: val.group, count: val.count, page: 0, items: [] })
            }
          })
          let currentGroup = state.groupsAOI.find(item => item.name === state.currentGroup.name)
          state.currentGroup = currentGroup || {}
          state.page = 1
        } else {
          data.payload.per_page = 'all'
        }

        data.payload.with = 'planet_key'
        const res = await getAOIs({
          projectId: data.projectId,
          payload: {
            ...state.queryData,
            group: data.payload?.group,
            per_page: data.payload.per_page,
          },
        })
        state.listAOI = []
        if (data.isLazy) {
          res.data.data.forEach(val => {
            val.thumbnail_url = `${config.backend_pub}${val.thumbnail_url}`
            val.color = undefined
            val.display = false
          })
          if (data.payload.group === '') {
            commit('SET_STATE_PROPERTY', { key: 'nullGroupAOIs', value: res.data.data })
            state.nullGroupCount = res.data.total
          } else {
            currentGroup.count = res.data.total
          }
        } else {
          commit('PROCESS_ITEMS', res.data)
          commit('SET_STATE_PROPERTY', { key: 'AOIs', value: res.data })
        }

        state.filters = data.payload?.filters ? JSON.parse(data.payload.filters) : undefined
      } catch (e) {
        console.warn('Error when get list AOI: ', e)
      }
    },
    async getListAllAOI({ state, commit }, data) {
      try {
        if (!data.payload) data.payload = {}
        data.payload.per_page = 'all'
        data.payload.with = 'planet_key'
        const res = await getAOIs({ projectId: data.projectId, payload: data.payload })
        state.allAOIs = res.data
        state.listAllAOI = []
        res.data.forEach(val => {
          val.thumbnail_url = `${config.backend_pub}${val.thumbnail_url}`
          val.color = undefined
          val.display = false
          if (val.group) {
            let group = state.listAllAOI.find(item => item.name === val.group && item.items)
            if (group) {
              group.items.push(val)
            } else {
              state.listAllAOI.push({ name: val.group, items: [val] })
            }
          } else {
            state.listAllAOI.push(val)
          }
        })
      } catch (e) {
        console.warn('Error when get list All AOI: ', e)
      }
    },
    async getMoreAOI({ state, commit }, data) {
      try {
        if (!data.payload) data.payload = {}
        let currentGroup = state.groupsAOI.find(item => item.name === data.payload.group)
        if (currentGroup) {
          currentGroup.page += 1
        } else {
          state.page += 1
        }
        data.payload.per_page = 10
        data.payload.page = currentGroup ? currentGroup.page : state.page
        data.payload.with = 'planet_key'
        const res = await getAOIs({ projectId: data.projectId, payload: data.payload })
        // TODO: duplicate code: commit('PROCESS_ITEMS', res.data);
        res.data.data.forEach(val => {
          val.thumbnail_url = `${config.backend_pub}${val.thumbnail_url}`
          val.color = undefined
          val.display = false
          if (val.group) {
            let group = state.listAOI.find(item => item.name === val.group && item.items)
            if (group) {
              group.items.push(val)
            } else {
              state.listAOI.push({ name: val.group, items: [val] })
            }
          } else state.listAOI.push(val)
        })
        if (res.data.data.length) {
          commit('APPEND_AOIS', { aois: res.data.data, data: data })
        }
        return res.data.data
      } catch (e) {
        console.warn('Error when getting more AOI: ', e)
        return []
      }
    },
    async createAOI({ commit }, { data, typeCreateAOI = '' }) {
      try {
        const res = await createAOI(data)
        commit('SET_STATE_PROPERTY', { key: 'currentTypeCreateAOI', value: typeCreateAOI })
        commit('SET_STATE_PROPERTY', { key: 'currentCreatedAOI', value: res.data })
      } catch (e) {
        console.log('Error when create AOI')
      }
    },
    async registerAlert({ dispatch }, data) {
      try {
        await registerAlert(data)
        await dispatch('getListAOI', { projectId: data.projectId })
      } catch (e) {
        console.log('Error when register alert')
      }
    },
    async unregisterAlert({ dispatch }, data) {
      try {
        await unregisterAlert(data)
        await dispatch('getListAOI', { projectId: data.projectId })
      } catch (e) {
        console.log('Error when unregister alert')
      }
    },
  },
}
