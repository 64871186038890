<template>
  <v-snackbar
    v-model="snackBar"
    :color="color"
    :timeout="timeout"
    :location="location"
    class="text-capitalize"
    style="z-index: 10000; margin: 20px"
    :timer="timer"
  >
    <div v-html="content"></div>
  </v-snackbar>
</template>

<script>
import { mapState } from '@/store/ults'

export default {
  name: 'message',
  data: () => ({
    snackbarColor: '#fc0303',
  }),
  computed: {
    ...mapState('message', ['content', 'snackBar', 'color', 'location', 'type', 'timeout']),
    timer() {
      return this.type === 'undo'
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped></style>
