<template>
  <div>
    <button @click="handleClick" class="custom-button">{{ text }}</button>
    <AoiCreateOptionDialog ref="optionDialog" @fetchAOI="fetchAOI" @startDraw="startDraw" />
  </div>
</template>

<script>
import AoiCreateOptionDialog from './clone-components/AoiCreateOptionDialog.vue'
import {
  CHAT_TYPE_AOI,
  DRAW_MAP,
  CANCEL_DRAW_MAP,
  CHAT_TYPE_CREATE_AOI,
  ADD_LAYER_TO_MAP,
  CHAT_TYPE_ONLY_CREATE_AOI,
  VIEW_RESULT,
  GO_TO_PROJECT,
  CREATE_AOI,
} from '@/constants/chat'
import { mapState } from '@/store/ults'

export default {
  name: 'CustomButton',
  components: {
    AoiCreateOptionDialog,
  },
  props: {
    text: {
      type: String,
      default: 'Button',
    },
    attributesString: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('AOI', ['currentTypeCreateAOI']),
    ...mapState('chat', ['isChating', 'dialogChat']),
    menuServices() {
      return this.$store.getters['notification/menuServices']
    },
    currentCreatedAOI() {
      return this.$store.getters['AOI/currentCreatedAOI']
    },
    messages() {
      return this.$store.getters['chat/messages']
    },
  },
  data() {
    return {
      waitForCheck: false,
      attributes: {},
    }
  },
  mounted() {
    this.attributes = this.parseAttributesString(this.attributesString)
  },
  methods: {
    handleClick() {
      // TODO: dynamic for button click action
      // this.$refs.optionDialog.openDialog()
      switch (this.attributes.action) {
        case CHAT_TYPE_ONLY_CREATE_AOI:
        case CREATE_AOI:
          this.startDraw()
          break
        case GO_TO_PROJECT:
          this.$emit('changeProject', this.attributes.uuid)
          break
        case VIEW_RESULT:
          let nameRoute = this.menuServices[this.attributes.service_id].replace('_', '-')
          this.dialogChat = false
          this.$router.push({
            name: nameRoute,
          })
          break
      }
    },
    fetchAOI() {
      this.$emit('fetchData', { type: CHAT_TYPE_AOI })
    },
    startDraw() {
      this.$emit('handleLayerMap', { type: DRAW_MAP })
      this.$refs.optionDialog.dialog = false
    },
    parseAttributesString(attributesString) {
      const attributesArray = attributesString.split(' ')
      const attributesObject = {}
      attributesArray.forEach(attr => {
        const [key, value] = attr.split('=')
        if (key && value) {
          attributesObject[key] = value.replace(/"/g, '')
        }
      })
      return attributesObject
    },
  },
  watch: {
    isChating(val) {
      this.waitForCheck = val
    },
    currentCreatedAOI: {
      async handler(aoi) {
        if (this.currentTypeCreateAOI === CHAT_TYPE_CREATE_AOI && !this.isChating && !this.waitForCheck) {
          this.waitForCheck = true
          this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'isChating', value: true })
          await this.$store.dispatch('AOI/getListAllAOI', {
            projectId: this.$route.params.id,
          })
          this.$emit('handleLayerMap', {
            type: CANCEL_DRAW_MAP,
          })
          this.$emit('handleLayerMap', {
            data: aoi,
            type: ADD_LAYER_TO_MAP,
          })
          this.$emit('submit', {
            data: aoi,
            type: this.attributes.action === CHAT_TYPE_ONLY_CREATE_AOI ? CHAT_TYPE_ONLY_CREATE_AOI : CHAT_TYPE_AOI,
          })
        }
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.custom-button {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  margin: 4px 0;
  border-radius: 20px;
  background: #736985;
  color: #f1f1f1;
}
</style>
