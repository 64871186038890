<template>
  <v-dialog v-model="dialog" persistent width="550">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="props.titleDialog" @close="dialog = false" />
      <v-card-text>
        <v-col class="d-flex align-center justify-center">
          <v-img :src="deleteImageSource" width="275" height="220" />
        </v-col>
        <div v-if="!props.message" style="text-align: center; width: 100%">
          Are you sure, you want to delete item
          <b style="color: red">{{ currentItem.name }}</b> !
        </div>
        <div v-else style="text-align: center; width: 100%">
          {{ props.message }}
        </div>
        <div class="d-flex pt-5">
          <v-spacer />
          <v-btn color="error" :loading="loading" rounded @click="submitData"> Delete </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'
import DialogHeader from '@/components/DialogHeader.vue'
import { cloneDeep } from 'lodash'

const deleteImageSource = new URL(`@/assets/svg/delete.svg`, import.meta.url).href

const dialog = ref(false)
const currentItem = ref({})
const loading = ref(false)

const props = defineProps({
  titleDialog: { type: String, default: 'Delete' },
  submitDelete: Function,
  message: { type: String, default: '' },
})

const openDialog = item => {
  currentItem.value = cloneDeep(item)
  dialog.value = true
}
const submitData = async () => {
  try {
    loading.value = true
    await props.submitDelete(currentItem.value)
  } catch (e) {
    console.warn('Error when call delete function: ', e)
  } finally {
    loading.value = false
    dialog.value = false
  }
}

watch(dialog, newVal => {
  if (!newVal) currentItem.value = {}
})

defineExpose({
  openDialog,
})
</script>
