import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import preset from './default-preset/preset'

export default createVuetify({
  ...preset,
  icons: {
    iconfont: 'mdi',
  },
})
