<template>
  <v-row @click="showDetail" @mouseover="showActions = true" @mouseleave="showActions = false">
    <v-col cols="12" class="pl-5">
      <div class="title-notification">
        <span class="reference_type" :style="reference_type[item.reference_type].styles">
          {{ reference_type[item.reference_type].name }}
        </span>
        <span class="project ml-4" v-if="Object.prototype.hasOwnProperty.call(item.reference_data, 'project')">
          Project: {{ item.reference_data.project.name }}
        </span>
      </div>
      <div class="description-notification" v-html="item.description"></div>
      <div class="time d-flex align-center">
        <v-icon size="small">mdi-clock-outline</v-icon>
        <span class="ml-2">{{ item.created_at }}</span>
      </div>
    </v-col>
    <div class="alert-icon">
      <v-icon v-if="item.is_alert" class="alert">mdi-alert-outline</v-icon>
    </div>
    <div v-if="showActions" class="actions d-flex justify-space-around">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn
            class="clickable"
            size="x-small"
            variant="text"
            icon="mdi-send-outline"
            v-bind="props"
            @click.stop="showDetail"
          ></v-btn>
        </template>
        <span>Show detail</span>
      </v-tooltip>

      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn
            class="clickable"
            size="x-small"
            variant="text"
            :icon="!item.read ? 'mdi-circle-outline' : 'mdi-check-circle-outline'"
            v-bind="props"
            @click.stop="markAsRead"
          ></v-btn>
        </template>
        <span>{{ item.read ? 'Mark as unread' : 'Mark as read' }}</span>
      </v-tooltip>

      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn
            class="clickable"
            size="x-small"
            variant="text"
            icon="icon-trash"
            v-bind="props"
            @click.stop="deleteNotification"
          ></v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </div>
  </v-row>
</template>
<script>
import {
  REFERENCE_FOREST_FIRE,
  REFERENCE_EARTHQUAKE,
  REFERENCE_TASKING_ORDER,
  REFERENCE_ORDER,
  REFERENCE_RESULT,
  REFERENCE_SYSTEM,
  REFERENCE_TASK,
  REFERENCE_ASSIGNMENT,
  REFERENCE_TYPE,
  STATUS_TYPE,
  REFERENCE_ASSIGNMENT_WORK_UPDATE,
  REFERENCE_ASSIGNMENT_RECORD_APPROVED,
} from '@/constants/notification'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    menuServices() {
      return this.$store.getters['notification/menuServices']
    },
  },
  data: () => {
    return {
      status_type: STATUS_TYPE,
      reference_type: REFERENCE_TYPE,
      showActions: false,
    }
  },
  methods: {
    async showDetail() {
      let nameRoute = ''
      switch (this.item.reference_type) {
        case REFERENCE_RESULT:
          nameRoute = this.menuServices[this.item.reference_data.service.id].replace('_', '-')
          break
        case REFERENCE_FOREST_FIRE:
          nameRoute = 'forest-fire'
          break
        case REFERENCE_EARTHQUAKE:
          nameRoute = 'earthquake'
          break
        case REFERENCE_TASKING_ORDER:
          nameRoute = 'tasking-orders'
          break
        case REFERENCE_ORDER:
          nameRoute = 'orders'
          break
        case REFERENCE_TASK:
          nameRoute = 'admin-order'
          break
        case REFERENCE_ASSIGNMENT:
          // if (this.item.reference_data.key == REFERENCE_ASSIGNMENT_WORK_UPDATE) nameRoute = 'record-form'
          // else if (this.item.reference_data.key !== REFERENCE_ASSIGNMENT_RECORD_APPROVED) nameRoute = 'work-management'
          nameRoute = 'work-management'
          break
        default:
          this.markAsRead()
          return
      }
      if (!nameRoute) return
      const currentRouter = this.$router.name
      if (Object.keys(this.item.reference_data).length > 0) {
        const noti = await this.$store.dispatch('notification/getNotification', this.item.uuid)
        this.$store.commit('service/SET_PROPERTY_VALUE', {
          property: 'defaultData',
          value: {
            ...noti.reference_data.order,
            result: noti.reference_data.result,
            name: nameRoute,
            aoi: noti.reference_data.aoi,
            service: noti.reference_data.service,
          },
        })
        if (currentRouter === nameRoute) {
          this.$router.replace({
            name: nameRoute,
            params: { id: this.item.reference_data?.project?.uuid },
            query: { notification: true, noti: this.item.uuid },
          })
        } else {
          this.$router.push({
            name: nameRoute,
            params: { id: this.item.reference_data?.project?.uuid },
            query: { notification: true, noti: this.item.uuid, work: this.item.reference_data.work?.id },
          })
        }
        this.$emit('close')
      } else {
        console.warn('Notification has no project')
        this.$store.commit('message/SHOW_ERROR', 'No result for show detail')
      }
      if (!this.item.read) this.$store.dispatch('notification/markAsRead', this.item.uuid)
    },
    markAsRead() {
      this.$store.commit('notification/SET_STATE_PROPERTY', {
        property: 'isOpenChildElement',
        value: true,
      })
      if (this.item.read) {
        this.$store.dispatch('notification/markAsUnread', this.item.uuid)
      } else {
        this.$store.dispatch('notification/markAsRead', this.item.uuid)
      }
    },
    deleteNotification() {
      this.$store.commit('notification/SET_STATE_PROPERTY', {
        property: 'isOpenChildElement',
        value: true,
      })
      this.$store.dispatch('notification/deleteNotification', this.item.uuid)
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.alert.v-theme--dark.v-icon) {
  color: #db1212 !important;
}

.reference_type {
  font-size: 14px;
  border-radius: 6px;
  padding: 0 6px;
}

.actions {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.title-notification {
  font-size: 16px !important;
  margin-bottom: 6px;
}

.description-notification {
  color: #b9b9b9;
}

.time {
  color: #b9b9b9;
  font-size: 12px !important;
  margin-top: 5px;
}

.alert-icon {
  position: absolute;
  right: 10px;
  top: 4px;
}
</style>
