<template>
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <div class="container-icon-alert" @click="toggleNotification" :style="styleIcon" v-bind="props">
        <v-btn
          icon="icon-notification"
          variant="text"
          :readonly="readonly"
          :disabled="readonly && unreadNotificationsCount === 0"
          :class="unreadNotificationsCount ? 'has-notification' : ''"
        />
        <span class="unread-notifications" v-if="unreadNotificationsCount">{{ unreadNotificationsCount }}</span>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: 'Notifications',
    },
    unreadNotificationsCount: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styleIcon: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    toggleNotification() {
      this.$emit('toggleNotification')
    },
  },
}
</script>
<style lang="scss" scoped>
.has-notification {
  animation: ring-bell 1s infinite;
}
.container-icon-alert {
  position: relative;

  .unread-notifications {
    --size: 13px;
    position: absolute;
    top: 0;
    right: 4px;
    background-color: #e50707;
    color: white;
    height: var(--size);
    line-height: var(--size);
    border-radius: 3px;
    font-size: var(--size);
    text-align: center;
    padding: 0 2px;
  }
}
</style>
