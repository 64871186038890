<template>
  <div>
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <div style="z-index: 2; border: 2px #66808d double; border-radius: 8px">
          <v-btn
            class="elevation-0"
            :color="color"
            height="32"
            min-height="0"
            min-width="0"
            v-bind="props"
            width="32"
            size="small"
            @click="startTour()"
          >
            <v-icon :color="iconColor">mdi-book-open-variant</v-icon>
          </v-btn>
        </div>
      </template>
      <span>Guide</span>
    </v-tooltip>
  </div>
</template>

<script>
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'
import { getOrders } from '@/api/order'

export default {
  name: 'TourGuide',
  props: {
    elements: {
      type: Array,
      required: true,
    },
    isOnScreenDialog: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'bgContainer',
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      orders: [],
      tour: null,
    }
  },
  computed: {
    isCompletedOrder() {
      return this.orders.filter(order => order.status === 'completed').length > 0
    },
  },
  async mounted() {
    await this.getAllOrder()

    let isGuide = localStorage.getItem(`${this.$route.name}_guide`)

    this.$nextTick(() => {
      if (isGuide !== 'false') this.startTour()
    })
  },
  beforeDestroy() {
    this.cancelTour()
  },
  methods: {
    async getAllOrder() {
      try {
        let res = await getOrders({
          projectId: this.$route.params.id,
          payload: { per_page: 'all', service_name: this.$route.meta.service },
        })
        this.orders = res.data
      } catch (e) {
        console.log(e)
      }
    },
    completeTour() {
      if (this.tour) {
        this.tour.complete()
      }
    },
    continue() {
      if (this.tour) this.tour.next()
    },
    cancelTour() {
      if (this.tour) this.tour.cancel()
    },
    startTour() {
      this.tour = new Shepherd.Tour({
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          classes: 'shepherd-theme-custom',
        },
        useModalOverlay: true,
      })

      this.elements.forEach((element, index) => {
        let buttons = []
        if (!element.isHideContinueBtn) {
          buttons.push({
            text: index === this.elements.length - 1 ? 'Finish' : 'Continue',
            action:
              index === this.elements.length - 1
                ? () => {
                    this.tour.complete()
                    if (!this.isOnScreenDialog) localStorage.setItem(`${this.$route.name}_guide`, false)
                  }
                : this.tour.next,
          })
        }
        if (index > 0 && !element.isHideBackBtn) {
          buttons.unshift({
            text: 'Back',
            action: this.tour.back,
            disabled: index === 0,
          })
        }
        if (element.buttons) {
          buttons = buttons.concat(
            element.buttons.map(btn => ({
              text: btn.text,
              action: () => {
                btn.action()
                if (btn.completeTour) {
                  this.tour.complete()
                }
              },
            })),
          )
        }
        let offsetClass
        switch (element.position) {
          case 'top':
            offsetClass = 'offset-top'
            break
          case 'bottom':
            offsetClass = 'offset-bottom'
            break
          case 'left':
            offsetClass = 'offset-left'
            break
          case 'right':
            offsetClass = 'offset-right'
            break
          default:
            offsetClass = ''
        }

        this.tour.addStep({
          id: element.id,
          title: element.title,
          text: element.text,
          attachTo: {
            element: element.ref,
            on: element.position,
            offset: '50px',
          },
          buttons: [...buttons],
          classes: `shepherd-theme-custom ${offsetClass}`,
        })
      })
      this.tour.start()
    },
  },
}
</script>
<style>
.offset-top.shepherd-element {
  transform: translateY(-20px);
}

/* Offset cho vị trí phía dưới */
.offset-bottom.shepherd-element {
  transform: translateY(20px);
}

/* Offset cho vị trí bên trái */
.offset-left.shepherd-element {
  transform: translateX(-20px);
}

/* Offset cho vị trí bên phải */
.offset-right.shepherd-element {
  transform: translateX(20px);
}
</style>
