export default {
  namespaced: true,
  state: {
    tabs: [
      {
        closAble: false,
        icon: 'mdi-view-dashboard-outline',
        name: 'All field',
        id: undefined,
      },
    ],
    currentRouter: undefined,
  },

  mutations: {
    UPDATE_FIELD(state, newValue) {
      state.currentRouter = newValue
    },
    UPDATE_TAB(state, newValue) {
      let currentTab = state.tabs.find(val => val.id === newValue.oldId)
      delete newValue.oldId
      currentTab = newValue
    },
    REMOVE_TAB(state, id) {
      let index = state.tabs.findIndex(val => val.id === id)
      state.tabs.splice(index, 1)
    },
    ADD_TAB(state, tab) {
      state.tabs.push(tab)
    },
  },
}
