const getRandomColor = {
  getColor(index) {
    var colors = [
      '#ff4c4c',
      '#c49b9b',
      '#f59275',
      '#c20fde',
      '#02face',
      '#839dab',
      '#004769',
      '#FF6D00',
      '#fdaa43',
      '#eddf42',
      '#c8a700',
      '#cada61',
      '#777101',
      '#806a6a',
      '#827717',
      '#9E9D24',
      '#59c2fd',
      '#b3f8ff',
      '#00BFA5',
      '#1DE9B6',
      '#01579B',
      '#0277BD',
      '#6200EA',
      '#651FFF',
      '#304FFE',
      '#9baaf8',
      '#8fad00',
      '#283593',
      '#311B92',
      '#4527A0',
      '#0D47A1',
      '#2962FF',
      '#1565C0',
      '#1976D2',
      '#B71C1C',
      '#c75eff',
      '#6A1B9A',
      '#D32F2F',
      '#C2185B',
      '#7B1FA2',
      '#303F9F',
      '#512DA8',
      '#1976D2',
      '#fdd835',
      '#ffa000',
      '#795548',
    ]
    return colors[index]
  },
  getDarkColor(hex, amt = -40) {
    let col = hex.replace('#', '')
    var num = parseInt(col, 16)
    var r = (num >> 16) + amt
    if (r > 255) r = 255
    else if (r < 0) r = 0
    var b = ((num >> 8) & 0x00ff) + amt
    if (b > 255) b = 255
    else if (b < 0) b = 0
    var g = (num & 0x0000ff) + amt
    if (g > 255) g = 255
    else if (g < 0) g = 0
    return '#' + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
  },
}
export default getRandomColor
