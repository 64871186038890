import Store from '@/store'
import { CHAT_REQUEST_AOI, CHAT_REGEX_UUID } from '@/constants/chat'

// Define a function to get the AOI value from the store
function getAOIValue(uuid) {
  const listAOIs = Store.getters['AOI/allAOIs']
  return listAOIs.find(aoi => aoi.uuid === uuid)?.name || `Unknown`
}

export const rulesUuid = [
  // Custom chat
  {
    regex: /\b(?:id|uuid!=)\b/gi,
    replacement: '',
  },
  {
    regex: new RegExp(`${CHAT_REQUEST_AOI} (${CHAT_REGEX_UUID})`, 'g'),
    replacement: (match, uuid) => {
      return `${CHAT_REQUEST_AOI} ***${getAOIValue(uuid)}***`
    },
  },
  {
    regex: new RegExp(`(?<!\=)(${CHAT_REGEX_UUID})`, 'g'),
    replacement: (match, uuid) => {
      return `***${getAOIValue(uuid)}***`
    },
  },
]

// Define other rules
export const rules = [
  // Custom component
  {
    regex: /<p-button\s+([^>]+)>([^<]*?)<\/p-button>/g,
    replacement:
      '<div class="dynamic-component-placeholder" data-component="CustomButton" data-text="$2" data-attributes="$1"></div>',
  },
  {
    regex: /<p-link url="([^"]+)">([^"]+)<\/p-link>/g,
    replacement:
      '<div class="dynamic-component-placeholder" data-component="CustomLink" data-url="$1" data-text="$2"></div>',
  },
  {
    regex: /<p-select type="([^"]+)"><\/p-select>/g,
    replacement: '<div class="dynamic-component-placeholder" data-component="CustomSelect" data-type="$1"></div>',
  },

  // Headers
  { regex: /#### (.+)\n/g, replacement: '<h4>$1</h4>' },
  { regex: /### (.+)\n/g, replacement: '<h3>$1</h3>' },

  // List
  // - Unorder list
  {
    regex: /(\n- +?)(?=\n\n)/gs,
    replacement: function (match) {
      const items = match
        .trim()
        .split('\n')
        .map(item => {
          return item.replace(/^(\d+)\. (.+)$/, '<li>$2</li>')
        })
        .join('')
      return `<ul>${items}</ul>`
    },
  },
  // - Ordered list
  {
    regex: /(\n\d+\..+?)(?=\n\n(?!\d+))/gs,
    replacement: function (match) {
      const items = match
        .trim()
        .split('\n')
        .map(item => {
          return item.replace(/^(\d+)\. (.+)$/, '<li>$2</li>')
        })
        .join('')
      return `<ol>${items}</ol>`
    },
  },

  // Horizontal
  { regex: /---/gm, replacement: '<hr>' },

  // Break line
  { regex: /<\/ol>\n\n/g, replacement: `</ol>` },
  { regex: /\n/g, replacement: `<br>` },
]
export const rulesText = [
  // Text format
  { regex: /\*\*\*(.+?)\*\*\*/gm, replacement: '<strong><em>$1</em></strong>' },
  { regex: /\*\*(.+?)\*\*/gm, replacement: '<strong>$1</strong>' },
  { regex: /\*(.+?)\*/gm, replacement: '<em>$1</em>' },
  { regex: /__(.+?)__/gm, replacement: '<u>$1</u>' },
]
