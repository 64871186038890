<template>
  <v-card class="custom-card-bg-0" column>
    <v-autocomplete
      v-model="country"
      :items="countries"
      class="pt-2"
      clearable
      density="compact"
      hide-details
      item-title="name"
      item-value="code"
      label="Country"
      variant="outlined"
      return-object
      @update:modelValue="changeCountry(country)"
      :loading="countryLoading"
    />
    <v-autocomplete
      v-model="state"
      :items="states"
      class="pt-2"
      clearable
      density="compact"
      hide-details
      item-title="name"
      item-value="code"
      label="State/Province"
      variant="outlined"
      return-object
      @update:modelValue="changeState(state)"
      :loading="stateLoading"
    />
    <v-autocomplete
      v-model="district"
      :items="districts"
      :multiple="multiple"
      class="pt-2"
      clearable
      density="compact"
      hide-details
      item-title="name"
      item-value="code"
      label="District"
      variant="outlined"
      return-object
      @update:modelValue="changeDistrict(district)"
      :loading="districtLoading"
    />
    <v-autocomplete
      v-model="subDistrict"
      :items="sub_districts"
      class="pt-2"
      density="compact"
      hide-details
      item-title="name"
      item-value="code"
      label="Sub District"
      variant="outlined"
      return-object
      @update:modelValue="changeSubDistrict(subDistrict)"
      :loading="subDistrictLoading"
    />
  </v-card>
</template>

<script>
import { mapState } from '@/store/ults'
import { getTerritoryDetail } from '@/api/administrator-boundary-api'
export default {
  name: 'AdministratorBoundary',
  data() {
    return {
      countryLoading: false,
      stateLoading: false,
      districtLoading: false,
      subDistrictLoading: false,
      country: undefined,
      state: undefined,
      district: undefined,
      subDistrict: undefined,
    }
  },
  computed: {
    ...mapState('boundary', ['countries', 'states', 'districts', 'sub_districts']),
  },
  mounted() {
    this.getCountries()
  },
  props: {
    multiple: { type: Boolean, default: false },
  },
  methods: {
    reset() {
      this.country = undefined
      this.province = undefined
      this.subDistrict = undefined
    },
    async getTerritoryList(data) {
      await this.$store.dispatch('boundary/getTerritoryList', data)
    },
    async getTerritoryDetail(data) {
      const res = await getTerritoryDetail(data)
      return res.data
    },
    async getCountries() {
      if (this.countries.length === 0) {
        try {
          this.countryLoading = true
          await this.getTerritoryList({ type: 'countries' })
        } catch (e) {
          console.log(e)
        } finally {
          this.countryLoading = false
        }
      }
    },
    async changeCountry(country) {
      try {
        this.stateLoading = true
        this.state = undefined
        this.states = []
        this.districts = []
        this.district = undefined
        this.sub_districts = []
        this.subDistrict = undefined
        await this.getTerritoryList({ type: 'states', payload: { country: country.code } })
        this.$emit('geometry', { data: [] })
      } catch (e) {
        console.log(e)
      } finally {
        this.stateLoading = false
      }
    },
    async changeState(state) {
      try {
        this.districtLoading = true
        this.district = undefined
        this.districts = []
        this.sub_districts = []
        this.subDistrict = undefined
        if (state) {
          const stateDetail = await this.getTerritoryDetail({
            type: 'state',
            code: state.code,
          })
          this.$emit('geometry', { data: [stateDetail.geojson] })
          await this.getTerritoryList({ type: 'districts', payload: { state: state.code } })
        } else {
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.districtLoading = false
      }
    },
    async changeDistrict(district) {
      try {
        this.subDistrictLoading = true
        this.sub_districts = []
        this.subDistrict = undefined
        if (district) {
          const districtDetail = await this.getTerritoryDetail({
            type: 'district',
            code: district.code,
          })
          this.$emit('geometry', { data: [districtDetail.geojson] })
          await this.getTerritoryList({
            type: 'sub_districts',
            payload: { district: district.code },
          })
        } else {
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.subDistrictLoading = false
      }
    },
    async changeSubDistrict(subDistrict) {
      try {
        this.subDistrictLoading = true
        if (subDistrict) {
          const subDistrictDetail = await this.getTerritoryDetail({
            type: 'sub_district',
            code: subDistrict.code,
          })
          this.$emit('geometry', { data: [subDistrictDetail.geojson] })
        } else {
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.subDistrictLoading = false
      }
    },
  },
}
</script>

<style scoped></style>
