export default {
  namespaced: true,
  state: {
    menu: [],
    group: {
      icon: 'mdi-view-dashboard-outline',
      label: 'DashBoard',
      path: '/app',
      id: 'dashboard',
    },
    currentMenu: {
      icon: 'mdi-map',
      type: 'menu',
      name: '/map',
      id: 'map',
    },
    mini: false,
    isDrawerOpen: null,
  },

  mutations: {
    SET_MENU(state, newValue) {
      state.menu = [{ name: 'Home', code: 'home', icon: 'mdi-home' }, ...newValue]
    },
  },
}
