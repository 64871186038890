import api from '@/api/api'
import config from '@/config'

export async function getServices() {
  return await api.get(`${config.backend}/services`)
}

export async function getAvailableServices(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/services`)
}
export async function validateService(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/services/${data.serviceId}/validates`,
  )
}
