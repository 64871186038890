<template>
  <div class="list-notifications">
    <v-tabs-window v-model="currentTab">
      <TypeNotification
        :tab="currentTab"
        :listNotifications="listNotifications"
        noContent="No notifications found."
        @close="close"
      />
      <TypeNotification
        :tab="currentTab"
        :listNotifications="listUnreadNotifications"
        noContent="All notifications already read."
        @close="close"
      />
      <TypeNotification
        :tab="currentTab"
        :listNotifications="listAlertNotifications"
        noContent="No alerts found."
        @close="close"
      />
    </v-tabs-window>
  </div>
</template>
<script>
import TypeNotification from './type-notification.vue'

export default {
  components: {
    TypeNotification,
  },
  props: {
    tab: {
      type: Number,
      default: null,
    },
  },
  computed: {
    listNotifications() {
      return this.$store.getters['notification/listNotifications']
    },
    listUnreadNotifications() {
      return this.$store.getters['notification/listUnreadNotifications']
    },
    listAlertNotifications() {
      return this.$store.getters['notification/listAlertNotifications']
    },
    currentTab: {
      get() {
        return this.tab
      },
      set(value) {
        this.$emit('update:tab', value)
      },
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.list-notifications {
  background-color: transparent;
  max-height: calc(100vh - 235px);
  overflow-y: auto;
}
</style>
