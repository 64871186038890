const mapboxBgImageSource = new URL('@/assets/images/map/mapbox-bg.png', import.meta.url).href

export default {
  namespaced: true,
  state: {
    base64Image: undefined,
    savedLayers: [],
    compare: false,
    currentMap: undefined,
    legends: [],
    currentLayerId: undefined,
    currentBaseMap: {
      avatar: mapboxBgImageSource,
      name: 'Mapbox Satellite',
      id: 'mapbox-satellite',
    },
  },

  mutations: {},
}
