<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card :loading="loading">
      <DialogHeader :is-close="true" :title="'Download'" @close="dialog = false" />
      <v-card-text>
        <v-select
          v-model="format"
          :items="formats"
          density="compact"
          hide-details
          label="Format"
          variant="outlined"
          placeholder="Format"
        ></v-select>
        <v-autocomplete
          v-model="crs"
          :items="projections"
          class="mt-3"
          density="compact"
          hide-details
          item-title="name"
          item-value="srid"
          label="Projection"
          variant="outlined"
          placeholder="Projection"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" rounded variant="flat" @click="download">Download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { downloadVector } from '@/api/aoi-api'
import config from '@/config'
import { mapState } from '@/store/ults'

export default {
  components: { DialogHeader },
  data() {
    return {
      crs: 4326,
      dialog: false,
      loading: false,
      format: 'geojson',
      formats: ['kml', 'kmz', 'geojson', 'shp', 'gml'],
      layer: undefined,
    }
  },
  computed: {
    ...mapState('service', ['projections']),
  },
  methods: {
    openDialog(layer) {
      if (layer.isRaster) {
        window.open(layer.data.data.download_url, '_blank')
      } else {
        this.layer = layer
        this.dialog = true
      }
    },
    async download() {
      if (this.layer.isGeojson && (!this.layer.data || (this.layer.data && !this.layer.data.data.download_url))) {
        const res = await downloadVector({
          params: {
            geojson: this.layer.geometry,
            crs: this.crs,
            output_type: this.format,
          },
        })
        window.open(`${config.upload_url}/download-temp?path=${res.data}&name=${this.layer.name}`, '_blank')
      } else window.open(`${this.layer.data.data.download_url}&output_type=${this.format}&crs=${this.crs}`, '_blank')
      this.dialog = false
    },
  },
}
</script>
