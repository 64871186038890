<template>
  <v-card-title class="d-flex align-center">
    <v-icon v-if="icon" class="me-2" color="white" size="1.5rem">
      {{ icon }}
    </v-icon>
    <slot name="title">
      <TooltipBase :title="title" />
    </slot>
    <v-spacer />
    <v-btn
      v-if="isClose"
      icon="mdi-window-close"
      size="small"
      variant="text"
      style="margin-right: -6px"
      @click="() => $emit('close')"
    ></v-btn>
  </v-card-title>
</template>

<script>
import TooltipBase from '@/components/TooltipBase.vue'

export default {
  name: 'DialogHeader',
  components: { TooltipBase },
  props: {
    isClose: { type: Boolean, default: true },
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
  },
}
</script>
