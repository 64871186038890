import { cloneDeep } from 'lodash'
import { CLIENT } from '@/constants/chat'
import {
  sendMessage,
  getListConversations,
  deleteConversation,
  getListMessages,
  createConversation,
  updateConversation,
  createDescriptionConversation,
} from '@/api/chat-api'

export default {
  namespaced: true,
  state: {
    messages: [],
    isBottom: true,
    conversations: [],
    currentConversation: null,
    loadingAnswer: false,
    loadingRecentActivity: false,
    currentDataPages: {},
    perPage: 10,
    loadingMore: false,
    isNewMessage: false,
    typingText: false,
    isChating: false,
  },
  getters: {
    conversations: state => state.conversations,
    messages: state => state.messages.toSorted((a, b) => a.chat_time - b.chat_time),
    isBottom: state => state.isBottom,
  },
  mutations: {
    SET_STATE_PROPERTY(state, { property, value }) {
      state[property] = value
    },
    SET_CURRENT_DATA_PAGE(state, { property, dataPage }) {
      const tempData = cloneDeep(dataPage)
      delete tempData.data
      state[property] = tempData
    },
    APPEND_LIST_MESSAGES(state, { property, newMessages }) {
      if (!newMessages.length) return
      let oldMessages = state[property]

      const uniqueValuesMap = new Map()
      oldMessages.forEach(message => {
        uniqueValuesMap.set(message.id, message)
      })
      newMessages.forEach(message => {
        uniqueValuesMap.set(message.id, message)
      })

      state[property] = Array.from(uniqueValuesMap.values())
    },
  },
  actions: {
    // conversation
    async getRecentConversations({ state, commit }, { projectUuid }) {
      state.loadingRecentActivity = true
      const res = await getListConversations({ project_uuid: projectUuid })
      commit('SET_STATE_PROPERTY', { property: 'conversations', value: res.data.data })
      state.loadingRecentActivity = false
    },
    async createNewConversation({ commit, dispatch }, { description, projectUuid }) {
      try {
        const res = await createConversation({ description, project_uuid: projectUuid })
        await commit('SET_STATE_PROPERTY', {
          property: 'currentConversation',
          value: res.data,
        })
        commit('SET_CURRENT_DATA_PAGE', { property: 'currentDataPages', dataPage: {} })
        dispatch('getRecentConversations', { projectUuid })
      } catch (e) {
        console.warn('Error when create new conversation: ', e)
      }
    },
    async updateConversation({ state, commit }, { conversationId, description }) {
      try {
        await updateConversation(conversationId, { description })
        const newConversations = state.conversations.map(conversation => {
          if (conversation.id === conversationId) {
            conversation.description = description
          }
          return conversation
        })
        commit('SET_STATE_PROPERTY', { property: 'conversations', value: newConversations })
      } catch (e) {
        console.warn('Error when edit conversation: ', e)
      }
    },
    async makeNewConversation({ state, commit }) {
      if (state.messages.length !== 0) {
        commit('SET_STATE_PROPERTY', { property: 'currentConversation', value: null })
        commit('SET_STATE_PROPERTY', { property: 'messages', value: [] })
      }
    },
    async deleteConversation({ state, commit, dispatch }, conversationId) {
      try {
        if (state.currentConversation && conversationId === state.currentConversation?.id) {
          commit('SET_STATE_PROPERTY', { property: 'currentConversation', value: null })
          commit('SET_STATE_PROPERTY', { property: 'messages', value: [] })
        }
        await deleteConversation(conversationId)
        state.conversations = state.conversations.filter(conversation => conversation.id !== conversationId)
        await dispatch('getRecentConversations')
      } catch (e) {
        console.warn('Error when delete conversation: ', e)
      }
    },
    // message
    async getListMessages({ state, commit }, { conversationId, params = '' }) {
      params = {
        per_page: state.perPage,
        ...params,
      }
      const res = await getListMessages(conversationId, params)
      commit('SET_STATE_PROPERTY', { property: 'messages', value: res.data.data.toReversed() })
      commit('SET_CURRENT_DATA_PAGE', { property: 'currentDataPages', dataPage: res.data })
    },
    async getMoreMessages({ state, commit }) {
      if (state.loadingAnswer || state.typingText) return
      commit('SET_STATE_PROPERTY', { property: 'loadingMore', value: true })
      const params = {
        page: state.currentDataPages?.current_page + 1 || 1,
        per_page: 3,
      }

      const result = await getListMessages(state.currentConversation.id, params)
      commit('APPEND_LIST_MESSAGES', {
        property: 'messages',
        newMessages: result.data.data.toReversed(),
      })
      commit('SET_CURRENT_DATA_PAGE', { property: 'currentDataPages', dataPage: result.data })
      return result.data.data
    },
    updateMessage({ state, commit }, { message, index }) {
      const messages = state.messages
      messages[index] = message
      commit('SET_STATE_PROPERTY', { property: 'messages', value: messages })
    },
    addNewMessage({ state, commit }, message) {
      const messages = state.messages
      messages.push(message)
      commit('SET_STATE_PROPERTY', { property: 'messages', value: messages })
    },
    async sendMessage({ state, commit, dispatch }, { message, projectUuid }) {
      try {
        state.loadingAnswer = true
        const newMessage = {
          role: CLIENT,
          content: message,
        }
        dispatch('addNewMessage', newMessage)
        if (!state.currentConversation) {
          const res = await createDescriptionConversation({ message })
          await dispatch('createNewConversation', { description: res.data.title, projectUuid })
        }
        const payload = {
          content: message,
          timezone: 0 - new Date().getTimezoneOffset(),
        }
        const res = await sendMessage(state.currentConversation.id, payload)
        commit('SET_STATE_PROPERTY', { property: 'isNewMessage', value: true })
        dispatch('updateMessage', {
          message: res.data.question,
          index: state.messages.length - 1,
        })
        dispatch('addNewMessage', res.data.answer)
      } catch (e) {
        console.warn('Error when send message: ', e)
      } finally {
        state.loadingAnswer = false
      }
    },
  },
}
