import MapboxDraw from '@mapbox/mapbox-gl-draw'
import circle from '@turf/circle'

const { modes, constants } = MapboxDraw

const CircleMode = { ...modes.draw_polygon }
const DEFAULT_RADIUS_IN_KM = 0.003

CircleMode.onSetup = function (opts) {
  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: [],
    },
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  })

  this.addFeature(polygon)

  this.clearSelectedFeatures()
  this.map.doubleClickZoom.disable()
  this.updateUIClasses({ mouse: constants.cursors.ADD })
  this.activateUIButton(constants.types.POLYGON)
  this.setActionableState({
    trash: true,
  })

  return {
    initialRadiusInKm: opts.initialRadiusInKm || DEFAULT_RADIUS_IN_KM,
    polygon,
    currentVertexPosition: 0,
  }
}

CircleMode.clickAnywhere = function (state, e) {
  if (state.currentVertexPosition === 0) {
    state.currentVertexPosition++
    const center = [e.lngLat.lng, e.lngLat.lat]
    const circleFeature = circle(center, state.initialRadiusInKm)
    state.polygon.incomingCoords(circleFeature.geometry.coordinates)
    state.polygon.properties.center = center
    state.polygon.properties.radiusInKm = state.initialRadiusInKm
  }
  return this.changeMode(constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] })
}

export default CircleMode
