import { bearing, bezierSpline, center, length, lineOffset, lineString, polygon } from '@turf/turf'

import { Arrow, createArrowHead } from './Arrow'

export function ArrowDoubleDraw(_draw, _feature = {}) {
  let parent = new Arrow(_draw, { done: createArrow, build: createBuildArrow }, _feature)

  parent.start = () => {
    _draw.start({ max_point: 6, min_point: 6 })
  }
  parent.draw_type = 'ArrowDoubleDraw'
  return parent
}

function createBuildArrow(coordinates) {
  if (!coordinates || coordinates.length < 3) {
    return []
  }
  let res = [lineString([coordinates[0], coordinates[1]])]
  if (coordinates.length === 3) {
    res.push(lineString([coordinates[1], coordinates[2]]))
    return res
  }
  let {
    _head_arrow_coordinates: _right_head_arrow_point,
    _wing_1_coordinates: _right_wing_point_1,
    _wing_2_coordinates: _right_wing_point_2,
  } = createArrowHead(
    coordinates[3],
    bearing(coordinates[2], coordinates[3]),
    length(lineString([coordinates[2], coordinates[3]])),
  )
  res = res.concat([
    lineString([coordinates[1], coordinates[2], coordinates[3]]),
    lineString([_right_wing_point_1, _right_head_arrow_point, _right_wing_point_2]),
  ])
  if (coordinates.length === 4) {
    return res
  }
  if (coordinates.length === 5) {
    res.push(lineString([coordinates[0], coordinates[4]]))
    return res
  }
  let {
    _head_arrow_coordinates: _left_head_arrow_point,
    _wing_1_coordinates: _left_wing_point_1,
    _wing_2_coordinates: _left_wing_point_2,
  } = createArrowHead(
    coordinates[5],
    bearing(coordinates[4], coordinates[5]),
    length(lineString([coordinates[4], coordinates[5]])),
  )
  res = res.concat([
    lineString([coordinates[0], coordinates[4], coordinates[5]]),
    lineString([_left_wing_point_1, _left_head_arrow_point, _left_wing_point_2]),
  ])
  return res
}

function createArrow(coordinates) {
  if (!coordinates || coordinates.length < 6) {
    return []
  }

  let _bottom_line = lineString([coordinates[0], coordinates[1]])
  let _bottom_len = length(_bottom_line)

  let _bottom_offset = lineOffset(_bottom_line, -_bottom_len / 3)
  let _bottom_move_point = center(_bottom_offset).geometry.coordinates

  let _offset_len = _bottom_len / 5

  let _right_line = lineString([coordinates[1], coordinates[2], coordinates[3]])
  let _right_line_offset_1 = lineOffset(_right_line, _offset_len)
  let _right_line_offset_2 = lineOffset(_right_line, -_offset_len)

  let {
    _head_arrow_coordinates: _right_head_arrow_point,
    _wing_1_coordinates: _right_wing_point_1,
    _wing_2_coordinates: _right_wing_point_2,
  } = createArrowHead(
    coordinates[3],
    bearing(coordinates[2], coordinates[3]),
    length(lineString([coordinates[2], coordinates[3]])),
  )

  let _left_line = lineString([coordinates[0], coordinates[4], coordinates[5]])
  let _left_line_offset_1 = lineOffset(_left_line, _offset_len)
  let _left_line_offset_2 = lineOffset(_left_line, -_offset_len)

  let {
    _head_arrow_coordinates: _left_head_arrow_point,
    _wing_1_coordinates: _left_wing_point_1,
    _wing_2_coordinates: _left_wing_point_2,
  } = createArrowHead(
    coordinates[5],
    bearing(coordinates[4], coordinates[5]),
    length(lineString([coordinates[4], coordinates[5]])),
  )
  return [
    polygon([
      [
        ...bezierSpline(lineString([coordinates[1], ..._right_line_offset_1.geometry.coordinates.slice(1)])).geometry
          .coordinates,
        _right_wing_point_1,
        _right_head_arrow_point,
        _right_wing_point_2,
        ...bezierSpline(
          lineString([
            ..._right_line_offset_2.geometry.coordinates.slice(1).reverse(),
            _bottom_move_point,
            ..._left_line_offset_1.geometry.coordinates.slice(1),
          ]),
        ).geometry.coordinates,
        _left_wing_point_1,
        _left_head_arrow_point,
        _left_wing_point_2,
        ...bezierSpline(lineString([..._left_line_offset_2.geometry.coordinates.slice(1).reverse(), coordinates[0]]))
          .geometry.coordinates,
        coordinates[1],
      ],
    ]),
  ]
}
