<template>
  <v-tooltip v-if="isTruncated" :location="location">
    <template v-slot:activator="{ props }">
      <div class="text-truncate" :style="styles" ref="textElement" v-bind="props">
        <slot name="truncate">
          {{ title }}
        </slot>
      </div>
    </template>
    <slot name="description">
      <span>{{ title }}</span>
    </slot>
  </v-tooltip>
  <div v-else ref="textElement" :style="styles" class="no-w text-truncate">
    <slot name="truncate">
      {{ title }}
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    triggerChange: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    location: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      isTruncated: false,
    }
  },
  mounted() {
    this.checkTruncation()
  },
  methods: {
    checkTruncation() {
      const element = this.$refs.textElement
      if (element) this.isTruncated = element.scrollWidth > element.clientWidth
    },
  },
  watch: {
    title() {
      this.$nextTick(() => {
        this.checkTruncation()
      })
    },
    triggerChange: {
      handler() {
        this.$nextTick(() => {
          this.checkTruncation()
        })
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.text-truncate {
  width: fit-content;
  //line-height: 20px;
}
.no-w {
  text-wrap: nowrap;
}
</style>
