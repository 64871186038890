import booleanWithin from '@turf/boolean-within'
import Project from '@/store/modules/project'

const withinArea = {
  check(geojson) {
    if (!Project.state.projectArea) return true
    else return booleanWithin(geojson, Project.state.projectArea)
  },
}
export default withinArea
