<template>
  <v-card height="100%" min-width="280" width="100%">
    <v-overlay v-model="loading" class="align-center justify-center" contained>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex flex-column fill-height pa-2">
      <div style="flex: none; width: 100%; height: 40px">
        <div class="d-flex align-center justify-center fill-height">
          <span style="font-weight: 500; font-size: 18px">Layers control</span>
          <v-spacer />
          <v-tooltip v-if="!isDrawing" location="bottom">
            <template v-slot:activator="{ props }">
              <v-btn class="ml-1" color="primary" min-width="0" size="small" width="38" v-bind="props" @click="saveMap">
                <v-icon size="20">mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
            <span>Save all layer to map</span>
          </v-tooltip>
          <v-tooltip v-if="!isDrawing" location="bottom">
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-1"
                color="primary"
                min-width="0"
                width="38"
                size="small"
                v-bind="props"
                :disabled="isDrawing"
                @click="() => ($refs.createLayer.dialog = true)"
              >
                <v-icon size="20">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add annotation</span>
          </v-tooltip>
          <v-tooltip v-if="removable" location="bottom">
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-1"
                color="error"
                min-width="0"
                size="small"
                width="38"
                :disabled="layers.length === 0 || isDrawing"
                v-bind="props"
                @click="() => $refs.deleteDialog.openDialog()"
              >
                <v-icon size="20">icon-trash</v-icon>
              </v-btn>
            </template>
            <span>Remove all layers</span>
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex flex-column fill-height pa-1 overflow-y-auto">
        <draggable
          v-model="layers"
          group="row"
          handle=".handle"
          v-bind="dragOptions"
          @end="moveLayer"
          @start="checkValid"
          :component-data="{ name: !drag ? 'flip-list' : null }"
          item-key="id"
        >
          <template #item="{ element }">
            <div
              class="d-flex elevation-4 my-2 custom-card-bg-2"
              style="min-height: 75px; width: 100%; border-radius: 4px"
              @contextmenu="handleContextMenuItem(element.id, $event)"
            >
              <div
                :style="{ 'background-color': getPreviewColor(element) }"
                class="handle"
                style="
                  width: 16px;
                  min-height: 75px;
                  flex: none;
                  cursor: move;
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                "
              >
                <div class="d-flex align-center justify-center fill-height">
                  <v-icon color="#e9e9e9" size="15">icon-line_space</v-icon>
                </div>
              </div>

              <div class="d-flex flex-column flex-fill justify-center fill-height pa-2" style="width: 0">
                <div class="d-flex align-center justify-space-between mt-1" style="width: 100%">
                  <TooltipBase :title="element.name" :styles="{ width: '90%' }" />
                  <div
                    class="d-flex align-center justify-end fill-height"
                    style="height: 100%; width: fit-content; flex: none"
                  >
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          :style="{
                            visibility: compare || sync || isDraw ? 'hidden' : 'visible',
                          }"
                          height="20"
                          width="20"
                          :icon="element.layerRight ? 'icon-eye' : 'icon-eye_off'"
                          size="small"
                          variant="text"
                          v-bind="props"
                          @click="changeVisibleRightLayer(element)"
                        ></v-btn>
                      </template>
                      <span>{{ element.layerRight ? 'Hide' : 'Visible' }}</span>
                    </v-tooltip>
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          height="20"
                          width="20"
                          icon="icon-gps"
                          size="small"
                          variant="text"
                          v-bind="props"
                          @click="() => $emit('zoomTo', element.bbox)"
                        ></v-btn>
                      </template>
                      <span>Zoom to</span>
                    </v-tooltip>
                    <CustomMenu
                      :sizeIcon="18"
                      :ref="`actionmenu${element.id}`"
                      :items="actions(element)"
                      :icon="'mdi-dots-vertical'"
                      :tip="'Action'"
                      @sort-change="menu => selectAction(menu, element)"
                    />
                  </div>
                </div>
                <div class="d-flex align-center" style="width: 100%">
                  <v-icon color="primary" size="20">
                    {{ element.type === 'raster' ? 'icon-image' : 'icon-layers' }}
                  </v-icon>
                  <v-slider
                    v-show="element.paint[element.typeStyle + '-opacity'] !== undefined"
                    v-model="element.paint[element.typeStyle + '-opacity']"
                    class="custom-slider mr-3"
                    color="primary"
                    hide-details
                    hide-spin-buttons
                    max="1"
                    thumb-label
                    min="0"
                    step="0.1"
                    thumb-size="10"
                    track-size="1"
                    track-color="#83D1FF"
                    density="default"
                    @update:modelValue="updateStyle(element)"
                  ></v-slider>
                  <div class="d-flex align-center" style="height: 100%; flex: none">
                    <v-btn
                      :style="{
                        'background-color': element.layerLeft ? 'rgb(var(--v-theme-primary))' : '',
                        visibility: compare || sync ? 'visible' : 'hidden',
                      }"
                      style="border-radius: 2px"
                      text="L"
                      size="x-small"
                      @click="changeVisibleLeftLayer(element)"
                    ></v-btn>
                    <v-btn
                      :style="{
                        'background-color': element.layerRight ? 'rgb(var(--v-theme-primary))' : '',
                        visibility: compare || sync ? 'visible' : 'hidden',
                      }"
                      class="ml-1"
                      style="border-radius: 2px"
                      text="R"
                      size="x-small"
                      @click="changeVisibleRightLayer(element)"
                    ></v-btn>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="layer-control-footer">
        <div class="d-flex align-center justify-space-between fill-height pa-2">
          <v-switch v-model="switchMapMode" color="#1966f7" hide-details>
            <template #label>
              <span style="font-size: 14px">Compare map</span>
            </template>
          </v-switch>
          <v-checkbox v-if="switchMapMode" v-model="checkboxMapMode" color="#1966f7" label="Sync" hide-details>
            <template #label>
              <span style="font-size: 14px">Sync</span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <CreateLayer
      ref="createLayer"
      @addToMap="data => $emit('addToMap', data)"
      @createLayer="(name, type) => $emit('createLayer', name, type)"
      @setLayer="(name, geojson) => $emit('setLayer', name, geojson)"
    />
    <VectorStyle ref="vectorStyle" @updateStyle="updateStyle" />
    <DownloadVector ref="download" />
    <DeleteDialog
      ref="deleteDialog"
      :submit-delete="removeAllLayers"
      message="All the layers will be deleted. Are you sure you want to delete them?"
    />
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import sleep from '@/utils/sleep'
import CreateLayer from '@/components/layer-control/create-layer/CreateLayer.vue'
import VectorStyle from '@/components/layer-control/vector-style/VectorStyle.vue'
import { mapState } from '@/store/ults'
import DownloadVector from '@/components/DownloadVector.vue'
import { debounce } from 'lodash'
import DeleteDialog from '@/components/DeleteDialog.vue'
import TooltipBase from '@/components/TooltipBase.vue'
import CustomMenu from '@/components/CustomMenu.vue'
import { initDB, saveData } from '@/store/indexedDB/indexedDB'

export default {
  name: 'LayerControl',
  emits: [
    'update:currentLayers',
    'removeAllLayer',
    'createLayer',
    'removeLayer',
    'setLayer',
    'addToMap',
    'reloadMap',
    'zoomTo',
    'updateStyleProperties',
    'changeToSyncMap',
    'changeCompare',
    'changeVisible',
    'changeVisibleLeftLayer',
  ],
  components: {
    TooltipBase,
    DownloadVector,
    VectorStyle,
    CreateLayer,
    draggable,
    DeleteDialog,
    CustomMenu,
  },
  data() {
    return {
      switchMapMode: false,
      checkboxMapMode: false,
      mapMode: 'overlay',
      loading: false,
      compare: false,
      sync: false,
      isDraw: false,
      drag: false,
      currentDrag: undefined,
      actionsList: [
        {
          name: 'Style',

          icon: 'mdi-format-color-fill',
        },
        {
          name: 'Download',

          icon: 'mdi-download',
        },
        {
          name: 'Remove',

          icon: 'mdi-delete',
        },
      ],
    }
  },
  props: {
    isDrawing: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    mapControl: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false },
    removable: { type: Boolean, default: true },
    currentLayers: { type: Array, default: () => [] },
    withoutLayers: { type: Array, default: () => [] },
  },
  watch: {
    switchMapMode: debounce(function (val) {
      this.mapMode = val ? (this.checkboxMapMode ? 'sync' : 'slide') : 'overlay'
    }, 300),
    checkboxMapMode: debounce(function (val) {
      this.mapMode = val ? 'sync' : 'slide'
    }, 300),
    mapMode() {
      this.changeMapDisplay()
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    layers: {
      get() {
        return this.currentLayers.filter(layer => !this.withoutLayers.includes(layer.id))
      },
      set(val) {
        this.$emit('update:currentLayers', val)
      },
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  mounted() {
    initDB(['layersStore'])
  },
  methods: {
    handleContextMenuItem(uuid, event) {
      event.preventDefault()
      const actionMenuRefs = Object.keys(this.$refs).filter(refName => refName.startsWith('actionmenu'))
      for (const refName of actionMenuRefs) {
        if (this.$refs[refName]) this.$refs[refName].closeMenu()
      }
      let actionMenu = 'actionmenu' + uuid
      this.$refs[actionMenu].openMenu()
    },
    selectAction(item, element) {
      switch (item.name) {
        case 'Style':
          this.$refs.vectorStyle.openDialog(element)
          break
        case 'Download':
          this.download(element)
          break
        case 'Remove':
          this.removeLayer(element.id)
          break
      }
    },
    actions(element) {
      return this.actionsList
        .filter(action => {
          switch (action.name) {
            case 'Style':
              return element.isVector || element.isGeojson
            case 'Download':
              return this.checkDownload(element)
            case 'Remove':
              return this.removable
            default:
              return true
          }
        })
        .map(action => ({
          ...action,
          available: true,
        }))
    },
    saveMap() {
      try {
        saveData('layersStore', this.layers, 'savedLayers')
        this.$store.commit('message/SHOW_SUCCESS', 'Save map successfully')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e)
      }
    },
    removeAllLayers() {
      this.$emit('removeAllLayer')
    },
    updateStyle(layer) {
      this.$emit('updateStyleProperties', layer.id)
    },
    checkDownload(layer) {
      return layer.isGeojson || (!!layer.data.data && !!layer.data.data.download_url)
    },
    download(layer) {
      this.$refs.download.openDialog(layer)
    },
    async changeMapDisplay(mapMode = this.mapMode) {
      await sleep(200)
      this.mapMode = mapMode
      switch (mapMode) {
        case 'overlay':
          this.sync = false
          this.compare = false
          this.$emit('changeToSyncMap', false)
          this.$emit('changeCompare', false)
          break
        case 'slide':
          this.sync = false
          this.compare = true
          this.$emit('changeToSyncMap', false)
          this.$emit('changeCompare', true)
          break
        case 'sync':
          this.sync = true
          this.compare = false
          this.$emit('changeToSyncMap', true)
          this.$emit('changeCompare', false)
          break
      }
    },
    getPreviewColor(layer) {
      if (layer.typeStyle && layer.paint[layer.typeStyle + '-color']) {
        if (typeof layer.paint[layer.typeStyle + '-color'] === 'string') {
          return layer.paint[layer.typeStyle + '-color']
        } else {
          return 'rgb(var(--v-theme-primary))'
        }
      } else return 'rgb(var(--v-theme-primary))'
    },
    checkValid(e) {
      this.drag = true
      this.currentDrag = this.layers[e.oldIndex]
    },
    changeVisibleRightLayer(layer) {
      layer.layerRight = !layer.layerRight
      this.$emit('changeVisible', layer)
    },
    changeVisibleLeftLayer(layer) {
      layer.layerLeft = !layer.layerLeft
      this.$emit('changeVisibleLeftLayer', layer, this.sync)
    },
    moveLayer() {
      this.currentDrag = undefined
      this.$emit('reloadMap')
      this.drag = false
    },
    removeLayer(id) {
      this.$emit('removeLayer', id)
      let index = this.layers.findIndex(layer => layer.id === id)
      if (index >= 0) this.layers.splice(index, 1)
    },
  },
}
</script>

<style scoped>
:deep(.v-card) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.layer-control-footer {
  height: 48px;
  width: 100%;
  flex: none;
  border-top: 1px solid #5e5669;
  font-size: 18px;
}
</style>
