export const REFERENCE_FOREST_FIRE = 'forest_fire'
export const REFERENCE_EARTHQUAKE = 'earthquake'
export const REFERENCE_ORDER = 'order'
export const REFERENCE_TASKING_ORDER = 'tasking_order'
export const REFERENCE_RESULT = 'result'
export const REFERENCE_SYSTEM = 'system'
export const REFERENCE_TASK = 'task'
export const REFERENCE_ASSIGNMENT = 'assignment'

export const REFERENCE_ASSIGNMENT_WORK_UPDATE = 'work_update'
export const REFERENCE_ASSIGNMENT_WORK_ASSIGN = 'work_assign'
export const REFERENCE_ASSIGNMENT_RECORD_APPROVED = 'record_approved'


export const REFERENCE_TYPE = {
  [REFERENCE_FOREST_FIRE]: {
    name: 'Forest Fire',
    styles: {
      backgroundColor: '#e56a00',
    },
  },
  [REFERENCE_EARTHQUAKE]: {
    name: 'Earthquake',
    styles: {
      backgroundColor: '#5b431f',
    },
  },
  [REFERENCE_ORDER]: {
    name: 'Order',
    styles: {
      backgroundColor: '#025195',
    },
  },
  [REFERENCE_TASKING_ORDER]: {
    name: 'Tasking Order',
    styles: {
      backgroundColor: '#81196f',
    },
  },
  [REFERENCE_RESULT]: {
    name: 'Result',
    styles: {
      backgroundColor: '#d48b07',
    },
  },
  [REFERENCE_SYSTEM]: {
    name: 'System',
    styles: {
      backgroundColor: '#c3094a',
    },
  },
  [REFERENCE_TASK]: {
    name: 'Task',
    styles: {
      backgroundColor: '#0c20e2',
    },
  },
  [REFERENCE_ASSIGNMENT]: {
    name: 'Assignment',
    styles: {
      backgroundColor: '#702afc',
    },
  },
}

export const STATUS_TYPE = {
  new: {
    name: 'New',
    color: 'created',
    icon: 'mdi-check',
  },
  processing: {
    name: 'Processing',
    color: 'processing',
    icon: 'mdi-autorenew',
  },
  completed: {
    name: 'Completed',
    color: 'success',
    icon: 'mdi-check-bold',
  },
}
