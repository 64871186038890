import randomColor from '@/utils/randomColor'
import RandomColor from '@/utils/randomColor'

const VectorStyle = {
  getStyle(info) {
    let filter = ['case']
    let filterOutline = ['case']

    if (!info.filter) {
      filter = randomColor.getColor(Math.floor(Math.random() * 10))
      filterOutline = randomColor.getColor(Math.floor(Math.random() * 10))
    } else {
      info.filter.data.forEach(val => {
        filter.push(['==', ['get', info.filter.field], val.value], val.color)
        filterOutline.push(['==', ['get', info.filter.field], val.value], RandomColor.getDarkColor(val.color))
      })
      filterOutline.push('#8d6900')
      filter.push('#ffc400')
    }
    switch (info.type) {
      case 'line':
        return {
          'line-color': filter,
          'line-opacity': 1,
          'line-width': 3,
        }
      case 'fill':
        return {
          'fill-color': filter,
          'fill-opacity': 'opacity' in info ? info.opacity : 1,
          'fill-outline-color': filterOutline,
        }
      case 'circle':
        return {
          'circle-color': filter,
          'circle-opacity': 1,
          'circle-radius': 5,
          'circle-stroke-color': filterOutline,
          'circle-stroke-opacity': 1,
          'circle-stroke-width': 2,
        }
      default:
        return randomColor.getColor(Math.floor(Math.random() * 10))
    }
  },
}
export default VectorStyle
