<template>
  <div id="bot-map-view" ref="mapBound">
    <div class="d-flex fill-height">
      <v-overlay :model-value="loading" class="align-center justify-center" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div class="data-sync-map left-map" v-if="dataSyncMap.left && mapSync">
        {{ dataSyncMap.left.customDate }}
      </div>
      <div class="data-sync-map right-map" v-if="dataSyncMap.right && mapSync">
        {{ dataSyncMap.right.customDate }}
      </div>

      <div v-show="mapSync" class="hard-flex" style="border-right: 3px #d5d5d5 solid">
        <div ref="mapSync" class="map-container" @mouseover="mapOnHover('syncMap')">
          <div v-show="isDrawing" class="pt-1" style="z-index: 2; position: absolute; top: 10px; left: 10px">
            <DrawTool @cancelDraw="cancelDraw" :get-all-draw-feature="getAllDrawFeature" />
          </div>
        </div>
      </div>
      <div class="hard-flex" style="position: relative">
        <div id="wrapper" class="map"></div>
        <div
          v-show="isMapView"
          ref="mapBotContainer"
          class="map-container map"
          @mouseleave="deSelect"
          @mouseover="mapOnHover('map')"
        />

        <div ref="beforeMap" class="map-container map" @mouseover="mapOnHover('beforeMap')">
          <div v-show="isDrawing" class="pt-1" style="z-index: 2; position: absolute; top: 10px; left: 10px">
            <DrawTool @cancelDraw="cancelDraw" :get-all-draw-feature="getAllDrawFeature" />
          </div>
        </div>
      </div>
      <div v-if="isCopyRight" class="location-detail elevation-1">
        <div class="d-flex align-center fill-height" style="padding: 2px 4px">
          <div
            class="ml-3 d-flex align-center"
            style="flex: none; height: 100%; min-width: 50px; border-right: 1px solid #7c7c7c"
          >
            <v-icon color="#333333" size="14">mdi-magnify</v-icon>
            {{ zoomLevel }}
          </div>
          <div class="d-flex align-center fill-height pl-2" v-html="currentPoint"></div>
          <v-menu location="top">
            <template #activator="{ props: propsMenu }">
              <v-tooltip location="top">
                <template #activator="{ props: propsTooltip }">
                  <v-btn
                    icon="mdi-cached"
                    variant="text"
                    size="x-small"
                    v-bind="mergeProps(propsMenu, propsTooltip)"
                  ></v-btn>
                </template>
                <span>Transform</span>
              </v-tooltip>
            </template>

            <v-list density="compact">
              <v-list-item
                v-for="transformCoordinate in transformCoordinateMenu"
                :key="transformCoordinate"
                :value="transformCoordinate.value"
                @click="changePixelValue(transformCoordinate.value)"
              >
                <v-list-item-title style="font-size: 0.75rem !important">{{
                  transformCoordinate.text
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div
      v-if="!isMiniMap"
      style="
        position: absolute;
        bottom: 55px;
        right: 85px;
        z-index: 900;
        border: 2px #66808d double;
        border-radius: 8px;
        background-color: #e3e3e3;
      "
    >
      <DefaultLayers @changeDisplayLayer="changeDisplayLayer" />
    </div>
    <v-menu v-if="!isPreview && !isMiniMap" top>
      <template v-slot:activator="{ props }">
        <div class="d-flex base-layer elevation-2" style="cursor: pointer" title="Base map" v-bind="props">
          <img :src="currentBaseMap.avatar" alt="avatar" height="60" style="border-radius: 8px" width="60" />
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in mapStyles"
          :key="index"
          style="height: 55px"
          @click="currentBaseMap = item"
        >
          <div class="d-flex align-center">
            <div style="width: 49px; height: 48px; border: 2px solid lightslategray; border-radius: 10px">
              <img :src="item.avatar" alt="avatar" height="45" style="border-radius: 8px" width="45" />
            </div>
            <h5 class="ml-3" style="width: 130px">{{ item.name }}</h5>
          </div>
        </v-list-item>
        <v-list-subheader>
          <v-checkbox v-model="isLabel" density="compact" hide-details label="Show the label"></v-checkbox>
        </v-list-subheader>
      </v-list>
    </v-menu>
    <!-- drawing area -->
    <div v-show="isDrawing" style="z-index: 2; position: absolute; top: 10px; left: 10px">
      <DrawTool
        ref="drawTool"
        @cancelDraw="cancelDraw"
        :get-all-draw-feature="getAllDrawFeature"
        :isDrawing="isDrawing"
        :typeCreateAOI="typeCreateAOI"
      />
    </div>
    <v-chip
      v-show="isDrawing"
      :style="styleDrawingMode"
      color="primary"
      variant="flat"
      style="z-index: 2; position: absolute; top: 10px; height: 30px; border-radius: 5px"
    >
      <div class="d-flex align-center fill-height text-truncate" style="max-width: 140px; text-align: center">
        <v-icon class="mr-2">icon-edit_square</v-icon>
        <div class="text-truncate" style="width: 175px; text-align: center">Drawing mode</div>
      </div>
    </v-chip>
    <div v-show="isDrawing" style="z-index: 2; position: absolute; top: 10px; right: 10px">
      <DrawMenu ref="drawMenu" :drawType="drawType" :selectedDrawMode="selectedDrawMode" @changeMode="changeMode" />
    </div>
    <!-- end: drawing area -->
    <div v-if="!isDrawing" class="d-flex flex-column tools-top-right-map-area">
      <SearchLocation @onSearch="submitZoom" @zoomToCenter="zoomToCenter" />
      <v-tooltip v-if="!isMapFullscreen" location="left">
        <template v-slot:activator="{ props }">
          <v-btn
            class="elevation-0 mt-1"
            min-height="0"
            min-width="0"
            v-bind="props"
            @click="handleScreenshot"
            size="small"
            :loading="loadingCapture"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Screenshot</span>
      </v-tooltip>
      <MeasureTool
        v-if="!isPreview"
        ref="measureTool"
        :activeMeasureTool.sync="isOpenMeasurementTool"
        :disabled="isDrawing"
        @activeMeasureTool="activeMeasureTool"
        @resetDraw="resetDraw"
      />
      <v-tooltip v-if="isStaticMode" location="left">
        <template v-slot:activator="{ props }">
          <v-btn
            class="elevation-0 mt-1"
            min-height="0"
            min-width="0"
            v-bind="props"
            @click="removeDrawLayer"
            size="small"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
      <v-tooltip color="#000000c4" location="left">
        <template v-slot:activator="{ props }">
          <v-btn class="elevation-0 mt-1" min-height="0" min-width="0" v-bind="props" @click="fullScreen" size="small">
            <v-icon>{{ isMapFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
          </v-btn>
        </template>
        <span>Full screen</span>
      </v-tooltip>
    </div>
    <v-dialog v-model="propertiesDialog" persistent width="500">
      <v-card class="custom-card-bg-gradient" height="100%" width="100%">
        <DialogHeader :is-close="true" :title="'Create annotation'" @close="propertiesDialog = false" />
        <v-card-text>
          <v-form ref="formData" v-model="valid" @submit.prevent>
            <v-text-field
              v-model="property"
              :rules="[rules.required]"
              density="compact"
              label="Text"
              variant="outlined"
              placeholder="Text"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" rounded @click="saveProperty"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeleteDialog
      ref="deleteDialog"
      :submit-delete="submitDeleteDraw"
      message="All the AOIs you drawn will be deleted. Please save them before draw new one.
Are you sure you want to delete them?"
    />
  </div>
</template>

<script>
import { latDMS, lngDMS } from '@/utils/convertLatLngToDMS'
import { debounce } from 'lodash'
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Compare from 'mapbox-gl-compare'
import sleep from '@/utils/sleep'
import utils from '@/utils/genUUID'
import VectorLayer from '@/models/layer/vector/vector'
import RasterLayer from '@/models/layer/raster/raster'
import { CircleMode, DirectMode, SimpleSelectMode } from '@/utils/draw'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import SearchLocation from '@/components/SearchLocation'
import area from '@turf/area'
import * as turf from '@turf/turf'
import MeasureTool from '@/components/Measurement'
import turfLength from '@turf/length'
import { mapState } from '@/store/ults'
import VectorTilesLayer from '@/models/layer/vector/vector-tiles'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import DrawTool from '@/components/DrawTool.vue'
import { tileMapbox } from '@/api/auth-api'
import 'mapbox-gl/dist/mapbox-gl.css'
import colorFormat from '@/utils/ColorFormat'
import DialogHeader from '@/components/DialogHeader.vue'
import { MarkerDrawControl } from '@/utils/arrow/draw/MarkerDrawControl'
import html2canvas from 'html2canvas'
import DeleteDialog from '@/components/DeleteDialog.vue'
import proj4 from 'proj4'
import proj4List from 'proj4-list'
import ElemFullScreen from '@/utils/ElemFullScreen'
import DefaultLayers from '@/components/DefaultLayers.vue'
import AOIWithin from '@/utils/AOIWithin'
import config from '@/config'
import DrawMenu from '@/components/map/DrawMenu.vue'
import staticMode from '@mapbox/mapbox-gl-draw-static-mode'
import { mergeProps } from 'vue'

const DrawPolygon = MapboxDraw.modes.draw_polygon
const DrawPoint = MapboxDraw.modes.draw_point
const DrawLineString = MapboxDraw.modes.draw_line_string

//
const mapStyleSource = new URL(`@/assets/MapStyle/map-style.json`, import.meta.url).href
// const customDrawStyleSource = new URL(`@/assets/MapStyle/custom-draw-style.json`, import.meta.url).href

const mapboxBgImageSource = new URL(`@/assets/images/map/mapbox-bg.png`, import.meta.url).href
const skymapBaseImageSource = new URL(`@/assets/images/map/skymap-base.png`, import.meta.url).href
const satelliteImageSource = new URL(`@/assets/images/map/satellite.jpg`, import.meta.url).href
const mapStreetImageSource = new URL(`@/assets/images/map/map-street.jpg`, import.meta.url).href

const labelJsonSource = new URL(`@/assets/MapStyle/label.json`, import.meta.url).href

let draw
let popup
let marker
let markers = []
let diffMap
let x

export default {
  name: 'Map',
  emits: [
    'update:isDraw',
    'update:geometry',
    'update:currentLayers',
    'update:isLoading',
    'onMoveEnd',
    'zoomLevel',
    'changeArea',
    'currentPoint',
    'onSelectEditingFeature',
    'activateEditMode',
    'addFeatureHandleWithoutProp',
    'formData',
    'showDashBoard',
    'featureInfo',
    'resetDraw',
    'updateFeatureHandle',
    'deleteFeatureHandle',
    'clickToPoint',
    'getWithinLayer',
    'closeSetPropDialog',
    'addFeatureHandle',
    'initHistory',
    'clickGeojson',
  ],
  components: {
    DefaultLayers,
    DeleteDialog,
    DialogHeader,
    DrawTool,
    MeasureTool,
    SearchLocation,
    DrawMenu,
  },
  data() {
    return {
      topRightMenuTools: [{}],
      transformCoordinateMenu: [
        { text: 'Latitude, Longitude', value: 'latLng' },
        { text: 'UTM zone 48S', value: '48s' },
        { text: 'UTM zone 49S', value: '49s' },
        { text: 'DMS', value: 'dms' },
      ],

      loadingCapture: false,
      selectedDrawMode: undefined,
      drawInMap: 'map',
      currentMap: 'map',
      valid: false,
      propertiesDialog: false,
      property: undefined,
      currentFeature: undefined,
      rules: {
        required: value => !!value || 'Name is required',
      },
      isLabel: false,
      addedIcon: [],
      isStaticMode: false,
      mapLoading: false,
      center: undefined,
      markers: [],
      scaleControl: undefined,
      mapStyles: [
        {
          avatar: mapboxBgImageSource,
          name: 'Mapbox Satellite',
          id: 'mapbox-satellite',
        },
        {
          avatar: skymapBaseImageSource,
          name: 'Skymap Base',
          id: 'basemap',
        },
        {
          avatar: satelliteImageSource,
          name: 'Google Satellite',
          id: 'google-satellite',
        },
        {
          avatar: mapStreetImageSource,
          name: 'OSM Street layer',
          id: 'OSM',
        },
      ],
      mapSync: false,
      isMapView: true,
      disableMoveEvent: false,
      proj: 'latLng',
      beforeMap: undefined,
      syncMap: undefined,
      currentLatLng: undefined,
      currentPoint: undefined,
      zoomLevel: 0,
      firstLoad: true,
      mapBbox: [],
      isSmall: false,
      disable: false,
      isColorPicker: false,
      pointInfo: {},
      isMapFullscreen: false,
      editing: false,
      map: undefined,
      clusterId: undefined,
      imageLayers: [],
      imageSelected: undefined,
      currentGeometry: {},
      currentArea: 0,
      isOpenMeasurementTool: false,
      popupArea: false,
      mode: {},
      evtContainer: [],
      errorKeys: [],
    }
  },
  computed: {
    ...mapState('layers', ['currentBaseMap']),
    ...mapState('setting', ['keys']),
    styleDrawingMode() {
      return this.isChat ? { right: '60px' } : { left: '50%', transform: 'translateX(-50%)' }
    },
    layers: {
      get() {
        return this.currentLayers
      },
      set(val) {
        this.$emit('update:currentLayers', val)
      },
    },
    currentAOI: {
      get() {
        return this.geometry
      },
      set(val) {
        this.$emit('update:geometry', val)
      },
    },
    loading: {
      get() {
        return this.isLoading
      },
      set(val) {
        this.$emit('update:isLoading', val)
      },
    },
    isDrawing: {
      get() {
        return this.isDraw
      },
      set(val) {
        this.$emit('update:isDraw', val)
      },
    },
  },
  props: {
    type: {
      type: Object,
      default: () => {},
    },
    isLimitedDraw: { type: Boolean, default: false },
    isCreateText: { type: Boolean, default: false },
    isPreview: { type: Boolean, default: false },
    compare: { type: Boolean, default: false },
    inspect: { type: Boolean, default: false },
    isCopyRight: { type: Boolean, default: true },
    isShared: { type: Boolean, default: false },
    isIndexColor: { type: Boolean, default: false },
    isNature: { type: Boolean, default: false },
    isFalseColor: { type: Boolean, default: false },
    geometry: {
      type: Object,
      default: () => {},
    },
    currentLayers: {
      type: Array,
      default: () => [],
    },
    isLoading: { type: Boolean, default: false },
    area: { type: String, default: '0' },
    editable: { type: Boolean, default: false },
    isDraw: { type: Boolean, default: false },
    drawType: { type: String, default: 'polygon' },
    isMiniMap: { type: Boolean, default: false },
    typeCreateAOI: { type: String, default: '' },
    isChat: { type: Boolean, default: false },
    dataSyncMap: { type: Object, default: () => ({}) },
  },
  watch: {
    isDrawing(newValue) {
      if (newValue) {
        if (draw.getMode() !== 'draw_rectangle' && this.isDrawing && (this.drawType === 'polygon' || !this.drawType)) {
          this.selectedDrawMode = 'rectangle'
          draw.changeMode('draw_rectangle')
        }
      }
    },
    isLabel(val) {
      if (val) {
        this.map.addLayer(labelJsonSource)
        this.beforeMap.addLayer(labelJsonSource)
        this.syncMap.addLayer(labelJsonSource)
      } else {
        this.map.removeLayer('map-label').removeSource('map-label')
        this.beforeMap.removeLayer('map-label').removeSource('map-label')
        this.syncMap.removeLayer('map-label').removeSource('map-label')
      }
    },
    isStaticMode() {
      if (this.clusterId) {
        this.moveClusterToTop()
      }
    },
    currentBaseMap(val) {
      this.changeBaseMap(val)
    },
    mapSync() {
      this.reSize()
    },
    mapLoading(val) {
      if (val) this.checkMapLoading()
    },
  },
  mounted() {
    this.setMapboxKey()
  },
  methods: {
    mergeProps,
    cancelDraw() {
      this.isDrawing = false
      this.resetDraw()
    },
    fullScreen() {
      if (!this.isMapFullscreen) ElemFullScreen.setFullscreen('bot-map-view')
      else ElemFullScreen.closeFullscreen('bot-map-view')
      this.isMapFullscreen = !this.isMapFullscreen
    },
    saveProperty() {
      this.$refs.formData.validate()
      if (!this.valid) return
      draw.setFeatureProperty(this.currentFeature.id, 'text', this.property)
      this.propertiesDialog = false
    },
    async handleScreenshot() {
      this.loadingCapture = true
      setTimeout(async () => {
        if (this.mapSync) {
          await this.SyncMapScreenCapture()
        } else {
          await this.SlideMapScreenCapture()
        }
        this.loadingCapture = false
      }, 0)
    },
    SlideMapScreenCapture() {
      const mapBotContainerElement = this.$refs.mapBotContainer
      const mapBoundElement = this.$refs.beforeMap
      const sliderElement = document.querySelector('.mapboxgl-compare')
      const locationDetail = document.querySelector('.location-detail')
      const legendElement = document.getElementById('legend-object-detection')

      if (!sliderElement) {
        console.error('Slider element not found')
        return
      }

      const transformStyle = window.getComputedStyle(sliderElement).getPropertyValue('transform')
      const matrix = transformStyle.match(/matrix.*\((.+)\)/)[1].split(', ')
      const sliderPosition = parseFloat(matrix[4]) * window.devicePixelRatio

      const captureElement = element => {
        return html2canvas(element, {
          width: element.clientWidth,
          height: element.clientHeight,
          useCORS: true,
          scrolling: 'scroll',
          backgroundColor: 'rgba(0,0,0,0)',
        })
      }

      Promise.all([
        captureElement(mapBoundElement),
        captureElement(mapBotContainerElement),
        captureElement(sliderElement),
        captureElement(locationDetail),
        captureElement(legendElement),
      ])
        .then(([canvas1, canvas2, sliderCanvas, locationDetailCanvas, legendCanvas]) => {
          const mergedCanvas = document.createElement('canvas')
          mergedCanvas.width = Math.max(canvas1.width, canvas2.width)
          mergedCanvas.height = Math.max(canvas1.height, canvas2.height)
          const ctx = mergedCanvas.getContext('2d')

          ctx.drawImage(canvas1, 0, 0, sliderPosition, canvas1.height, 0, 0, sliderPosition, mergedCanvas.height)
          ctx.drawImage(
            canvas2,
            sliderPosition,
            0,
            canvas2.width - sliderPosition,
            canvas2.height,
            sliderPosition,
            0,
            mergedCanvas.width - sliderPosition,
            mergedCanvas.height,
          )
          ctx.drawImage(sliderCanvas, sliderPosition, 0, sliderCanvas.width, sliderCanvas.height)

          const y = mergedCanvas.height - locationDetailCanvas.height - 12
          const x = mergedCanvas.width - locationDetailCanvas.width - 150
          ctx.drawImage(locationDetailCanvas, x, y, locationDetailCanvas.width, locationDetailCanvas.height)

          // Position the legend in the bottom-left corner
          const xLegend = 12
          const yLegend = mergedCanvas.height - legendCanvas.height - 12
          ctx.drawImage(legendCanvas, xLegend, yLegend, legendCanvas.width, legendCanvas.height)

          const anchorTag = document.createElement('a')
          anchorTag.download = 'Map.png'
          anchorTag.href = mergedCanvas.toDataURL('image/png')
          anchorTag.target = '_blank'
          anchorTag.click()
        })
        .catch(error => {
          console.error('Error capturing screenshot:', error)
        })
    },

    SyncMapScreenCapture() {
      let mapBotContainerElement = this.$refs.mapBotContainer
      let mapBoundElement = this.$refs.mapSync
      let locationDetail = document.querySelector('.location-detail')
      html2canvas(mapBoundElement, {
        width: mapBoundElement.clientWidth,
        height: mapBoundElement.clientHeight,
        useCORS: true,
        scrolling: 'scroll',
      })
        .then(canvas1 => {
          html2canvas(mapBotContainerElement, {
            width: mapBotContainerElement.clientWidth,
            height: mapBotContainerElement.clientHeight,
            useCORS: true,
            scrolling: 'scroll',
          })
            .then(canvas2 => {
              html2canvas(locationDetail, {
                width: locationDetail.clientWidth,
                height: locationDetail.clientHeight,
                useCORS: true,
                scrolling: 'scroll',
              }).then(locationDetailCanvas => {
                let mergedCanvas = document.createElement('canvas')
                mergedCanvas.width = canvas1.width + canvas2.width
                mergedCanvas.height = Math.max(canvas1.height, canvas2.height)

                let ctx = mergedCanvas.getContext('2d')
                ctx.drawImage(canvas1, 0, 0)
                ctx.drawImage(canvas2, canvas1.width, 0)
                let y = mergedCanvas.height - locationDetailCanvas.height - 12
                let x = mergedCanvas.width - locationDetailCanvas.width - 150
                // Draw the location detail on the merged canvas
                ctx.drawImage(locationDetailCanvas, x, y, locationDetailCanvas.width, locationDetailCanvas.height)
                let anchorTag = document.createElement('a')
                document.body.appendChild(anchorTag)
                anchorTag.download = 'Map.png'
                anchorTag.href = mergedCanvas.toDataURL('image/png')
                anchorTag.target = '_blank'
                anchorTag.click()
              })
            })
            .catch(error => {
              console.error('Error capturing screenshot of mapBotContainerElement:', error)
            })
        })
        .catch(error => {
          console.error('Error capturing screenshot of mapBoundElement:', error)
        })
    },
    moveClusterToTop() {
      this.map.moveLayer('clusters' + this.clusterId)
      this.map.moveLayer('cluster-count' + this.clusterId)
      this.map.moveLayer('unclustered-point' + this.clusterId)
    },
    removeDrawLayer() {
      this.resetDraw()
      this.$emit('resetDraw')
    },
    async checkMapLoading() {
      await sleep(15000)
      this.mapLoading = false
    },
    async setMapboxKey() {
      let availableKeys = this.keys.filter(key => !this.errorKeys.some(errorKey => errorKey === key))
      let key = availableKeys[Math.floor(Math.random() * availableKeys.length)]
      mapboxgl.accessToken = key
      try {
        await tileMapbox('https://api.mapbox.com/v4/mapbox.satellite/1/0/0.png?access_token=' + key)
        await this.initMap()
      } catch (e) {
        this.errorKeys.push(key)
        if (this.errorKeys.length < this.keys.length) await this.setMapboxKey()
        else {
          this.currentBaseMap = {
            avatar: satelliteImageSource,
            name: 'Google Satellite',
            id: 'google-satellite',
          }
          this.mapStyles = this.mapStyles.filter(item => item.id !== 'mapbox-satellite')
          await this.initMap()
        }
      }
    },
    // region: handle map

    // base map
    handleInitBaseMap() {
      this.map = new mapboxgl.Map({
        container: this.$refs.mapBotContainer,
        style: mapStyleSource,
        center: [94.66114, 28.173],
        zoom: 0,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        transformRequest: url => {
          const convertUrl = new URL(url)
          if (
            convertUrl.origin !== 'https://mt0.google.com' &&
            convertUrl.origin !== 'https://mt1.google.com' &&
            convertUrl.origin !== 'https://mt2.google.com'
          )
            return {
              url: url,
            }
        },
        preserveDrawingBuffer: true,
      })
      this.map.on('load', async () => {
        this.center = this.map.getCenter()
        this.changeBaseMap(this.currentBaseMap)
        this.firstLoad = false
        this.zoomLevel = this.map.getZoom().toFixed(2)
      })
      this.handleEventBaseMap()
      // TODO: prevent map rotation
      this.map.dragRotate.disable()
      this.map.touchZoomRotate.disableRotation()
    },
    handleEventBaseMap() {
      this.map.on('mousemove', e => {
        this.getPixelProperties(e)
        this.getPixelValue(e)
      })
      this.map.on('zoomend', () => {
        this.center = this.map.getCenter()
        this.zoomLevel = this.map.getZoom().toFixed(2)
        this.$emit('zoomLevel', this.zoomLevel)
      })
      this.map.on('move', () => {
        this.center = this.map.getCenter()
        const center = this.map.getCenter()
        const zoom = this.map.getZoom()
        const pitch = this.map.getPitch()
        const bearing = this.map.getBearing()
        if (!this.disableMoveEvent) this.$emit('onMoveEnd', { center, zoom, pitch, bearing })
        this.$nextTick(() => {
          this.disableMoveEvent = false
        })
        if (!this.disable) {
          this.disable = true
          this.syncMap.setCenter(center)
          this.syncMap.setZoom(zoom)
          this.syncMap.setPitch(pitch)
          this.syncMap.setBearing(bearing)
          this.disable = false
        }
      })
      this.map.on('moveend', () => {
        this.changeArea()
      })
      this.map.on('click', e => {
        this.$emit('currentPoint', e.lngLat)
        this.$emit('onSelectEditingFeature', e.lngLat, this.getDistanceByPixel(4))
        let features = this.map.queryRenderedFeatures(e.point)
        if (features.length) {
          this.$emit('featureInfo', features, [e.lngLat['lng'], e.lngLat['lat']])
          if (this.inspect) this.displayData(e, features)
        }
        const bufferLength = this.getDistanceByPixel(4)
        if (this.$refs.indetify) this.$refs.indetify.getInfoFeature([e.lngLat['lat'], e.lngLat['lng']], bufferLength)
        if (this.isOpenMeasurementTool && this.mode === 'Location') {
          markers.forEach(marker => {
            marker.remove()
          })
          this.addMarkerToMap(
            {
              type: 'Point',
              coordinates: [e.lngLat['lng'], e.lngLat['lat']],
            },
            'red',
          )
          this.$refs.measureTool.setResult(e.lngLat)
        }

        window.getSelection().removeAllRanges() // remove black out when click to map
      })
      if (this.scaleControl) {
        this.map.removeControl(this.scaleControl)
      }
      this.scaleControl = new mapboxgl.ScaleControl({
        maxWidth: 90,
        minWidth: 60,
        unit: 'metric',
      })
      this.map.addControl(this.scaleControl, 'bottom-right')
    },
    // before map
    handleInitBeforeMap() {
      this.beforeMap = new mapboxgl.Map({
        container: this.$refs.beforeMap,
        style: mapStyleSource,
        center: [94.66114, 28.173],
        zoom: 0,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        transformRequest: url => {
          const convertUrl = new URL(url)
          if (
            convertUrl.origin !== 'https://mt0.google.com' &&
            convertUrl.origin !== 'https://mt1.google.com' &&
            convertUrl.origin !== 'https://mt2.google.com'
          )
            return {
              url: url,
            }
        },
        preserveDrawingBuffer: true,
      })
      this.handleEventBeforeMap()
    },
    handleEventBeforeMap() {
      this.beforeMap.on('click', e => {
        if (this.isOpenMeasurementTool && this.mode === 'Location') {
          markers.forEach(marker => {
            marker.remove()
          })
          this.addMarkerToMap(
            {
              type: 'Point',
              coordinates: [e.lngLat['lng'], e.lngLat['lat']],
            },
            'red',
            'beforeMap',
          )
          this.$refs.measureTool.setResult(e.lngLat)
        }
      })

      this.beforeMap.on('style.load', () => {
        let boundingBox = this.map.getBounds()
        this.mapBbox = turf.bboxPolygon([
          boundingBox.getWest(),
          boundingBox.getSouth(),
          boundingBox.getEast(),
          boundingBox.getNorth(),
        ])
        this.changeCompare(this.compare)
        this.reloadMap()
      })
    },
    // sync map
    handleInitSyncMap() {
      this.syncMap = new mapboxgl.Map({
        container: this.$refs.mapSync, // container id
        style: mapStyleSource,
        zoom: 0, // starting zoom,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        preserveDrawingBuffer: true,
      })

      this.handleEventSyncMap()
    },
    handleEventSyncMap() {
      this.syncMap.on('style.load', () => {
        this.reSize()
      })
      this.syncMap.on('move', () => {
        if (!this.disable) {
          const center = this.syncMap.getCenter()
          const zoom = this.syncMap.getZoom()
          const pitch = this.syncMap.getPitch()
          const bearing = this.syncMap.getBearing()

          this.disable = true
          this.map.setCenter(center)
          this.map.setZoom(zoom)
          this.map.setPitch(pitch)
          this.map.setBearing(bearing)
          this.disable = false
        }
      })
      this.syncMap.on('click', e => {
        if (this.isOpenMeasurementTool && this.mode === 'Location') {
          markers.forEach(marker => {
            marker.remove()
          })
          this.addMarkerToMap(
            {
              type: 'Point',
              coordinates: [e.lngLat['lng'], e.lngLat['lat']],
            },
            'red',
            'syncMap',
          )
          this.$refs.measureTool.setResult(e.lngLat)
        }
      })
    },
    handleDrawMap() {
      // console
      try {
        draw = new MapboxDraw({
          // styles: customDrawStyleSource,
          keybindings: true,
          displayControlsDefault: false,
          userProperties: true,
          controls: {
            line_string: false,
            polygon: false,
            trash: false,
          },
          modes: {
            ...MapboxDraw.modes,
            static: staticMode,
            draw_assisted_rectangle: DrawRectangleAssisted,
            draw_rectangle: DrawRectangle,
            direct_select: DirectMode,
            simple_select: SimpleSelectMode,
            draw_circle: CircleMode,
            draw_polygon: DrawPolygon,
            draw_line: DrawLineString,
            draw_point: DrawPoint,
          },
        })
      } catch (e) {
        console.warn('Error when init draw 123: ', e)
      }
      // base map
      this.map.on('draw.create', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
        }
        this.setAOI()
        this.setMeasureResult()
        if (this.isCreateText) {
          this.currentFeature = e.features[0]
          this.propertiesDialog = true
        }
        // this.$emit('openSetPropDialog', e.features[0])
        this.$emit('activateEditMode')
        this.$emit('addFeatureHandleWithoutProp', e.features[0])
      })
      this.map.on('draw.update', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
          // draw.deleteAll()
        }
        this.drawInMap = 'map'
        this.setAOI()
        this.setMeasureResult()
        this.$emit('updateFeatureHandle', e.features[0])
      })
      this.map.on('draw.delete', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('deleteFeatureHandle', e.features[0])
      })
      // before map
      this.beforeMap.on('draw.create', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
          // draw.deleteAll()
        }
        this.setAOI()
        this.setMeasureResult()
        if (this.isCreateText) {
          this.currentFeature = e.features[0]
          this.propertiesDialog = true
        }
        // this.$emit('openSetPropDialog', e.features[0])
        this.$emit('activateEditMode')
        this.$emit('addFeatureHandleWithoutProp', e.features[0])
      })
      this.beforeMap.on('draw.update', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
          // draw.deleteAll()
        }
        this.drawInMap = 'beforeMap'
        this.setAOI()
        this.setMeasureResult()
        this.$emit('updateFeatureHandle', e.features[0])
      })
      this.beforeMap.on('draw.delete', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('deleteFeatureHandle', e.features[0])
      })
      // sync map
      this.syncMap.on('draw.create', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
          // draw.deleteAll()
        }
        this.setAOI()
        this.setMeasureResult()
        if (this.isCreateText) {
          this.currentFeature = e.features[0]
          this.propertiesDialog = true
        }
        // this.$emit('openSetPropDialog', e.features[0])
        this.$emit('activateEditMode')
        this.$emit('addFeatureHandleWithoutProp', e.features[0])
      })
      this.syncMap.on('draw.update', e => {
        let drawnFeatures = e.features[0]
        if (!AOIWithin.check(drawnFeatures) && this.isLimitedDraw) {
          alert('Drawing outside allowed area!')
          draw.delete([e.features[0].id])
          // draw.deleteAll()
        }
        this.drawInMap = 'syncMap'
        this.setAOI()
        this.setMeasureResult()
        this.$emit('updateFeatureHandle', e.features[0])
      })
      this.syncMap.on('draw.delete', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('deleteFeatureHandle', e.features[0])
      })

      this.drawControl()
      this.map.addControl(draw, 'top-right')
    },

    async initMap() {
      try {
        await sleep(100)
        this.handleInitBaseMap()
        this.handleInitBeforeMap()
        this.handleInitSyncMap()

        diffMap = new Compare(this.beforeMap, this.map, '#wrapper', {})
        x = diffMap.currentPosition
        diffMap.setSlider(-150)
        //
        this.handleDrawMap()
        this.currentMap = 'map'
        //
        addEventListener('fullscreenchange', () => {
          if (!document.fullscreenElement) this.isMapFullscreen = false
        })
      } catch (e) {
        console.warn('Error when init map: ', e)
      }
    },

    // endregion: handle map

    mapOnHover: debounce(function (map) {
      if (map !== this.currentMap) this.changeDrawMap(map)
    }, 50),
    async changeDrawMap(map) {
      this.currentMap = map
      // if (this.currentMap) this[this.currentMap].removeControl(draw)
      // this.currentMap = undefined
      // await sleep(50)
      // this.drawControl()
      // this[map].addControl(draw, 'top-right')
      // this.currentMap = map
      await sleep(50)
      if (!this.isOpenMeasurementTool) return
      switch (this.mode) {
        case 'Area':
          draw.changeMode('draw_polygon')
          break
        case 'Distance':
          draw.changeMode('draw_line_string')
          break
        case 'Location':
          draw.changeMode('draw_point')
          break
      }
    },
    changeArea: debounce(function () {
      this.$emit('changeArea', this.map.getZoom(), this.map.getBounds())
    }, 800),

    getZoom() {
      return this.map.getZoom()
    },
    changeBaseMap(baseMap) {
      if (!this.map) return
      this.map.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      this.beforeMap.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      // this.syncMap.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      this.mapStyles
        .filter(val => val.id !== baseMap.id)
        .forEach(layer => {
          this.map.setLayoutProperty(layer.id, 'visibility', 'none')
          this.beforeMap.setLayoutProperty(layer.id, 'visibility', 'none')
          // this.syncMap.setLayoutProperty(layer.id, 'visibility', 'none')
        })
    },
    drawArrow() {
      let id = 'arrow' + utils.getUUID()
      let DRAW_MARKER_CONTROL = new MarkerDrawControl(this.map)
      // DRAW_MARKER_CONTROL.setOption()
      DRAW_MARKER_CONTROL.changeMode('ArrowSimpleDraw')
      DRAW_MARKER_CONTROL.draw()
      DRAW_MARKER_CONTROL.on('draw.create', async e => {
        this.map.addSource(id, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: e.features,
          },
        })
        this.map.addLayer({
          id: id,
          type: 'fill',
          source: id,
          layout: {},
          paint: {
            'fill-color': '#005bff',
            'fill-opacity': 0.5,
          },
        })
        this.beforeMap.addSource(id, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: e.features,
          },
        })
        this.beforeMap.addLayer({
          id: id,
          type: 'fill',
          source: id,
          layout: {},
          paint: {
            'fill-color': '#005bff',
            'fill-opacity': 0.5,
          },
        })
        this.syncMap.addSource(id, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: e.features,
          },
        })
        this.syncMap.addLayer({
          id: id,
          type: 'fill',
          source: id,
          layout: {},
          paint: {
            'fill-color': '#005bff',
            'fill-opacity': 0.5,
          },
        })
        await sleep(200)
        this.drawArrow()
      })
    },
    changeDisplayLayer(defaultLayer) {
      let layer = this.map.getStyle().layers.find(val => val.id === defaultLayer.name)
      if (!layer) {
        // this.addGeoJsonToMap(defaultLayer.name, defaultLayer.layer, 'yellow', defaultLayer.name, 'circle', true)
        // this.addTextToMap(defaultLayer.name + '_text', defaultLayer.layer, '#ffffff', 'name')
        this.addMarkerSymbolToMap(
          defaultLayer.name + '_text',
          defaultLayer.layer,
          'yellow',
          config.backend_pub + '/images/dam.png',
          'name',
          8,
          defaultLayer.name,
          ['interpolate', ['linear'], ['zoom'], 1, 0.01, 5, 0.03, 8, 0.07],
        )
      } else this.removeLayer(defaultLayer.name)
    },
    addTextToMap(name, featureCollection, color, property = 'text', zoomLv = 0) {
      let id = utils.getUUID()
      let vector = new VectorLayer({
        id: id,
        name: name,
        type: 'symbol',
        geometry: featureCollection,
        vectorType: 'symbol',
        color: color,
        layerLeft: true,
        layerRight: true,
        opacity: 1,
        layout: {
          visibility: 'visible',
          'text-ignore-placement': true,
          'text-allow-overlap': true,
          'text-field': ['step', ['zoom'], '', zoomLv, ['get', property]],
          'text-font': ['Noto Sans Regular'],
          'text-size': 14,
          'text-offset': [0, 1.2],
        },
        paint: {
          'text-opacity': 1,
          'text-color': color,
        },
      })
      let layer = vector.getMapboxLayer()
      this.addLayer(layer)
      this.layers.unshift(layer)
    },
    addMarkerSymbolToMap(
      name,
      featureCollection,
      color,
      imageUrl = 'https://platform.eofactory.ai/core/images/marker.png',
      property = 'text',
      zoomLv = 0,
      layerId,
      iconSize = 0.07,
    ) {
      this.map.loadImage(imageUrl, (error, image) => {
        if (error) throw error
        let id = layerId ? layerId : utils.getUUID()
        this.map.addImage(id + '-icon', image)
        this.syncMap.addImage(id + '-icon', image)
        this.beforeMap.addImage(id + '-icon', image)
        let vector = new VectorLayer({
          id: id,
          name: name,
          type: 'symbol',
          geometry: featureCollection,
          vectorType: 'symbol',
          color: color,
          layerLeft: true,
          layerRight: true,
          opacity: 1,
          layout: {
            visibility: 'visible',
            'icon-image': id + '-icon',
            'icon-ignore-placement': true,
            'icon-size': iconSize,
            'icon-allow-overlap': false,
            'text-ignore-placement': true,
            'text-allow-overlap': true,
            'text-field': ['step', ['zoom'], '', zoomLv, ['get', property]],
            'text-font': ['Noto Sans Regular'],
            'text-size': 14,
            'text-offset': [0, 2],
          },
          paint: {
            'text-opacity': 1,
            'text-color': color,
          },
        })
        let layer = vector.getMapboxLayer()
        this.addLayer(layer)
        this.layers.unshift(layer)
      })
    },
    displayCluster(data, isHover = false, customIcon = undefined) {
      // this.removeAllLayer()
      this.removeCluster()
      this.clusterId = utils.getUUID()
      this.map.addSource(this.clusterId, {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 22,
        clusterRadius: 22,
      })
      // Add layer to map
      this.map.addLayer({
        id: 'clusters' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        paint: {
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fffdfd',
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#fcca00',
            5,
            '#ee5a5a',
            20,
            '#e81747',
            100,
            '#ff0080',
            500,
            '#ff0000',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      })
      this.map.addLayer({
        id: 'cluster-count' + this.clusterId,
        type: 'symbol',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-ignore-placement': true,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })
      if (customIcon) this.addCustomIcon(customIcon, this.clusterId)
      else {
        this.map.addLayer({
          id: 'unclustered-point' + this.clusterId,
          type: 'circle',
          source: this.clusterId,
          filter: ['!', ['has', 'point_count']],
          paint: data.style
            ? data.style
            : {
                'circle-color': '#da1122',
                'circle-radius': 7,
                'circle-stroke-width': 3,
                'circle-stroke-color': '#fffdfd',
              },
        })
      }
      // on Click layer
      this.map.on('click', 'clusters' + this.clusterId, e => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters' + this.clusterId],
        })
        const clusterId = features[0].properties.cluster_id
        this.map.getSource(this.clusterId).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return
          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          })
        })
      })
      this.map.on('click', 'unclustered-point' + this.clusterId, async e => {
        this.$emit('clickToPoint', e.features[0])
        // if (document.fullscreenElement) await document.exitFullscreen()
      })
      if (isHover) this.addHoverToLayer('unclustered-point' + this.clusterId)
    },

    getColor(aqi) {
      if (aqi < 50) {
        return '#00787e'
      } else if (aqi < 100) {
        return '#059a65'
      } else if (aqi < 150) {
        return '#f9a01c'
      } else if (aqi < 200) {
        return '#f93c1c'
      } else if (aqi < 300) {
        return '#a30000'
      } else if (isNaN(aqi)) {
        return '#958f8f'
      }
      return '#7e0023'
    },

    displayMarker(data) {
      this.map.addSource('markers', {
        type: 'geojson',
        data: data,
      })
      let colors = [
        { limit: 50, color: '#009966' },
        { limit: 100, color: '#ffde33' },
        { limit: 150, color: '#ff9933' },
        { limit: 200, color: '#cc0033' },
        { limit: 300, color: '#660099' },
        { limit: Infinity, color: '#808080' },
        { limit: 10000, color: '#7e0023' },
      ]

      colors.forEach((item, index) => {
        let svg = `<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="20" style="fill:${item.color}" />
<polygon points="15,30 10,20 20,20" style="fill:${item.color}" />
</svg>`
        let img = new Image()
        img.src = 'data:image/svg+xml;base64,' + window.btoa(svg)

        img.onload = () => {
          this.map.addImage('aqi' + index, img)
        }
      })
      this.map.addLayer({
        id: 'markers',
        type: 'symbol',
        source: 'markers',
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'aqi'], null],
            'aqi5',
            ['<', ['get', 'aqi'], 50],
            'aqi0',
            ['<', ['get', 'aqi'], 100],
            'aqi1',
            ['<', ['get', 'aqi'], 150],
            'aqi2',
            ['<', ['get', 'aqi'], 200],
            'aqi3',
            ['<', ['get', 'aqi'], 300],
            'aqi4',
            'aqi6',
          ],
          'text-field': ['coalesce', ['get', 'aqi'], '-'],
          'text-size': 12,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-allow-overlap': false,
          'icon-allow-overlap': false,
          'icon-ignore-placement': false,
          'text-ignore-placement': false,
          'icon-offset': [0, -10],
          'text-offset': [0, -1.2],
        },
        paint: {
          'text-color': '#000000',
        },
      })
      this.map.addLayer({
        id: 'circle',
        type: 'circle',
        source: 'markers',
        layout: { visibility: 'none' },
        paint: {
          'circle-radius': 15,
          'circle-color': 'transparent',
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2,
          'circle-translate': [0, -10],
        },
      })
      const vm = this
      this.map.on('click', 'markers', e => {
        const feature = e.features[0]
        this.map.setLayoutProperty('circle', 'visibility', 'visible')
        this.map.setFilter('circle', ['case', ['==', ['get', 'name'], feature.properties.name], true, false])

        this.currentFeature = feature
        vm.$emit('showDashBoard', feature)
        vm.$emit('showHistorical', feature)

        this.map.flyTo({
          center: feature.geometry.coordinates,
          zoom: 13,
        })
      })
      let popup
      this.map.on('mouseenter', 'markers', e => {
        let data = e.features[0].properties
        let properties = ''
        for (const property in data) {
          if (property !== 'uid') {
            let value = data[property] === 'null' ? 'no data' : data[property]
            properties =
              properties +
              `<div style='float: left; color: black; margin-left: 10px'><b style='text-transform: capitalize'>${property}: </b>${value}</div>
      <br>`
          }
        }

        let html = `<div style='width: fit-content; text-align: left;'>` + properties + `</div>`
        popup = new mapboxgl.Popup({ offset: [0, -25] }) // offset in pixels
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(html)
          .addTo(this.map)
      })
      this.map.on('mouseleave', 'markers', () => {
        if (popup) {
          popup.remove()
        }
      })
    },
    async updateSourceData(sourceId, data) {
      await this.map.getSource(sourceId).setData(data)
      if (this.currentFeature && this.currentFeature.properties) {
        const feature = data.features.find(feature => feature.properties.name === this.currentFeature.properties.name)
        let isUpdate = true
        this.$emit('showDashBoard', feature, isUpdate)
      }
    },
    getFeatureById(sourceId, layerId, featureName) {
      const source = this.map.getSource(sourceId)
      if (!source) {
        console.error('Source not found:', sourceId)
        return null
      }
      const features = this.map.querySourceFeatures(sourceId, {
        sourceLayer: layerId,
      })
      const matchingFeature = features.find(feature => feature.properties.name === featureName)
      if (!matchingFeature) {
        console.error('Feature not found with id:', featureId)
        return null
      }
      return matchingFeature
    },

    addCustomIcon(customIcon, id) {
      let availableIcon = []
      customIcon.images.map(icon => {
        if (!this.addedIcon.some(val => val === icon.id)) availableIcon.push(icon)
      })
      availableIcon.forEach(icon => {
        this.map.loadImage(icon.src, (error, image) => {
          if (error) throw error
          this.map.addImage(icon.id, image, { sdf: false })
        })
        this.addedIcon.push(icon.id)
      })

      this.map.addLayer({
        id: 'unclustered-point' + id,
        type: 'symbol',
        source: id,
        minzoom: 0,
        maxzoom: 22,
        layout: {
          'icon-image': customIcon.filterIcon,
          'icon-size': 0.3,
        },
        filter: ['!', ['has', 'point_count']],
      })
    },
    removeCluster() {
      try {
        if (this.clusterId) {
          const mapLayers = this.map.getStyle().layers || []
          const mapSources = this.map.getStyle().sources || {}

          const clusterLayers = [
            'clusters' + this.clusterId,
            'cluster-count' + this.clusterId,
            'unclustered-point' + this.clusterId,
          ]

          clusterLayers.forEach(layerId => {
            if (mapLayers.some(val => val.id === layerId)) {
              this.map.removeLayer(layerId)
            }
          })

          if (mapSources[this.clusterId]) {
            this.map.removeSource(this.clusterId)
          }

          this.clusterId = undefined
        }
      } catch (e) {
        console.error(e)
      }
    },
    getCanvas() {
      return this.map.getCanvas()
    },
    async addHighlightLayer(geometry) {
      this.displayVector(
        'highlight',
        geometry,
        geometry.type === 'Linestring' ? 'line' : geometry.type === 'Point' ? 'circle' : 'fill',
        '#ff0015',
        'highlight',
      )
      await sleep(1200)
      this.map.removeLayer('highlight')
      this.map.removeSource('highlight')
      this.beforeMap.removeLayer('highlight')
      this.beforeMap.removeSource('highlight')
      this.syncMap.removeLayer('highlight')
      this.syncMap.removeSource('highlight')
    },
    getDistanceByPixel(width) {
      const y = this.map._container.clientHeight / 2
      return this.getDistance(this.map.unproject([0, y]), this.map.unproject([width, y]))
    },
    getDistance(latlng1, latlng2) {
      const R = 6371000

      const rad = Math.PI / 180
      const lat1 = latlng1.lat * rad
      const lat2 = latlng2.lat * rad
      const a =
        Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad)

      return R * Math.acos(Math.min(a, 1))
    },
    changeCompare(show) {
      this.sync = false
      this.$nextTick(() => {
        this.beforeMap.resize()
      })
      if (show) diffMap.setSlider(x)
      else diffMap.setSlider(-100000)
    },
    // updateLayerMapTiles(layer) {
    //   this.map.getSource(layer.source).tiles = [layer.data.tiles[0] + '&ver=' + utils.getUUID()]
    //   this.map.getSource(layer.source).minzoom = layer.minzoom
    //   this.map.getSource(layer.source).maxzoom = layer.maxzoom
    //   this.map.style.sourceCaches[layer.source].clearTiles()
    //   this.map.style.sourceCaches[layer.source].update(this.map.transform)
    //   this.map.triggerRepaint()

    //   this.beforeMap.getSource(layer.source).tiles = [layer.data.tiles[0] + '&ver=' + utils.getUUID()]
    //   this.beforeMap.getSource(layer.source).minzoom = layer.minzoom
    //   this.beforeMap.getSource(layer.source).maxzoom = layer.maxzoom
    //   this.beforeMap.style.sourceCaches[layer.source].clearTiles()
    //   this.beforeMap.style.sourceCaches[layer.source].update(this.beforeMap.transform)
    //   this.beforeMap.triggerRepaint()
    // },
    // updateLayerMapData(layer) {
    //   this.map.getSource(layer.source).setData(layer.geometry)
    //   this.map.style.sourceCaches[layer.source].clearTiles()
    //   this.map.style.sourceCaches[layer.source].update(this.map.transform)
    //   this.map.triggerRepaint()

    //   this.beforeMap.getSource(layer.source).setData(layer.geometry)
    //   this.beforeMap.style.sourceCaches[layer.source].clearTiles()
    //   this.beforeMap.style.sourceCaches[layer.source].update(this.beforeMap.transform)
    //   this.beforeMap.triggerRepaint()
    // },
    removeSource(sourceId) {
      ;[this.map, this.beforeMap, this.syncMap].forEach(mapInstance => {
        if (mapInstance.getSource(sourceId)) {
          const layers = mapInstance.getStyle().layers
          layers.forEach(layer => {
            if (layer.source === sourceId) {
              mapInstance.removeLayer(layer.id)
            }
          })
          mapInstance.removeSource(sourceId)
        }
      })
    },
    addSource(data, type, sourceId) {
      let source = {
        type: type,
        tiles: data.data.tiles,
        tileSize: type === 'raster' ? 256 : 512,
        maxzoom: data.maxzoom || 22,
        minzoom: data.minzoom ? Number(data.minzoom) : 1,
        bounds: data.data.bounds ? data.data.bounds : [-180.0, -85.0511, 180.0, 85.0511],
      }
      if (this.map && !this.map.getSource(sourceId)) {
        this.map.addSource(sourceId, source)
        this.syncMap.addSource(sourceId, source)
        this.beforeMap.addSource(sourceId, source)
      }
      return sourceId
    },

    addVectorTiles(data, isSync = true, isLeft = false, layerId = utils.getUUID()) {
      try {
        data.data.tiles = data.tiles
        data.data.bounds = data.bounds ? data.bounds : [-180.0, -85.0511, 180.0, 85.0511]
        let display = true
        if (data.display === false) display = false
        let sourceId = `source_${data.id}`
        this.addSource(data, 'vector', sourceId)
        let vector = new VectorTilesLayer({
          source: sourceId,
          data: data,
          icon: data.icon,
          label: data.label,
          bbox: data.bounds,
          sourceLayer: data.layerName,
          jsonUrl: data.jsonUrl,
          name: data.name,
          layerLeft: display,
          layerRight: display,
          style: data.paint,
          dataId: data.id,
          opacity: 1,
          filter: data.filter,
          id: layerId,
          type: data.type,
          session: data.session,
        })
        let layer = vector.getMapboxLayer()
        this.addLayer(layer, undefined, isSync, isLeft)
        this.layers.unshift(layer)
        if (data.isHover) this.addHoverToLayer(layer.id, isLeft)
        // this.submitZoom(data.bounds)
      } catch (e) {
        console.log(e)
      }
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return new File([theBlob], fileName, {
        type: theBlob.type,
      })
    },
    getMapFormData() {
      const mapCanvas = this.map.getCanvas()
      const baseCanvas = document.createElement('canvas')
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      const context = baseCanvas.getContext('2d')

      const mapImage = new window.Image()
      mapImage.src = mapCanvas.toDataURL()
      mapImage.onload = () => {
        context.drawImage(mapImage, 0, 0)
        baseCanvas.toBlob(result => {
          let binary = this.blobToFile(result, 'test')
          const formData = new FormData()
          formData.append('file', binary)
          formData.append('zoom_level', this.zoomLevel)
          formData.append('left', this.map.getBounds()._sw.lng)
          formData.append('right', this.map.getBounds()._ne.lng)
          formData.append('top', this.map.getBounds()._ne.lat)
          formData.append('bottom', this.map.getBounds()._sw.lat)
          this.$emit('formData', formData)
        })
      }
    },
    closeColorPicker() {
      this.isColorPicker = false
      this.map.getCanvas().style.cursor = ''
    },
    addHoverToLayer(layerId, isLeft = false) {
      if (!layerId) return
      const map = isLeft ? this.syncMap : this.map
      map.on('mousemove', layerId, () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', layerId, () => {
        map.getCanvas().style.cursor = ''
      })
    },
    displayData(e, features) {
      if (popup) popup.remove()
      let html = `<div style='width: fit-content; text-align: center;'>
              <div style='float: left; color: black; margin-left: 10px'>ID: ${features[0].properties.id}</div>
              <br>
              <div style='float: left; color: black; margin-left: 10px'>Type: ${features[0].properties.type}</div>
          </div>`
      this.displayPopup([e.lngLat['lng'], e.lngLat['lat']], html, true)
    },
    getCurrentPoint(e) {
      this.$emit('currentPoint', [e.lngLat['lng'], e.lngLat['lat']])
    },
    getPixelProperties(e) {
      if (!this.isColorPicker) return
      this.currentPoint = e.lngLat['lng'].toFixed(4) + ',&nbsp;&nbsp;' + e.lngLat['lat'].toFixed(4)
      this.map.getCanvas().style.cursor = ''
      if (popup) popup.remove()
      this.map.getCanvas().style.cursor = 'pointer'
      const { point } = e
      const { x, y } = point
      const canvas = this.map.getCanvas()
      const gl = canvas.getContext('webgl') || canvas.getContext('webgl2')
      if (gl) {
        const data = new Uint8Array(4)
        const canvasX = x - canvas.offsetLeft
        const canvasY = canvas.height - y - canvas.offsetTop
        gl.readPixels(canvasX, canvasY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data)
        const [r, g, b, a] = data
        const color = `rgba(${r}, ${g}, ${b}, ${a})`
        let pointData = this.type.data.find(
          val => val.color.toUpperCase() === colorFormat.covertToHex(color).toUpperCase(),
        )
        if (pointData) {
          this.$emit('getWithinLayer', [e.lngLat['lng'], e.lngLat['lat']], color, pointData)
        }
      }
    },
    displayPopup(point, html, closeOnClick = true, closeButton = true) {
      try {
        popup = new mapboxgl.Popup({ closeOnClick: closeOnClick, closeButton: closeButton })
          .setLngLat(point)
          .setHTML(html)
          .addTo(this.map)
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },
    removePopup() {
      this.pointInfo = {}
    },
    closePopup() {
      if (popup) popup.remove()
    },
    changePixelValue() {
      proj4.defs([proj4List['EPSG:32748'], proj4List['EPSG:4326'], proj4List['EPSG:32749']])
      switch (this.proj) {
        case 'latLng':
          this.currentPoint =
            this.currentLatLng['lat'].toFixed(6) + ',&nbsp;&nbsp;' + this.currentLatLng['lng'].toFixed(6)
          break
        case 'dms':
          this.currentPoint = latDMS(+this.currentLatLng['lat']) + ', &nbsp;' + lngDMS(+this.currentLatLng['lng'])
          break
        case '48s':
          let UTM48S = proj4('EPSG:4326', 'EPSG:32748', [this.currentLatLng['lng'], this.currentLatLng['lat']])
          this.currentPoint = UTM48S[0] + ', &nbsp;' + UTM48S[1] + ', &nbsp;' + '48S'
          break
        case '49s':
          let UTM49S = proj4('EPSG:4326', 'EPSG:32749', [this.currentLatLng['lng'], this.currentLatLng['lat']])
          this.currentPoint = UTM49S[0] + ', &nbsp;' + UTM49S[1] + ', &nbsp;' + '49S'
          break
      }
    },
    getPixelValue(e) {
      this.currentLatLng = e.lngLat
      this.changePixelValue()
    },
    zoomToCenter(point) {
      if (point) {
        try {
          let pt = point.replaceAll(' ', '').split(',')
          this.map.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10,
          })
          this.beforeMap.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10,
          })
        } catch (e) {}
      }
    },
    createLayer(name, vectorType) {
      this.$refs.drawTool.createLayer(name, vectorType)
    },
    disableEditDraw() {
      draw.changeMode('static')
      this.isStaticMode = true
      let b = [
        'gl-draw-line-static.cold',
        'gl-draw-line-static.hot',
        'gl-draw-polygon-stroke-static.cold',
        'gl-draw-polygon-stroke-static.hot',
      ]
      b.forEach(id => {
        this.map.setPaintProperty(id, 'line-color', '#ff0015')
      })
      let a = ['gl-draw-polygon-fill-static.cold', 'gl-draw-polygon-fill-static.hot']
      a.forEach(id => {
        this.map.setPaintProperty(id, 'fill-outline-color', '#ff0015')
        this.map.setPaintProperty(id, 'fill-color', 'red')
      })
    },
    async submitDeleteDraw() {
      try {
        if (this.currentMap && this.currentMap !== this.drawInMap) this[this.drawInMap].removeControl(draw)
        await sleep(100)
        this.drawControl()
        this[this.currentMap].addControl(draw, 'top-right')
        this.drawInMap = this.currentMap
      } catch (e) {
      } finally {
        await sleep(100)
        if (!this.isOpenMeasurementTool) this.changeMode(this.selectedDrawMode)
        else
          switch (this.mode) {
            case 'Area':
              draw.changeMode('draw_polygon')
              break
            case 'Distance':
              draw.changeMode('draw_line_string')
              break
            case 'Location':
              draw.changeMode('draw_point')
              break
          }
      }
    },
    openClearDraw() {
      if (draw.getAll().features.length && draw.getAll().features[0].geometry.coordinates[0][0])
        this.$refs.deleteDialog.openDialog({ name: 'AOI' })
      else this.submitDeleteDraw()
    },
    changeMode(mode) {
      this.selectedDrawMode = mode
      if (!this.isDrawing) return
      if (this.currentMap !== this.drawInMap) this.openClearDraw()
      else
        switch (mode) {
          case 'polygon':
            if (draw.getMode() !== 'draw_polygon') draw.changeMode('draw_polygon')
            break
          case 'assist-rectangle':
            if (draw.getMode() !== 'draw_assisted_rectangle') draw.changeMode('draw_assisted_rectangle')
            break
          case 'rectangle':
            if (draw.getMode() !== 'draw_rectangle') draw.changeMode('draw_rectangle')
            break
          case 'LineString':
            if (draw.getMode() !== 'draw_line_string') draw.changeMode('draw_line_string')
            break
          case 'static':
            if (draw.getMode() !== 'static') draw.changeMode('static')
            break
          case 'Point':
            if (draw.getMode() !== 'draw_point') draw.changeMode('draw_point')
            break
          case 'delete':
            if (draw && draw.getSelected()) {
              draw.trash()
            }
            break

          default:
            break
        }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    drawControl() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (
              (draw.getMode() !== 'draw_polygon' &&
                this.isDrawing &&
                (this.drawType === 'polygon' || !this.drawType)) ||
              (this.isOpenMeasurementTool && this.mode === 'Area')
            ) {
              this.selectedDrawMode = 'polygon'
              if (this.isOpenMeasurementTool) draw.deleteAll()
              draw.changeMode('draw_polygon')
            }
            break
          case '4':
          case 'w':
            if (
              draw.getMode() !== 'draw_rectangle' &&
              this.isDrawing &&
              (this.drawType === 'polygon' || !this.drawType)
            ) {
              this.selectedDrawMode = 'rectangle'
              draw.changeMode('draw_rectangle')
            }
            break
          case '5':
          case 'e':
            if (
              draw.getMode() !== 'draw_assisted_rectangle' &&
              this.isDrawing &&
              (this.drawType === 'polygon' || !this.drawType)
            ) {
              this.selectedDrawMode = 'assisted_rectangle'
              draw.changeMode('draw_assisted_rectangle')
            }
            break
          case '6':
          case 'r':
            if (
              (draw.getMode() !== 'draw_line_string' &&
                this.isDrawing &&
                (this.drawType === 'line' || !this.drawType)) ||
              (this.isOpenMeasurementTool && this.mode === 'Distance')
            ) {
              this.selectedDrawMode = 'LineString'
              if (this.isOpenMeasurementTool) draw.deleteAll()
              draw.changeMode('draw_line_string')
            }
            break
          case '7':
          case 'd':
            if (
              (draw.getMode() !== 'draw_point' && this.isDrawing && (this.drawType === 'point' || !this.drawType)) ||
              (this.isOpenMeasurementTool && this.mode === 'Location')
            ) {
              this.selectedDrawMode = 'Point'
              if (this.isOpenMeasurementTool) draw.deleteAll()
              draw.changeMode('draw_point')
            }
            break
          case 'Delete':
          case 'Backspace':
            if (draw && draw.getSelected()) {
              draw.trash()
            }
            break
          default:
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    resetDraw() {
      this.isStaticMode = false
      markers.forEach(marker => {
        marker.remove()
      })
      draw.deleteAll()
      draw.changeMode('simple_select')
      this.currentAOI = {}
    },
    deSelect() {
      if (popup) popup.remove()
      // if (draw) {
      //   if (!this.isStaticMode) draw.changeMode('simple_select')
      //   else draw.changeMode('static')
      // }
    },
    activeMeasureTool(mode, drawMode) {
      this.resetDraw()
      this.mode = mode
      switch (drawMode) {
        case 'polygon':
          draw.changeMode('draw_polygon')
          break
        case 'drag-line':
          draw.changeMode('draw_line_string')
          break
        default:
          break
      }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    setAOI() {
      if (draw.getAll().features.length) this.currentAOI = draw.getAll()
      else this.currentAOI = {}
    },
    setMeasureResult() {
      switch (this.mode) {
        case 'Distance':
          this.$refs.measureTool.setResult(turfLength(draw.getAll()))
          break
        case 'Area':
          this.$refs.measureTool.setResult(area(draw.getAll()) / 1000000)
          break
      }
    },
    async reSize() {
      await sleep(500)
      this.map.resize()
      this.beforeMap.resize()
      this.syncMap.resize()
    },
    // mapFullscreen() {
    //   this.isMapFullscreen = !this.isMapFullscreen
    //   if (this.isMapFullscreen) this.$refs.mapBound.parentElement.requestFullscreen()
    //   else document.exitFullscreen()
    // },
    drawAOI(AOI) {
      this.submitZoom(turf.bbox(AOI))
      this.isStaticMode = false
      if (this.zoomLevel > 18) this.map.setZoom(18)
    },
    updateStyleProperties(layerId) {
      let currentLayer = this.layers.find(layer => layer.id === layerId)
      if (currentLayer.paint) {
        for (const key in currentLayer.paint) {
          this.map.setPaintProperty(layerId, key, currentLayer.paint[key])
          this.beforeMap.setPaintProperty(layerId, key, currentLayer.paint[key])
        }
      }
    },
    displayVector(name, geometry, type, color, id, addToList, vectorType) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'fill':
        case 'line':
          this.addGeoJsonToMap(name, geometry, color, id, type, addToList, vectorType)
          break
        case 'circle':
        case 'Point':
          this.addGeoJsonToMap(name, geometry, color, id, 'circle', addToList, vectorType)
          break
      }
    },
    addMarkerToMap(geometry, color, map = 'map') {
      let currentMarker = new mapboxgl.Marker({ color: color }).setLngLat(geometry.coordinates).addTo(this[map])
      markers.push(currentMarker)
    },
    addGeoJsonToMap(name, geometry, color, id, type, addToList, vectorType = undefined, style = undefined) {
      let vector = new VectorLayer({
        id: id,
        name: name,
        type: type,
        geometry: geometry,
        vectorType: vectorType,
        color: color,
        layerLeft: true,
        layerRight: true,
        opacity: 1,
        paint: style,
      })

      let layer = vector.getMapboxLayer()
      this.addLayer(layer)

      if (addToList) this.layers.unshift(layer)
    },
    updateSourceLayer(id, url) {
      this.map.getSource(id).tiles = [url]
      this.map.style.sourceCaches[id].clearTiles()
      this.map.style.sourceCaches[id].update(this.map.transform)
      this.map.triggerRepaint()

      this.beforeMap.getSource(id).tiles = [url]
      this.beforeMap.style.sourceCaches[id].clearTiles()
      this.beforeMap.style.sourceCaches[id].update(this.beforeMap.transform)
      this.beforeMap.triggerRepaint()

      this.syncMap.getSource(id).tiles = [url]
      this.syncMap.style.sourceCaches[id].clearTiles()
      this.syncMap.style.sourceCaches[id].update(this.syncMap.transform)
      this.syncMap.triggerRepaint()
    },
    addImageToMap(image, beforeId, isSync = true, isLeft = false) {
      let display = true
      if (image.display === false) display = false
      let raster = new RasterLayer({
        id: utils.getUUID(),
        dataId: image.id,
        data: image,
        min_zoom: image.meta ? image.meta.MIN_ZOOM : 0,
        max_zoom: image.meta ? image.meta.MAX_ZOOM : 22,
        layerType: image.layerType ? image.layerType : 'Raster_Image',
        name: image.name,
        tiles: image.tiles,
        bbox: image.bounds,
        layerLeft: display,
        layerRight: display,
        paint: undefined,
        opacity: 1,
        session: image.session,
      })
      this.addSource(
        {
          minzoom: image.meta ? image.meta.MIN_ZOOM : 0,
          maxzoom: image.meta ? image.meta.MAX_ZOOM : 22,
          data: {
            tiles: image.tiles,
            bounds: image.bounds,
          },
        },
        'raster',
        raster.id,
        isSync,
      )
      let layer = raster.getMapboxLayer()
      this.addLayer(layer, beforeId, isSync, isLeft)
      if (beforeId) {
        let index = this.layers.findIndex(layer => layer.id === beforeId)
        this.layers.splice(index + 1, 0, layer)
      } else this.layers.unshift(layer)
      // this.submitZoom(image.bounds)
    },
    convertBbox4326To3857(bbox) {
      const epsg4326 = 'EPSG:4326'
      const epsg3857 = 'EPSG:3857'
      const [minLng, minLat, maxLng, maxLat] = bbox
      const [minX, minY] = proj4(epsg4326, epsg3857, [minLng, minLat])
      const [maxX, maxY] = proj4(epsg4326, epsg3857, [maxLng, maxLat])
      return [minX, minY, maxX, maxY]
    },
    addWMSToMap(data) {
      var myCanvas = this.map.getCanvas()
      let wmsUrl = data.tile_info.tile_url
      const bbox4326 = data.layer.bbox4326.map(coord => Number(coord))
      const bbox3857 = this.convertBbox4326To3857(bbox4326)
      let wmsParams = {
        SERVICE: 'WMS',
        REQUEST: 'GetMap',
        VERSION: '1.3.0',
        LAYERS: data.layer.name,
        STYLES: '',
        FORMAT: 'image/png',
        TRANSPARENT: 'true',
        SRS: 'EPSG:3857',
        BBOX: bbox3857.join(','),
        HEIGHT: myCanvas.height / window.devicePixelRatio,
        WIDTH: myCanvas.width / window.devicePixelRatio,
      }
      const url = `${wmsUrl}?${new URLSearchParams(wmsParams).toString()}`

      const [minLon, minLat, maxLon, maxLat] = bbox4326
      const coordinates = [
        [minLon, maxLat],
        [maxLon, maxLat],
        [maxLon, minLat],
        [minLon, minLat],
      ]
      let raster = new RasterLayer({
        id: utils.getUUID(),
        min_zoom: data.MIN_ZOOM,
        max_zoom: data.MIN_ZOOM,
        layerType: 'Raster_Image',
        name: data.layer.name,
        tiles: [url],
        data: data,
        bbox: bbox4326,
        layerLeft: 'visible',
        layerRight: 'visible',
        paint: undefined,
        opacity: 1,
      })
      this.map.addSource(raster.id, {
        type: 'image',
        url: url,
        coordinates: coordinates,
      })
      this.syncMap.addSource(raster.id, {
        type: 'image',
        url: url,
        coordinates: coordinates,
      })
      this.beforeMap.addSource(raster.id, {
        type: 'image',
        url: url,
        coordinates: coordinates,
      })
      let layer = raster.getMapboxLayer()

      this.submitZoom(bbox4326)
      this.layers.unshift(layer)
      this.addLayer(layer)
    },
    addLayer(layer, beforeId = undefined, isSync = false, isLeft = true) {
      try {
        layer.isLeft = isLeft
        this.second = 0
        this.mapLoading = false
        if (!this.map.getSource(layer.id)) {
          if (layer.isGeojson) {
            this.beforeMap.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
            this.map.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
            this.syncMap.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
          } else if (layer.isVector) this.addSource(layer, 'vector', layer.source)
          else if (layer.isRaster) this.addSource(layer, layer.type, layer.source)
        }
        layer.layout.visibility = layer.layerRight ? 'visible' : 'none'
        if (!isSync || (isSync && !isLeft)) this.map.addLayer(layer, beforeId)
        if (!isSync || (isSync && isLeft)) this.syncMap.addLayer(layer, beforeId)
        layer.layout.visibility = layer.layerLeft ? 'visible' : 'none'
        this.beforeMap.addLayer(layer, beforeId)

        if (['fill', 'line', 'symbol'].includes(layer.type)) {
          if (layer.geometry && layer.geometry.features[0].properties.clickable) {
            this.map.on('click', layer.id, e => {
              this.$emit('clickGeojson', e.lngLat)
            })
            this.map.on('mouseenter', layer.id, e => {
              this.setCursorToPointer()
            })
            this.map.on('mouseleave', layer.id, e => {
              this.setCursorToNormal()
            })
          }
        }
      } catch (e) {
        console.log('Error when add layer to map: ', e)
      } finally {
        this.map.on('idle', () => {
          this.mapLoading = false
        })
      }
    },
    removeAllLayer(isSync = false, isLeft = true) {
      if (popup) popup.remove()
      let removeValFromIndex = []
      this.layers.forEach((layer, index) => {
        const mapLayers = this.map.getStyle().layers || []
        const syncMapLayers = this.syncMap.getStyle().layers || []
        const beforeMapLayers = this.beforeMap.getStyle().layers || []
        if (mapLayers.some(val => val.id === layer.id)) {
          if ((!isSync || (isSync && !isLeft)) && !(isSync && layer.isLeft)) {
            this.map.removeLayer(layer.id)
          }
        }
        if (syncMapLayers.some(val => val.id === layer.id)) {
          if ((!isSync || (isSync && isLeft)) && !(isSync && !layer.isLeft)) {
            this.syncMap.removeLayer(layer.id)
          }
        }
        if (beforeMapLayers.some(val => val.id === layer.id)) {
          this.beforeMap.removeLayer(layer.id)
        }
        if (layer && layer.type === 'raster') {
          if (mapLayers.some(val => val.id === layer.id)) {
            if (!isSync || (isSync && !isLeft)) this.map.removeSource(layer.id)
          }
          if (syncMapLayers.some(val => val.id === layer.id)) {
            if (!isSync || (isSync && isLeft)) this.syncMap.removeSource(layer.id)
          }
          if (beforeMapLayers.some(val => val.id === layer.id)) {
            this.beforeMap.removeSource(layer.id)
          }
        }
        removeValFromIndex.push(index)
      })
      for (let i = removeValFromIndex.length - 1; i >= 0; i--) {
        this.layers.splice(removeValFromIndex[i], 1)
      }
    },
    removeLayer(layerId) {
      const mapLayers = this.map.getStyle().layers || []
      const syncMapLayers = this.syncMap.getStyle().layers || []
      const beforeMapLayers = this.beforeMap.getStyle().layers || []

      let layer = mapLayers.find(val => val.id === layerId)
      if (!layer) return

      if (mapLayers.some(val => val.id === layerId)) {
        this.map.removeLayer(layerId)
        if (layer.type === 'raster') {
          this.map.removeSource(layerId)
        }
      }

      if (syncMapLayers.some(val => val.id === layerId)) {
        this.syncMap.removeLayer(layerId)
        if (layer.type === 'raster') {
          this.syncMap.removeSource(layerId)
        }
      }

      if (beforeMapLayers.some(val => val.id === layerId)) {
        this.beforeMap.removeLayer(layerId)
        if (layer.type === 'raster') {
          this.beforeMap.removeSource(layerId)
        }
      }

      let index = this.layers.findIndex(layer => layer.id === layerId)
      if (index >= 0) this.layers.splice(index, 1)
    },
    changeVisibleLeftLayer(layerId, visibility = undefined) {
      let layer = this.beforeMap.getStyle().layers.find(val => val.id === layerId)
      if (visibility) {
        this.syncMap.setLayoutProperty(layerId, 'visibility', visibility)
        this.beforeMap.setLayoutProperty(layerId, 'visibility', visibility)
      } else {
        if (layer.layout.visibility === 'visible') {
          this.syncMap.setLayoutProperty(layerId, 'visibility', 'none')
          this.beforeMap.setLayoutProperty(layerId, 'visibility', 'none')
        } else {
          this.syncMap.setLayoutProperty(layerId, 'visibility', 'visible')
          this.beforeMap.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },
    changeVisibility(layerId, visibility = undefined) {
      let layer = this.map.getStyle().layers.find(val => val.id === layerId)
      if (visibility) {
        this.map.setLayoutProperty(layerId, 'visibility', visibility)
      } else {
        if (layer.layout.visibility === 'visible') {
          this.map.setLayoutProperty(layerId, 'visibility', 'none')
        } else {
          this.map.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },
    submitZoom(bbox, padding = 50) {
      this.reSize()
      this.map.fitBounds(bbox, {
        duration: 0,
        padding,
      })
    },
    reloadMap() {
      this.layers.forEach(layer => {
        if (this.map.getStyle().layers.some(val => val.id === layer.id)) {
          this.map.removeLayer(layer.id)
          this.beforeMap.removeLayer(layer.id)
        }
      })
      for (let i = this.layers.length - 1; i > -1; i--) {
        let layer = this.layers[i]
        this.addLayer(layer)
      }
    },
    //Draw functions
    getAllDrawFeature() {
      if (draw.getMode() !== 'simple_select') draw.changeMode('simple_select')
      return draw.getAll().features
    },
    deleteAllDraw() {
      draw.deleteAll()
    },
    moveDrawLayerToTop() {
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    setCursorToPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    setCursorToNormal() {
      this.map.getCanvas().style.cursor = ''
    },
  },
  destroyed() {
    this.destroyDrawControlShortcuts()
    if (this.map) {
      this.map.remove()
      this.syncMap.remove()
      this.map = undefined
      markers = []
    }
    if (this.beforeMap) {
      this.beforeMap.remove()

      this.beforeMap = undefined
      markers = []
    }
  },
}
</script>

<style scoped>
.tools-top-right-map-area {
  height: 200px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
#bot-map-view {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.map-container {
  width: 100%;
  height: 100%;
}
:deep(.mapboxgl-ctrl-scale) {
  font-size: 10px;
  white-space: nowrap !important;
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.location-detail {
  position: absolute;
  bottom: 12px;
  right: 100px;
  background-color: #ffffffc0;
  border-radius: 4px;
  min-width: 160px;
  height: 20px;
  color: #333333;
  font-size: 11px;
  font-weight: bold;
}

.base-layer {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  border: 2px solid #a8a8a8;
  position: absolute;
  bottom: 55px;
  right: 10px;
  z-index: 900;
}
.data-sync-map {
  position: absolute;
  top: 10px;
  z-index: 1;
  background-color: var(--v-theme-primary);
  border-radius: 10px;
  padding: 0 10px;
  color: white;
  &.left-map {
    left: 25%;
    transform: translateX(-50%);
  }
  &.right-map {
    right: 25%;
    transform: translateX(50%);
  }
}
</style>
