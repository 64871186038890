<template>
  <div>
    <span>{{ checkGrammar(layers.length, 'layer') }} are selected</span>
    <SearchBarEarth
      v-model="search"
      append-inner-icon="mdi-magnify"
      density="compact"
      hide-details
      label="Search"
      class="mt-2"
      variant="outlined"
      color="primary"
      placeholder="Search"
      @keydown.enter.prevent
    >
    </SearchBarEarth>
    <div v-if="typeList === 'data'" style="width: 100%; height: 50px; flex: none">
      <div class="d-flex align-center fill-height">
        Group by:
        <v-spacer />
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn class="text-capitalize" color="primary" small v-bind="props">
              {{ groupBy }}
              <v-icon class="ml-1"> icon-chevron_down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="groupBy = 'Date'">
              <v-list-item-title class="pl-1"> Date </v-list-item-title>
            </v-list-item>
            <v-list-item @click="groupBy = 'Type'">
              <v-list-item-title class="pl-1"> Type </v-list-item-title>
            </v-list-item>
            <v-list-item @click="groupBy = 'Data type'">
              <v-list-item-title class="pl-1"> Data type </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-list class="custom-card-bg-0" style="height: 270px; overflow-y: auto">
      <v-overlay :model-value="loading" class="align-center justify-center" contained>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div v-for="item in displayedItems" class="pt-0" style="width: 100%; border-radius: 4px">
        <v-list-item
          v-if="!item.items || !item.items.length"
          class="mt-1 py-1 pl-2 pr-5 custom-card-bg-2"
          @click="selectItem(item)"
        >
          <BorderArrow v-if="layers.some(layer => layer.uuid === item.uuid)" />
          <div class="d-flex align-center">
            <div style="width: 50px; flex: none">
              <div class="d-flex align-center" style="width: 45px; height: 45px">
                <v-img
                  :lazy-src="item.thumbnail_url"
                  :src="item.thumbnail_url"
                  contain
                  max-height="100%"
                  max-width="100%"
                  style="border-radius: 4px"
                >
                  <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                      <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
            <div class="d-flex flex-column fill-height">
              <TooltipBase :title="item.name" />
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <v-list-item-subtitle class="subtitle-2 text-truncate" v-bind="props">
                    <div style="font-size: 10px" v-html="transformArea(item.area)"></div>
                  </v-list-item-subtitle>
                </template>
                <span v-html="' Area: ' + transformArea(item.area)"></span>
              </v-tooltip>
            </div>
          </div>
        </v-list-item>
        <v-expansion-panels v-else class="custom-expansion" focusable>
          <BorderArrow v-if="groupCount(item.items) === item.items.length" />
          <v-expansion-panel>
            <v-expansion-panel-title class="custom-card-bg-2 text-truncate mt-1">
              <template v-slot:default="{ open }">
                <div class="pr-4" style="flex: none">
                  <CustomIcon icon="folder" />
                </div>
                <TooltipBase :styles="{ fontSize: '14px' }" :title="item.name" />
                <v-spacer />
                <v-tooltip top>
                  <template v-slot:activator="{ props }">
                    <v-icon style="flex: none" v-bind="props" @click.stop="selectAll(item)">
                      {{
                        groupCount(item.items) === item.items.length ? 'mdi-playlist-check' : 'mdi-border-all-variant'
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{ groupCount(item.items) === item.items.length ? 'Deselect All' : 'Select All' }}
                  </span>
                </v-tooltip>
                <div class="px-2" style="flex: none; font-size: 12px; word-wrap: break-word">
                  {{ groupCount(item.items) }} / {{ checkGrammar(item.items.length, 'item') }} are selected
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text style="background: transparent">
              <div v-for="child in item.items">
                <v-list-item
                  @click="selectItem(child)"
                  :key="child.uuid"
                  class="pr-0 pl-1 my-1 position-relative custom-card-bg-2"
                >
                  <BorderArrow v-if="layers.some(layer => layer.uuid === child.uuid)" />
                  <div class="d-flex align-center py-1" style="height: 62px">
                    <div style="width: 50px; flex: none">
                      <div class="d-flex align-center" style="width: 45px; height: 45px">
                        <v-img
                          :lazy-src="child.thumbnail_url || child.tile_info.thumbnail_url"
                          :src="child.thumbnail_url || child.tile_info.thumbnail_url"
                          contain
                          max-height="100%"
                          max-width="100%"
                          style="border-radius: 4px"
                        >
                          <template v-slot:placeholder>
                            <v-row align="center" class="fill-height ma-0" justify="center">
                              <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <TooltipBase class="ml-2" :styles="{ fontSize: '14px' }" :title="child.name" />
                      <v-tooltip location="bottom" v-if="typeList === 'AOI'">
                        <template v-slot:activator="{ props }">
                          <div class="subtitle-2 text-truncate" v-bind="props">
                            <div style="font-size: 10px" v-html="' Area: ' + transformArea(child.area)"></div>
                          </div>
                        </template>
                        <span v-html="'Area: ' + transformArea(child.area)"></span>
                      </v-tooltip>
                      <div v-if="typeList === 'data'" class="caption pt-0 ml-2">Date: {{ child.date }}</div>
                    </div>
                  </div>
                </v-list-item>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <InfiniteLoading
        ref="infiniteLoading"
        v-if="filteredItems.length > 0"
        @infinite="infiniteHandler"
        :identifier="reload"
      >
        <template v-slot:complete>
          <span></span>
        </template>
      </InfiniteLoading>
    </v-list>
  </div>
</template>

<script>
import { mapState } from '@/store/ults'
import TooltipBase from '@/components/TooltipBase.vue'
import BorderArrow from '@/components/BorderArrow.vue'
import { getDataExplorer } from '@/api/explorer-api'
import InfiniteLoading from 'v3-infinite-loading'
import AreaTransform from '@/utils/convertArea'
import CustomIcon from '@/components/CustomIcon.vue'
import convertDate from '@/utils/convertDate'
import { checkGrammar } from '@/utils/stringHandle'
import config from '@/config'
import SearchBarEarth from '@/components/SearchBarEarth.vue'

export default {
  components: { TooltipBase, BorderArrow, InfiniteLoading, CustomIcon, SearchBarEarth },
  props: {
    typeList: {
      type: String,
      default: () => 'AOI',
    },
  },
  data() {
    return {
      search: '',
      data: [],
      displayedItems: [],
      itemsPerPage: 7,
      currentIndex: 0,
      layers: [],
      groupBy: 'Date',
      loading: false,
      filteredItems: [],
      dataList: [],
      reload: true,
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
  },
  watch: {
    groupBy(val) {
      this.updateFilteredItems(val, this.search)
    },
    search(val) {
      this.updateFilteredItems(this.groupBy, val)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    updateFilteredItems(groupBy, search) {
      if (this.typeList === 'data') {
        this.filteredItems = this.processDataList(this.dataList, groupBy, search)
      } else if (this.typeList === 'AOI') {
        this.filteredItems = this.dataList.map(item => this.filterRecursive(item, search)).filter(Boolean)
      }
      this.resetInfinite()
    },
    processDataList(dataList, groupBy, search) {
      return this.groupData(dataList, groupBy)
        .map(item => this.filterRecursive(item, search))
        .filter(Boolean)
    },
    groupData(dataList, groupBy) {
      let groups = []
      switch (groupBy) {
        case 'Date':
          dataList.forEach(item => {
            let index = groups.findIndex(group => group.name === convertDate.yearMonthFormatted(item.acquired_date))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: convertDate.yearMonthFormatted(item.acquired_date),
                items: [item],
              })
          })
          groups.sort((a, b) => {
            if (a.name < b.name) return 1
            if (a.name > b.name) return -1
            return 0
          })
          return groups
        case 'Type':
          dataList.forEach(item => {
            let index = groups.findIndex(group => group.name === item.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
        case 'Data type':
          dataList.forEach(item => {
            let index = groups.findIndex(group => group.name === item.tile_info.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.tile_info.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
      }
    },
    filterRecursive(item, search) {
      if (search === undefined) return
      const searchLower = search.toLowerCase()
      const matches = item.name.toLowerCase().includes(searchLower)
      let filteredChildren = []
      if (item.items) {
        filteredChildren = item.items.map(child => this.filterRecursive(child, search)).filter(Boolean)
      }

      if (matches) {
        return { ...item, items: item.items ? item.items : [] }
      } else if (filteredChildren.length > 0) {
        return { ...item, items: filteredChildren }
      }
      return null
    },
    checkGrammar,
    selectAll(group) {
      if (group.items.length === this.groupCount(group.items)) {
        const groupUuids = group.items.map(item => item.uuid)
        this.layers = this.layers.filter(layer => !groupUuids.includes(layer.uuid))
      } else {
        group.items.forEach(item => {
          if (!this.layers.some(layer => layer.uuid === item.uuid)) {
            this.layers.push(item)
          }
        })
      }
      this.$emit('updateList', this.layers, this.typeList)
    },
    groupCount(items) {
      const layerUuids = this.layers.map(layer => layer.uuid)
      return items.reduce((acc, item) => {
        if (layerUuids.includes(item.uuid)) {
          acc++
        }
        return acc
      }, 0)
    },
    transformArea(area) {
      if (!area) return ''
      return AreaTransform.transformUnit(area)
    },
    resetForm() {
      this.layers = []
    },
    async getDataExplorer(payload = { per_page: 30 }) {
      try {
        this.loading = true
        payload.includes = ['type']
        const res = await getDataExplorer({
          payload: payload,
          projectId: this.$route.params.id,
        })
        return res.data.map(item => {
          if (item.tile_info.type === 'aoi')
            item.tile_info.thumbnail_url = `${config.backend_pub}${item.tile_info.thumbnail_url}`
          return item
        })
      } catch (e) {
        console.warn('Error when get data explorer: ', e)
        return []
      } finally {
        this.loading = false
      }
    },
    infiniteHandler($state) {
      if (this.currentIndex < this.filteredItems.length) {
        const nextItems = this.filteredItems.slice(this.currentIndex, this.currentIndex + this.itemsPerPage)
        this.displayedItems.push(...nextItems)
        this.currentIndex += this.itemsPerPage
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    resetInfinite() {
      this.reload = !this.reload
      this.displayedItems = []
      this.currentIndex = 0
    },
    selectItem(item) {
      if (this.layers.some(layer => layer.uuid === item.uuid)) {
        this.layers = this.layers.filter(layer => layer.uuid !== item.uuid)
      } else {
        this.layers.push(item)
      }
      this.$emit('updateList', this.layers, this.typeList)
    },
    async getData() {
      try {
        this.loading = true
        if (this.typeList === 'AOI') {
          await this.$store.dispatch('AOI/getListAOI', {
            projectId: this.$route.params.id,
            payload: { search: this.search, sort: this.sort },
          })
          this.dataList = this.listAOI
          this.filteredItems = this.listAOI
        }
        if (this.typeList === 'data') {
          const res = await this.getDataExplorer()
          this.dataList = res
          this.filteredItems = this.groupData(res, this.groupBy)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
