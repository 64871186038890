<template>
  <v-card class="custom-card-bg-1" height="100%" width="100%">
    <div
      v-show="displayLayerControl"
      style="width: 360px; height: 95%; position: absolute; bottom: 10px; left: 10px; z-index: 2"
    >
      <DataControl
        ref="layerControl"
        :showLayerControl="showLayerControl"
        v-model:currentLayers="layers"
        v-model:isDraw="isDraw"
        :removable="removable"
        comparable
        map-control
        @addToMap="addToMap"
        @cancel="cancelCreate"
        @changeCompare="show => $refs.map.changeCompare(show)"
        @changeToSyncMap="status => ($refs.map.mapSync = status)"
        @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
        @changeVisibleLeftLayer="layer => $refs.map.changeVisibleLeftLayer(layer.id)"
        @createLayer="createLayer"
        @reloadMap="() => $refs.map.reloadMap()"
        @removeAllLayer="() => $refs.map.removeAllLayer()"
        @removeLayer="layerId => $refs.map.removeLayer(layerId)"
        @saveLayer="saveLayer"
        @setLayer="setLayer"
        @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
        @zoomTo="bbox => $refs.map.submitZoom(bbox)"
      />
    </div>
    <v-btn
      v-show="displayLayerControl"
      color="bgContainer"
      icon="icon-chevrons_left"
      variant="flat"
      size="small"
      style="position: absolute; top: 45%; left: 350px; z-index: 2"
      @click.native.stop="displayLayerControl = false"
    ></v-btn>
    <v-btn
      v-if="showLayerControl"
      v-show="!displayLayerControl"
      color="bgContainer"
      icon="icon-layers"
      variant="flat"
      size="small"
      style="position: absolute; bottom: 10px; left: 10px; z-index: 2"
      @click.native.stop="displayLayerControl = true"
    ></v-btn>
    <Map
      ref="map"
      v-model:currentLayers="layers"
      v-model:drawType="drawType"
      :inspect="inspect"
      v-model:isDraw="isDraw"
      :elements="elements"
      :isCreateText="displayType === 'text'"
      @updateFeatureHandle="feature => $emit('updateFeatureHandle', feature)"
    />
  </v-card>
</template>

<script>
import Map from '@/components/Map.vue'
import sleep from '@/utils/sleep'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import DataControl from '@/components/data-control/DataControl.vue'
import VectorStyle from '@/utils/VectorStyle'
import utils from '@/utils/genUUID'

export default {
  props: {
    elements: {
      type: Array,
      default: undefined,
    },
    inspect: { type: Boolean, default: false },
    showLayerControl: { type: Boolean, default: true },
    removable: { type: Boolean, default: false },
  },
  name: 'CardMapView',
  components: { DataControl, LayerControl, Map },
  data() {
    return {
      layerName: '',
      displayType: '',
      isDraw: false,
      drawType: null,
      displayLayerControl: this.showLayerControl,
      layers: [],
    }
  },
  methods: {
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    drawAOI(AOI) {
      this.$refs.map.drawAOI(AOI)
    },
    getDraw() {
      return this.$refs.map.getAllDrawFeature()
    },
    setLayer(name, geojson) {
      this.$refs.map.addGeoJsonToMap(name, geojson, '#e2ed09', utils.getUUID(), 'line', true)
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      this.$refs.map.createLayer(name, type)
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isDraw = true
    },
    addToMap(val) {
      if (val.tile_info.type === 'images') {
        let image = {
          id: val.name,
          display: true,
          data: val.tile_info,
          layerType: 'Raster_Image',
          name: val.name,
          tiles: [val.tile_info.tile_url],
          bounds: val.tile_info.bbox,
        }
        this.$refs.map.addImageToMap(image)
      } else if (val.tile_info.type === 'aoi') {
        this.$refs.map.addGeoJsonToMap(val.name, val.geojson, '#ff0015', utils.getUUID(), 'line', true)
      } else {
        this.$refs.map.addVectorTiles({
          display: true,
          data: val.tile_info,
          tiles: [val.tile_info.tile_url],
          bounds: val.tile_info.bbox,
          paint: VectorStyle.getStyle(val.tile_info.styles),
          layerName: 'default',
          name: val.name,
          id: val.name,
          type: val.tile_info.styles.type,
        })
      }
    },
    reSize() {
      this.$refs.map.reSize()
    },
    removeAllLayer() {
      this.$refs.map.removeAllLayer()
    },
    async addRasterToMap(layer, display) {
      if (!this.$refs.map.map) {
        await sleep(100)
        await this.addRasterToMap(layer, display)
        return
      }
      let image = {
        dataId: layer.id,
        data: layer,
        layerType: 'Raster_Image',
        name: layer.name,
        tiles: [layer.tile_url],
        bounds: layer.bbox,
        display: display,
        meta: {
          MIN_ZOOM: layer.min_zoom ? (layer.min_zoom > 3 ? layer.min_zoom - 3 : layer.min_zoom) : 0,
          MAX_ZOOM: layer.max_zoom ? layer.max_zoom : 22,
        },
      }
      this.$refs.map.addImageToMap(image)
    },
    zoomTo(bbox) {
      this.$refs.map.submitZoom(bbox)
    },
  },
}
</script>

<style scoped></style>
