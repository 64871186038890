<template>
  <div class="d-flex flex-column">
    <v-tooltip v-for="(menu, index) in listDrawMenuAvailable" :key="index" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          class="elevation-0 mb-2"
          :color="selectedDrawMode === menu.mode ? 'primary' : 'bgContainer'"
          height="38"
          width="38"
          min-height="0"
          min-width="0"
          v-bind="props"
          size="small"
          @click="chooseDrawMode(menu.mode)"
        >
          <v-icon size="22">{{ menu.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ menu.text }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  emits: ['changeMode'],
  props: {
    drawType: { type: String, default: 'polygon' },
    selectedDrawMode: { type: String, default: 'polygon' },
  },
  computed: {
    listDrawMenuAvailable() {
      return this.listDrawMenu.filter(menu => menu.visible)
    },
    listDrawMenu() {
      return [
        {
          icon: 'mdi-vector-polygon',
          text: 'Draw polygon (3)',
          mode: 'polygon',
          visible: !this.drawType || this.drawType === 'polygon',
        },
        {
          icon: 'mdi-rectangle-outline',
          text: 'Draw rectangle (4)',
          mode: 'rectangle',
          visible: !this.drawType || this.drawType === 'polygon',
        },
        {
          icon: 'mdi-vector-rectangle',
          text: 'Draw assist rectangle (5)',
          mode: 'assist-rectangle',
          visible: !this.drawType || this.drawType === 'polygon',
        },
        {
          icon: 'mdi-vector-line',
          text: 'Draw line (6)',
          mode: 'LineString',
          visible: this.drawType === 'line',
        },
        {
          icon: 'mdi-record-circle-outline',
          text: 'Draw point (7)',
          mode: 'Point',
          visible: this.drawType === 'point',
        },
      ]
    },
  },
  methods: {
    chooseDrawMode(mode) {
      this.$emit('changeMode', mode)
    },
  },
}
</script>
