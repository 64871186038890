import MapboxDraw from '@mapbox/mapbox-gl-draw'

import turfLength from '@turf/length'
import * as turfHelpers from '@turf/helpers'
import circle from '@turf/circle'
import createSupplementaryPointsForCircle from '../utils/create_supplementary_points_circle'

const { modes, constants, lib } = MapboxDraw

const { createSupplementaryPoints, moveFeatures, constrainFeatureMovement } = lib
// const createSupplementaryPoints = MapboxDraw.createSupplementaryPoints
// const moveFeatures = MapboxDraw.moveFeatures
// const constrainFeatureMovement = MapboxDraw.constrainFeatureMovement

const DirectModeOverride = modes.direct_select
DirectModeOverride.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta)
  this.getSelected()
    .filter(feature => feature.properties.isCircle)
    .map(circle => circle.properties.center)
    .forEach(center => {
      center[0] += delta.lng
      center[1] += delta.lat
    })
  state.dragMoveLocation = e.lngLat
}

DirectModeOverride.dragVertex = function (state, e, delta) {
  if (state.feature.properties.isCircle) {
    const center = state.feature.properties.center
    const movedVertex = [e.lngLat.lng, e.lngLat.lat]
    const radius = turfLength(turfHelpers.point(center), turfHelpers.point(movedVertex), {
      units: 'kilometers',
    })
    const circleFeature = circle(center, radius)
    state.feature.incomingCoords(circleFeature.geometry.coordinates)
    state.feature.properties.radiusInKm = radius
  } else {
    const selectedCoords = state.selectedCoordPaths.map(coordPath => state.feature.getCoordinate(coordPath))
    const selectedCoordPoints = selectedCoords.map(coords => ({
      type: constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: constants.geojsonTypes.POINT,
        coordinates: coords,
      },
    }))

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta)
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i]
      state.feature.updateCoordinate(
        state.selectedCoordPaths[i],
        coord[0] + constrainedDelta.lng,
        coord[1] + constrainedDelta.lat,
      )
    }
  }
}

DirectModeOverride.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = constants.activeStates.ACTIVE
    push(geojson)
    const supplementaryPoints = geojson.properties.user_isCircle
      ? createSupplementaryPointsForCircle(geojson)
      : createSupplementaryPoints(geojson, {
          map: this.map,
          midpoints: true,
          selectedPaths: state.selectedCoordPaths,
        })
    supplementaryPoints.forEach(push)
  } else {
    geojson.properties.active = constants.activeStates.INACTIVE
    push(geojson)
  }
  this.fireActionable(state)
}

export default DirectModeOverride
