<template>
  <v-menu>
    <template v-slot:activator="{ props: propsMenu }">
      <v-tooltip location="left">
        <template v-slot:activator="{ props: propsTooltip }">
          <v-btn
            class="elevation-0 pt-1 mt-1"
            height="38"
            width="38"
            min-height="0"
            min-width="0"
            v-bind="mergeProps(propsMenu, propsTooltip)"
            size="small"
            variant="flat"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Search location</span>
      </v-tooltip>
    </template>
    <v-card width="400">
      <v-card-title>
        <v-icon>mdi-map</v-icon>
        <span class="ml-4">Search location</span>
      </v-card-title>
      <v-card-text>
        <SearchBarEarth
          ref="textSearch"
          v-model="search"
          :loading="isLoading"
          :hide-spin-buttons="true"
          autofocus
          density="compact"
          label="Search Location"
          variant="outlined"
          placeholder="Search"
          color="primary"
          clearable
          @click.stop
          @blur="isSearch = false"
          @focus="isSearch = true"
          @update:modelValue="debounceInput"
        >
        </SearchBarEarth>
        <v-list v-show="results.length" density="compact" max-height="150" style="overflow-y: auto">
          <v-list-item v-for="(item, index) in results" :key="index" :title="item.name" @click="selectLocation(item)" />
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { debounce } from 'lodash'
import { searchLocation } from '@/api/map'
import { mergeProps } from 'vue'
import SearchBarEarth from './SearchBarEarth.vue'

export default {
  components: { SearchBarEarth },
  name: 'SearchLocation',
  data() {
    return {
      isSearch: false,
      isResult: false,
      address_name: undefined,
      isLoading: false,
      search: '',
      location: undefined,
      results: [],
    }
  },
  watch: {
    search(val) {
      if (val) this.debounceInput()
    },
  },
  methods: {
    mergeProps,
    selectLocation(item) {
      this.location = item.name
      this.changeAddress(item)
    },
    endSearch() {
      this.onSearch = false
    },
    changeAddress(address) {
      if (!address) return
      this.$emit('onSearch', address.bbox)
      this.isResult = false
    },
    zoomTo() {
      if (/^([-+]?)(\d{1,3})((\.)(\d+)(,))(\s*)(([-+]?)(\d{1,2})((\.)(\d+))?)$/.test(this.search)) {
        this.$emit('zoomToCenter', this.search)
      }
    },
    debounceInput: debounce(function () {
      this.searchLocation()
    }, 500),
    async searchLocation() {
      if (!this.search) {
        this.results = []
        return
      }
      try {
        this.isLoading = true
        let res = await searchLocation({ payload: { q: this.search } })
        this.results = []
        this.results = res.data
      } catch (e) {
        console.warn('Error search location', e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
