<template>
  <div :class="'icon-' + icon" v-bind:style="{ 'font-size': size + 'px', color: color }"></div>
</template>
<script>
export default {
  methods: {},
  props: {
    icon: { type: String, require: true },
    color: {
      type: String,
      default: '#ffffff',
    },
    size: { default: 20 },
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style scoped></style>
