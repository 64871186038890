<template>
  <v-menu location="bottom">
    <template v-slot:activator="{ props: propsMenu }">
      <v-tooltip location="left">
        <template v-slot:activator="{ props: propsTooltip }">
          <v-badge
            v-model="hasFilter"
            location="bottom right"
            offset-x="6"
            offset-y="6"
            color="success"
            dot
            v-bind="mergeProps(propsMenu, propsTooltip)"
          >
            <v-btn size="small" variant="text" icon="true">
              <v-icon :color="hasFilter ? 'accent' : ''" size="26">icon-filter</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Filter</span>
      </v-tooltip>
    </template>
    <v-card class="custom-card-bg-2" style="box-shadow: 2px 2px 8px rgba(0, 0, 0, 1)">
      <v-card-text>
        <div class="filter-field my-3" v-for="(f, ind) in listFilters" :key="ind">
          <v-select
            @click.stop
            :items="f.items"
            v-model="filters[f.value]"
            :label="f.name"
            density="compact"
            variant="outlined"
            hide-details
            item-title="name"
            item-value="value"
          ></v-select>
        </div>
        <div @click.stop class="d-flex align-end" style="width: 250px; min-width: 250px">
          <v-spacer></v-spacer>
          <span @click="clearFilter" class="clear-filter">Clear filter</span>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mergeProps } from 'vue'

export default {
  props: {
    listFilters: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      menuFilter: false,
      filters: {},
    }
  },
  computed: {
    hasFilter() {
      return Object.keys(this.filters).length > 0
    },
  },
  watch: {
    filters: {
      async handler() {
        await this.$store.commit('notification/SET_STATE_PROPERTY', {
          property: 'filterNotification',
          value: this.filters,
        })
        this.filterData()
      },
      deep: true,
    },
    menuFilter(val) {
      this.$store.commit('notification/SET_STATE_PROPERTY', {
        property: 'isOpenChildElement',
        value: val,
      })
    },
  },
  mounted() {
    this.clearFilter()
  },
  methods: {
    mergeProps,
    filterData() {
      this.$emit('filterData')
    },
    clearFilter() {
      this.filters = {}
      this.filterData()
    },
  },
}
</script>
<style lang="scss" scoped>
:deep{
  &.v-theme--dark.v-sheet {
    background-color: #434659 !important;
  }
  &.v-theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background-color: #666c86 !important;
  }
}
.clear-filter {
  cursor: pointer;
  text-decoration: underline;
}
</style>
