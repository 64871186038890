import api from '@/api/api'
import config from '@/config'

export function getLegends(params) {
  return api.get(`${config.backend}/map/legend`, params)
}
export function getDataDetection(params) {
  return api.get(
    `${config.backend}/projects/${params.projectId}/aois/${params.aoiId}/dashboard/object-detection/time-series`,
    params.payload,
  )
}

export function getLayer(params) {
  return api.get(
    `${config.backend}/projects/${params.projectId}/aois/${params.aoiId}/map/vectors/${params.vectorId}/object-detection`,
    params.payload,
  )
}
