<template>
  <v-dialog
    class="fill-height"
    content-class="bottom-right-dialog"
    v-model="dialogChat"
    :width="expandWindow ? '' : '500'"
    :fullscreen="expandWindow"
  >
    <v-card class="body-chat">
      <v-card-title class="d-flex align-center">
        <v-img :src="logoSource" max-width="24" min-width="24" />
        <span class="chat-title">Platform Assistant</span>
        <div class="chat-handle">
          <v-btn size="small" color="#9155fd" @click="makeNewConversation">
            <v-icon>mdi-chat-plus-outline</v-icon>
            <span class="chat-handle">New chat</span>
          </v-btn>
          <v-menu v-model="menuRecent">
            <template #activator="{ props: propsMenu }">
              <v-tooltip location="bottom">
                <template #activator="{ props: propsTooltip }">
                  <v-btn icon="mdi-history" variant="text" v-bind="mergeProps(propsMenu, propsTooltip)"></v-btn>
                </template>
                <span>Recent</span>
              </v-tooltip>
            </template>
            <v-card width="400px">
              <v-card-title> Recent activity </v-card-title>
              <v-card-text class="list-recent">
                <v-progress-linear
                  indeterminate
                  color="primary secondary"
                  v-if="loadingRecentActivity"
                ></v-progress-linear>
                <v-list>
                  <div v-for="(conversation, i) in conversationsAvailable" :key="i">
                    <v-list-subheader v-if="conversation.isGroup" class="group-name">
                      {{ conversation.group }}
                    </v-list-subheader>
                    <v-list-item v-else @click="openChat(conversation)" class="hover-border-radius">
                      <v-list-item-title style="font-size: 16px !important">
                        {{ conversation.description }}
                      </v-list-item-title>
                      <template v-slot:append>
                        <v-menu>
                          <template #activator="{ props: propsMenu }">
                            <v-tooltip location="top">
                              <template #activator="{ props: propsTooltip }">
                                <v-img
                                  :src="logoHorizontalDotsSource"
                                  v-bind="mergeProps(propsMenu, propsTooltip)"
                                  width="20"
                                />
                              </template>
                              <span>Options</span>
                            </v-tooltip>
                          </template>

                          <v-list>
                            <v-list-item @click.stop="editConversation(conversation)">
                              <div class="d-flex align-center">
                                <v-icon small>mdi-pencil</v-icon>
                                <span class="ml-2">Rename</span>
                              </div>
                            </v-list-item>
                            <v-list-item @click.stop="deleteConversation(conversation)">
                              <div class="d-flex align-center">
                                <v-icon small>icon-trash</v-icon>
                                <span class="ml-2">Delete</span>
                              </div>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-list-item>
                  </div>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              class="clickable-base"
              :icon="expandWindow ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
              size="small"
              variant="text"
              v-bind="props"
              @click="expandWindow = !expandWindow"
            ></v-btn>
          </template>
          <span>{{ expandWindow ? 'Collapse window' : 'Expand window' }}</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              icon="mdi-window-close"
              size="small"
              variant="text"
              v-bind="props"
              @click="dialogChat = false"
            ></v-btn>
          </template>
          <span>Close window</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div class="box-area" :style="stylesBoxArea">
          <div class="box-map" :style="stylesBoxMap">
            <div v-show="hasMap" class="fill-height" style="position: relative">
              <Map
                ref="mapBot"
                v-model:currentLayers="layers"
                v-model:drawType="drawType"
                :isCreateText="displayType === 'text'"
                v-model:isDraw="isCreating"
                :isMiniMap="!expandWindow"
                :isChat="true"
                :typeCreateAOI="CHAT_TYPE_CREATE_AOI"
                @featureInfo="onPointClick"
              />
            </div>
          </div>
          <div class="box-chat" :style="stylesBoxChat">
            <div v-if="messages.length > 0" class="handle-map">
              <v-tooltip location="right">
                <template #activator="{ props }">
                  <v-btn
                    variant="text"
                    :icon="hasMap ? 'mdi-map-minus' : 'mdi-map-plus'"
                    v-bind="props"
                    @click="hasMap = !hasMap"
                  ></v-btn>
                </template>
                <span>{{ hasMap ? 'Hide map' : 'Display map' }}</span>
              </v-tooltip>
            </div>
            <ChatgptBoxBody
              ref="chatgptBoxBody"
              @addToMap="addToMap"
              @startDraw="startDraw"
              @cancelDraw="cancelDraw"
              @addServiceResult="addServiceResult"
            />
            <ChatgptBoxFooter
              ref="chatgptBoxFooter"
              :scroll-to-bottom="scrollToBottom"
              @makeNewConversation="makeNewConversation"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>

    <RenameDialog ref="renameDialog" @submit="renameConversation" />
  </v-dialog>
</template>
<script>
import { upperFirst } from 'lodash'
import { mapState } from '@/store/ults'
import Map from './components/clone-components/Map.vue'
import utils from '@/utils/genUUID'
import ChatgptBoxBody from './ChatgptBoxBody.vue'
import ChatgptBoxFooter from './ChatgptBoxFooter.vue'
import ConvertDate from '@/utils/convertDate'
import RenameDialog from './components/RenameDialog.vue'
import { CHAT_TYPE_CREATE_AOI, CHAT_TYPE_ONLY_CREATE_AOI, HOTSPOTS, EARTHQUAKE } from '@/constants/chat'
import { getDetailAOI } from '@/api/aoi-api'
import { renameIncrease } from '@/utils/stringHandle'
import { mergeProps } from 'vue'
import { TASKING_ORDER_CREATE } from '@/constants/permission'

const logoSource = new URL('@/assets/images/logos/logo.svg', import.meta.url).href
const logoHorizontalDotsSource = new URL('@/assets/svg/horizontal-dots.svg', import.meta.url).href
const fireHighImageSource = new URL('@/assets/images/marker/fire-high.png', import.meta.url).href
const fireModerateImageSource = new URL('@/assets/images/marker/fire-moderate.png', import.meta.url).href
const fireLowImageSource = new URL('@/assets/images/marker/fire-low.png', import.meta.url).href
import { getDetailEarthquake, getDetailHotspots } from '@/api/hotspots-api'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'
export default {
  components: {
    Map,
    ChatgptBoxBody,
    ChatgptBoxFooter,
    RenameDialog,
    CreateOrder,
  },
  props: {
    styleChatArea: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('chat', ['conversations', 'loadingRecentActivity', 'messages', 'dialogChat']),
    ...mapState('auth', ['currentUser']),
    conversationsAvailable() {
      const conversations = []
      const groups = []
      this.conversations.forEach(conversation => {
        const group = ConvertDate.categorizeDates(conversation.created_at)
        if (!groups.includes(group)) {
          groups.push(group)
          conversations.push({
            group,
            isGroup: true,
          })
        }
        conversations.push({
          ...conversation,
          description: upperFirst(conversation.description),
        })
      })
      return conversations
    },
  },
  data() {
    return {
      menuOption: false,
      CHAT_TYPE_CREATE_AOI,
      CHAT_TYPE_ONLY_CREATE_AOI,
      logoHorizontalDotsSource,
      logoSource,
      // style
      stylesBoxArea: {
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        height: 'calc(100vh - 222px)',
      },
      stylesBoxMap: {
        height: '0',
      },
      stylesBoxChat: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      expandWindow: false,
      expandHandle: null,
      hasMap: false,
      menuRecent: false,
      selectedConversation: null,
      // map
      layers: [],
      isDraw: false,
      isCreating: false,
      layerControl: false,
      drawType: '',
      displayType: '',
      itemIncludeMap: ['aoi'],
      service: undefined,
    }
  },
  watch: {
    isCreating(val) {
      if (val) this.layerControl = false
      else this.$refs.mapBot.deleteAllDraw()
    },
    async menuRecent(val) {
      if (val) {
        await this.$store.dispatch('chat/getRecentConversations', {
          projectUuid: this.$route.params.id,
        })
      }
    },
    messages(val) {
      if (val.length === 0) this.hasMap = false
      if (!this.hasMap && val.length > 0)
        this.hasMap = this.itemIncludeMap.some(item => val[val.length - 1].content.includes(item))
    },
    async hasMap(val) {
      this.$refs.mapBot.reSize()
      this.setRatioView(val, this.expandWindow)
    },
    expandWindow(val) {
      const areaStyle = val
        ? {
            flexDirection: 'row-reverse',
            height: 'calc(100vh - 80px)',
          }
        : {
            flexDirection: 'column',
            height: 'calc(100vh - 222px)',
          }
      this.stylesBoxArea = {
        ...this.stylesBoxArea,
        ...areaStyle,
      }
      this.setRatioView(this.hasMap, val)
      this.$nextTick(() => {
        if (this.hasMap) this.$refs.mapBot.reSize()
      })
      this.$refs.chatgptBoxFooter.focusTextarea()
    },
  },
  methods: {
    mergeProps,
    // map
    startDraw() {
      if (!this.hasMap) this.hasMap = true
      this.expandWindow = true
      this.$nextTick(() => {
        this.isCreating = true
      })
    },
    async getInfoAOI(AOI) {
      this.$refs.mapBot.removeAllLayer()
      let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
      res.data.geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: res.data.geojson,
          },
        ],
      }
      return res.data
    },
    addToMap(AOI) {
      if (!this.hasMap) this.hasMap = true
      this.$nextTick(async () => {
        this.$refs.mapBot.removeAllLayer()
        const res = await this.getInfoAOI(AOI)
        this.$refs.mapBot.addGeoJsonToMap(res.name, res.geojson, 'red', res.uuid, 'line', true)
        this.$refs.mapBot.submitZoom(res.bbox)
        // AOI.display = true
      })
    },

    // map - layer
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.mapBot.deleteAllDraw()
      this.isDraw = false
    },
    saveLayer() {
      let features = this.$refs.mapBot.getAllDrawFeature()
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.mapBot.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.mapBot.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.mapBot.addGeoJsonToMap(
          this.layerName,
          featureCollection,
          'yellow',
          utils.getUUID(),
          vectorType,
          true,
        )
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.mapBot.deleteAllDraw()
      this.isCreating = false
    },
    createLayer(name, type) {
      this.layerName = renameIncrease(
        name,
        this.layers.map(layer => layer.name),
      )
      this.displayType = type
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isCreating = true
    },
    cancelDraw() {
      this.isCreating = false
      this.$refs.mapBot.deleteAllDraw()
    },
    addClusterToMap(geoJson, customIcon = undefined, hover = true) {
      this.$refs.mapBot.removeCluster()
      this.$refs.mapBot.displayCluster(geoJson, hover, customIcon)
      this.$refs.mapBot.disableEditDraw()
    },
    onPointClick(features, point) {
      this.displayPopup(features, point, this.service)
    },

    convertCoordinatesToGeojson(result) {
      let geoJson = {
        type: 'FeatureCollection',
        features: [],
      }
      result.forEach(point => {
        point.isFire = true
        geoJson.features.push({
          type: 'Feature',
          properties: point,
          geometry: {
            coordinates: JSON.parse(point.geojson).coordinates,
            type: 'Point',
          },
        })
      })

      return geoJson
    },
    // chat handle
    scrollToBottom() {
      this.$refs.chatgptBoxBody.scrollToBottom()
    },
    open() {
      if (this.currentConversation) this.isNewMessage = false
      this.dialogChat = true
    },
    async openChat(conversation) {
      this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'isNewMessage', value: false })
      await this.$store.dispatch('chat/getListMessages', { conversationId: conversation.id })
      this.scrollToBottom()
      this.$store.commit('chat/SET_STATE_PROPERTY', {
        property: 'currentConversation',
        value: conversation,
      })
    },
    deleteConversation(conversation) {
      this.$store.dispatch('chat/deleteConversation', conversation.id)
    },
    makeNewConversation() {
      this.$refs.mapBot.map.setZoom(0)
      this.$refs.mapBot.removeAllLayer()
      this.$refs.mapBot.removeCluster()
      this.$store.dispatch('chat/makeNewConversation')
    },
    editConversation(conversation) {
      this.$refs.renameDialog.open(conversation)
    },
    renameConversation(conversation) {
      this.$store.dispatch('chat/updateConversation', {
        conversationId: conversation.id,
        description: conversation.description,
      })
    },
    setRatioView(hasMap, expandWindow) {
      const ratioWidthMap = 50
      const ratioHeightMap = 35
      const rWidthMap = hasMap ? (expandWindow ? ratioWidthMap : 100) : 0
      const rHeightMap = hasMap ? (expandWindow ? 98 : ratioHeightMap) : 0

      this.stylesBoxMap = {
        ...this.stylesBoxMap,
        width: `${rWidthMap}%`,
        height: `${rHeightMap}%`,
      }

      this.stylesBoxChat = {
        ...this.stylesBoxChat,
        width: expandWindow ? `${100 - rWidthMap}%` : '100%',
        height: expandWindow ? '100%' : `${100 - rHeightMap}%`,
      }
    },
    // service
    addServiceResult(serviceKey, result) {
      this.service = serviceKey
      switch (serviceKey) {
        case HOTSPOTS:
          let filter = [
            'case',
            ['==', ['get', 'confidence'], 'l'],
            'fire-low',
            ['==', ['get', 'confidence'], 'n'],
            'fire-moderate',
            'fire-high',
          ]
          let customIcon = {
            images: [
              { src: fireHighImageSource, id: 'fire-high' },
              { src: fireModerateImageSource, id: 'fire-moderate' },
              { src: fireLowImageSource, id: 'fire-low' },
            ],
            filterIcon: filter,
          }
          let geoJson = this.convertCoordinatesToGeojson(result)
          this.addClusterToMap(geoJson, customIcon)
          break
        case EARTHQUAKE:
          let newResult = {}
          if (result) {
            newResult = {
              ...result,
              style: {
                'circle-color': [
                  'case',
                  ['<', ['get', 'mag'], '1'],
                  '#f9ff8e',
                  ['<', ['get', 'mag'], '2'],
                  '#ffc500',
                  ['<', ['get', 'mag'], '3'],
                  '#ff9a50',
                  ['<', ['get', 'mag'], '4'],
                  '#ff7070',
                  ['<', ['get', 'mag'], '5'],
                  '#ff7950',
                  ['<', ['get', 'mag'], '6'],
                  '#ff5f00',
                  ['<', ['get', 'mag'], '7'],
                  '#fd0069',
                  '#FF0000',
                ],
                'circle-radius': [
                  'case',
                  ['<', ['get', 'mag'], '1'],
                  2,
                  ['<', ['get', 'mag'], '2'],
                  3,
                  ['<', ['get', 'mag'], '3'],
                  4,
                  ['<', ['get', 'mag'], '4'],
                  6,
                  ['<', ['get', 'mag'], '5'],
                  8,
                  ['<', ['get', 'mag'], '6'],
                  10,
                  ['<', ['get', 'mag'], '7'],
                  12,
                  15,
                ],
                'circle-stroke-width': ['case', ['<', ['get', 'mag'], '1'], 1, ['<', ['get', 'mag'], '2'], 2, 3],
                'circle-stroke-color': '#fffdfd',
              },
            }
          }
          this.addClusterToMap(newResult)
          break
      }
    },
    createPopup(point, data, features, dataType = 'forest_fire') {
      let properties = ''
      for (const property in data) {
        if (property !== 'geojson' && property !== 'isFire' && property !== 'isUpload') {
          properties += `
            <div style='color: black; margin-left: 10px; text-align: left;'>
                <b style='text-transform: capitalize;'>${property}: </b>${data[property]}
            </div>
        `
        }
      }

      let buttonHtml = ''
      if (this.currentUser.permissions.includes(TASKING_ORDER_CREATE)) {
        buttonHtml = `
        <div style='color: black; margin-left: 10px; margin-top: 12px; text-align: left;'>
            <button
                id='task'
                style='
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 4px 12px;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                    background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
                    background-origin: border-box;
                    box-shadow: 0 1px 2px rgba(54, 122, 246, 0.25), inset 0 1px 0 -0.25px rgba(255, 255, 255, 0.2);
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                '
                role='button'
            >
                Add to tasking
            </button>
        </div>
    `
      }

      properties += buttonHtml

      let html = `<div style='width: fit-content; text-align: left;'>` + properties + `</div>`
      this.$refs.mapBot.closePopup()
      this.$refs.mapBot.displayPopup(point, html, true)
      const btn = document.getElementById('task')
      btn.addEventListener('click', () => {
        this.$refs.createOrder.openDialog({
          geojson: {
            type: 'FeatureCollection',
            features: [features[0]],
          },
          dataType: dataType,
          name: `${dataType}${data.date ? '_' + data.date : ''}`,
          id: features[0].properties.id,
        })
      })
    },
    async displayPopup(features, point, type) {
      try {
        this.loading = true
        let res
        if (type === 'hotspots' && features[0].properties.isFire) {
          res = await getDetailHotspots({
            payload: {
              source: 'modis',
              id: features[0].properties.id,
            },
          })
        } else if (type === 'earthquake' && features[0].properties.mag) {
          res = await getDetailEarthquake({
            id: features[0].properties.id,
          })
        }

        if (res) {
          this.createPopup(point, res.data, features, type)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
:deep{
  &.v-btn-toggle > .v-btn.v-btn {
    padding: 15px !important;
  }

  &.v-btn--icon.v-size--x-small {
    height: 16px !important;
  }

  &.v-list-item:hover {
    border-radius: 8px;
  }

  &.body-chat.v-card > .v-card-text {
    padding-bottom: 4px !important;
  }
}
.hover-border-radius {
  border-radius: 8px;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
}
.hover-border-radius:hover,
.hover-border-radius:hover .v-list-item-content {
  border-radius: 8px; /* Ensure inner elements match the border radius */
}
$padding: 8px;
.body-chat {
  overflow: hidden !important;
}
.chat-box {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $padding;
  height: 100%;
}
.chat-handle,
.chat-title {
  margin-left: 10px;
}

.list-recent {
  max-height: 300px;
  overflow-y: auto;
}
.group-name {
  color: #aaa;
  padding: 0;
}
</style>
