<template>
  <v-dialog v-model="dialog" persistent width="1000">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Create your AOI'" @close="dialog = false" />
      <v-card-text>
        <div style="display: flex; flex-direction: row">
          <div
            class="d-flex flex-column justify-center pa-5 ma-2"
            style="flex: 1; border: 1px solid #5d5d5d; border-radius: 10px"
          >
            <div class="d-flex align-center justify-center" style="height: 200px">
              <div style="width: 250px; height: 220px">
                <v-img :src="createSvgSource" />
              </div>
            </div>
            <div class="" style="width: 100%; height: 175px; border-radius: 10px">
              <DragBox :accept-types="acceptTypes" v-model:files="files" multiple />
            </div>
          </div>
          <div
            class="d-flex flex-column justify-center pa-5 ma-2"
            style="flex: 1; border: 1px solid #5d5d5d; border-radius: 10px"
          >
            <div class="d-flex align-center justify-center" style="height: 200px">
              <div class="" style="width: 100%; height: 100%">
                <v-img :src="drawAoiSvgSource" />
              </div>
            </div>
            <div class="custom-zone" style="height: 175px; cursor: pointer" @click="() => $emit('startDraw')">
              <h3>Click to draw a AOI</h3>
              <div class="ml-2 mr-2" style="text-align: center; font-size: 12px; color: #a8a8a8">
                Draw your field on a map, shaping polygon with a drawing tool
              </div>
            </div>
          </div>

          <div
            v-if="isBoundary"
            class="d-flex flex-column justify-center pa-5 ma-2"
            style="flex: 1; border: 1px solid #5d5d5d; border-radius: 10px"
          >
            <div class="d-flex align-center justify-center" style="height: 200px">
              <div style="width: 250px; height: 220px">
                <v-img :src="createSvgSource" />
              </div>
            </div>
            <div
              class="custom-zone"
              style="height: 175px; cursor: pointer"
              @click="() => $refs.adminBoundary.openDialog()"
            >
              <h3>Admin Boundary</h3>
              <div class="ml-2 mr-2" style="text-align: center; font-size: 12px; color: #a8a8a8">
                Define your analysis area by simply selecting administrative boundaries (state, county etc.).
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <AOIConfig v-if="dialog" ref="config" @closeConfig="uploadDialog = false" @fetchAOI="() => $emit('fetchAOI')" />
    <AdminBoundary
      ref="adminBoundary"
      @fetchAOI="
        () => {
          $emit('fetchAOI')
          dialog = false
        }
      "
    />
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { uploadAOI } from '@/api/aoi-api'
import Map from '@/components/Map.vue'
import AOIConfig from '@/views/aoi/AOIConfig.vue'
import DragBox from '@/components/DragBox.vue'
import AdminBoundary from './AdminBoundary.vue'

const createSvgSource = new URL('@/assets/svg/create.svg', import.meta.url).href
const drawAoiSvgSource = new URL('@/assets/svg/draw-aoi.svg', import.meta.url).href

export default {
  name: 'UploadAOI',
  components: { DragBox, AOIConfig, Map, DialogHeader, AdminBoundary },
  data() {
    return {
      createSvgSource,
      drawAoiSvgSource,
      dialog: false,
      loading: false,
      geojson: {},
      config: false,
      rules: {
        fileRequired: value => !!value || 'File is required',
      },
      valid: false,
      files: [],
      acceptTypes: '.zip,.geojson,.kml,.gml,.kmz,.shp,.shx,.prj,.dbf',
    }
  },
  props: {
    isBoundary: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    files(val) {
      if (val.length !== 0) this.submitUpload()
      if (!val.length) {
        this.geojson = {}
      }
    },
  },
  methods: {
    async openDialog() {
      this.dialog = true
      this.files = []
      this.geojson = {}
    },
    async submitUpload() {
      try {
        this.loading = true
        const formData = new FormData()
        let totalItemOfShapeFile = 0
        let types = []
        for (let i = 0; i < this.files.length; i++) {
          let extension = this.files[i].name.split('.').pop()
          switch (extension.toLowerCase()) {
            case 'zip':
            case 'geojson':
            case 'kml':
            case 'kmz':
            case 'gml':
              formData.append('files[]', this.files[i])
              formData.append('type', extension.toLowerCase())
              types.push(extension.toLowerCase())
              break
            case 'shp':
            case 'prj':
            case 'shx':
            case 'dbf':
              totalItemOfShapeFile = totalItemOfShapeFile + 1
              formData.append('files[]', this.files[i])
              if (totalItemOfShapeFile === 1) formData.append('type', 'shp')
              types.push('shp')
              break
          }
        }
        if ([...new Set(types)].length > 1)
          return this.$store.commit('message/SHOW_ERROR', 'The system does not support many file formats')
        const res = await uploadAOI(formData)
        this.geojson = res.data
        this.$refs.config.openDialog(this.geojson)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.custom-zone {
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-zone:hover {
  border: 2px dashed #2e94c4;
  color: #2e94c4;
}
</style>
