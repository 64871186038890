<template>
  <div>
    <v-menu
      ref="menu"
      v-model="dateMenu"
      :close-on-content-click="false"
      min-width="auto"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
          v-model="customDate"
          :clearable="$attrs['clearable']"
          :density="density"
          :disabled="disabled"
          :hide-details="$attrs['hide-details']"
          :label="label"
          :variant="variant"
          :placeholder="$attrs['placeholder']"
          :prepend-inner-icon="$attrs['prepend-inner-icon']"
          :append-inner-icon="$attrs['append-inner-icon']"
          :rules="rules"
          readonly
          @click:appendInner="dateMenu = !dateMenu"
          v-bind="props"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" :max="max" :min="min" @change="dateMenu = false"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import ConvertDate from '@/utils/convertDate'
export default {
  props: {
    modelValue: { type: String, default: undefined },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: 'YYYY-MM-DD format' },
    density: { type: String, default: 'compact' },
    variant: { type: String, default: 'outlined' },
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue() {
      this.dateModel = this.modelValue
    },
    date() {
      this.dateMenu = false
    }
  },
  computed: {
    date: {
      get() {
        return this.dateModel ? new Date(this.dateModel) : undefined
      },
      set(val) {
        this.dateModel = val
        this.$emit('update:modelValue', ConvertDate.dateFormatted(this.dateModel))
      },
    },
    customDate: {
      get() {
        if (this.date === 'Invalid Date') return undefined
        return ConvertDate.dateFormatted(this.date)
      },
      set(val) {

      }
    }
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined,
  }),
  created() {
    this.dateModel = this.modelValue
  },
}
</script>
