export default {
  namespaced: true,
  state: {
    unit: 'km',
    planetKey: '1221cfc2e35a4b8b838245a88aa96a50',
    serviceKeys: {
      planet: '',
      tasking: '',
    },
    keys: [
      'pk.eyJ1IjoibmdvYy1kaWVwLTIwMTEiLCJhIjoiY2xpYjhvc2ppMDJ3czNmazJsem4xbjd4byJ9.pUUROmkopbFNgOYZi6rvTg',
      'pk.eyJ1IjoiaGFpLWNhby10djIiLCJhIjoiY2xpYmNyZndqMGFmbTNldDdlYWhnMHg0eCJ9.8cFoQLHoxpfUpE0efforuA',
      'pk.eyJ1IjoicGFub2wtcm9jazU2IiwiYSI6ImNsaWJjemV5bTA2OGozbHF4YnNnejQ3bjAifQ.blRDTUDLdOeTBiS3zsAX2Q',
      'pk.eyJ1IjoicmF5aS15ZS03NzA2IiwiYSI6ImNsaWJkYW8zNzBhZWczZm8yYWRmeGR5ejQifQ.iIe4UemWR9VUVtNYYGz4Xg',
      'pk.eyJ1IjoibmktcGV4LTM3OSIsImEiOiJjbGliZGUwZjUwNmgzM2RtcWh0dGUwZ3ExIn0.aP03Vz62tHna3-r4Yzmhwg',
      'pk.eyJ1Ijoidnd5NTk3MjMiLCJhIjoiY2xpYmRjZG9mMGFvdzNqbjEycDdlaDZ4dCJ9.L-YB_i0-XVSBjjBJh6HlHA',
      'pk.eyJ1Ijoia3pvNzQ5NTciLCJhIjoiY2xpYmQ3NDdmMGFqaTNtbzJqZmx2cXJzcyJ9.cTycHfqm21oHKr9ZwmUy3w',
      'pk.eyJ1IjoiYndjMjgxNjMiLCJhIjoiY2xpYmQzZTBlMGFrdjNybXd1YzU2c3MyeiJ9.1QkDQozuLNmMdKb3_kjVxQ',
      'pk.eyJ1IjoiaGZhMjYwMDAyIiwiYSI6ImNsaWJjeTR2bTBhbGczc3BtNWppZXUyeDEifQ.epS2okexc5N15zwvYdw8dw',
      'pk.eyJ1IjoiaGllbnR0IiwiYSI6ImNsaWI4b2RueDA5ZjgzZW41Mzd2ems2ejEifQ.fryucFtL3f4zWloN7Xkuuw',
    ],
  },
  mutations: {},
  actions: {},
}
