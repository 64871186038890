import MapboxglDraw from '@mapbox/mapbox-gl-draw'
// import { createSupplementaryPoints, moveFeatures, modes, constants } from '@mapbox/mapbox-gl-draw'
// import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points'
// import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features'
// import Modes from '@mapbox/mapbox-gl-draw/src/modes'
// import * as Constants from '@mapbox/mapbox-gl-draw/src/constants'
import createSupplementaryPointsForCircle from '../utils/create_supplementary_points_circle'

// const { createSupplementaryPoints, moveFeatures, modes, constants } = MapboxglDraw
const { modes, constants, lib } = MapboxglDraw
const { createSupplementaryPoints, moveFeatures } = lib

const SimpleSelectModeOverride = modes.simple_select

SimpleSelectModeOverride.dragMove = function (state, e) {
  // Dragging when drag move is enabled
  state.dragMoving = true
  e.originalEvent.stopPropagation()

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  }

  moveFeatures(this.getSelected(), delta)

  this.getSelected()
    .filter(feature => feature.properties.isCircle)
    .map(circle => circle.properties.center)
    .forEach(center => {
      center[0] += delta.lng
      center[1] += delta.lat
    })

  state.dragMoveLocation = e.lngLat
}

SimpleSelectModeOverride.toDisplayFeatures = function (state, geojson, display) {
  geojson.properties.active = this.isSelected(geojson.properties.id)
    ? constants.activeStates.ACTIVE
    : constants.activeStates.INACTIVE
  display(geojson)
  this.fireActionable()
  if (
    geojson.properties.active !== constants.activeStates.ACTIVE ||
    geojson.geometry.type === constants.geojsonTypes.POINT
  )
    return
  const supplementaryPoints = geojson.properties.user_isCircle
    ? createSupplementaryPointsForCircle(geojson)
    : createSupplementaryPoints(geojson)
  supplementaryPoints.forEach(display)
}

export default SimpleSelectModeOverride
