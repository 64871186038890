<template>
  <v-form ref="formData" v-model="valid" @submit.prevent style="height: 100%">
    <v-overlay :model-value="loading" class="align-center justify-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex flex-column">
      <v-text-field
        v-model="name"
        :rules="[rules.required]"
        label="Name"
        variant="outlined"
        placeholder="Name"
      ></v-text-field>
      <div class="d-flex">
        <div class="mr-1" style="flex: 1">
          <v-select
            v-model="form.pl_number"
            :items="contracts"
            :rules="[rules.required]"
            item-title="pl_number"
            item-value="pl_number"
            label="Contract number"
            variant="outlined"
            placeholder="Contract number"
          ></v-select>
        </div>
        <div class="ml-1" style="flex: 2">
          <v-select
            v-model="form.product"
            :items="products"
            :rules="[rules.required]"
            item-title="name"
            item-value="name"
            label="Product name"
            variant="outlined"
            placeholder="Product name"
            @update:modelValue="changeProduct"
          ></v-select>
        </div>
      </div>
      <div class="d-flex align-center mb-4">
        <v-btn
          v-if="currentProduct && currentProduct.allowed_geometries.some(val => val === 'Point')"
          :color="geometryType.includes('Point') ? '#1976D2' : ''"
          class="mx-2"
          min-height="80"
          variant="outlined"
          width="175"
          @click="geometryType = 'Point'"
        >
          <div class="d-flex flex-column align-center fill-height justify-center">
            <h4>Point</h4>
            <v-icon class="mt-1">mdi-map-marker-radius</v-icon>
          </div>
        </v-btn>
        <v-btn
          v-if="
            currentProduct &&
            currentData.geojson.features[0].geometry.type.includes('Polygon') &&
            currentProduct.allowed_geometries.some(val => val === 'Polygon')
          "
          :color="geometryType.includes('Polygon') ? '#1976D2' : ''"
          class="mx-2"
          min-height="80"
          variant="outlined"
          width="175"
          @click="geometryType = 'Polygon'"
        >
          <div class="d-flex flex-column align-center fill-height justify-center">
            <h4>Polygon</h4>
            <v-icon class="mt-1">mdi-vector-square</v-icon>
          </div>
        </v-btn>
        <div v-if="errorGeometryType" class="ml-4" style="color: yellow">
          {{ errorGeometryType }}
        </div>
      </div>
      <div v-if="geometryType.includes('Point')" class="mb-4">
        <div class="d-flex">
          <div style="flex: 1">
            <v-text-field
              v-model.number="coordinates[1]"
              class="mr-2"
              label="Latitude"
              variant="outlined"
              type="number"
              @update:modelValue="changeCoordinate"
            ></v-text-field>
          </div>
          <div style="flex: 1">
            <v-text-field
              v-model.number="coordinates[0]"
              label="Longitude"
              variant="outlined"
              type="number"
              @update:modelValue="changeCoordinate"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="d-flex align-center" @click="toi = !toi">
        <div class="px-3">TIME OF INTEREST</div>
        <v-divider />
        <v-btn :icon="toi ? 'mdi-chevron-up' : 'mdi-chevron-down'" variant="text"></v-btn>
      </div>
      <div class="d-flex flex-column pa-4" v-show="toi">
        <div class="d-flex flex-column" v-if="form.product === 'Monitoring Tasking'">
          <div class="d-flex align-start">
            <div class="pt-4" style="width: 80px; flex: none">Starts</div>
            <div style="width: 100%">
              <DatePicker
                v-model="form.start_time"
                :min="convertDate(new Date())"
                label="Starts time"
                prepend-inner-icon="mdi-calendar-month-outline"
                @input="getPreview"
              ></DatePicker>
            </div>
          </div>
          <div class="d-flex align-start">
            <div class="pt-4" style="width: 80px; flex: none">Frequency</div>
            <div class="pt-4" style="width: 100px">every</div>
            <div style="width: 130px">
              <v-text-field
                v-model.number="toiData.interval"
                :max="30"
                :min="1"
                :rules="[rules.requiredInterval]"
                class="mr-2"
                label="Interval"
                variant="outlined"
                type="number"
                @update:modelValue="getPreview"
              ></v-text-field>
            </div>
            <v-select
              v-model="toiData.freq"
              :items="freqs"
              class="ml-2"
              item-title="name"
              item-value="value"
              label="Frequency"
              variant="outlined"
              placeholder="Frequency"
              @update:modelValue="getPreview"
            ></v-select>
          </div>
          <div class="d-flex align-center" v-show="toiData.freq === 'WEEKLY'">
            <div style="width: 80px; flex: none"></div>
            <div style="width: 100px">on</div>
            <div class="d-flex align-center pb-4">
              <v-chip-group v-model="toiData.by_day" active-class="primary" column @update:modelValue="getPreview">
                <v-chip class="mx-1" value="Mo"> Mo </v-chip>
                <v-chip class="mx-1" value="Tu"> Tu </v-chip>
                <v-chip class="mx-1" value="We"> We </v-chip>
                <v-chip class="mx-1" value="Th"> Th </v-chip>
                <v-chip class="mx-1" value="Fr"> Fr </v-chip>
                <v-chip class="mx-1" value="Sa"> Sa </v-chip>
                <v-chip class="mx-1" value="Su"> Su </v-chip>
              </v-chip-group>
            </div>
          </div>
          <div class="d-flex align-center" v-show="toiData.freq === 'MONTHLY'">
            <v-radio-group v-model="typeOfMonth" @update:modelValue="getPreview">
              <div class="d-flex">
                <div style="width: 80px; flex: none"></div>
                <div style="width: 100px">
                  <div class="d-flex pt-4">
                    <v-radio value="day"></v-radio>
                    on the
                  </div>
                </div>
                <div class="d-flex">
                  <div style="width: 130px">
                    <v-text-field
                      v-model="toiData.by_month_day"
                      :disabled="typeOfMonth === 'week'"
                      :max="31"
                      :min="1"
                      :rules="[rules.requiredDay]"
                      class="mr-2"
                      label="Day"
                      variant="outlined"
                      type="number"
                      @update:modelValue="getPreview"
                    ></v-text-field>
                  </div>
                  <div class="pt-4 pl-4">st of the month</div>
                </div>
              </div>
              <div class="d-flex">
                <div style="width: 80px; flex: none"></div>
                <div style="width: 100px">
                  <div class="d-flex pt-4">
                    <v-radio value="week"></v-radio>
                    on the
                  </div>
                </div>
                <div class="d-flex">
                  <div style="width: 130px">
                    <v-select
                      v-model="toiData.by_set_pos"
                      :disabled="typeOfMonth === 'day'"
                      :items="pos"
                      class="mr-2"
                      item-title="name"
                      item-value="value"
                      variant="outlined"
                      @update:modelValue="getPreview"
                    ></v-select>
                  </div>
                  <div style="width: 130px">
                    <v-select
                      v-model="toiData.by_day"
                      :disabled="typeOfMonth === 'day'"
                      :items="days"
                      class="ml-2"
                      item-title="name"
                      item-value="value"
                      variant="outlined"
                      @update:modelValue="getPreview"
                    ></v-select>
                  </div>
                  <div class="pt-4 pl-4">of the month</div>
                </div>
              </div>
            </v-radio-group>
          </div>
          <div class="d-flex align-start">
            <div class="pt-4" style="width: 80px; flex: none">Ends</div>
            <div class="pt-4" style="width: 100px">after</div>
            <div style="width: 130px">
              <v-text-field
                v-model.number="toiData.count"
                :max="30"
                :min="1"
                :rules="[rules.requiredCount]"
                class="mr-2"
                label="Interval"
                variant="outlined"
                type="number"
                @update:modelValue="getPreview"
              ></v-text-field>
            </div>
            <div class="ml-2 pt-4">occurrences</div>
          </div>
          <div class="d-flex align-start">
            <v-table style="width: 100%">
              <thead>
                <tr>
                  <th class="text-left">Start time</th>
                  <th class="text-left">End time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, ind) in schedule" :key="ind">
                  <td>{{ transformDate(item[0]) }}</td>
                  <td>{{ transformDate(item[1]) }}</td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
        <div class="d-flex flex-column" v-if="form.product === 'Flexible Tasking'">
          <div class="d-flex">
            <div class="mr-1" style="flex: 1">
              <DatePicker
                v-model="form.start_time"
                :min="convertDate(new Date())"
                label="Starts time"
                @input="changeStartTime"
              ></DatePicker>
            </div>
            <div class="ml-1" style="flex: 1">
              <DatePicker
                v-model="form.end_time"
                :min="convertDate(new Date(form.start_time).setDate(new Date(form.start_time).getDate() + 14))"
                label="Ends time"
              ></DatePicker>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-icon class="mr-1" color="info" small>mdi-information</v-icon>
            End date must be more than 14 days from start date
          </div>
        </div>
      </div>
      <div class="d-flex align-center" v-if="form.product === 'Flexible Tasking'" @click="requirement = !requirement">
        <div class="px-3">OTHER REQUIREMENTS</div>
        <v-divider />
        <v-btn :icon="requirement ? 'mdi-chevron-up' : 'mdi-chevron-down'" small></v-btn>
      </div>
      <div class="d-flex flex-column pa-4" v-if="form.product === 'Flexible Tasking'" v-show="requirement">
        <div class="pb-2">Order type</div>
        <v-list class="py-0" style="background: transparent">
          <v-list-group v-model="form.n_stereo_pov" active-class="border" color="indigo">
            <v-list-item
              v-if="currentProduct.available_order_types.some(val => val === 'IMAGE')"
              :value="null"
              class="mb-2"
              style="border: 1px solid lightslategray; border-radius: 8px"
            >
              <template v-slot:prepend>
                <div style="width: 100px; height: 100px">
                  <img alt="a" height="100%" :src="imageOtherSource1" width="100%" />
                </div>
              </template>

              <v-list-item-subtitle>
                <div class="pl-6 d-flex flex-column fill-height">
                  <div class="pb-2">Single image</div>
                  <div class="d-flex">
                    <h3>Single image</h3>
                    <v-spacer />
                    x1
                  </div>
                  <div class="pt-2">1 capture per satellite pass, with a view angle between 0º and 28º off-nadir</div>
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              v-if="currentProduct.available_order_types.some(val => val === 'STEREO')"
              :value="2"
              class="my-2"
              style="border: 1px solid lightslategray; border-radius: 8px"
            >
              <template v-slot:prepend>
                <div style="width: 100px; height: 100px">
                  <img alt="a" height="100%" :src="imageOtherSource2" width="100%" />
                </div>
              </template>

              <v-list-item-subtitle>
                <div class="pl-6 d-flex flex-column fill-height">
                  <div class="pb-2">15&#176; half convergence</div>
                  <div class="d-flex">
                    <h3>Stereo</h3>
                    <v-spacer />
                    x2
                  </div>
                  <div class="pt-2">2 captures per satellite pass, at 15º half convergence angle from each other</div>
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              v-if="currentProduct.available_order_types.some(val => val === 'STEREO')"
              :value="3"
              class="mt-2"
              style="border: 1px solid lightslategray; border-radius: 8px"
            >
              <template v-slot:prepend>
                <div style="width: 100px; height: 100px">
                  <img alt="a" height="100%" :src="imageOtherSource3" width="100%" />
                </div>
              </template>

              <v-list-item-subtitle>
                <div class="pl-6 d-flex flex-column fill-height">
                  <div class="pb-2">27.5&#176; half convergence</div>
                  <div class="d-flex">
                    <h3>Tri-stereo</h3>
                    <v-spacer />
                    x3
                  </div>
                  <div class="pt-2">3 captures per satellite pass, at 27.5º half convergence angle from each other</div>
                </div>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </div>
  </v-form>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import sleep from '@/utils/sleep'
import DatePicker from '@/components/DatePicker.vue'
import planetTaskingApi from '@/utils/planet/planetTaskingApi'
import ConvertDate from '@/utils/convertDate'
import * as turf from '@turf/turf'

const imageOtherSource1 = new URL(`/images/other/other_1.PNG`, import.meta.url).href
const imageOtherSource2 = new URL(`/images/other/other_2.PNG`, import.meta.url).href
const imageOtherSource3 = new URL(`/images/other/other_3.PNG`, import.meta.url).href

export default {
  components: { DatePicker, CardMapView, DialogHeader },
  data() {
    return {
      imageOtherSource1,
      imageOtherSource2,
      imageOtherSource3,
      coordinates: [],
      valid: false,
      loading: false,
      toi: true,
      requirement: true,
      angles: true,
      contracts: [],
      typeOfMonth: 'day',
      schedule: [],
      geometryType: '',
      errorGeometryType: null,
      is_ref: true,
      days: [
        { name: 'Monday', value: 'Mo' },
        { name: 'Tuesday', value: 'Tu' },
        { name: 'Wednesday', value: 'We' },
        { name: 'Thursday', value: 'Th' },
        { name: 'Friday', value: 'Fr' },
        { name: 'Saturday', value: 'Sa' },
        { name: 'Sunday', value: 'Su' },
        { name: 'Day', value: 'Mo, Tu, We, Th, Fr, Sa, Su' },
      ],
      pos: [
        { name: 'first', value: '1' },
        { name: 'second', value: '2' },
        { name: 'third', value: '3' },
        { name: 'fourth', value: '4' },
        { name: 'last', value: '-1' },
      ],
      freqs: [
        { name: 'day', value: 'DAILY' },
        { name: 'weeks', value: 'WEEKLY' },
        { name: 'months', value: 'MONTHLY' },
      ],
      toiData: {
        freq: 'DAILY',
        by_day: 'Mo',
        by_month_day: 1,
        by_set_pos: '1',
        count: 1,
        interval: 1,
      },
      name: '',
      form: {
        imaging_window: null,
        pl_number: undefined,
        product: undefined,
        start_time: ConvertDate.dateFormatted(new Date()),
        end_time: ConvertDate.dateFormatted(new Date().setDate(new Date().getDate() + 14)),
        n_stereo_pov: null,
        exclusivity_days: null,
        // angles: [0, 20],
        scheduling_type: null,
      },
      rules: {
        required: value => !!value || 'Item is required',
        requiredCount: value => (!!value && value > 0) || 'Invalid count',
        requiredInterval: value => (!!value && value > 0) || 'Invalid interval',
        requiredDay: value => (!!value && value > 0 && value <= 31) || 'Invalid interval',
      },
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    // The current geometry is referenced by a Change Detection Result
  },
  mounted() {
    this.initData()
  },
  watch: {
    geometryType(newVal, oldVal) {
      if (!this.currentData.geojson) return null
      if (this.currentData.geojson.features[0].geometry.type.includes(newVal)) {
        if (oldVal) this.$emit('addDrawToMap', this.currentData.geojson)
        if (newVal === 'Point') {
          this.coordinates = turf.center(this.currentData.geojson).geometry.coordinates
        }
      } else if (newVal === 'Point') {
        this.coordinates = turf.center(this.currentData.geojson).geometry.coordinates
        this.$emit('addDrawToMap', {
          type: 'FeatureCollection',
          features: [turf.center(this.currentData.geojson)],
        })
      }
      if (!this.currentProduct.allowed_geometries.some(val => this.geometryType.includes(val)))
        this.errorGeometryType = 'The product does not support the current geometry type'
      else this.errorGeometryType = null
    },
    'form.pl_number'() {
      if (!this.form.product) this.form.product = this.products[0].name
    },
  },
  computed: {
    currentData: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      },
    },
    products() {
      if (!this.form.pl_number) return []
      else return this.contracts.find(val => val.pl_number === this.form.pl_number).products
    },
    currentProduct() {
      return this.products.find(val => val.name === this.form.product)
    },
    refMessage() {
      switch (this.currentData.dataType) {
        case 'aoi':
          return `The current geometry is referenced by AOI: <b>${this.currentData.name}</b>`
        case 'forest_fire':
          return `The current geometry is referenced by selecting "Forest Fire": <b>${this.currentData.name}</b>`
        case 'vector-result':
          return `The current geometry is referenced by a Change Detection Result: <b>${this.currentData.name}</b>`
      }
    },
  },
  methods: {
    changeCoordinate() {
      this.$emit('addDrawToMap', {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: this.coordinates,
              type: 'Point',
            },
          },
        ],
      })
    },
    convertDate(date) {
      return ConvertDate.dateFormatted(date)
    },
    transformDate(date) {
      return ConvertDate.localDateString(date)
    },
    changeStartTime() {
      if (
        ConvertDate.dateFormatted(
          new Date(this.form.start_time).setDate(new Date(this.form.start_time).getDate() + 14),
        ) > this.form.end_time
      ) {
        this.form.end_time = ConvertDate.dateFormatted(
          new Date(this.form.start_time).setDate(new Date(this.form.start_time).getDate() + 14),
        )
      }
    },
    async initData() {
      await this.getProduct()
      await sleep(10)
      this.geometryType = this.currentData.geojson.features[0].geometry.type
    },
    async displayMap() {
      await sleep(250)
      this.showMap = true
      await sleep(350)
      this.$emit('addDrawToMap', this.currentData.geojson)
    },
    async getProduct() {
      try {
        this.loading = true
        let res = await planetTaskingApi.getProduct()
        res.data.results.forEach(item => {
          let index = this.contracts.findIndex(val => val.pl_number === item.pl_number)
          if (index >= 0) this.contracts[index].products.push(item)
          else
            this.contracts.push({
              pl_number: item.pl_number,
              products: [item],
            })
        })
        this.form.pl_number = this.contracts[0].pl_number
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeProduct() {
      if (!this.currentProduct.allowed_geometries.some(val => this.geometryType.includes(val)))
        this.errorGeometryType = 'The product does not support the current geometry type'
      else this.errorGeometryType = null
      this.getPreview()
    },
    async getPreview() {
      try {
        if (this.form.product !== 'Monitoring Tasking') return
        this.loading = true
        let rule = `RRULE:COUNT=${this.toiData.count};FREQ=${this.toiData.freq};INTERVAL=${this.toiData.interval}`
        if (this.toiData.freq === 'WEEKLY') rule = rule + `;BYDAY=${this.toiData.by_day.toUpperCase()}`
        else if (this.toiData.freq === 'MONTHLY') {
          if (this.typeOfMonth === 'day') rule = rule + `;BYMONTHDAY=${this.toiData.by_month_day}`
          else
            rule =
              rule +
              `;BYDAY=${this.toiData.by_day.toUpperCase()};
          BYSETPOS=${this.toiData.by_set_pos}`
        }
        let payload = {
          early_start: false,
          rrule: rule,
          pl_number: this.form.pl_number,
          product: this.form.product,
          scheduling_type: this.currentProduct.scheduling_type,
          include: ['schedule'],
          start_time: this.form.start_time,
        }
        let res = await planetTaskingApi.getPreview(payload)
        this.schedule = res.data.schedule
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getParam() {
      let params = {
        image_source: null,
        ref_id: this.currentData.id,
        ref_type: this.currentData.dataType,
        ref_properties: null,
        name: this.name,
        geometry: {},
        planet_tasking_key: planetTaskingApi.getApikey(),
        order_info: { ...this.form },
      }
      params.order_info.exclusivity_days = this.currentProduct.exclusivity_days
      params.order_info.scheduling_type = this.currentProduct.scheduling_type
      switch (this.form.product) {
        case 'Monitoring Tasking':
          delete params.order_info.start_time
          delete params.order_info.end_time
          delete params.order_info.n_stereo_pov
          params.order_info.schedule = this.schedule
          break
        case 'Flexible Tasking':
          params.order_info.start_time = new Date(params.order_info.start_time).toISOString()
          params.order_info.end_time = this.addHours(new Date(params.order_info.end_time), 23, 59, 59).toISOString()
          if (params.order_info.schedule) delete params.order_info.schedule
          break
        default:
      }
      return params
    },
    addHours(date, hours, minutes = 0, seconds = 0) {
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000 + 1000 * 60 * minutes + 1000 * seconds))
      return date
    },
    getData() {
      try {
        this.loading = true
        this.$refs.formData.validate()
        if (!this.valid || this.errorGeometryType) return
        return this.getParam()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
