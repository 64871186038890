<template>
  <div>
    <v-btn class="save-btn mr-1" size="small" prepend-icon="mdi-content-save-outline" @click="openSaveDialog"
      >Save</v-btn
    >
    <v-btn class="cancel-btn" size="small" prepend-icon="mdi-cancel" @click="cancelDraw">Cancel</v-btn>
  </div>
  <CreateAOI ref="createAOI" @cancelDraw="cancelDraw" />
</template>

<script>
import CreateAOI from '@/views/aoi/CreateAOI.vue'

export default {
  name: 'DrawTool',
  emits: ['cancelDraw'],
  components: { CreateAOI },
  props: {
    isDrawing: { type: Boolean, default: false },
    typeCreateAOI: { type: String, default: '' },
    getAllDrawFeature: { type: Function, default: () => {} },
  },
  data() {
    return {
      layerName: '',
      vectorType: '',
    }
  },
  methods: {
    createLayer(name, vectorType) {
      this.layerName = name
      this.vectorType = vectorType
    },
    async openSaveDialog() {
      let geometry = this.getAllDrawFeature()
      this.$refs.createAOI.openDialog(geometry, this.layerName, this.vectorType, this.typeCreateAOI)
    },
    cancelDraw() {
      this.$emit('cancelDraw')
      this.layerName = undefined
      this.vectorType = undefined
    },
  },
}
</script>

<style scoped>
:deep(.v-btn) {
  width: 100px;
  border-color: rgb(30, 30, 72);
  color: #fff;
}

.save-btn {
  background-color: rgb(var(--v-theme-primary));
}
</style>
