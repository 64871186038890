import Store from '@/store'
import api from '@/api/api'
import { checkToken } from '@/api/auth-api'

export async function checkAuth(to, from, next) {
  try {
    if (to.query.token) {
      const token = to.query.token
      api.setToken(JSON.parse(window.atob(token)).access_token)
      return next(to.path)
    }

    if (!api.getToken()) {
      throw 'token'
    }

    if (!Store.state.auth.currentUser) {
      let result = await checkToken()
      Store.commit('auth/SET_USER', result.data)
    }

    // Handle project-specific routes and caching
    if (
      to.fullPath !== '/projects' &&
      to.fullPath !== '/projects/' &&
      !to.fullPath.startsWith('/error-404') &&
      !to.fullPath.includes('/group-view') &&
      to.fullPath.startsWith('/projects/')
    ) {
      localStorage.setItem('cacheRoute', to.fullPath)
    }

    const user = Store.state.auth.currentUser
    const menu = user.menus.find(m => m.code === to.name)

    // Role and permission checks
    if (
      (to.meta.permissions && to.meta.permissions.some(permission => user.permissions.includes(permission))) ||
      (!to.meta.permissions && !to.meta.sysAdmin) ||
      user.isSysAdmin
    ) {
      if (!menu || (menu && menu.have_license && menu.viewable)) {
        return next()
      } else {
        if (to.params.id) {
          if (to.fullPath === localStorage.getItem('cacheRoute')) {
            localStorage.removeItem('cacheRoute')
          }
          return (location.href = `${location.origin}/projects/${to.params.id}/unlicensed`)
        } else {
          return next()
        }
      }
    } else {
      return (location.href = `${location.origin}/error-401`)
    }
  } catch (e) {
    api.deleteToken()
    return (location.href = `${location.origin}/pages/login?redirect=${window.location.href}`)
  }
}
