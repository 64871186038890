<template>
  <div class="d-flex" ref="menuBtn">
    <v-menu
      offset-y
      v-model="menu"
      :style="{ left: `${x}px!important`, top: `${y}px!important`, position: 'absolute' }"
    >
      <template #activator="{ props: propsMenu }">
        <v-tooltip location="bottom">
          <template #activator="{ props: propsTooltip }">
            <v-badge :model-value="!!currentItem" location="bottom right" color="success" dot overlap>
              <v-btn
                icon="true"
                :width="width"
                :height="height"
                size="x-small"
                variant="text"
                v-bind="mergeProps(propsMenu, propsTooltip)"
              >
                <v-icon :size="sizeIcon" :color="currentItem ? 'accent' : ''">{{ icon }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>{{ tip }}</span>
        </v-tooltip>
      </template>
      <v-list min-width="100" density="compact">
        <div v-for="(group, groupIndex) in groupedSorts" :key="groupIndex">
          <v-list-item
            v-for="(item, index) in group.items"
            :key="index"
            @click="handleItemChange(item)"
            class="menu-item"
            :class="{ 'list-item-small': hasIcon }"
          >
            <v-list-item-subtitle :style="{ color: currentItem === item.code ? 'var(--v-primary-base)' : '' }">
              {{ item.name }}
            </v-list-item-subtitle>
            <template #prepend>
              <v-icon size="18" v-if="item.icon">{{ item.icon }}</v-icon>
            </template>
            <template #append>
              <v-icon v-if="currentItem === item.code" class="pl-2" color="success" small>mdi-check</v-icon>
            </template>
          </v-list-item>
          <v-divider v-if="groupIndex < groupedSorts.length - 1"></v-divider>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mergeProps } from 'vue'

export default {
  name: 'SortMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItem: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    sizeIcon: {
      type: Number,
      default: 22,
    },
    tip: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      menu: false,
      x: 0,
      y: 0,
    }
  },
  computed: {
    groupedSorts() {
      const groups = {}
      this.items.forEach(item => {
        const group = item.group || 'default'
        if (!groups[group]) {
          groups[group] = { items: [] }
        }
        groups[group].items.push(item)
      })
      return Object.values(groups)
    },
  },
  methods: {
    mergeProps,
    closeMenu() {
      this.menu = false
    },
    openMenu() {
      let rect = this.$refs.menuBtn.getBoundingClientRect()
      this.x = rect.left
      this.y = rect.bottom
      this.menu = true
    },
    handleItemChange(item) {
      this.$emit('sort-change', item)
    },
  },
}
</script>

<style scoped>
:deep(.v-list) {
  padding: 0;
}
:deep(.v-menu__content) {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
</style>
