<template>
  <div class="chat-gpt">
    <div class="icon-platform clickable" @click="openChatBox">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-img :src="iconChatSource" max-width="26" width="26" v-bind="props"></v-img>
        </template>
        <span>Platform Assistant</span>
      </v-tooltip>

      <ChatgptBox ref="dialogChatBox" />
    </div>
  </div>
</template>
<script>
import ChatgptBox from './ChatgptBox.vue'

const iconChatSource = new URL('@/assets/svg/chat-ai.svg', import.meta.url).href

export default {
  components: {
    ChatgptBox,
  },
  data() {
    return {
      expand: false,
      showChat: false,
      iconChatSource,
    }
  },
  methods: {
    openChatBox() {
      this.$refs.dialogChatBox.open()
      this.$store.dispatch('AOI/getListAllAOI', { projectId: this.$route.params.id })
    },
  },
}
</script>
<style scoped lang="scss">
.chat-gpt {
  width: 48px;
  margin-right: -6px;
  color: white;
  .icon-platform {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 12px;
      margin-left: 8px;
      text-wrap: nowrap;
    }
  }
}
.clickable:hover {
  filter: brightness(1) !important;
}
</style>
