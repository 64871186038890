<template>
  <div ref="mapBound" style="position: relative; height: 100%; width: 100%; overflow: hidden; border-radius: inherit">
    <div class="d-flex fill-height">
      <div ref="miniMapContainer" class="map-container" />
    </div>
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import sleep from '@/utils/sleep'
import VectorLayer from '@/models/layer/vector/vector'
import RasterLayer from '@/models/layer/raster/raster'
import DrawTool from '@/components/DrawTool'
import SearchLocation from '@/components/SearchLocation'
import MeasureTool from '@/components/Measurement'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'

const mapStyleJsonSource = new URL('@/assets/MapStyle/map-style.json', import.meta.url).href

let map
export default {
  name: 'MiniMap',
  components: { MeasureTool, SearchLocation, DrawTool },
  data() {
    return {
      map: undefined,
      loading: false,
      mode: {},
    }
  },
  computed: {},
  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.map = new mapboxgl.Map({
      container: this.$refs.miniMapContainer,
      style: mapStyleJsonSource,
      center: [105.8466249704361, 21.003883313511878],
      zoom: 1,
      attributionControl: false,
    })
    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.on('style.load', () => {
      this.map.dragPan.disable()
      this.map.scrollZoom.disable()
      this.map.doubleClickZoom.disable()
      if (this.field && Object.keys(this.field).length)
        this.addPolygonToMap(this.field.featureCollection, '#ff0015', this.field.name)
    })
    this.map.on('move', () => {})
  },
  methods: {
    async reSize() {
      let mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      mapCanvas.style.height = '100%'
      mapCanvas.style.width = '100%'
      await sleep(0)
      this.map.resize()
    },
    displayVector(geometry, type, color, id) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'polygon':
          this.addPolygonToMap(geometry, color, id)
          break
      }
    },
    addMarkerToMap(geometry, color) {
      new mapboxgl.Marker({ color: color }).setLngLat(geometry.coordinates).addTo(this.map)
      this.submitFlyTo(geometry.coordinates)
    },
    addPolygonToMap(geometry, color, id, layerType = 'fill') {
      this.map.addSource(id, {
        type: 'geojson',
        data: geometry,
      })
      let vector = new VectorLayer({
        id: id,
        type: layerType,
        geometry: geometry,
        color: color,
      })
      let layer = vector.getMapboxLayer()
      this.map.addLayer(layer)
      this.submitZoom(layer.bbox)
    },
    addMultiImageToMap(layers) {
      this.removeAllImage()
      layers.forEach((layer, index) => {
        this.addImageToMap(layer.tileUrl, layer.name, !index ? 'visible' : 'none')
      })
    },
    addImageToMap(tileUrl, name, visibility) {
      // let bbox = this.layers[0].bbox
      let bbox = [103.605701, 1.15865671, 104.088417548, 1.470775]
      let raster = new RasterLayer({
        id: name,
        name: name,
        tileUrl: tileUrl,
        bbox: bbox,
        visibility: visibility,
      })
      this.map.addLayer(raster.getMapboxLayer())
      this.submitZoom(bbox)
      this.imageLayers.push(raster.getMapboxLayer())
      if (visibility === 'visible') {
        this.imageSelected = raster.getMapboxLayer().id
      }
    },
    removeAllImage() {
      this.imageLayers.forEach(layer => {
        this.removeLayer(layer.id)
      })
      this.imageSelected = undefined
      this.imageLayers = []
    },
    removeLayer(layerId) {
      this.map.removeLayer(layerId)
      this.map.removeSource(layerId)
    },
    changeVisibility(layerId) {
      this.imageLayers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
      this.map.setLayoutProperty(layerId, 'visibility', 'visible')
      this.$emit('getWeatherData', this.imageLayers.find(layer => layerId === layer.id).name)
    },
    zoomToLayer(layerId) {
      let layer = this.layers.find(val => val.id === layerId)
      if (layer) this.submitZoom(layer.bbox)
    },
    submitFlyTo(point) {
      this.map.easeTo({
        center: point,
        zoom: 15.5,
      })
    },
    submitZoom(bbox) {
      this.map.fitBounds(bbox, {
        duration: 5,
        padding: 20,
      })
    },
  },
  destroyed() {
    if (map) {
      map.remove()
      map = undefined
    }
  },
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

:deep(.image-radio i) {
  font-size: 18px;
}

:deep(.image-radio .v-input--selection-controls__ripple) {
  width: 0;
  height: 0;
}
</style>
