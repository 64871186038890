export const colorToHex = color => {
  // Create a dummy element to use the browser's built-in color parsing
  const dummyElement = document.createElement('div')
  dummyElement.style.color = color
  document.body.appendChild(dummyElement)

  // Get the computed color of the dummy element in RGB format
  const computedColor = window.getComputedStyle(dummyElement).color
  document.body.removeChild(dummyElement)

  // Use regex to extract the RGB values
  const rgb = computedColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/)

  if (rgb) {
    // Convert each RGB value to a 2-digit hex string
    const r = parseInt(rgb[1]).toString(16).padStart(2, '0')
    const g = parseInt(rgb[2]).toString(16).padStart(2, '0')
    const b = parseInt(rgb[3]).toString(16).padStart(2, '0')
    return `#${r}${g}${b}`
  }

  return color // If not a color format we handle, return the original input
}

const converter = {
  highlightSVG() {
    return 'invert(54%) sepia(82%) saturate(1921%) hue-rotate(171deg) brightness(100%) contrast(102%)'
  },
}

export default converter
