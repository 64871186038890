<template>
  <div class="d-flex align-center">
    <span class="ml-2 item" @click="markAllAsRead">Mark all as read</span>
    <v-tooltip location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn icon="mdi-cog-outline" v-bind="props" @click="goToSettings" size="small" variant="text"></v-btn>
      </template>
      <span>Settings</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuNotification: false,
    }
  },
  methods: {
    callAction(action) {
      action()
      this.menuNotification = false
    },
    markAllAsRead() {
      this.$store.dispatch('notification/markAllAsRead')
    },
    goToSettings() {
      this.$emit('closeNotification')
      this.$store.commit('auth/SET_CURRENT_TAB', 2) // to notification tab
      this.$router.push('/pages/account-settings')
    },
  },
}
</script>
<style scoped lang="scss">
.item {
  cursor: pointer;
  text-decoration: underline;
  &:active {
    color: #7c90ff;
  }
}
</style>
