<template>
  <div>
    <v-navigation-drawer
      v-model="isDrawer"
      :rail="miniDrawer"
      :value="isDrawerOpen"
      app
      style="overflow: visible; background: #292f4c"
      class="app-navigation-menu"
      floating
      rail-width="75"
      width="280"
    >
      <div class="d-flex flex-column fill-height pr-4 pb-4" style="position: relative; overflow: visible">
        <!-- Navigation Header -->
        <div class="vertical-nav-header items-center" style="flex: none; height: 64px">
          <div class="d-flex align-center justify-center fill-height">
            <router-link class="d-flex align-center text-decoration-none" to="/projects">
              <img :src="logoImage" alt="logo" class="app-logo" width="60" />
              <v-slide-x-transition v-if="!mini">
                <div style="font-size: 12px">
                  Sustainability Monitoring Platform
                  <div style="font-size: 10px">MEASURE, REPORT & VERIFY</div>
                  <div style="font-size: 9px">(Everything that matters to our planet)</div>
                </div>
              </v-slide-x-transition>
            </router-link>
          </div>
        </div>

        <!-- Navigation Items -->
        <v-list
          v-if="currentUser && currentUser.menu_grid"
          class="vertical-nav-menu-items overflow-y-auto custom-card-bg-1"
          density="compact"
          expand
          height="100%"
          shaped
          style="border-top-right-radius: 16px"
        >
          <v-list-item
            density="compact"
            v-for="(item, index) in listMenuAvailable"
            :key="index"
            :style="{ 'background-color': isSelect(item) ? 'rgba(255,255,255,0.1)' : '' }"
            @click="goToMenu(item)"
            :prepend-icon="item.icon"
            :subtitle="!mini ? item.name : ''"
            rounded="lg"
          />
        </v-list>
        <v-list
          v-else
          class="vertical-nav-menu-items mt-1 overflow-y-auto custom-card-bg-1"
          density="compact"
          expand
          height="100%"
          shaped
          style="border-top-right-radius: 16px"
        >
          <div v-for="(item, index) in listMenu" :key="index">
            <NavMenuLink
              v-if="checkDisplay(item) && item.type === 'menu'"
              :key="index"
              :icon="item.icon"
              :mini.sync="mini"
              :target="item.target"
              :title="item.name"
              @click="goToMenu(item)"
              class="px-6 mt-1"
              bold
            />
            <nav-menu-group
              v-if="
                (item.type === 'group' || item.type === 'domain') &&
                item.children &&
                item.children.length > 0 &&
                checkDisplay(item)
              "
              :key="index"
              :icon="item.icon"
              :title="item.name"
              class="custom-icon"
              style="font-size: 12px !important"
            >
              <template #default>
                <template v-for="(childItem, childIndex) in item.children" :key="childIndex">
                  <NavMenuLink
                    v-if="checkDisplay(childItem)"
                    :icon="childItem.icon"
                    :target="childItem.target"
                    :title="childItem.name"
                    @click="goToMenu(childItem)"
                    class="pl-7 pr-6 mt-1"
                  />
                </template>
              </template>
            </nav-menu-group>
          </div>
        </v-list>

        <v-spacer />
        <SearchMenu v-if="!mini" :items="menu" item-text="name"></SearchMenu>
        <div class="d-flex align-center justify-center fill-height mini-draw" @click="changeStatus()">
          <v-icon>{{ mini ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left' }}</v-icon>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { mapState } from '@/store/ults'
import SearchMenu from '@/components/SearchMenu.vue'

const logoImage = new URL('@/assets/images/logos/logo.png', import.meta.url).href

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
    SearchMenu,
  },
  emits: ['update:isDrawerOpen'],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapState('menu', ['menu', 'mini']),
    ...mapState('auth', ['currentUser']),
    ...mapState('project', ['projects']),
    listMenu() {
      let menuList = []
      this.menu.forEach(menu => {
        if (menu.children) {
          if (!menu.children.some(child => child.type !== 'menu')) {
            menuList.push(menu)
            return
          }
          menu.children.forEach(child => {
            menuList.push(child)
          })
        }
      })
      return menuList
    },
    listMenuAvailable() {
      return this.menu.filter(item => this.checkDisplay(item))
    },
    group() {
      return this.$route.query.group
    },

    isDrawer: {
      get() {
        return this.isDrawerOpen
      },
      set(val) {
        this.$emit('update:isDrawerOpen', val)
      },
    },
  },
  data() {
    return {
      logoImage,
      miniDrawer: false,
      currentMenu: null,
    }
  },
  watch: {},
  methods: {
    goToMenu(item) {
      this.currentMenu = item
      if (!this.currentUser.menu_grid) {
        this.$router.push(`/projects/${this.$route.params.id}/${item.code}`)
        return
      }
      if (this.$route.query.group === item.code) return
      this.$router.push('/projects/' + this.$route.params.id + '/group-view/?group=' + item.code)
    },
    isSelect(item) {
      if (this.$route.name === item.code) return true
      if (this.$route.name === 'group-view' && item.code === this.$route.query.group) return true
      if (!this.$route.query.group) return false
      return this.$route.query.group.split('/')[0] === 'home'
        ? this.$route.query.group.split('/')[1] === item.code
        : this.$route.query.group.split('/')[0] === item.code
    },
    async changeStatus() {
      if (!this.mini) {
        this.mini = !this.mini
        this.miniDrawer = this.mini
      } else {
        this.miniDrawer = !this.miniDrawer
        this.mini = this.miniDrawer
      }
    },
    checkDisplay(item) {
      if (!item) return false
      if (item.code === 'home') return true
      let currentProject = this.projects.find(project => project.uuid === this.$route.params.id)
      if (item.type === 'domain' || item.type === 'group') {
        let isChildrenViewable = false
        for (let i = 0; i < item.children.length; i++) {
          if (this.checkDisplay(item.children[i])) {
            isChildrenViewable = true
            break
          }
        }
        return isChildrenViewable
      }
      if (item.type === 'menu' && currentProject) {
        return currentProject.menus.some(menu => menu.code === item.code && menu.viewable)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-menu {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      :deep(.v-list-item__icon) {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
