<template>
  <v-menu content-class="user-profile-menu-content" left min-width="230" nudge-bottom="14" offset-y>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="text" icon="true" size="small">
        <v-badge location="bottom right" offset-x="4" offset-y="4" color="success" dot>
          <v-avatar>
            <v-img :src="currentUserAvatar" alt="avatar user" sizes="40px" />
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        @click="goToSettingAccount"
        :prepend-avatar="currentUserAvatar"
        :subtitle="currentUser.email"
        :title="currentUser.name"
      >
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <v-list-item
        v-for="(menu, index) in listMenusAvailable"
        :key="index"
        :title="menu.title"
        @click="menu.action"
        :prepend-icon="menu.icon"
      />
    </v-list>
  </v-menu>
</template>

<script setup>
import { authLogout } from '@/api/auth-api'
import AvatarImg from '@/assets/images/user/avatar.png'
import { ICON_FORM } from '@/constants/icon/form'
import { ICON_ORGANIZATION, ICON_TEAM, ICON_LOGOUT, ICON_WORK, ICON_ROLE } from '@/constants/icon/account'
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import { useRouter } from 'vue-router'
const router = useRouter()

import { ROLE_VIEW, WORKMANAGEMENT_VIEW, FORM_VIEW, TEAM_VIEW, USER_VIEW } from '@/constants/permission'

const listMenus = computed(() => {
  // TODO: complete this feature
  return [
    {
      title: 'Work Management',
      icon: ICON_WORK,
      action: () => goTo('/work-management'),
      available: currentUser.value.organization && currentUser.value.permissions.includes(WORKMANAGEMENT_VIEW),
    },
    {
      title: 'Form Management',
      icon: ICON_FORM,
      action: () => goTo('/form-management'),
      available: currentUser.value.organization && currentUser.value.permissions.includes(FORM_VIEW),
    },
    {
      title: 'Team Management',
      icon: ICON_TEAM,
      action: () => goTo('/team-management'),
      available: currentUser.value.organization && currentUser.value.permissions.includes(TEAM_VIEW),
    },
    {
      title: 'User Management',
      icon: ICON_ORGANIZATION,
      action: () => goTo('/user-management'),
      available: currentUser.value.organization && currentUser.value.permissions.includes(USER_VIEW),
    },
    {
      title: 'Role Management',
      icon: ICON_ROLE,
      action: () => goTo('/role-management'),
      available:
        (currentUser.value.organization && currentUser.value.permissions.includes(ROLE_VIEW)) ||
        currentUser.value.isSysAdmin,
    },
    { title: 'Logout', icon: ICON_LOGOUT, action: logout, available: true },
  ]
})
const goTo = newRoute => {
  router.push(newRoute)
}

const listMenusAvailable = computed(() => {
  return listMenus.value.filter(menu => menu.available)
})

const currentUser = computed(() => store.state.auth.currentUser)
const currentUserAvatar = computed(() => currentUser.value?.avatar || AvatarImg)

const goToSettingAccount = () => {
  store.commit('auth/SET_CURRENT_TAB', 0) // go to account tab
  // TODO: check if the current route is already the account settings page: for all router push
  const path = '/pages/account-settings'
  if (path !== router.currentRoute.fullPath) router.push(path)
}
const logout = async () => {
  try {
    await authLogout()
  } catch (e) {
    console.warn('Error when logging out', e)
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  cursor: pointer;
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
