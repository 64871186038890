<template>
  <component :is="resolveLayout" :key="routeKey">
    <router-view> </router-view>
  </component>
</template>

<script setup>
// import { abortAllControllers } from '@/composables/abortControllerManager'
import { RouterView, useRoute } from 'vue-router'
import api from '@/api/api'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import CustomBlank from '@/layouts/CustomBlank.vue'
import MobileLayout from '@/layouts/mobile/Mobile.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'

const { mobile  } = useDisplay()
const store = useStore()

const echoChannel = computed(() => store.state.socket.echoChannel)
const currentUser = computed(() => store.getters['auth/currentUser'])
const routeKey = computed(() => {
  const newQuery = { ...route.query }
  delete newQuery.group
  return route.params.id + JSON.stringify(newQuery)
})
const route = useRoute()
const resolveLayout = computed(() => {
  // Handles initial route
  if (route.name === undefined) return null
  if (route.meta.layout === 'blank') return LayoutBlank
  if (mobile.value) return MobileLayout
  if (route.meta.layout === 'content') return CustomBlank

  return LayoutContent
})

const currentChannel = ref(null)
const initListen = () => {
  if (currentChannel.value) {
    echoChannel.value.private(currentChannel.value).unsubscribe()
  }

  currentChannel.value = `notification.${currentUser.value?.uuid}`
  echoChannel.value.private(`notification.${currentUser.value?.uuid}`).listen('.notification.new', () => {
    store.dispatch('notification/getUnreadNotificationsCount')
    store.dispatch('notification/getListNotifications')
  })
}

watch(
  currentUser,
  () => {
    initListen()
  },
  { deep: true },
)

onMounted(async () => {
  try {
    const token = api.getToken()
    await store.dispatch('socket/initEcho', token)
  } catch (e) {
    console.warn('Error when init app: ', e)
  }
  // window.addEventListener("beforeunload", () => {
  //   abortAllControllers();
  // });
})
</script>
