import { rules, rulesUuid, rulesText } from './rules'

export function parseToHTML(message) {
  let parsedMessage = ''
  let parsedUuidMessage = ''
  let parsedTextMessage = ''
  if (message) {
    // Match and preserve code blocks
    const codeBlocks = []
    const codeBlockRegex = /```([\s\S]*?)```/g
    message = message.replace(codeBlockRegex, (match, code) => {
      codeBlocks.push(code.replace(/```([\s\S]*?)\n/g, '```'))
      return `{{CODE{BLOCK}${codeBlocks.length - 1}}}`
    })

    // Match and preserve inline code
    const inlineCodeBlocks = []
    const inlineCodeRegex = /`([^`]+)`/g
    message = message.replace(inlineCodeRegex, (match, code) => {
      inlineCodeBlocks.push(code)
      return `{{INLINE{CODE}${inlineCodeBlocks.length - 1}}}`
    })

    parsedMessage = rules.reduce((text, rule) => text.replace(rule.regex, rule.replacement), message)

    // Restore inline code blocks
    inlineCodeBlocks.forEach((code, index) => {
      const placeholder = `{{INLINE{CODE}${index}}}`
      parsedMessage = parsedMessage.replace(
        placeholder,
        `<code>${code.replace(/</g, '&lt;').replace(/>/g, '&gt;')}</code>`,
      )
    })

    // Restore code blocks
    codeBlocks.forEach((code, index) => {
      const placeholder = `{{CODE{BLOCK}${index}}}`
      parsedMessage = parsedMessage.replace(
        placeholder,
        `<pre><code>${code.replace(/</g, '&lt;').replace(/>/g, '&gt;')}</code></pre>`,
      )
    })

    parsedUuidMessage = rulesUuid.reduce((text, rule) => text.replace(rule.regex, rule.replacement), parsedMessage)
    parsedTextMessage = rulesText.reduce((text, rule) => text.replace(rule.regex, rule.replacement), parsedUuidMessage)
  }

  return parsedTextMessage
}
