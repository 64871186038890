import randomColor from '@/utils/randomColor'
import RandomColor from '@/utils/randomColor'
import bbox from '@turf/bbox'

export default class VectorLayer {
  constructor(obj) {
    this._legend = false
    this._showLegend = false
    this._layerLeft = true
    this._layerRight = true
    this._isVector = true
    this._id = obj.id
    this._name = obj.name
    this._opacity = obj.opacity ? obj.opacity : 1
    this._color = obj.color ? obj.color : randomColor.getColor(Math.floor(Math.random() * 10))
    this._paint = obj.paint ? obj.paint : this.getDefaultPaint(obj.type)
    this._type = obj.type ? obj.type : 'line'
    this._geometry = obj.geometry
    this._visibility = obj.visibility ? obj.visibility : 'visible'
    this._bbox = obj.geometry ? bbox(obj.geometry) : [103.596, 1.1443, 104.4309, 1.4835]
    this._vectorType = obj.vectorType
    this._layout = obj.layout ? obj.layout : { visibility: this._visibility }
  }

  get legend() {
    return this._legend
  }

  set legend(newValue) {
    this._legend = newValue
  }

  get showLegend() {
    return this._showLegend
  }

  set showLegend(newValue) {
    this._showLegend = newValue
  }

  get isVector() {
    return this._isVector
  }

  get type() {
    return this._type
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  get opacity() {
    return this._opacity
  }

  set opacity(newValue) {
    this._opacity = newValue
  }

  get paint() {
    return this._paint
  }

  set paint(newValue) {
    this._paint = newValue
  }

  get geometry() {
    return this._geometry
  }

  get visibility() {
    return this._visibility
  }

  set visibility(newValue) {
    this._visibility = newValue
  }

  get bbox() {
    return this._bbox
  }

  set bbox(newValue) {
    this._bbox = newValue
  }

  get layout() {
    return this._layout
  }

  getDefaultPaint(type) {
    switch (type) {
      case 'line':
        return {
          'line-color': this._color,
          'line-width': 2,
          'line-opacity': this._opacity ? this._opacity : 0,
        }
      case 'fill':
        return {
          'fill-color': this._color,
          'fill-opacity': this._opacity ? this._opacity : 0,
          'fill-outline-color': RandomColor.getDarkColor(this._color),
        }
      case 'circle':
        return {
          'circle-color': this._color,
          'circle-opacity': this._opacity ? this._opacity : 0,
          'circle-radius': 5,
          'circle-stroke-color': this._color,
          'circle-stroke-opacity': 1,
          'circle-stroke-width': 2,
        }
      case 'symbol':
        return {
          'text-color': this._color,
          'text-halo-blur': 0.2,
          'text-halo-color': this._color,
          'text-halo-width': 1,
          'text-opacity': 1,
        }
    }
  }

  getMapboxLayer() {
    return {
      maxzoom: 23,
      minzoom: 0,
      name: this._name,
      opacity: 1,
      isProperties: true,
      visible: true,
      layerLeft: this._layerLeft,
      layerRight: this._layerRight,
      id: this._id,
      isGeojson: true,
      type: this._type,
      geometry: this._geometry,
      visibility: this._visibility,
      typeStyle: this._type === 'symbol' ? 'text' : this._type,
      bbox: this._bbox,
      source: this._id,
      color: this._color,
      vectorType: this._vectorType,
      layout: this._layout,
      paint: this._paint,
    }
  }
}
