import { createWebHistory, createRouter } from 'vue-router'
import { checkAuth } from './auth'
import { checkCache } from './cacheRoute'
// import { abortController, abortAllControllers } from "@/composables/abortControllerManager"
import { FORM_CREATE, FORM_DELETE, FORM_UPDATE, FORM_VIEW } from '@/constants/permission'
import {
  WORKMANAGEMENT_CREATE,
  WORKMANAGEMENT_DELETE,
  WORKMANAGEMENT_UPDATE,
  WORKMANAGEMENT_VIEW,
} from '@/constants/permission'
import { ROLE_CREATE, ROLE_DELETE, ROLE_UPDATE } from '@/constants/permission'
import { USER_CREATE, USER_UPDATE } from '@/constants/permission'
import { TEAM_CREATE, TEAM_DELETE, TEAM_UPDATE, TEAM_VIEW } from '@/constants/permission'

const routes = [
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/TestPage.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    beforeEnter: checkAuth,
    path: '/form-management',
    name: 'form-management',
    component: () => import('@/views/form/FormHome.vue'),
    meta: {
      layout: 'content',
      permissions: [FORM_CREATE, FORM_DELETE, FORM_UPDATE, FORM_VIEW],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/work-management',
    name: 'work-management',
    component: () => import('@/views/form/work-management/WorkHome.vue'),
    meta: {
      layout: 'content',
      permissions: [WORKMANAGEMENT_CREATE, WORKMANAGEMENT_DELETE, WORKMANAGEMENT_UPDATE, WORKMANAGEMENT_VIEW],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/submit-form',
    name: 'submit-form',
    component: () => import('@/views/form/form-submit/FormSubmit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/record-form',
    name: 'record-form',
    component: () => import('@/views/form/form-record/FormRecord.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/role-management',
    name: 'role-management',
    component: () => import('@/views/role/RoleManagement.vue'),
    meta: {
      layout: 'content',
      permissions: [ROLE_CREATE, ROLE_DELETE, ROLE_UPDATE],
    },
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/MaintenancePage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/views/landing-page/LandingPage.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: checkCache,
  },
  {
    beforeEnter: checkAuth,
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/project/Project.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/projects/:id',
    name: 'detail',
    redirect: to => {
      return {
        name: 'group-view',
        params: { id: to.params.id },
      }
    },
  },
  {
    beforeEnter: checkAuth,
    name: 'group-view',
    path: '/projects/:id/group-view',
    component: () => import('@/views/group-view/GroupView.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/dashboard',
    name: 'dashboard',
    component: () => import('@/views/change-detection/dashboard/Dashboard.vue'),
    meta: {
      service: 'Change Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/oil-spill',
    name: 'oil-spill',
    component: () => import('@/views/oil-spill/OilSpill.vue'),
    meta: {
      service: 'Oil Spill Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/tasking-orders',
    name: 'tasking-orders',
    component: () => import('@/views/tasking-order/TaskingOrders.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/quality-assurance-quality-control',
    name: 'quality-assurance-quality-control',
    component: () => import('@/views/QA&QC/QA&QC.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/aqi',
    name: 'aqi',
    component: () => import('@/views/aqi/index.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/classification',
    name: 'classification',
    component: () => import('@/views/classification/Classification.vue'),
    meta: {
      service: 'Classification',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/aoi-management',
    name: 'aoi-management',
    component: () => import('@/views/aoi/AOI.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/map-view',
    name: 'map-view',
    component: () => import('@/views/map-overview/Overview.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/vegetation-indices',
    name: 'vegetation-indices',
    component: () => import('@/views/monitoring/vegetation/Vegetation.vue'),
    meta: {
      service: 'Crop Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/thermal-index',
    name: 'thermal-index',
    component: () => import('@/views/monitoring/thermal/Thermal.vue'),
    meta: {
      service: 'Thermal Discomfort Monitoring',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/zoning',
    name: 'zoning',
    component: () => import('@/views/monitoring/vegetation/Zoning.vue'),
    meta: {
      service: 'Crop Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/flood-detection',
    name: 'flood-detection',
    component: () => import('@/views/flood-detection/FloodDetection.vue'),
    meta: {
      service: 'Flood Detection',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/water-indices',
    name: 'water-indices',
    component: () => import('@/views/monitoring/water/Water.vue'),
    meta: {
      service: 'Water Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/unlicensed',
    name: 'unlicensed',
    component: () => import('@/views/Unlicensed.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/ai-farm-boundary',
    name: 'ai-farm-boundary',
    component: () => import('@/views/interactiveAI/FarmBoundary.vue'),
    meta: {
      service: 'ai-farm-boundary',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/ai-tree-counting',
    name: 'ai-tree-counting',
    component: () => import('@/views/interactiveAI/TreeCounting.vue'),
    meta: {
      service: 'ai-tree-counting',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/weather',
    name: 'weather',
    component: () => import('@/views/weather/Weather.vue'),
    meta: {
      service: 'weather',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/deforestation',
    name: 'deforestation',
    component: () => import('@/views/forest-fire/Deforestation.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/volume',
    name: 'volume',
    component: () => import('@/views/volume/Volume.vue'),
    meta: {
      service: 'Volume',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/land-subsidence',
    name: 'land-subsidence',
    component: () => import('@/views/land-subsidence/LandSubsidence.vue'),
    meta: {
      service: 'Land Subsidence',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/soil-moisture',
    name: 'soil-moisture',
    component: () => import('@/views/soil-moisture/SoilMoisture.vue'),
    meta: {
      service: 'Soil Moisture',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/lst',
    name: 'lst',
    component: () => import('@/views/land-surface-temperature/LandSurfaceTemperature.vue'),
    meta: {
      service: 'Land Surface Temperature',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/forest-fire',
    name: 'forest-fire',
    component: () => import('@/views/forest-fire/MapView.vue'),
    meta: {
      service: undefined,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/earthquake',
    name: 'earthquake',
    component: () => import('@/views/forest-fire/MapView.vue'),
    meta: {
      service: undefined,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/land-indices',
    name: 'land-indices',
    component: () => import('@/views/monitoring/land-cover/land-cover.vue'),
    meta: {
      service: 'Land Cover Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/resource-indices',
    name: 'resource-indices',
    component: () => import('@/views/monitoring/natural-resources/natural-resources.vue'),
    meta: {
      service: 'Resources Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/biodiversity',
    name: 'biodiversity',
    component: () => import('@/views/monitoring/natural-resources/biodiversity.vue'),
    meta: {
      service: 'Biodiversity',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/burn-detection',
    name: 'burn-detection',
    component: () => import('@/views/burn-detection/BurnDetection.vue'),
    meta: {
      service: 'Burn Area Detection',
    },
  },
  {
    path: '/projects/:id/ship-detection',
    name: 'ship-detection',
    component: () => import('@/views/object-detection/ShipDetection.vue'),
    beforeEnter: checkAuth,
    meta: {
      service: 'Ship Detection',
    },
  },
  {
    path: '/projects/:id/aircraft-detection',
    name: 'aircraft-detection',
    component: () => import('@/views/object-detection/AircraftDetection.vue'),
    beforeEnter: checkAuth,
    meta: {
      service: 'Aircraft Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/orders',
    name: 'orders',
    component: () => import('@/views/order/service-order/ServiceOrderManagement.vue'),
    meta: {},
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/unlicensed',
    name: 'projects-unlicensed',
    component: () => import('@/views/Unlicensed.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/user-management',
    name: 'user-management',
    component: () => import('@/views/organization/user-management/UserManagement.vue'),
    meta: {
      layout: 'content',
      permissions: [USER_CREATE, USER_UPDATE],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/team-management',
    name: 'team-management',
    component: () => import('@/views/organization/team-management/TeamManagement.vue'),
    meta: {
      layout: 'content',
      permissions: [TEAM_CREATE, TEAM_DELETE, TEAM_UPDATE, TEAM_VIEW],
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: checkCache,
  },
  //Admin side
  {
    beforeEnter: checkAuth,
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin-side/index.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin-side/user-management/UserManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/organizations',
    name: 'admin-organizations',
    component: () => import('@/views/admin-side/organizations/OrganizationManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms',
    name: 'admin-cms',
    component: () => import('@/views/admin-side/cms/CMSManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/domains',
    name: 'admin-cms-domains',
    component: () => import('@/views/admin-side/cms/DomainManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/groups',
    name: 'admin-cms-groups',
    component: () => import('@/views/admin-side/cms/GroupManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/menus',
    name: 'admin-cms-menus',
    component: () => import('@/views/admin-side/cms/MenuManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/structure',
    name: 'admin-cms-structure',
    component: () => import('@/views/admin-side/cms/StructureManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/orders',
    name: 'admin-order',
    component: () => import('@/views/admin-side/order/OrderManagement.vue'),
    meta: {
      layout: 'content',
      sysAdmin: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/401.vue'),
    meta: {
      layout: 'blank',
    },
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', redirect: 'error-404' },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect: 'error-404' },
]

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(),
  routes,
})

// cancel call wait API when route change
// router.beforeEach((to, from, next) => {
//   // console.log('to from', to, from)
//   // if (from?.name) abortController(from.name);
//   // abortController('test');
//   // abortAllControllers()
//   next();
// })

export default router
