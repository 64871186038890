<template>
  <v-list-item
    :class="[mini ? 'pl-7' : '']"
    ref="listItem"
    class="vertical-nav-menu-link"
    style="height: 36px !important"
    v-bind="$attrs"
  >
    <template #prepend>
      <v-icon class="mx-auto" size="20">
        {{ icon || alternateIcon }}
      </v-icon>
    </template>
    <v-list-item-title
      v-bind:style="{
        'font-weight': bold ? '650 !important' : 'normal',
        'font-size': bold ? '14px !important' : '13px !important',
      }"
    >
      {{ title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

export default {
  props: {
    bold: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    group: {
      type: String,
      // required: true,
    },
  },

  computed: {
    isActive() {
      return this.$route.query.group === this.group
    },
  },
  setup() {
    return {
      alternateIcon: mdiCheckboxBlankCircleOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';
.bold-title {
  font-size: 14px !important;
}

.normal-title {
  font-size: 12px !important;
}

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 4px -4px rgba(94, 86, 105, 0.42);
    //@include elevationTransition();
  }
}
</style>
