import api from '@/api/api'
import config from '@/config'

export async function getDomains(param) {
  return await api.get(`${config.backend}/system/domains`, param)
}

export async function createDomain(data) {
  return await api.post(`${config.backend}/system/domains`, data)
}

export async function destroyDomain(id) {
  return await api.delete(`${config.backend}/system/domains/${id}`)
}

export async function updateDomain(form) {
  return await api.post(`${config.backend}/system/domains/${form.id}?_method=PUT`, form.data)
}

export async function getGroups(param) {
  return await api.get(`${config.backend}/system/groups`, param)
}

export async function createGroup(data) {
  return await api.post(`${config.backend}/system/groups`, data)
}

export async function deleteGroup(id) {
  return await api.delete(`${config.backend}/system/groups/${id}`)
}

export async function updateGroup(form) {
  return await api.post(`${config.backend}/system/groups/${form.id}?_method=PUT`, form.data)
}

export async function getMenus(param) {
  return await api.get(`${config.backend}/system/menus`, param)
}

export async function createMenu(data) {
  return await api.post(`${config.backend}/system/menus`, data)
}

export async function deleteMenu(id) {
  return await api.delete(`${config.backend}/system/menus/${id}`)
}

export async function updateMenu(form) {
  return await api.post(`${config.backend}/system/menus/${form.id}?_method=PUT`, form.data)
}

export async function updateDataMenu(data) {
  return await api.put(`${config.backend}/system/menus/${data.id}`, data.payload)
}

export async function getSettingMenus() {
  return await api.get(`${config.backend}/system/settings/menus`)
}
export async function updateSettingMenus(id, param) {
  return await api.put(`${config.backend}/system/settings/${id}`, param)
}
