<template>
  <div class="loader-container">
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped lang="scss">
$diametterCircle: 6px;

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 10px;
}

.loader {
  display: flex;
  justify-content: space-between;
  width: 30px;
}

.loader div {
  width: $diametterCircle;
  height: $diametterCircle;
  background-color: #fff;
  border-radius: 50%;
  animation: grow-shrink 1s infinite;
}

.loader div:nth-child(1) {
  animation-delay: 0s;
}

.loader div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}
</style>
