<template>
  <div class="d-flex flex-column align-center">
    <v-tooltip location="bottom" v-if="!isBottom">
      <template v-slot:activator="{ props }">
        <v-btn
          size="x-small"
          icon="mdi-arrow-down"
          v-bind="props"
          @click="scrollToBottom"
          class="btn-scroll-down"
        ></v-btn>
      </template>
      <span>Scroll to bottom</span>
    </v-tooltip>

    <div class="chat-box-footer d-flex align-center">
      <form class="chat-box-footer py-1" @submit.prevent>
        <v-textarea
          ref="textarea"
          class="chat-textarea"
          v-model="message"
          placeholder="Type a message"
          variant="outlined"
          density="compact"
          hide-details
          no-resize
          rows="1"
          max-rows="5"
          row-height="24"
          auto-grow
          color="primary"
          autofocus
          dark
          @keydown="handleKeydown"
          @click:appendInner="scrollToBottom"
        >
          <template v-slot:append-inner>
            <v-tooltip location="top" v-if="!typingText">
              <template v-slot:activator="{ props }">
                <v-btn
                  type="submit"
                  class="btn-send"
                  size="x-small"
                  :disabled="message.length === 0"
                  @click="sendMessage"
                  icon="mdi-arrow-up"
                  variant="text"
                  v-bind="props"
                ></v-btn>
              </template>
              <span>Send</span>
            </v-tooltip>
            <v-tooltip location="top" v-else>
              <template v-slot:activator="{ props }">
                <v-btn
                  class="btn-send"
                  variant="text"
                  size="x-small"
                  icon="mdi-stop"
                  v-bind="props"
                  @click="stopAnswer"
                ></v-btn>
              </template>
              <span>Stop typing</span>
            </v-tooltip>
          </template>
        </v-textarea>
      </form>
    </div>
    <span class="mt-1 footer-note">
      Press <code>Enter</code> to send message, <code>Shift + Enter</code> to break new line
    </span>
  </div>
</template>
<script>
import { mapState } from '@/store/ults'

export default {
  props: {
    scrollToBottom: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('chat', ['isBottom', 'messages', 'typingText', 'loadingAnswer']),
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    stopAnswer() {
      this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'typingText', value: false })
    },
    sendMessage() {
      if (this.message.length > 0 && !this.typingText && !this.loadingAnswer) {
        this.$store.dispatch('chat/sendMessage', {
          message: this.message,
          projectUuid: this.$route.params.id,
        })
        this.message = ''
      }
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.sendMessage()
      }
    },
    makeNewConversation() {
      this.$emit('makeNewConversation')
    },
    focusTextarea() {
      this.$nextTick(() => {
        this.$refs.textarea.focus()
      })
    },
  },
  watch: {
    messages: {
      handler() {
        this.focusTextarea()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="scss">
:deep{
  &.v-theme--dark.v-icon:focus::after {
    background-color: transparent !important;
  }
  &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding-right: 6px !important;
  }
}
$padding: 8px;

.chat-box-footer {
  z-index: 1;
  display: flex;
  align-items: flex-end;
  width: calc(100% - #{$padding});
  height: auto;
  max-height: 150px;
  overflow: hidden;
  .chat-textarea {
    margin-bottom: -2px;
  }
  .btn-send {
    background-color: #5d5f6b;
    border-radius: 50%;
    border: none;
  }
}
.btn-scroll-down {
  border: 1px solid #656565;
  margin-top: -34px;
  margin-bottom: 4px;
  background-color: #2f3241;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.footer-note {
  font-size: 0.9em;
}
</style>
