<template>
  <div id="chat-box-body" ref="contentContainer">
    <div
      v-for="(message, ind) in messagesAvailable"
      :key="message.id"
      :class="[`${message.role}-chat`, loadingAnswer ? 'non-actionable' : '']"
    >
      <TypingEffect
        v-if="ind === messages.length - 1 && message.role === BOT && isNewMessage"
        :text="message.content"
        :isBottom.sync="isBottom"
        @renderDynamicComponents="renderDynamicComponents"
        @scrollToBottom="scrollToBottom"
      />
      <div
        v-else
        class="chat-content"
        :class="ind !== messages.length - 1 ? 'non-actionable' : ''"
        :id="`chat-content-${message.id}`"
        v-html="message.content"
      ></div>
      <div class="chat-handle" v-if="message.role === BOT">
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              :icon="copied !== message.id ? 'mdi-content-copy' : 'mdi-check-all'"
              size="small"
              variant="text"
              v-bind="props"
              @click="copyToClipboard(message)"
            ></v-btn>
          </template>
          <span>{{ copied !== message.id ? 'Copy' : 'Copied' }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="assistant-chat" v-if="loadingAnswer">
      <LoadingChat />
    </div>
    <div v-if="messages.length === 0">
      <div class="title">
        <span>Hello there! Need any help with this system?</span>
      </div>
    </div>
  </div>
</template>
<script>
// import * as Vue from 'vue'
import { createApp, h } from 'vue'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'

import { mapState } from '@/store/ults'
import LoadingChat from './LoadingChat.vue'
import { parseToHTML } from '@/services/chat-guide/parser'
import { BOT } from '@/constants/chat'
import CustomButton from './components/CustomButton.vue'
import CustomLink from './components/CustomLink.vue'
import CustomSelect from './components/CustomSelect.vue'
import {
  CHAT_TYPE_AOI,
  CHAT_REQUEST_AOI,
  CHAT_CREATED_AOI,
  ADD_LAYER_TO_MAP,
  DRAW_MAP,
  CANCEL_DRAW_MAP,
  CHAT_TYPE_ONLY_CREATE_AOI,
} from '@/constants/chat'
import { Clipboard } from 'v-clipboard'
import TypingEffect from './components/TypingEffect.vue'

export default {
  components: {
    LoadingChat,
    CustomButton,
    CustomLink,
    CustomSelect,
    TypingEffect,
  },
  computed: {
    ...mapState('chat', ['messages', 'loadingAnswer', 'perPage', 'loadingMore', 'isNewMessage']),
    isBottom: {
      get() {
        return this.$store.getters['chat/isBottom']
      },
      set(value) {
        this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'isBottom', value })
      },
    },
    messagesAvailable() {
      return this.messages.map(message => {
        return {
          ...message,
          content: parseToHTML(message.content),
        }
      })
    },
  },
  data() {
    return {
      BOT,
      copied: null,
      timeout: null,
      hasMoreMessages: true,
      displayedText: '',
      answering: false,
    }
  },
  methods: {
    scrollToBottom() {
      const scrollContainer = document.getElementById('chat-box-body')
      scrollContainer.scrollTop = scrollContainer.scrollHeight
      this.isBottom = true
    },
    async copyToClipboard(message) {
      const element = document.getElementById(`chat-content-${message.id}`)
      const text = element.innerText
      await Clipboard.copy(text)

      this.copied = message.id
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.copied = null
      }, 1500)
    },
    renderDynamicComponents() {
      const placeholders = this.$refs.contentContainer.querySelectorAll('.dynamic-component-placeholder')
      placeholders.forEach(placeholder => {
        const componentName = placeholder.getAttribute('data-component')
        const componentUrl = placeholder.getAttribute('data-url')
        const componentText = placeholder.getAttribute('data-text')
        const componentAttributes = placeholder.getAttribute('data-attributes')
        const componentType = placeholder.getAttribute('data-type')

        // Get the component
        const Component = this.$options.components[componentName]

        // Create the Vue instance
        const app = createApp({
          render: () =>
            h(Component, {
              url: componentUrl,
              text: componentText,
              typeList: componentType,
              attributesString: componentAttributes,
              onSubmit: this.handleSubmit,
              onHandleLayerMap: this.handleLayerMap,
              onFetchData: this.fetchData,
              onChangeProject: this.changeProject,
            }),
        })

        // Inject store, router, and Vuetify if required
        app.use(store)
        app.use(router)
        app.use(vuetify)

        // Mount and replace the placeholder
        const instance = app.mount(document.createElement('div'))
        placeholder.parentNode.replaceChild(instance.$el, placeholder)
      })
    },
    // handle map
    handleLayerMap({ data, type }) {
      switch (type) {
        case ADD_LAYER_TO_MAP:
          this.$emit('addToMap', data)
          break
        case DRAW_MAP:
          this.$emit('startDraw')
          break
        case CANCEL_DRAW_MAP:
          this.$emit('cancelDraw')
          break
        default:
          console.warn('no service support')
      }
    },
    fetchData(type) {
      switch (type) {
        case CHAT_TYPE_AOI:
          this.$store.dispatch('AOI/getListAllAOI')
          break
        default:
          console.warn('no service support')
      }
    },

    // handle submit message
    async handleSubmit({ data, type }) {
      let message = ''
      switch (type) {
        case CHAT_TYPE_AOI:
        case CHAT_TYPE_ONLY_CREATE_AOI:
          message = this.handleMessageAOI(data, type)
          break
        default:
          console.warn('no service support')
      }
      if (message) {
        await this.$store.dispatch('chat/sendMessage', {
          message,
          projectUuid: this.$route.params.id,
        })
        this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'isChating', value: false })
      }
    },
    handleMessageAOI(aoi, type) {
      let message = ''
      let prependMessage = ''
      switch (type) {
        case CHAT_TYPE_AOI:
          prependMessage = CHAT_REQUEST_AOI
          break
        case CHAT_TYPE_ONLY_CREATE_AOI:
          prependMessage = CHAT_CREATED_AOI
          break
        default:
          console.warn('no chat type support')
      }
      if (aoi?.uuid) {
        message = `${prependMessage} ${aoi.uuid}`
      }
      return message
    },
    async onScroll(event) {
      const scrollContainer = event.target
      const lastElement = scrollContainer.lastElementChild

      if (lastElement) {
        const lastElementBottom = lastElement.offsetTop + lastElement.offsetHeight
        const containerBottom = scrollContainer.scrollTop + scrollContainer.clientHeight
        this.isBottom = lastElementBottom <= containerBottom
      }
      if (event.target.scrollTop === 0 && !this.loadingMore && this.hasMoreMessages) {
        const res = await this.$store.dispatch('chat/getMoreMessages')
        if (res) {
          this.hasMoreMessages = res.length > 0
          if (this.hasMoreMessages) event.target.scrollTop = 10
        }
      }
    },
    async changeProject(projectUuid) {
      if (
        this.$route.path === `/projects/${projectUuid}/${this.$route.name}` ||
        this.$route.path === `/projects/${projectUuid}/group-view/`
      )
        return
      this.savedLayers = []
      // let historyName = this.$route.name
      await this.$router.push(`/projects/${projectUuid}/${this.$route.name}?group=${this.group}`)
      // await sleep(0)
      // this.$nextTick(() => {
      //   if (this.$route.name === historyName) location.reload()
      // })
    },
    handleMessage(message) {
      if (message?.info?.aoi_id) {
        this.$emit('addToMap', { uuid: message.info.aoi_id })
      }
      if (message?.key) {
        this.$emit('addServiceResult', message.key, message.result)
      }
    },
  },
  mounted() {
    this.$refs.contentContainer.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.$refs.contentContainer.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    messages: {
      handler(val) {
        this.$nextTick(() => {
          this.renderDynamicComponents()
          if (!this.loadingMore && this.isNewMessage) {
            this.scrollToBottom()
            this.hasMoreMessages = true
            this.handleMessage(val[val.length - 1])
          } else {
            this.$store.commit('chat/SET_STATE_PROPERTY', {
              property: 'loadingMore',
              value: false,
            })
          }
        })
      },
      deep: true,
    },
  },
  filters: {
    displayMessage(value) {
      return value
    },
  },
}
</script>
<style scoped lang="scss">
:deep(ol) {
  padding-left: 24px;
}
#chat-box-body {
  .non-actionable {
    pointer-events: none;
  }
  position: relative;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
  padding: 10px 10px 50px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  .user-chat {
    align-self: flex-end;
    background-color: #9155fd;
    color: #f1e8ff;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    max-width: 90%;
  }
  .assistant-chat {
    position: relative;
    align-self: flex-start;
    //background-color: #f1f1f1;
    color: #eeeef8;
    padding: 5px 5px 5px 34px;
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 90%;
    .chat-handle {
      position: absolute;
      bottom: -28px;
      left: 30px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: -5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-image: url('@/assets/svg/robot.svg');
      background-position: center;
      background-size: 60%;
      border: 1px solid #a3a3a3;
    }
  }
}
.btn-scroll-down {
  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
