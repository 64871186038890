import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/style/main.scss'
import '@/assets/fontIcon/style.css'
import 'leaflet/dist/leaflet.css'
import 'v3-infinite-loading/lib/style.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-enterprise'
import { registerPlugins } from '@/plugins'

const app = createApp(App)

registerPlugins(app)

app.mount('#app')
