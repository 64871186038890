<template>
  <div ref="mapBound" style="position: relative; height: 100%; width: 100%; overflow: hidden; border-radius: 8px">
    <div class="d-flex fill-height">
      <div v-show="isDrawing" style="position: absolute; top: 12px; left: 12px; z-index: 2">
        <DrawTool @cancelDraw="cancelDraw" />
      </div>

      <div v-show="mapSync" class="hard-flex" style="border-right: 3px #d5d5d5 solid">
        <div ref="mapSync" class="map-container" />
      </div>
      <div class="hard-flex" style="position: relative">
        <div id="wrapper" class="map"></div>
        <div v-show="isMapView" ref="mapContainer" class="map-container map" @mouseleave="deSelect" />
        <div ref="beforeMap" class="map-container map" />
      </div>
      <div v-if="isCopyRight" class="location-detail elevation-1">
        <div class="d-flex align-center fill-height" style="padding: 2px 4px">
          <div
            class="ml-3"
            style="flex: none; height: 100%; min-width: 50px; border-right: 1px solid #7c7c7c; padding-top: 1px"
          >
            <v-icon color="#333333" size="14">mdi-magnify</v-icon>
            {{ zoomLevel }}
          </div>
          <div class="d-flex align-center fill-height pl-2" v-html="currentPoint"></div>
          <v-tooltip min-width="210" location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-2"
                icon="mdi-cached"
                variant="text"
                v-bind="props"
                size="x-small"
                @click="changeDisplayTypePixelValue"
              ></v-btn>
            </template>
            <span>
              {{ isDMS ? 'DMS' : 'Latitude, Longitude' }}
              <v-icon color="white" size="20">mdi-swap-horizontal</v-icon>
              {{ isDMS ? 'Latitude, Longitude' : 'DMS' }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div style="position: absolute; top: 10px; right: 95px"></div>
    <v-menu v-if="!isPreview" top>
      <template v-slot:activator="{ props }">
        <div class="d-flex base-layer elevation-2" style="cursor: pointer" title="Base map" v-bind="props">
          <img :src="currentBaseMap.avatar" alt="avatar" height="60" style="border-radius: 8px" width="60" />
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in mapStyles"
          :key="index"
          style="height: 55px"
          @click="currentBaseMap = item"
        >
          <div class="d-flex align-center">
            <div style="width: 49px; height: 48px; border: 2px solid lightslategray; border-radius: 10px">
              <img :src="item.avatar" alt="avatar" height="45" style="border-radius: 8px" width="45" />
            </div>
            <h5 class="ml-3" style="width: 130px">{{ item.name }}</h5>
          </div>
        </v-list-item>
        <v-list-subheader>
          <v-checkbox v-model="isLabel" density="compact" hide-details label="Show the label"></v-checkbox>
        </v-list-subheader>
      </v-list>
    </v-menu>
    <div class="d-flex flex-column" style="width: 33px; height: 200px; position: absolute; top: 10px; right: 10px">
      <SearchLocation @onSearch="submitZoom" @zoomToCenter="zoomToCenter" />
      <MeasureTool
        v-if="!isPreview"
        ref="measureTool"
        :activeMeasureTool.sync="isOpenMeasurementTool"
        :disabled="isDrawing"
        @activeMeasureTool="activeMeasureTool"
        @resetDraw="resetDraw"
      />
      <v-tooltip v-if="isStaticMode" location="left">
        <template v-slot:activator="{ props }">
          <v-btn
            class="elevation-0 mt-1"
            color="bgContainer"
            min-height="0"
            min-width="0"
            v-bind="props"
            @click="removeDrawLayer"
          >
            <v-icon color="error" size="22">mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { latDMS, lngDMS } from '@/utils/convertLatLngToDMS'
import { debounce } from 'lodash'
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import Compare from 'mapbox-gl-compare'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import sleep from '@/utils/sleep'
import utils from '@/utils/genUUID'
import VectorLayer from '@/models/layer/vector/vector'
import RasterLayer from '@/models/layer/raster/raster'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { CircleMode, DirectMode, SimpleSelectMode } from '@/utils/draw'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import SearchLocation from '@/components/SearchLocation'
import area from '@turf/area'
import * as turf from '@turf/turf'
import MeasureTool from '@/components/Measurement'
import turfLength from '@turf/length'
import { mapState } from '@/store/ults'
import VectorTilesLayer from '@/models/layer/vector/vector-tiles'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import DrawTool from '@/components/DrawTool.vue'
import { tileMapbox } from '@/api/auth-api'
import 'mapbox-gl/dist/mapbox-gl.css'
import colorFormat from '@/utils/ColorFormat'

const DrawPolygon = MapboxDraw.modes.draw_polygon
const DrawPoint = MapboxDraw.modes.draw_point
const DrawLineString = MapboxDraw.modes.draw_line_string

const mapStyleSource = new URL(`@/assets/MapStyle/map-style.json`, import.meta.url).href
const customDrawStyleSource = new URL(`@/assets/MapStyle/custom-draw-style.json`, import.meta.url).href

const mapboxBgImageSource = new URL(`@/assets/images/map/mapbox-bg.png`, import.meta.url).href
const skymapBaseImageSource = new URL(`@/assets/images/map/skymap-base.png`, import.meta.url).href
const satelliteImageSource = new URL(`@/assets/images/map/satellite.jpg`, import.meta.url).href
const mapStreetImageSource = new URL(`@/assets/images/map/map-street.jpg`, import.meta.url).href

const labelJsonSource = new URL(`@/assets/MapStyle/label.json`, import.meta.url).href

let draw
let popup
let marker
let markers = []
let diffMap
let x

export default {
  name: 'MapV2',
  components: { DrawTool, MeasureTool, SearchLocation },
  data() {
    return {
      isLabel: false,
      addedIcon: [],
      isStaticMode: false,
      mapLoading: false,
      center: undefined,
      mapStyles: [
        {
          avatar: mapboxBgImageSource,
          name: 'Mapbox Satellite',
          id: 'mapbox-satellite',
        },
        {
          avatar: skymapBaseImageSource,
          name: 'Skymap Base',
          id: 'basemap',
        },
        {
          avatar: satelliteImageSource,
          name: 'Google Satellite',
          id: 'google-satellite',
        },
        {
          avatar: mapStreetImageSource,
          name: 'OSM Street layer',
          id: 'OSM',
        },
      ],
      mapSync: false,
      isMapView: true,
      disableMoveEvent: false,
      isLegend: true,
      isDMS: true,
      beforeMap: undefined,
      syncMap: undefined,
      currentLatLng: undefined,
      currentPoint: undefined,
      zoomLevel: 0,
      firstLoad: true,
      isSmall: false,
      disable: false,
      isColorPicker: false,
      pointInfo: {},
      isMapFullscreen: false,
      editing: false,
      map: undefined,
      clusterId: undefined,
      imageLayers: [],
      imageSelected: undefined,
      currentGeometry: {},
      currentArea: 0,
      isOpenMeasurementTool: false,
      popupArea: false,
      mode: {},
      evtContainer: [],
      errorKeys: [],
    }
  },
  computed: {
    ...mapState('layers', ['currentBaseMap']),
    ...mapState('setting', ['keys']),
    layers: {
      get() {
        return this.currentLayers
      },
      set(val) {
        this.$emit('update:currentLayers', val)
      },
    },
    currentAOI: {
      get() {
        return this.geometry
      },
      set(val) {
        this.$emit('update:geometry', val)
      },
    },
    loading: {
      get() {
        return this.isLoading
      },
      set(val) {
        this.$emit('update:isLoading', val)
      },
    },
    isDrawing: {
      get() {
        return this.isDraw
      },
      set(val) {
        this.$emit('update:isDraw', val)
      },
    },
  },
  props: {
    type: {
      type: Object,
      default: () => {},
    },
    isPreview: { type: Boolean, default: false },
    compare: { type: Boolean, default: false },
    inspect: { type: Boolean, default: false },
    isCopyRight: { type: Boolean, default: true },
    isShared: { type: Boolean, default: false },
    geometry: {
      type: Object,
      default: () => {},
    },
    currentLayers: {
      type: Array,
      default: () => [],
    },
    isLoading: { type: Boolean, default: false },
    area: { type: String, default: '0' },
    editable: { type: Boolean, default: false },
    isDraw: { type: Boolean, default: false },
  },
  watch: {
    isLabel(val) {
      if (val) {
        this.map.addLayer(labelJsonSource)
        this.beforeMap.addLayer(labelJsonSource)
        this.syncMap.addLayer(labelJsonSource)
      } else {
        this.map.removeLayer('map-label').removeSource('map-label')
        this.beforeMap.removeLayer('map-label').removeSource('map-label')
        this.syncMap.removeLayer('map-label').removeSource('map-label')
      }
    },
    isStaticMode() {
      if (this.clusterId) {
        this.moveClusterToTop()
      }
    },
    currentBaseMap(val) {
      this.changeBaseMap(val)
    },
    mapSync() {
      this.reSize()
    },
    mapLoading(val) {
      if (val) this.checkMapLoading()
    },
  },
  mounted() {
    this.setMapboxKey()
  },
  methods: {
    cancelDraw() {
      this.isDrawing = false
      this.resetDraw()
    },
    moveClusterToTop() {
      this.map.moveLayer('clusters' + this.clusterId)
      this.map.moveLayer('cluster-count' + this.clusterId)
      this.map.moveLayer('unclustered-point' + this.clusterId)
    },
    removeDrawLayer() {
      this.resetDraw()
      this.$emit('resetDraw')
    },
    async checkMapLoading() {
      await sleep(15000)
      this.mapLoading = false
    },
    async setMapboxKey() {
      let availableKeys = this.keys.filter(key => !this.errorKeys.some(errorKey => errorKey === key))
      let key = availableKeys[Math.floor(Math.random() * availableKeys.length)]
      mapboxgl.accessToken = key
      try {
        await tileMapbox('https://api.mapbox.com/v4/mapbox.satellite/1/0/0.png?access_token=' + key)
        await this.initMap()
      } catch (e) {
        console.log(e)
        this.errorKeys.push(key)
        if (this.errorKeys.length < this.keys.length) await this.setMapboxKey()
        else {
          this.currentBaseMap = {
            avatar: satelliteImageSource,
            name: 'Google Satellite',
            id: 'google-satellite',
          }
          this.mapStyles = [
            {
              avatar: skymapBaseImageSource,
              name: 'Skymap Base',
              id: 'basemap',
            },
            {
              avatar: satelliteImageSource,
              name: 'Google Satellite',
              id: 'google-satellite',
            },
            {
              avatar: mapStreetImageSource,
              name: 'OSM Street layer',
              id: 'OSM',
            },
          ]
          await this.initMap()
        }
      }
    },
    async initMap() {
      await sleep(100)
      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: mapStyleSource,
        center: [94.66114, 28.173],
        zoom: 0,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        transformRequest: url => {
          const convertUrl = new URL(url)
          if (
            convertUrl.origin !== 'https://mt0.google.com' &&
            convertUrl.origin !== 'https://mt1.google.com' &&
            convertUrl.origin !== 'https://mt2.google.com'
          )
            return {
              url: url,
            }
        },
        preserveDrawingBuffer: true,
      })
      this.beforeMap = new mapboxgl.Map({
        container: this.$refs.beforeMap,
        style: mapStyleSource,
        center: [94.66114, 28.173],
        zoom: 0,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        transformRequest: url => {
          const convertUrl = new URL(url)
          if (
            convertUrl.origin !== 'https://mt0.google.com' &&
            convertUrl.origin !== 'https://mt1.google.com' &&
            convertUrl.origin !== 'https://mt2.google.com'
          )
            return {
              url: url,
            }
        },
        preserveDrawingBuffer: true,
      })
      this.syncMap = new mapboxgl.Map({
        container: this.$refs.mapSync, // container id
        style: mapStyleSource,
        zoom: 0, // starting zoom,
        maxZoom: 22,
        minZoom: 0,
        attributionControl: false,
        preserveDrawingBuffer: true,
      })
      diffMap = new Compare(this.beforeMap, this.map, '#wrapper', {})
      x = diffMap.currentPosition
      diffMap.setSlider(-150)
      draw = new MapboxDraw({
        keybindings: true,
        displayControlsDefault: false,
        userProperties: true,
        controls: {
          line_string: false,
          polygon: false,
          trash: false,
        },
        modes: {
          ...MapboxDraw.modes,
          static: StaticMode,
          draw_assisted_rectangle: DrawRectangleAssisted,
          draw_rectangle: DrawRectangle,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
          draw_circle: CircleMode,
          draw_polygon: DrawPolygon,
          draw_line: DrawLineString,
          draw_point: DrawPoint,
        },
      })
      this.syncMap.on('style.load', () => {
        this.reSize()
      })

      this.map.on('load', async () => {
        this.center = this.map.getCenter()
        this.changeBaseMap(this.currentBaseMap)
        // await this.reSize()
        this.firstLoad = false
        this.zoomLevel = this.map.getZoom().toFixed(2)
      })
      this.map.on('draw.create', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('openSetPropDialog', e.features[0])
        this.$emit('activateEditMode')
        this.$emit('addFeatureHandleWithoutProp', e.features[0])
      })
      this.map.on('draw.update', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('updateFeatureHandle', e.features[0])
      })
      this.map.on('draw.delete', e => {
        this.setAOI()
        this.setMeasureResult()
        this.$emit('deleteFeatureHandle', e.features[0])
      })
      this.map.on('mousemove', e => {
        this.getPixelProperties(e)
        this.getPixelValue(e)
      })
      this.map.on('zoomend', () => {
        this.center = this.map.getCenter()
        this.zoomLevel = this.map.getZoom().toFixed(2)
        this.$emit('zoomLevel', this.zoomLevel)
      })
      this.map.on('move', () => {
        this.center = this.map.getCenter()
        const center = this.map.getCenter()
        const zoom = this.map.getZoom()
        const pitch = this.map.getPitch()
        const bearing = this.map.getBearing()
        if (!this.disableMoveEvent) this.$emit('onMoveEnd', { center, zoom, pitch, bearing })
        this.$nextTick(() => {
          this.disableMoveEvent = false
        })
        if (!this.disable) {
          const center = this.map.getCenter()
          const zoom = this.map.getZoom()
          const pitch = this.map.getPitch()
          const bearing = this.map.getBearing()

          this.disable = true
          this.syncMap.setCenter(center)
          this.syncMap.setZoom(zoom)
          this.syncMap.setPitch(pitch)
          this.syncMap.setBearing(bearing)
          this.disable = false
        }
      })
      this.map.on('moveend', () => {
        this.changeArea()
      })
      this.syncMap.on('move', () => {
        if (!this.disable) {
          const center = this.syncMap.getCenter()
          const zoom = this.syncMap.getZoom()
          const pitch = this.syncMap.getPitch()
          const bearing = this.syncMap.getBearing()

          this.disable = true
          this.map.setCenter(center)
          this.map.setZoom(zoom)
          this.map.setPitch(pitch)
          this.map.setBearing(bearing)
          this.disable = false
        }
      })
      this.map.on('click', e => {
        this.$emit('currentPoint', e.lngLat)
        this.$emit('onSelectEditingFeature', e.lngLat, this.getDistanceByPixel(4))
        let features = this.map.queryRenderedFeatures(e.point)
        if (features.length) {
          this.$emit('featureInfo', features, [e.lngLat['lng'], e.lngLat['lat']])
          if (this.inspect) this.displayData(e, features)
        }
        const bufferLength = this.getDistanceByPixel(4)
        if (this.$refs.indetify) this.$refs.indetify.getInfoFeature([e.lngLat['lat'], e.lngLat['lng']], bufferLength)
        if (this.isOpenMeasurementTool && this.mode === 'Location') {
          markers.forEach(marker => {
            marker.remove()
          })
          this.addMarkerToMap(
            {
              type: 'Point',
              coordinates: [e.lngLat['lng'], e.lngLat['lat']],
            },
            '#ff0015',
          )
          this.$refs.measureTool.setResult(e.lngLat)
        }
      })
      this.beforeMap.on('style.load', () => {
        this.changeCompare(this.compare)
        this.reloadMap()
      })
      this.map.dragRotate.disable()
      this.map.touchZoomRotate.disableRotation()
      // this.map.addControl(new mapboxgl.ScaleControl())
      this.map.addControl(draw, 'top-right')
      this.drawControl()
      addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) this.isMapFullscreen = false
      })
    },
    changeArea: debounce(function () {
      this.$emit('changeArea', this.map.getZoom(), this.map.getBounds())
    }, 800),
    calculateZoom(scale, lat) {
      const R_EARTH = 591657550.5
      return Math.log((R_EARTH * Math.cos((lat * Math.PI) / 180)) / (scale * 2)) / Math.log(2)
    },
    calculateScale(zoom, lat) {
      const R_EARTH = 591657550.5
      return (R_EARTH / Math.pow(2, zoom + 1)) * Math.cos((lat * Math.PI) / 180)
    },
    changeBaseMap(baseMap) {
      if (!this.map) return
      this.map.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      this.beforeMap.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      this.syncMap.setLayoutProperty(baseMap.id, 'visibility', 'visible')
      this.mapStyles
        .filter(val => val.id !== baseMap.id)
        .forEach(layer => {
          this.map.setLayoutProperty(layer.id, 'visibility', 'none')
          this.beforeMap.setLayoutProperty(layer.id, 'visibility', 'none')
          this.syncMap.setLayoutProperty(layer.id, 'visibility', 'none')
        })
    },
    displayCluster(data, isHover = false, customIcon = undefined, addToList = false) {
      // this.removeAllLayer()
      this.removeCluster()
      this.clusterId = utils.getUUID()
      this.layers = []
      this.map.addSource(this.clusterId, {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 22,
        clusterRadius: 22,
      })
      // Add layer to map
      this.map.addLayer({
        id: 'clusters' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        paint: {
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fffdfd',
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#fcca00',
            5,
            '#ee5a5a',
            20,
            '#e81747',
            100,
            '#ff0080',
            500,
            '#ff0000',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      })
      this.map.addLayer({
        id: 'cluster-count' + this.clusterId,
        type: 'symbol',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-ignore-placement': true,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })
      if (customIcon) this.addCustomIcon(customIcon, this.clusterId)
      else {
        this.map.addLayer({
          id: 'unclustered-point' + this.clusterId,
          type: 'circle',
          source: this.clusterId,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#da1122',
            'circle-radius': 7,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#fffdfd',
          },
        })
      }
      // on Click layer
      this.map.on('click', 'clusters' + this.clusterId, e => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters' + this.clusterId],
        })
        const clusterId = features[0].properties.cluster_id
        this.map.getSource(this.clusterId).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return
          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          })
        })
      })
      this.map.on('click', 'unclustered-point' + this.clusterId, async e => {
        this.$emit('clickToPoint', e.features[0])
        if (document.fullscreenElement) await document.exitFullscreen()
      })
      // this.layers.push(
      //   'clusters' + this.clusterId,
      //   'cluster-count' + this.clusterId,
      //   'unclustered-point' + this.clusterId,
      // )
      if (isHover) this.addHoverToLayer('unclustered-point' + this.clusterId)
    },
    addCustomIcon(customIcon, id) {
      let availableIcon = []
      customIcon.images.map(icon => {
        if (!this.addedIcon.some(val => val === icon.id)) availableIcon.push(icon)
      })
      availableIcon.forEach(icon => {
        this.map.loadImage(icon.src, (error, image) => {
          if (error) throw error
          this.map.addImage(icon.id, image, { sdf: false })
        })
        this.addedIcon.push(icon.id)
      })

      this.map.addLayer({
        id: 'unclustered-point' + id,
        type: 'symbol',
        source: id,
        minzoom: 0,
        maxzoom: 22,
        layout: {
          'icon-image': customIcon.filterIcon,
          'icon-size': 0.3,
        },
        filter: ['!', ['has', 'point_count']],
      })
    },
    removeCluster() {
      try {
        if (this.clusterId) {
          this.map.removeLayer('clusters' + this.clusterId)
          this.map.removeLayer('cluster-count' + this.clusterId)
          this.map.removeLayer('unclustered-point' + this.clusterId)
          this.map.removeSource(this.clusterId)
          this.clusterId = undefined
        }
      } catch (e) {}
    },
    getCanvas() {
      return this.map.getCanvas()
    },
    async addHighlightLayer(geometry) {
      this.displayVector(
        'highlight',
        geometry,
        geometry.type === 'Linestring' ? 'line' : geometry.type === 'Point' ? 'circle' : 'fill',
        '#ff0015',
        'highlight',
      )
      await sleep(1200)
      this.map.removeLayer('highlight')
      this.map.removeSource('highlight')
      this.beforeMap.removeLayer('highlight')
      this.beforeMap.removeSource('highlight')
      this.syncMap.removeLayer('highlight')
      this.syncMap.removeSource('highlight')
    },
    getDistanceByPixel(width) {
      const y = this.map._container.clientHeight / 2
      return this.getDistance(this.map.unproject([0, y]), this.map.unproject([width, y]))
    },
    getDistance(latlng1, latlng2) {
      const R = 6371000

      const rad = Math.PI / 180
      const lat1 = latlng1.lat * rad
      const lat2 = latlng2.lat * rad
      const a =
        Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad)

      return R * Math.acos(Math.min(a, 1))
    },
    changeCompare(show) {
      this.sync = false
      this.$nextTick(() => {
        this.beforeMap.resize()
      })
      if (show) diffMap.setSlider(x)
      else diffMap.setSlider(-100000)
    },
    updateLayerMapTiles(layer) {
      this.map.getSource(layer.source).tiles = [layer.data.tiles[0] + '&ver=' + utils.getUUID()]
      this.map.getSource(layer.source).minzoom = layer.minzoom
      this.map.getSource(layer.source).maxzoom = layer.maxzoom
      this.map.style.sourceCaches[layer.source].clearTiles()
      this.map.style.sourceCaches[layer.source].update(this.map.transform)
      this.map.triggerRepaint()

      this.beforeMap.getSource(layer.source).tiles = [layer.data.tiles[0] + '&ver=' + utils.getUUID()]
      this.beforeMap.getSource(layer.source).minzoom = layer.minzoom
      this.beforeMap.getSource(layer.source).maxzoom = layer.maxzoom
      this.beforeMap.style.sourceCaches[layer.source].clearTiles()
      this.beforeMap.style.sourceCaches[layer.source].update(this.beforeMap.transform)
      this.beforeMap.triggerRepaint()
    },
    updateLayerMapData(layer) {
      this.map.getSource(layer.source).setData(layer.geometry)
      this.map.style.sourceCaches[layer.source].clearTiles()
      this.map.style.sourceCaches[layer.source].update(this.map.transform)
      this.map.triggerRepaint()

      this.beforeMap.getSource(layer.source).setData(layer.geometry)
      this.beforeMap.style.sourceCaches[layer.source].clearTiles()
      this.beforeMap.style.sourceCaches[layer.source].update(this.beforeMap.transform)
      this.beforeMap.triggerRepaint()
    },
    addSource(data, type, sourceId) {
      let source = {
        type: type,
        tiles: data.data.tiles,
        tileSize: type === 'raster' ? 256 : 512,
        maxzoom: data.maxzoom || 22,
        minzoom: data.minzoom || 1,
        bounds: data.data.bounds ? data.data.bounds : [-180.0, -85.0511, 180.0, 85.0511],
      }
      if (!this.map.getSource(sourceId)) {
        this.map.addSource(sourceId, source)
        this.syncMap.addSource(sourceId, source)
        this.beforeMap.addSource(sourceId, source)
      }
      return sourceId
    },
    addVectorTiles(data) {
      try {
        data.data.tiles = data.tiles
        data.data.bounds = data.bounds ? data.bounds : [-180.0, -85.0511, 180.0, 85.0511]
        let display = true
        if (data.display === false) display = false
        let sourceId = `source_${data.id}`
        this.addSource(data, 'vector', sourceId)
        let vector = new VectorTilesLayer({
          source: sourceId,
          data: data,
          icon: data.icon,
          label: data.label,
          bbox: data.bounds,
          sourceLayer: data.layerName,
          jsonUrl: data.jsonUrl,
          name: data.name,
          layerLeft: display,
          layerRight: display,
          style: data.paint,
          dataId: data.id,
          opacity: 1,
          filter: data.filter,
          id: utils.getUUID(),
          type: data.type,
          session: data.session,
        })
        let layer = vector.getMapboxLayer()
        this.addLayer(layer)
        this.layers.unshift(layer)
        if (data.isHover) this.addHoverToLayer(layer.id)
        // this.submitZoom(data.bounds)
      } catch (e) {
        console.log(e)
      }
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return new File([theBlob], fileName, {
        type: theBlob.type,
      })
    },
    getMapFormData() {
      const mapCanvas = this.map.getCanvas()
      const baseCanvas = document.createElement('canvas')
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      const context = baseCanvas.getContext('2d')

      const mapImage = new window.Image()
      mapImage.src = mapCanvas.toDataURL()
      mapImage.onload = () => {
        context.drawImage(mapImage, 0, 0)
        baseCanvas.toBlob(result => {
          let binary = this.blobToFile(result, 'test')
          const formData = new FormData()
          formData.append('file', binary)
          formData.append('zoom_level', this.zoomLevel)
          formData.append('left', this.map.getBounds()._sw.lng)
          formData.append('right', this.map.getBounds()._ne.lng)
          formData.append('top', this.map.getBounds()._ne.lat)
          formData.append('bottom', this.map.getBounds()._sw.lat)
          this.$emit('formData', formData)
        })
      }
    },
    changeDisplayTypePixelValue() {
      this.isDMS = !this.isDMS
      this.changePixelValue()
    },
    closeColorPicker() {
      this.isColorPicker = false
    },
    addHoverToLayer(layerId) {
      if (!layerId) return
      this.map.on('mousemove', layerId, e => {
        this.setCursorToPointer()
      })
      this.map.on('mouseleave', layerId, e => {
        this.setCursorToNormal()
      })
    },
    displayData(e, features) {
      if (popup) popup.remove()
      let html = `<div style='width: fit-content; text-align: center;'>
              <div style='float: left; color: black; margin-left: 10px'>ID: ${features[0].properties.id}</div>
              <br>
              <div style='float: left; color: black; margin-left: 10px'>Type: ${features[0].properties.type}</div>
          </div>`
      this.displayPopup([e.lngLat['lng'], e.lngLat['lat']], html, true)
    },
    getPixelProperties(e) {
      if (!this.isColorPicker) return
      this.currentPoint = e.lngLat['lng'].toFixed(4) + ',&nbsp;&nbsp;' + e.lngLat['lat'].toFixed(4)
      this.map.getCanvas().style.cursor = ''
      if (popup) popup.remove()
      this.map.getCanvas().style.cursor = 'pointer'
      const { point } = e
      const { x, y } = point
      const canvas = this.map.getCanvas()
      const gl = canvas.getContext('webgl') || canvas.getContext('webgl2')
      if (gl) {
        const data = new Uint8Array(4)
        const canvasX = x - canvas.offsetLeft
        const canvasY = canvas.height - y - canvas.offsetTop
        gl.readPixels(canvasX, canvasY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data)
        const [r, g, b, a] = data
        const color = `rgba(${r}, ${g}, ${b}, ${a})`
        let pointData = this.type.data.find(val => val.color === colorFormat.covertToHex(color).toUpperCase())
        if (pointData) {
          this.$emit('getWithinLayer', [e.lngLat['lng'], e.lngLat['lat']], color, pointData)
        }
      }
    },
    displayPopup(point, html, closeOnClick = true) {
      popup = new mapboxgl.Popup({ closeOnClick: closeOnClick }).setLngLat(point).setHTML(html).addTo(this.map)
    },
    removePopup() {
      this.pointInfo = {}
    },
    changePixelValue() {
      if (this.isDMS)
        this.currentPoint = latDMS(+this.currentLatLng['lat']) + ', &nbsp;' + lngDMS(+this.currentLatLng['lng'])
      else
        this.currentPoint =
          this.currentLatLng['lat'].toFixed(6) + ',&nbsp;&nbsp;' + this.currentLatLng['lng'].toFixed(6)
    },
    getPixelValue(e) {
      this.currentLatLng = e.lngLat
      this.changePixelValue()
    },
    zoomToCenter(point) {
      if (point) {
        try {
          let pt = point.replaceAll(' ', '').split(',')
          this.map.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10,
          })
          this.beforeMap.flyTo({
            center: [+pt[0], +pt[1]],
            zoom: 10,
          })
        } catch (e) {}
      }
    },
    disableEditDraw() {
      draw.changeMode('static')
      this.isStaticMode = true
      let b = [
        'gl-draw-line-static.cold',
        'gl-draw-line-static.hot',
        'gl-draw-polygon-stroke-static.cold',
        'gl-draw-polygon-stroke-static.hot',
      ]
      b.forEach(id => {
        this.map.setPaintProperty(id, 'line-color', '#ff0015')
      })
      let a = ['gl-draw-polygon-fill-static.cold', 'gl-draw-polygon-fill-static.hot']
      a.forEach(id => {
        this.map.setPaintProperty(id, 'fill-outline-color', '#ff0015')
        this.map.setPaintProperty(id, 'fill-color', '#ff0015')
      })
    },
    changeMode(mode) {
      if (!this.isDrawing) return
      switch (mode) {
        case 'polygon':
          if (draw.getMode() !== 'draw_polygon') draw.changeMode('draw_polygon')
          break
        case 'assist-rectangle':
          if (draw.getMode() !== 'draw_assisted_rectangle') draw.changeMode('draw_assisted_rectangle')
          break
        case 'rectangle':
          if (draw.getMode() !== 'draw_rectangle') draw.changeMode('draw_rectangle')
          break
        case 'LineString':
          if (draw.getMode() !== 'draw_line_string') draw.changeMode('draw_line_string')
          break
        case 'static':
          if (draw.getMode() !== 'static') draw.changeMode('static')
          break
        case 'Point':
          if (draw.getMode() !== 'draw_point') draw.changeMode('draw_point')
          break
        case 'delete':
          if (draw && draw.getSelected()) {
            draw.trash()
          }
          break

        default:
          break
      }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    drawControl() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (draw.getMode() !== 'draw_polygon' && this.isDrawing) draw.changeMode('draw_polygon')
            break
          case '4':
          case 'w':
            if (draw.getMode() !== 'draw_rectangle' && this.isDrawing) draw.changeMode('draw_rectangle')
            break
          case '5':
          case 'e':
            if (draw.getMode() !== 'draw_assisted_rectangle' && this.isDrawing)
              draw.changeMode('draw_assisted_rectangle')
            break
          case '6':
          case 'r':
            if (draw.getMode() !== 'draw_line_string' && this.isDrawing) draw.changeMode('draw_line_string')
            break
          case '7':
          case 'd':
            if (draw.getMode() !== 'draw_point' && this.isDrawing) draw.changeMode('draw_point')
            break
          case 'Delete':
          case 'Backspace':
            if (draw && draw.getSelected()) {
              draw.trash()
            }
            break
          default:
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    resetDraw() {
      this.isStaticMode = false
      markers.forEach(marker => {
        marker.remove()
      })
      draw.deleteAll()
      draw.changeMode('simple_select')
      this.currentAOI = {}
    },
    deSelect() {
      if (draw) {
        if (!this.isStaticMode) draw.changeMode('simple_select')
        else draw.changeMode('static')
      }
    },
    activeMeasureTool(mode, drawMode) {
      this.resetDraw()
      this.mode = mode
      switch (drawMode) {
        case 'polygon':
          draw.changeMode('draw_polygon')
          break
        case 'drag-line':
          draw.changeMode('draw_line_string')
          break
        default:
          break
      }
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    setAOI() {
      if (draw.getAll().features.length) this.currentAOI = draw.getAll()
      else this.currentAOI = {}
    },
    setMeasureResult() {
      switch (this.mode) {
        case 'Distance':
          this.$refs.measureTool.setResult(turfLength(draw.getAll()))
          break
        case 'Area':
          this.$refs.measureTool.setResult(area(draw.getAll()) / 1000000)
          break
      }
    },
    async reSize() {
      await sleep(500)
      this.map.resize()
      this.beforeMap.resize()
      this.syncMap.resize()
    },
    mapFullscreen() {
      this.isMapFullscreen = !this.isMapFullscreen
      if (this.isMapFullscreen) this.$refs.mapBound.parentElement.requestFullscreen()
      else document.exitFullscreen()
    },
    drawAOI(AOI) {
      let ids = draw.set(AOI)
      draw.changeMode('direct_select', {
        featureId: ids[0],
      })
      this.submitZoom(turf.bbox(AOI))
      this.isStaticMode = false
    },
    updateStyleProperties(layerId) {
      let currentLayer = this.layers.find(layer => layer.id === layerId)
      if (currentLayer.paint) {
        for (const key in currentLayer.paint) {
          this.map.setPaintProperty(layerId, key, currentLayer.paint[key])
          this.beforeMap.setPaintProperty(layerId, key, currentLayer.paint[key])
        }
      }
    },
    displayVector(name, geometry, type, color, id, addToList, vectorType) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'fill':
        case 'line':
          this.addGeoJsonToMap(name, geometry, color, id, type, addToList, vectorType)
          break
        case 'circle':
        case 'Point':
          this.addGeoJsonToMap(name, geometry, color, id, 'circle', addToList, vectorType)
          break
      }
    },
    addMarkerToMap(geometry, color) {
      let currentMarker = new mapboxgl.Marker({ color: color }).setLngLat(geometry.coordinates).addTo(this.map)
      markers.push(currentMarker)
      // this.submitFlyTo(geometry.coordinates)
    },
    addGeoJsonToMap(name, geometry, color, id, type, addToList, vectorType) {
      let vector = new VectorLayer({
        id: id,
        name: name,
        type: type,
        geometry: geometry,
        vectorType: vectorType,
        color: color,
        layerLeft: true,
        layerRight: true,
        opacity: 1,
      })
      let layer = vector.getMapboxLayer()
      this.addLayer(layer)
      if (addToList) this.layers.unshift(layer)
    },
    addImageToMap(image, beforeId) {
      let display = true
      if (image.display === false) display = false
      let raster = new RasterLayer({
        id: utils.getUUID(),
        dataId: image.id,
        data: image,
        min_zoom: image.meta ? image.meta.MIN_ZOOM : 0,
        max_zoom: image.meta ? image.meta.MAX_ZOOM : 22,
        layerType: image.layerType ? image.layerType : 'Raster_Image',
        name: image.name,
        tiles: image.tiles,
        bbox: image.bounds,
        layerLeft: display,
        layerRight: display,
        paint: undefined,
        opacity: 1,
        session: image.session,
      })
      this.addSource(
        {
          minzoom: image.meta ? image.meta.MIN_ZOOM : 0,
          maxzoom: image.meta ? image.meta.MAX_ZOOM : 22,
          data: {
            tiles: image.tiles,
            bounds: image.bounds,
          },
        },
        'raster',
        raster.id,
      )
      let layer = raster.getMapboxLayer()
      this.addLayer(layer, beforeId)
      if (beforeId) {
        let index = this.layers.findIndex(layer => layer.id === beforeId)
        this.layers.splice(index + 1, 0, layer)
      } else this.layers.unshift(layer)
      // this.submitZoom(image.bounds)
    },
    addLayer(layer, beforeId = undefined) {
      try {
        this.second = 0
        this.mapLoading = false
        if (!this.map.getSource(layer.id)) {
          if (layer.isGeojson) {
            this.beforeMap.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
            this.map.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
            this.syncMap.addSource(layer.id, {
              type: 'geojson',
              data: layer.geometry,
            })
          } else if (layer.isVector) this.addSource(layer, 'vector', layer.source)
          else if (layer.isRaster) this.addSource(layer, layer.type, layer.source)
        }
        layer.layout.visibility = layer.layerRight ? 'visible' : 'none'
        this.map.addLayer(layer, beforeId)
        this.syncMap.addLayer(layer, beforeId)
        layer.layout.visibility = layer.layerLeft ? 'visible' : 'none'
        this.beforeMap.addLayer(layer, beforeId)
        // if (layer.layerRight) this.submitZoom(layer.bbox)
        // this.map.on('dataloading', () => {
        //   this.mapLoading = true
        // })
      } catch (e) {
        // this.$store.commit("message/SHOW_ERROR", e.message);
      } finally {
        this.map.on('idle', () => {
          this.mapLoading = false
        })
      }
    },
    removeAllLayer() {
      if (popup) popup.remove()
      let removeValFromIndex = []
      this.layers.forEach((layer, index) => {
        if (this.map.getStyle().layers.some(val => val.id === layer.id)) {
          this.map.removeLayer(layer.id)
          this.syncMap.removeLayer(layer.id)
          this.beforeMap.removeLayer(layer.id)
          if (layer && layer.type === 'raster') {
            this.map.removeSource(layer.id)
            this.syncMap.removeSource(layer.id)
            this.beforeMap.removeSource(layer.id)
          }
          removeValFromIndex.push(index)
        }
      })
      for (let i = removeValFromIndex.length - 1; i >= 0; i--) {
        this.layers.splice(removeValFromIndex[i], 1)
      }
    },
    removeLayer(layerId) {
      let layer = this.map.getStyle().layers.find(val => val.id === layerId)
      if (!layer) return this.$store.commit('message/SHOW_ERROR', 'Layer doest not exits')
      this.map.removeLayer(layerId)
      this.syncMap.removeLayer(layerId)

      this.beforeMap.removeLayer(layerId)
      if (layer.type === 'raster') {
        this.map.removeSource(layerId)
        this.syncMap.removeSource(layerId)

        this.beforeMap.removeSource(layerId)
      }
      let index = this.layers.findIndex(layer => layer.id === layerId)
      if (index >= 0) this.layers.splice(index, 1)
    },
    changeVisibleLeftLayer(layerId, isSync) {
      let layer = this.beforeMap.getStyle().layers.find(val => val.id === layerId)
      if (layer.layout.visibility === 'visible') {
        this.syncMap.setLayoutProperty(layerId, 'visibility', 'none')
        this.beforeMap.setLayoutProperty(layerId, 'visibility', 'none')
      } else {
        this.syncMap.setLayoutProperty(layerId, 'visibility', 'visible')
        this.beforeMap.setLayoutProperty(layerId, 'visibility', 'visible')
      }
    },
    changeVisibility(layerId, visibility = undefined) {
      let layer = this.map.getStyle().layers.find(val => val.id === layerId)
      if (visibility) {
        this.map.setLayoutProperty(layerId, 'visibility', visibility)
      } else {
        if (layer.layout.visibility === 'visible') {
          this.map.setLayoutProperty(layerId, 'visibility', 'none')
        } else {
          this.map.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },
    addIconToMap(url, id, bbox) {
      if (this.map.getStyle().layers.some(val => val.id === id)) {
        this.changeVisibility(id)
        return
      }
      this.map.addSource(id, {
        type: 'image',
        url: url,
        coordinates: [
          [bbox[0], bbox[3]],
          [bbox[2], bbox[3]],
          [bbox[2], bbox[1]],
          [bbox[0], bbox[1]],
        ],
      })
      this.map.addLayer({
        id: id,
        type: 'raster',
        source: id,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'raster-fade-duration': 0,
        },
      })
    },
    zoomToLayer(layerId) {
      let layer = this.layers.find(val => val.id === layerId)
      if (layer) this.submitZoom(layer.bbox)
    },
    submitFlyTo(point) {
      this.map.easeTo({
        center: point,
        zoom: 15.5,
      })
    },
    submitZoom(bbox) {
      this.reSize()
      this.map.fitBounds(bbox, {
        duration: 0,
        padding: 50,
      })
    },
    reloadMap() {
      this.layers.forEach(layer => {
        if (this.map.getStyle().layers.some(val => val.id === layer.id)) {
          this.map.removeLayer(layer.id)
          this.syncMap.removeLayer(layer.id)
          this.beforeMap.removeLayer(layer.id)
        }
      })
      for (let i = this.layers.length - 1; i > -1; i--) {
        let layer = this.layers[i]
        this.addLayer(layer, this.clusterId ? 'clusters' + this.clusterId : undefined)
      }
    },
    //Draw functions

    setFeatureToEditMode(feature, filter) {
      this.map.setFilter(this.$parent.edittingLayer.id, filter)
      let featureId = draw.add(feature.pixel_values)
      draw.changeMode('simple_select', {
        featureIds: [feature.pixel_values.id],
      })
      return draw.get(featureId)
    },
    getAllDrawFeature() {
      return draw.getAll().features
    },
    deleteAllDraw() {
      draw.deleteAll()
    },
    removeFeatureFromDraw(featureId) {
      draw.delete([featureId])
    },
    saveFeatureProp(data, editedFeatures) {
      for (const prop in data) {
        draw.setFeatureProperty(editedFeatures.id, prop, data[prop])
      }
      this.$emit('closeSetPropDialog')
      this.$emit('addFeatureHandle', draw.get(editedFeatures.id))
      this.deSelect()
    },
    moveDrawLayerToTop() {
      for (let drawStyle of draw.options.styles) {
        this.map.moveLayer(drawStyle.id)
      }
    },
    drawFeature(feature) {
      return draw.add(feature)
    },
    setCursorToPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    setCursorToNormal() {
      this.map.getCanvas().style.cursor = ''
    },
    updateMap(data) {
      this.disableMoveEvent = true
      this.map.setCenter(data.center)
      this.map.setZoom(data.zoom)
      this.map.setPitch(data.pitch)
      this.map.setBearing(data.bearing)
    },
    setWholeLayerToEditMode(layer) {
      var ids = draw.set(layer.geometry)
      let features = []
      ids.forEach(id => {
        features.push(draw.get(id))
      })
      this.$emit('initHistory', features)
      this.map.setLayoutProperty(layer.id, 'visibility', 'none')
    },
    mouseMoveHandle(e) {
      if (e.features.length > 0 && draw.getMode() === 'simple_select') {
        this.setCursorToPointer()
      }
    },
    mouseLeaveHandle() {
      this.setCursorToNormal()
    },
    _addEventListener(eventType, handle, layerId) {
      this.evtContainer[layerId][eventType] = handle
      this.map.on(eventType, layerId, this.evtContainer[layerId][eventType])
    },
    _removeEventListener(events, layerId) {
      events.forEach(event => {
        if (this.evtContainer[layerId]) this.map.off(event, layerId, this.evtContainer[layerId][event])
      })
      if (this.evtContainer[layerId]) delete this.evtContainer[layerId]
    },
  },
  destroyed() {
    this.destroyDrawControlShortcuts()
    if (this.map) {
      this.map.remove()
      this.syncMap.remove()
      this.map = undefined
      markers = []
    }
    if (this.beforeMap) {
      this.beforeMap.remove()

      this.beforeMap = undefined
      markers = []
    }
  },
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.point-info {
  position: absolute;
  bottom: 40px;
  right: 80px;
  background-color: #ffffffc0;
  border-radius: 4px;
  min-width: 100px;
  height: 20px;
  color: #333333;
  font-size: 10px;
}

.location-detail {
  position: absolute;
  bottom: 10px;
  right: 80px;
  background-color: #ffffffc0;
  border-radius: 4px;
  min-width: 160px;
  height: 20px;
  color: #333333;
  font-size: 11px;
  font-weight: bold;
}

.base-layer {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  border: 2px solid #a8a8a8;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
