<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <DialogHeader :title="title" :isClose="true" @close="dialog = false" />
      <v-form ref="form" v-model="valid" @submit.prevent="submitData">
        <v-card-text>
          <v-text-field
            v-model="currentItem.description"
            :rules="[rules.nameRequired]"
            label="Name"
            required
            variant="outlined"
            density="compact"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" type="submit" rounded variant="flat">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  components: { DialogHeader },
  props: {
    title: {
      type: String,
      default: 'Update Conversation',
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      currentItem: {},
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
    }
  },
  methods: {
    open(item) {
      this.currentItem = { ...item }
      this.dialog = true
    },
    submitData() {
      try {
        this.$emit('submit', this.currentItem)
      } catch (e) {
        console.warn('Error when submit rename: ', e)
      } finally {
        this.dialog = false
      }
    },
  },
}
</script>
