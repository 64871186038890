<template>
  <div style="position: relative; z-index: 3">
    <v-menu v-model="menu">
      <template v-slot:activator="{ props: propsMenu }">
        <v-tooltip location="left">
          <template v-slot:activator="{ props: propsTooltip }">
            <v-btn
              :disabled="disabled"
              class="mt-1"
              height="38"
              width="38"
              min-height="0"
              min-width="0"
              v-bind="mergeProps(propsMenu, propsTooltip)"
              @click="menu = true"
              size="small"
            >
              <v-icon>mdi-pencil-ruler</v-icon>
            </v-btn>
          </template>
          <span>Map control</span>
        </v-tooltip>
      </template>
      <v-list density="compact">
        <v-list-item
          v-for="measure in listMeasures"
          :key="measure.title"
          @click="activeTool(measure.name)"
          :title="measure.title"
          :prepend-icon="measure.icon"
        ></v-list-item>
      </v-list>
    </v-menu>
    <v-card
      v-if="active"
      class="elevation-4"
      style="min-width: 350px; position: absolute; top: 50px; right: 0; border-radius: 8px"
      width="350"
    >
      <dialog-header :title="'Map control (' + mode + ')'" @close="closeMeasureTool" />
      <v-card-text class="px-6">
        <div class="d-flex flex-column justify-center align-center fill-height">
          <div class="d-flex align-center" style="width: 100%">
            <v-select
              v-if="mode === 'Distance'"
              v-model="unit"
              :items="distanceUnits"
              density="compact"
              hide-details
              item-title="name"
              label="Unit"
              variant="outlined"
              return-object
              @update:modelValue="changeUnit"
            >
            </v-select>
            <v-select
              v-else-if="mode === 'Location'"
              v-model="unit"
              :items="locationUnits"
              density="compact"
              hide-details
              item-title="name"
              label="Type"
              variant="outlined"
              return-object
              @update:modelValue="changeUnit"
            >
            </v-select>
            <v-select
              v-else
              v-model="unit"
              :items="areaUnits"
              density="compact"
              hide-details
              item-title="name"
              label="Unit"
              variant="outlined"
              return-object
              @update:modelValue="changeUnit"
            >
            </v-select>
            <v-spacer />
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="ml-3 elevation-2"
                  color="accent"
                  fab
                  icon
                  variant="outlined"
                  v-bind="props"
                  size="x-small"
                  @click="reDraw"
                >
                  <v-icon size="20">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Clear result</span>
            </v-tooltip>
          </div>
          <div style="width: 100%; height: 30px; min-height: 0; flex: none">
            <div class="d-flex align-center fill-height">
              <h3>Result:</h3>
            </div>
          </div>
          <div class="d-flex align-center px-2" style="width: 100%">
            <div
              v-if="result && mode !== 'Location'"
              class="subtitle-1"
              style="color: rgb(var(--v-theme-primary))"
              v-html="`${result.toFixed(4)} ${unit.code}`"
            ></div>
            <div v-if="result && mode === 'Location'" class="subtitle-1" v-html="result"></div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, mergeProps, ref } from 'vue'
import DialogHeader from '@/components/DialogHeader'
import { latDMS, lngDMS } from '@/utils/convertLatLngToDMS'

const props = defineProps({
  disabled: { type: Boolean, default: false },
  activeMeasureTool: { type: Boolean, default: false, required: false },
})

const emit = defineEmits(['update:activeMeasureTool', 'activeMeasureTool', 'resetDraw'])
const active = computed({
  get: () => props.activeMeasureTool,
  set: val => {
    emit('update:activeMeasureTool', val)
  },
})

const menu = ref(false)
const listMeasures = [
  {
    title: 'Measure Distance',
    name: 'Distance',
    icon: 'mdi-ruler',
  },
  {
    title: 'Measure Area',
    name: 'Area',
    icon: 'mdi-ruler-square-compass',
  },
  {
    title: 'Location',
    name: 'Location',
    icon: 'mdi-map-marker-radius',
  },
]
const unit = ref({})
const locationUnits = ref([
  {
    name: 'DMS',
    code: 'DMS',
  },
  {
    name: 'Latitude & Longitude',
    code: 'latlng',
  },
])
const distanceUnits = ref([
  {
    name: 'Kilometer',
    code: 'km',
    value: 1,
  },
  {
    name: 'Meter',
    code: 'm',
    value: 1000,
  },
])
const areaUnits = ref([
  {
    name: 'Square Kilometer',
    code: 'sqkm',
    value: 1,
  },
  {
    name: 'Square Meter',
    code: 'sqm',
    value: 1000000,
  },
  {
    name: 'Hecta',
    code: 'ha',
    value: 100,
  },
])

const mode = ref('Distance')
const result = ref(undefined)
const originResult = ref(undefined)
const resetResult = () => {
  originResult.value = undefined
  result.value = undefined
}
const reDraw = () => {
  resetResult()
  switch (mode.value) {
    case 'Distance':
      emit('activeMeasureTool', mode.value, 'drag-line')
      break
    case 'Area':
      emit('activeMeasureTool', mode.value, 'polygon')
      break
    case 'Location':
      emit('activeMeasureTool', mode.value, 'location')
      break
  }
}

const activeTool = currentMode => {
  active.value = true
  resetResult()
  mode.value = currentMode
  switch (mode.value) {
    case 'Distance':
      unit.value = {
        name: 'Kilometer',
        code: 'km',
        value: 1,
      }
      emit('activeMeasureTool', mode.value, 'drag-line')
      break
    case 'Area':
      unit.value = {
        name: 'Square Kilometer',
        code: 'sqkm',
        value: 1,
      }
      emit('activeMeasureTool', mode.value, 'polygon')
      break
    case 'Location':
      unit.value = {
        name: 'DMS',
        code: 'DMS',
      }
      emit('activeMeasureTool', mode.value, 'location')
      break
  }
}

const setResult = oResult => {
  originResult.value = oResult
  switch (mode.value) {
    case 'Location':
      if (unit.value.name === 'DMS')
        result.value = latDMS(+originResult.value['lat']) + '<br>' + lngDMS(+originResult.value['lng'])
      else
        result.value =
          'Latitude: ' +
          originResult.value['lat'].toFixed(6) +
          '<br>' +
          'Longitude: ' +
          originResult.value['lng'].toFixed(6)
      break
    default:
      result.value = unit.value.value ? originResult.value * unit.value.value : originResult.value
  }
}
const changeUnit = () => {
  if (originResult.value) setResult(originResult.value)
}
const closeMeasureTool = () => {
  resetResult()
  active.value = false
  emit('resetDraw')
}

defineExpose({
  setResult,
})
</script>
