import api from '@/api/api'
import config from '@/config'

export async function createTasking(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/tasking-orders`, data.params)
}

export async function getTaskingOrders(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/tasking-orders`, data.payload)
}

export async function getInfoTaskingOrders(data) {
  return await api.get(`${config.backend}/${data.url}`, data.payload)
}
