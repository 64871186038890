<template>
  <div class="typing-container">
    <span class="typing-text" v-html="displayedText"></span>
  </div>
</template>

<script>
import { mapState } from '@/store/ults'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    speed: {
      type: Number,
      default: 20,
    },
    isBottom: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('chat', ['typingText']),
  },
  watch: {
    typingText(val) {
      if (!val) {
        clearInterval(this.intevalTyping)
        this.displayedText = this.text
        this.$nextTick(() => {
          this.$emit('renderDynamicComponents')
        })
      }
    },
  },
  data() {
    return {
      displayedText: '',
      intevalTyping: null,
    }
  },
  methods: {
    typeText(text) {
      this.displayedText = ''
      let index = 0
      const baseSpeed = 100
      const minSpeed = 10
      const dynamicSpeed = Math.max(minSpeed, baseSpeed / Math.log(text.length + 1))

      this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'typingText', value: true })
      this.intevalTyping = setInterval(() => {
        if (text.charAt(index) === '<' && text.substr(index, 4) === '<div') {
          let tempText = ''
          let hasDynamicComponent = false
          while (index < text.length) {
            tempText += text.charAt(index)
            index++
            // Check for the closing div tag
            if (tempText.substring(tempText.length - 6) === '</div>') {
              this.displayedText += tempText
              this.$nextTick(() => {
                this.$emit('renderDynamicComponents')
              })
              hasDynamicComponent = true
              break
            }
          }
          if (!hasDynamicComponent) {
            this.displayedText += tempText
          }
        } else {
          // Normal character, just append to displayedText
          this.displayedText += text.charAt(index)
          index++
        }
        if (index >= text.length) {
          clearInterval(this.intevalTyping)
          this.$store.commit('chat/SET_STATE_PROPERTY', {
            property: 'typingText',
            value: false,
          })
          // this.$emit('typingFinished')
        }
        if (this.isBottom) this.$emit('scrollToBottom')
      }, dynamicSpeed)
    },
  },
  mounted() {
    this.typeText(this.text)
  },
}
</script>

<style>
.typing-container {
  display: inline-block;
  white-space: pre-wrap;
}
.typing-text {
  border-right: 2px solid;
  animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
  from {
    border-right-color: transparent;
  }
  to {
    border-right-color: black;
  }
}
</style>
