<template>
  <v-app class="page-background">
    <div class="elevation-2" style="flex: none; height: 64px; width: 100%">
      <div style="width: 100%; height: 100%">
        <div class="d-flex align-center fill-height px-6">
          <router-link
            :to="currentUser.isSysAdmin ? '/admin' : '/projects'"
            class="d-flex align-center text-decoration-none"
          >
            <img :src="logoSvgSource" alt="logo" class="app-logo me-3" width="85" />
            <v-slide-x-transition>
              <div style="font-size: 18px">
                Sustainability Monitoring Platform
                <div style="font-size: 11px">MEASURE, REPORT & VERIFY</div>
                <div style="font-size: 9px">(Everything that matters to our planet)</div>
              </div>
            </v-slide-x-transition>
          </router-link>
          <v-spacer />
          <Notification />
          <AppBarUserMenu></AppBarUserMenu>
        </div>
      </div>
    </div>
    <Message />
    <v-main
      style="
        height: calc(100vh - 65px);
        overflow-y: auto;
        overflow-x: hidden;
        background-repeat: no-repeat;
        background-size: auto 100%;
      "
    >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { RouterLink } from 'vue-router'
import Notification from '@/components/notification/Notification.vue'
import Message from '@/components/Message.vue'
import { SYSADMIN } from '@/constants/role'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import { getSettingMenus } from '@/api/menu-api'

const bgSvgSource = new URL('@/assets/images/project/bg.svg', import.meta.url).href
const logoSvgSource = new URL('@/assets/images/logos/logo.png', import.meta.url).href

export default {
  components: {
    AppBarUserMenu,
    Message,
    Notification,
  },
  data() {
    return {
      bgSvgSource,
      logoSvgSource,
      SYSADMIN,
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.getMenus()
  },
  methods: {
    getMenus() {
      getSettingMenus().then(res => {
        this.$store.commit('menu/SET_MENU', res.data)
      })
    },
  },
}
</script>
