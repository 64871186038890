import randomColor from '@/utils/randomColor'

export default class VectorTilesLayer {
  constructor(obj) {
    this._icon = obj.icon
    this._label = obj.label
    this._legend = false
    this._showLegend = false
    this._isProperties = true
    this._opacity = obj.opacity ? obj.opacity : 0
    this._sourceData = obj.data
    this._sourceLayer = obj.sourceLayer
    this._id = obj.id
    this._layerLeft = true
    this._layerRight = true
    this._jsonUrl = obj.jsonUrl
    this._dataId = obj.dataId
    this._name = obj.name
    this._paint = obj.style ? obj.style : this.getDefaultPaint(obj.type, obj.opacity)
    this._type = obj.type ? obj.type : 'line'
    this._bbox = obj.bbox ? obj.bbox : [-180.0, -85.0511, 180.0, 85.0511]
    this._filter = obj.filter ? obj.filter : ['all']
  }

  get isProperties() {
    return this._isProperties
  }

  get legend() {
    return this._legend
  }

  set legend(newValue) {
    this._legend = newValue
  }

  get jsonUrl() {
    return this._jsonUrl
  }

  get dataId() {
    return this._dataId
  }

  set dataId(newValue) {
    this._dataId = newValue
  }

  get showLegend() {
    return this._showLegend
  }

  set showLegend(newValue) {
    this._showLegend = newValue
  }

  get isVector() {
    return true
  }

  get type() {
    return this._type
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  set name(newValue) {
    this._name = newValue
  }

  get layerRight() {
    return this._layerRight
  }

  set layerRight(newValue) {
    this._layerRight = newValue
  }

  get layerLeft() {
    return this._layerLeft
  }

  set layerLeft(newValue) {
    this._layerLeft = newValue
  }

  get opacity() {
    return this._opacity
  }

  set opacity(newValue) {
    this._opacity = newValue
  }

  get paint() {
    return this._paint
  }

  set paint(newValue) {
    this._paint = newValue
  }

  get bbox() {
    return this._bbox
  }

  set bbox(newValue) {
    this._bbox = newValue
  }

  getDefaultPaint(type, opacity) {
    switch (type) {
      case 'line':
        return {
          'line-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'line-opacity': opacity ? opacity : 0,
          'line-width': 3,
        }
      case 'fill':
        return {
          'fill-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'fill-opacity': opacity ? opacity : 0,
          'fill-outline-color': randomColor.getColor(Math.floor(Math.random() * 10)),
        }
      case 'circle':
        return {
          'circle-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'circle-opacity': opacity ? opacity : 0,
          'circle-radius': 5,
          'circle-stroke-color': randomColor.getColor(Math.floor(Math.random() * 10)),
          'circle-stroke-opacity': 1,
          'circle-stroke-width': 2,
        }
      case 'symbol':
        return {
          'text-color': '#666666',
          'text-halo-blur': 0.5,
          'text-halo-color': '#ffffff',
          'text-halo-width': 1,
          'text-opacity': 1,
        }
    }
  }

  getLayout() {
    switch (this._type) {
      case 'line':
      case 'fill':
      case 'circle':
        return {
          visibility: 'visible',
        }
      case 'symbol':
        let layout = {
          'symbol-placement': 'line',
          visibility: 'visible',
          'text-anchor': 'center',
          'text-field': `{${this._label}}`,
          'text-font': ['Roboto Condensed Italic'],
          'text-max-width': 9,
          'text-offset': [0, 0],
          'icon-offset': [0, 3],
          'text-size': 12,
          'text-rotation-alignment': 'auto',
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'symbol-spacing': 25,
        }
        if (this._icon) layout['icon-image'] = this._icon
        return layout
    }
  }

  getMapboxLayer() {
    return {
      maxzoom: 22,
      minzoom: 0,
      data: this._sourceData,
      'source-layer': this._sourceLayer,
      name: this._name,
      isProperties: 1,
      isVector: true,
      opacity: this._opacity,
      visible: true,
      layerLeft: this._layerLeft,
      layerRight: this._layerRight,
      dataId: this._dataId,
      id: this._id,
      typeStyle: this._type === 'symbol' ? 'text' : this._type,
      type: this._type,
      displayType: 'single',
      bbox: this._bbox,
      source: `source_${this._dataId}`,
      layout: this.getLayout(),
      paint: this._paint,
      filter: this._filter,
    }
  }
}
