<template>
  <v-menu v-model="visible" :close-on-content-click="false" location="bottom" left min-width="300px">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        :style="{
          'min-width': `${size}px`,
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: iconColor,
        }"
        class="color-icon"
        style="border-radius: 6px"
      ></div>
    </template>

    <v-card class="pt-4">
      <v-card-text>
        <v-color-picker v-model="iconColor" hide-inputs mode="hex" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text="Cancel" @click="cancel"></v-btn>
        <v-btn v-if="removable" color="primary" @click="submit" text="REMOVE"></v-btn>
        <v-btn color="primary" text="Save" @click="save"></v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { colorToHex } from '@/utils/colorConverter'

export default {
  emits: ['update:color', 'change', 'remove'],
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    size: {
      type: Number,
      default: 24,
    },

    color: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    iconColorData: { r: 0, g: 0, b: 0 },
    visible: false,
  }),

  computed: {
    iconColor: {
      get() {
        return colorToHex(this.color)
      },
      set(val) {
        this.$emit('update:color', val.substring(0, 7))
      },
    },
  },

  watch: {
    color(val) {
      this.iconColor = val
    },
    iconColorData(val) {
      this.$emit('change', this.iconColor)
    },
  },

  created() {
    this.iconColor = this.color
  },

  methods: {
    submit() {
      this.$emit('remove')
      this.cancel()
    },
    cancel() {
      this.iconColor = this.color
      this.visible = false
    },

    save() {
      this.$emit('change', this.iconColor.substring(0, 7))
      this.visible = false
    },
  },
}
</script>

<style scoped>
.color-icon {
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  cursor: pointer;
}

.color-icon:active {
  border-color: rgba(0, 0, 0, 0.9);
}
</style>
