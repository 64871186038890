<template>
  <v-tabs-window-item>
    <v-list v-if="listNotifications.length > 0">
      <div v-for="(notification, i) in listGroupNotifications" :key="i">
        <v-list-subheader style="color: #cbced6" v-if="notification.isGroup">
          {{ notification.group }}
        </v-list-subheader>
        <v-list-item v-else class="item" :class="notification.read ? 'notification-read' : 'notification-unread'">
          <ItemNotification :item="notification" @close="close" />
        </v-list-item>
      </div>
    </v-list>
    <div v-else class="d-flex justify-center">{{ noContent }}</div>
    <InfiniteLoading v-if="listNotifications.length > perPage - 1" @infinite="loadMoreData" ref="infiniteLoading" />
  </v-tabs-window-item>
</template>
<script>
import ItemNotification from './item-notification.vue'
import InfiniteLoading from 'v3-infinite-loading'
import ConvertDate from '@/utils/convertDate'
import { OLDER } from '@/constants/categories-date'
import calculateDateDifference from '@/utils/calculateDateDifference'
import { checkGrammar } from '@/utils/stringHandle'

export default {
  components: {
    ItemNotification,
    InfiniteLoading,
  },
  props: {
    listNotifications: {
      type: Array,
      default: [],
    },
    noContent: {
      type: String,
      default: 'No notification found.',
    },
  },
  computed: {
    perPage() {
      return this.$store.getters['notification/perPage']
    },
    listGroupNotifications() {
      const listNotifications = []
      const groups = []
      this.listNotifications.forEach(notification => {
        const group = ConvertDate.categorizeDates(notification.created_at)
        if (!groups.includes(group)) {
          groups.push(group)
          listNotifications.push({
            group: group === OLDER ? 'Older notifications' : group,
            isGroup: true,
          })
        }
        const calcCreatedAt = this.calculateDateDifference(notification.created_at)
        listNotifications.push({
          ...notification,
          created_at: calcCreatedAt,
          isGroup: false,
        })
      })
      return listNotifications
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async loadMoreData($state) {
      const res = await this.$store.dispatch('notification/getMoreNotifications')
      if (res.length > 0) {
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    calculateDateDifference(value) {
      const timeDifference = calculateDateDifference(value)
      if (timeDifference.years > 0) {
        return `${checkGrammar(timeDifference.years, 'year')} ago`
      } else if (timeDifference.months > 0) {
        return `${checkGrammar(timeDifference.months, 'month')} ago`
      } else if (timeDifference.days > 0) {
        return `${checkGrammar(timeDifference.days, 'day')} ago`
      } else if (timeDifference.hours > 0) {
        return `${checkGrammar(timeDifference.hours, 'hour')} ago`
      } else if (timeDifference.minutes > 0) {
        return `${checkGrammar(timeDifference.minutes, 'minute')} ago`
      } else {
        return 'Just now'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
:deep{
  &.v-list {
    padding-top: 0;
  }
  &.v-list-item__content {
    padding: 0 2px !important;
  }
  &.v-theme--dark.v-list {
    background-color: transparent !important;
  }
  &.v-list .v-list-subheader {
    padding: 0;
    font-size: 16px;
    padding-inline-start: 4px !important;
  }
  &.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline-start: 10px !important;
    padding-inline-end: 0 !important;
  }
}
.item {
  margin: 3px 2px;
  border-radius: 4px !important;
  cursor: pointer;
  &:hover {
    background-color: #303249;
  }
}
.notification-unread {
  background-color: #323447;
}
</style>
