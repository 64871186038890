<template>
  <v-menu style="z-index: 2" rounded top left offset-y offset-x>
    <template #activator="{ props: propsMenu }">
      <v-tooltip location="top">
        <template #activator="{ props: propsTooltip }">
          <v-btn icon="true" size="x-small" variant="text" v-bind="mergeProps(propsMenu, propsTooltip)">
            <v-icon color="#66808d">mdi-layers-plus</v-icon>
          </v-btn>
        </template>

        <span>Add layer</span>
      </v-tooltip>
    </template>

    <v-list shaped width="250">
      <v-list-subheader class="py-0" style="border-bottom: 1px solid whitesmoke; height: 30px">
        <h4>Layers</h4>
      </v-list-subheader>
      <template v-for="(item, i) in layers">
        <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

        <v-list-item
          @click="changeDisplayLayer(item)"
          v-else
          :key="`item-${i}`"
          :value="item"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-slot:default="{ active }">
            <v-list-item-subtitle>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-subtitle>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import { mergeProps } from 'vue'

const defaultLayerJson = new URL(`@/assets/DefaultLayers/dam.json`, import.meta.url).href

export default {
  data() {
    return {
      layers: [],
    }
  },
  mounted() {
    this.layers.push({
      name: 'Dams',
      layer: defaultLayerJson,
    })
  },
  methods: {
    mergeProps,
    changeDisplayLayer(layer) {
      this.$emit('changeDisplayLayer', layer)
    },
  },
}
</script>
