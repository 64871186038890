<template>
  <v-text-field
    :class="{ 'search-earth': focusing && !cooldown }"
    id="search-earth"
    v-model="search"
    :placeholder="placeholder"
    :density="density"
    :variant="variant"
    :append-inner-icon="appendInnerIcon"
    :single-line="singleLine"
    :hide-details="hideDetails"
    :styles="styles"
    :label="label"
    :color="color"
    @blur="blurElement"
    @focus="focusElement"
    @input="input"
    autocomplete="off"
  ></v-text-field>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'

const barWidth = ref(0)
const barHeight = ref(0)
const focusing = ref(false)
const cooldown = ref(false)
const props = defineProps({
  modelValue: {},
  placeholder: { type: String, default: '' },
  density: { default: 'compact' },
  variant: { default: 'outlined' },
  appendInnerIcon: { type: String, default: 'mdi-magnify' },
  label: { type: String, default: '' },
  color: { type: String, default: '' },
  singleLine: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: true },
  styles: { type: Object, default: () => ({}) },
  blur: { type: Function, default: () => {} },
  focus: { type: Function, default: () => {} },
  input: { type: Function, default: () => {} },
})

const emit = defineEmits(['update:modelValue'])
const search = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:modelValue', value)
  },
})

const focusElement = e => {
  props.focus()
  if (e) {
    const searchEarthElement = e.target
    barWidth.value = searchEarthElement.clientWidth
    barHeight.value = searchEarthElement.clientHeight
    if (!cooldown.value) {
      focusing.value = true
    }
  }
}
const cooldownDuration = 10 * 60 * 1000
const blurElement = () => {
  props.blur()
  cooldown.value = true
  setTimeout(() => {
    cooldown.value = false
  }, cooldownDuration)
}
</script>

<style scoped lang="scss">
.cooldown {
  animation: none;
}
@keyframes earth_fly_in {
  0% {
    transform: translate(calc(-1px * v-bind('barWidth') + 20px), calc(0.75px * v-bind('barHeight'))) scale(3);
    opacity: 0;
  }
  5% {
    transform: translate(calc(-1px * v-bind('barWidth') + 20px), calc(0.75px * v-bind('barHeight'))) scale(3);
    opacity: 1;
  }
  25% {
    transform: translate(calc(-0.75px * v-bind('barWidth')), calc(0.4px * v-bind('barHeight'))) scale(2);
    opacity: 1;
  }
  75% {
    transform: translate(calc(-0.25px * v-bind('barWidth')), calc(0.1px * v-bind('barHeight'))) scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
}
@keyframes appear_earth {
  0% {
    left: 0;
    letter-spacing: 0;
    opacity: 1;
  }
  90% {
    transform: translate(10px, calc(0.1px * v-bind('barHeight') + 20px));
    letter-spacing: -5px;
    opacity: 1;
  }
  100% {
    transform: translate(10px, calc(0.1px * v-bind('barHeight') + 20px));
    letter-spacing: -10px;
    opacity: 0;
  }
}
:deep{
  &.search-earth {
    input:placeholder-shown:focus[placeholder],
    input:placeholder-shown:focus::-webkit-input-placeholder {
      animation: appear_earth 0.3s linear forwards;
    }
    .v-field:focus-within {
      clip-path: inset(-5px 0 0 0);
      .v-icon.mdi-magnify {
        animation: animated_magnify 0.5s linear;
      }
      .v-field__append-inner {
        position: relative;
        &::before {
          content: '';
          background: url('@/assets/svg/earth.svg');
          color: #9d9fa5;
          position: absolute;
          width: 20px;
          height: 20px;
          opacity: 0;
          animation: earth_fly_in 0.6s linear 0.3s;
        }
      }
    }
  }
}
</style>
