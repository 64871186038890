<template>
  <div class="custom-select d-flex">
    <div class="select-area d-flex align-center" style="max-width: 150px; min-width: 150px">
      <Select2
        v-model="currentData"
        :items="listDatas"
        density="compact"
        hide-details
        item-title="name"
        label="Select AOI"
        variant="outlined"
        return-object
        @update:modelValue="chooseValue"
      />
    </div>
    <button v-if="typeList === 'aois'" :disabled="!currentData" @click="submitValue" class="btn-choose-aoi">
      {{ submitText }}
    </button>
  </div>
</template>
<script>
import Select2 from '@/components/Select2/Select2.vue'
import { CHAT_TYPE_AOI, ADD_LAYER_TO_MAP } from '@/constants/chat'

export default {
  name: 'CustomSelect',
  props: {
    typeList: {
      type: String,
      default: '',
    },
  },
  components: {
    Select2,
  },
  data() {
    return {
      submitText: 'Choose this AOI',
      currentData: null,
      listDatas: [],
    }
  },
  computed: {
    listAOIs() {
      return this.$store.getters['AOI/listAllAOIs']
    },
  },
  methods: {
    submitValue() {
      this.$emit('submit', {
        data: this.currentData,
        type: CHAT_TYPE_AOI,
      })
    },
    chooseValue() {
      this.$emit('handleLayerMap', {
        data: this.currentData,
        type: ADD_LAYER_TO_MAP,
      })
    },
    async getDataAOIs() {
      this.listDatas = this.listAOIs
    },
    getDataServices() {
      this.listDatas = []
    },
    getListDatas() {
      switch (this.typeList) {
        case 'aois':
          this.getDataAOIs()
          break
        case 'services':
          this.getDataServices()
          break
        case 'select_aois':
          this.getDataAOIs()
          break
        default:
          return []
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getListDatas()
    })
  },
  watch: {
    listAOIs() {
      if (this.typeList === 'aoi') this.getDataAOIs()
    },
  },
}
</script>
<style scoped lang="scss">
:deep{
  .v-text-field .v-input__control {
    background-color: #736985;
    border-radius: 8px;
  }
  .v-text-field--outlined.v-input--dense .v-label,
  .v-input__append-inner .v-input__icon .v-icon,
  .v-select.v-input--dense .v-select__selection--comma {
    color: white;
  }
}

.btn-choose-aoi {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  margin: 4px 4px;
  border-radius: 20px;
  background: #9155fd;
  color: #f1f1f1;
  &:disabled {
    opacity: 0.5;
  }
}
</style>
