<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="800">
    <v-card class="custom-card-bg-gradient content" width="100%">
      <DialogHeader :is-close="true" :title="'Create Layer'" @close="dialog = false" />
      <v-form ref="formData" v-model="valid" @submit.prevent="createLayer">
        <v-card-text>
          <div class="create-form">
            <v-tabs v-model="selectedTab" grow>
              <v-tab v-for="tab in sources" :key="tab.value">
                {{ tab }}
              </v-tab>
            </v-tabs>
            <v-tabs-window v-model="selectedTab">
              <v-tabs-window-item v-for="tab in sources" :key="tab.value">
                <div class="custom-card-bg-0 ml-2 mr-2 mt-5 mb-2">
                  <v-text-field
                    v-if="tab === 'upload' || tab === 'draw'"
                    v-model="name"
                    :rules="[rules.required]"
                    density="compact"
                    label="Layer Name"
                    variant="outlined"
                    placeholder="Layer Name"
                    autofocus
                  ></v-text-field>
                  <div v-if="tab === 'draw'">
                    <div class="d-flex align-center mb-4 mt-2 ml-2">
                      Vector type :
                      <v-spacer />
                      <v-radio-group v-model="vectorType" hide-details inline>
                        <v-radio color="success" label="Polygon" value="polygon"></v-radio>
                        <v-radio color="success" label="Marker" value="marker"></v-radio>
                        <v-radio color="success" label="Text" value="text"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div v-if="tab === 'upload'">
                    <div style="width: 100%; height: 175px">
                      <DragBox :accept-types="acceptTypes" v-model:files="files" multiple />
                    </div>
                  </div>
                  <LayerSelect
                    v-if="tab === 'from aoi'"
                    ref="AOILayerSelect"
                    @createLayer="createLayer()"
                    @updateList="updateSelectedList"
                    :typeList="'AOI'"
                  />
                  <LayerSelect
                    v-if="tab === 'from data'"
                    ref="dataLayerSelect"
                    @createLayer="createLayer()"
                    @updateList="updateSelectedList"
                    :typeList="'data'"
                  />
                  <TileLayerForm v-if="tab === 'tile Layer'" ref="tileForm" :layer="tileLayer" />
                  <WMSLayer v-if="tab === 'wms'" ref="WMSForm" :layer="WMSLayer" />
                </div>
              </v-tabs-window-item>
            </v-tabs-window>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" variant="flat" rounded type="submit"> Submit </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import DragBox from '@/components/DragBox.vue'
import { uploadAOI } from '@/api/aoi-api'
import LayerSelect from './LayerSelect.vue'
import { getDetailAOI } from '@/api/aoi-api'
import TileLayerForm from './TileLayerForm.vue'
import WMSLayer from './WMSLayer.vue'

export default {
  components: { DragBox, DialogHeader, LayerSelect, TileLayerForm, WMSLayer },
  data() {
    return {
      more: false,
      dialog: false,
      loading: false,
      valid: false,
      name: '',
      vectorType: 'polygon',
      rules: {
        required: value => !!value || 'Name is required',
      },
      files: [],
      acceptTypes: '.zip,.geojson,.kml,.gml,.kmz,.shp,.shx,.prj,.dbf',
      selectedAOIs: [],
      selectedData: [],
      tileLayer: {
        name: undefined,
        tile_info: {
          tile_url: undefined,
          type: 'images',
          bbox: [-180, -85.051129, 180, 85.051129],
        },
        MIN_ZOOM: 13,
        MAX_ZOOM: 22,
      },
      WMSLayer: {
        name: undefined,
        tile_info: {
          tile_url: undefined,
          type: 'wms',
        },
        MIN_ZOOM: 0,
        MAX_ZOOM: 22,
        layer: undefined,
      },
      sources: ['draw', 'upload', 'from aoi', 'from data', 'tile Layer', 'wms'],
      selectedTab: 0,
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.name = ''
        this.files = []
        this.selectedAOIs = []
        this.selectedData = []
      }
    },
    selectedTab() {
      this.name = ''
      this.$refs.formData.resetValidation()
    },
  },
  methods: {
    createLayer() {
      switch (this.selectedTab) {
        case 0:
          this.$refs.formData.validate()
          if (!this.valid) return
          else {
            this.$emit('createLayer', this.name, this.vectorType)
            this.dialog = false
          }
          break
        case 1:
          this.$refs.formData.validate()
          if (!this.valid) return
          if (this.files.length) this.submitUpload()
          break
        case 2:
          this.selectedAOIs.forEach(AOI => {
            AOI.tile_info = {}
            AOI.tile_info.type = 'aoi'
            this.addToMap(AOI)
          })
          break
        case 3:
          this.selectedData.forEach(data => {
            this.addToMap(data)
          })
          break
        case 4:
          this.$refs.tileForm[0].$refs.formData.validate()
          if (!this.$refs.tileForm[0].valid) return
          this.addToMap(this.tileLayer)
          break
        case 5:
          this.$refs.WMSForm[0].$refs.formData.validate()
          if (!this.$refs.WMSForm[0].valid) return
          this.addToMap(this.WMSLayer)
      }
      this.dialog = false
    },
    async addToMap(data) {
      if (data.tile_info.type === 'aoi') {
        data.geojson = await this.getDetailAOI(data.uuid)
      }
      this.$emit('addToMap', data)
    },

    async getDetailAOI(uuid) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    updateSelectedList(list, type) {
      if (type === 'AOI') this.selectedAOIs = list
      if (type === 'data') this.selectedData = list
    },
    async submitUpload() {
      try {
        this.loading = true
        const formData = new FormData()
        let totalItemOfShapeFile = 0
        let types = []
        for (let i = 0; i < this.files.length; i++) {
          let extension = this.files[i].name.split('.').pop()
          switch (extension.toLowerCase()) {
            case 'zip':
            case 'geojson':
            case 'kml':
            case 'kmz':
            case 'gml':
              formData.append('files', this.files[i])
              formData.append('type', extension.toLowerCase())
              types.push(extension.toLowerCase())
              break
            case 'shp':
            case 'prj':
            case 'shx':
            case 'dbf':
              totalItemOfShapeFile = totalItemOfShapeFile + 1
              formData.append('files', this.files[i])
              if (totalItemOfShapeFile === 1) formData.append('type', 'shp')
              types.push('shp')
              break
          }
        }
        if ([...new Set(types)].length > 1)
          return this.$store.commit('message/SHOW_ERROR', 'The system does not support many file formats')

        const res = await uploadAOI(formData)
        this.$emit('setLayer', this.name, res.data)
        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.create-form {
  border: 1px solid #5d5d5d;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
}
:deep(.v-selection-control-group--inline) {
  justify-content: flex-end;
}
</style>
