<template>
  <div class="d-flex flex-column fill-height overflow-y-hidden pa-2">
    <div style="width: 100%; height: 50px; flex: none">
      <SearchBarEarth
        v-model="search"
        append-inner-icon="mdi-magnify"
        density="compact"
        hide-details
        label="Search"
        variant="outlined"
        placeholder="Search"
      >
        <template #append>
          <v-menu v-model="filter" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ props: propsMenu }">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: propsTooltip }">
                  <v-icon
                    :class="filters.from_date || filters.to_date ? 'active-filter' : ''"
                    v-bind="mergeProps(propsMenu, propsTooltip)"
                  >
                    mdi-filter-cog-outline
                  </v-icon>
                </template>
                <span>Filter Settings</span>
              </v-tooltip>
            </template>
            <v-card class="pa-3 elevation-4" style="width: 320px">
              <div class="d-flex flex-column fill-height">
                <div class="pb-1">
                  <DatePicker
                    v-model="filters.from_date"
                    :max="filters.to_date"
                    label="From date"
                    hide-details
                    variant="outlined"
                  />
                </div>
                <div class="pt-1">
                  <DatePicker
                    v-model="filters.to_date"
                    :min="filters.from_date"
                    label="To date"
                    hide-details
                    variant="outlined"
                  />
                </div>
                <div class="d-flex pt-3">
                  <v-spacer />
                  <v-btn class="mr-2" color="error" size="small" @click="resetFilter"> Reset </v-btn>
                  <v-btn color="primary" size="small" @click="applyFilter"> Apply </v-btn>
                </div>
              </div>
            </v-card>
          </v-menu>
        </template>
      </SearchBarEarth>
    </div>
    <div class="d-flex align-center" style="width: 100%; height: 50px; flex: none">
      <span>Group by:</span>
      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ props }">
          <v-btn
            class="text-capitalize"
            color="primary"
            size="small"
            v-bind="props"
            append-icon="icon-chevron_down"
            :text="groupBy"
          />
        </template>
        <v-list>
          <v-list-item v-for="gB in groupByMenu" @click="groupBy = gB.value" :title="gB.text" />
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex flex-column overflow-y-auto pt-2" style="height: calc(100% - 50px)">
      <v-expansion-panels v-model="expandPanel" class="custom-expansion" multiple>
        <v-expansion-panel v-for="(group, index) in availableData" :key="'group' + index">
          <v-expansion-panel-title>
            <div class="pr-4" style="flex: none">
              <CustomIcon icon="folder" />
            </div>
            <span class="uppercase">{{ group.name }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="pb-2">
            <div
              v-for="(item, index) in group.items"
              :key="index"
              class="my-1 pt-1 px-1 custom-card-bg-2"
              style="width: 100%; min-height: 75px; border-radius: 4px"
            >
              <div class="d-flex align-center fill-height">
                <div
                  class="d-flex align-center fill-height"
                  style="height: 60px; width: 60px; border-radius: 8px; text-align: center; flex: none"
                >
                  <v-img
                    v-if="item.tile_info.thumbnail_url"
                    :lazy-src="item.tile_info.thumbnail_url"
                    :src="item.tile_info.thumbnail_url"
                    alt="tbn"
                    contain
                    max-height="100%"
                    max-width="100%"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0 align-center justify-center">
                        <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon v-else-if="item.type === 'aoi'" size="30" style="margin: auto"> mdi-vector-square </v-icon>
                  <v-icon v-else size="30" style="margin: auto">icon-layers</v-icon>
                </div>
                <div class="d-flex flex-column pl-2" style="flex: 1; width: 0">
                  <TooltipBase
                    ref="tooltipBase"
                    :title="item.name"
                    :styles="{ width: '95%' }"
                    :triggerChange="loading"
                  />
                  <div v-if="item.type !== 'aoi'" class="caption cap-subtitle pt-1">
                    <TooltipBase
                      ref="tooltipBase"
                      :title="`AOI: ${item.aoi ? item.aoi.name : ''}`"
                      :styles="{ width: '95%' }"
                      :triggerChange="loading"
                    />
                  </div>
                  <div class="d-flex align-center">
                    <div class="caption cap-subtitle">Date: {{ item.date }}</div>
                    <v-spacer />
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          icon="mdi-map"
                          size="x-small"
                          variant="text"
                          v-bind="props"
                          @click="addToMap(item)"
                        ></v-btn>
                      </template>
                      <span>Add to map</span>
                    </v-tooltip>
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          icon="icon-download"
                          target="_blank"
                          size="x-small"
                          variant="text"
                          v-bind="props"
                          @click="downloadData(item)"
                        ></v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <DownloadVector ref="downloadVector" />
  </div>
</template>
<script>
import convertDate from '@/utils/convertDate'
import CustomIcon from '@/components/CustomIcon.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getDetailAOI } from '@/api/aoi-api'
import DownloadVector from '@/components/DownloadVector.vue'
import { mergeProps } from 'vue'
import TooltipBase from '@/components/TooltipBase.vue'
import SearchBarEarth from '../SearchBarEarth.vue'

export default {
  components: { TooltipBase, DownloadVector, DatePicker, CustomIcon, SearchBarEarth },
  data() {
    return {
      expandPanel: [],
      loading: false,
      groupByMenu: [
        { text: 'Date', value: 'Date' },
        { text: 'Type', value: 'Type' },
        { text: 'Data type', value: 'Data type' },
      ],
      filter: false,
      search: undefined,
      filters: {
        from_date: undefined,
        to_date: undefined,
      },
      groupBy: 'Date',
    }
  },
  watch: {
    filter(val) {
      if (!val) this.applyFilter()
    },
    expandPanel() {
      this.$nextTick(() => {
        this.loading = !this.loading
      })
    },
  },
  props: {
    data: { type: Array, default: () => [] },
  },
  computed: {
    listData() {
      return this.data
    },
    filteredItems() {
      if (this.search) {
        return this.listData.filter(
          data =>
            data.name.toUpperCase().includes(this.search.toUpperCase()) ||
            data.date.toUpperCase().includes(this.search.toUpperCase()),
        )
      } else return this.listData
    },
    availableData() {
      let groups = []
      switch (this.groupBy) {
        case 'Date':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === convertDate.yearMonthFormatted(item.acquired_date))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: convertDate.yearMonthFormatted(item.acquired_date),
                items: [item],
              })
          })
          groups.sort((a, b) => {
            if (a.name < b.name) return 1
            if (a.name > b.name) return -1
            return 0
          })
          return groups
        case 'Type':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === item.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
        case 'Data type':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === item.tile_info.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.tile_info.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
      }
    },
  },
  methods: {
    mergeProps,
    async addToMap(data) {
      if (data.tile_info.type === 'aoi') {
        data.geojson = await this.getDetailAOI(data)
      }
      this.$emit('addToMap', data)
    },
    downloadData(item) {
      if (item.tile_info.type === 'aoi') this.downloadAOI(item)
      else {
        let tmpData = JSON.parse(JSON.stringify(item))
        tmpData.isRaster = item.tile_info.type === 'images'
        tmpData.data = {
          data: {
            download_url: item.tile_info.download_url,
          },
        }
        this.$refs.downloadVector.openDialog(tmpData)
      }
    },
    async downloadAOI(AOI) {
      this.$refs.downloadVector.openDialog({
        name: AOI.name,
        isGeojson: true,
        geometry: await this.getDetailAOI(AOI),
      })
    },
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
        console.warn('Error when get detail AOI: ', e)
      } finally {
        this.loading = false
      }
    },
    applyFilter() {
      this.filter = false
      this.$emit('onFilter', this.filters)
    },
    resetFilter() {
      this.filters = {
        search: undefined,
        from_date: undefined,
        to_date: undefined,
      }
    },
  },
}
</script>

<style scoped>
:deep(.v-expansion-panel-title) {
  padding: 4px;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 4px;
}
.active-filter {
  color: rgb(var(--v-theme-primary));
}
.uppercase {
  text-transform: uppercase;
}
</style>
