export const VIEWER = 'viewer'
export const EDITOR = 'editor'

// Permissions
export const AOI_CREATE = 'aoi.create'
export const AOI_DELETE = 'aoi.delete'
export const AOI_UPDATE = 'aoi.update'

export const FORM_CREATE = 'form.create'
export const FORM_DELETE = 'form.delete'
export const FORM_UPDATE = 'form.update'
export const FORM_VIEW = 'form.view'

export const ORDER_CREATE = 'order.create'
export const ORDER_UPDATE = 'order.update'

export const ORGANIZATION_CREATE = 'organization.create'
export const ORGANIZATION_DELETE = 'organization.delete'
export const ORGANIZATION_UPDATE = 'organization.update'

export const PLANET_ORDER_SUBMIT = 'planet_order.submit'
export const PLANET_ORDER_VIEW = 'planet_order.view'

export const MAP_DISCOVERY_SSR_TILE = 'ssr_tile.view'

export const PROJECT_CREATE = 'project.create'
export const PROJECT_DELETE = 'project.delete'
export const PROJECT_UPDATE = 'project.update'

export const RASTER_INDEX_CREATE = 'raster_index.create'
export const RASTER_INDEX_DELETE = 'raster_index.delete'
export const RASTER_INDEX_UPDATE = 'raster_index.update'

export const ROLE_CREATE = 'role.create'
export const ROLE_DELETE = 'role.delete'
export const ROLE_UPDATE = 'role.update'
export const ROLE_VIEW = 'role.view'

export const TASKING_ORDER_CREATE = 'tasking_order.create'

export const TEAM_CREATE = 'team.create'
export const TEAM_DELETE = 'team.delete'
export const TEAM_UPDATE = 'team.update'
export const TEAM_VIEW = 'team.view'

export const USER_CREATE = 'user.create'
export const USER_UPDATE = 'user.update'
export const USER_VIEW = 'user.view'

export const WORKMANAGEMENT_CREATE = 'workmanagement.create'
export const WORKMANAGEMENT_DELETE = 'workmanagement.delete'
export const WORKMANAGEMENT_UPDATE = 'workmanagement.update'
export const WORKMANAGEMENT_VIEW = 'workmanagement.view'
