import { getListProjects } from '@/api/project-api'
import ConvertDate from '@/utils/convertDate'

export default {
  namespaced: true,
  state: {
    projects: [],
    currentProjectId: undefined,
    projectArea: undefined,
  },
  getters: {
    projects: state => state.projects,
    currentProjectId: state => state.currentProjectId,
  },
  mutations: {
    SET_PROJECTS(state, projects) {
      state.projects = projects
    },
    SET_CURRENT_PROJECT_ID(state, projectId) {
      state.currentProjectId = projectId
    },
  },
  actions: {
    async getListProject({ commit }, params) {
      const res = await getListProjects(params)
      // make model for this?
      const projects = res.data.map(project => {
        project.created_at = ConvertDate.dateFormatted(project.created_at)
        return project
      })
      commit('SET_PROJECTS', projects)
      return projects
    },
  },
}
