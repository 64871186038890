const colorFormat = {
  convertToRGB(hex) {
    try {
      if (hex.charAt(0) === '#') {
        hex = hex.substr(1)
      }
      if (hex.length < 2 || hex.length > 6) {
        return
      }
      let values = hex.split('')
      let r
      let g
      let b

      if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = r
        b = r
      } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16)
        g = parseInt(values[1].toString() + values[1].toString(), 16)
        b = parseInt(values[2].toString() + values[2].toString(), 16)
      } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16)
        g = parseInt(values[2].toString() + values[3].toString(), 16)
        b = parseInt(values[4].toString() + values[5].toString(), 16)
      } else {
        return
      }
      return `rgb(${r},${g},${b})`
    } catch (e) {
      return `rgb(255, 255, 255)`
    }
  },
  covertToHex(rgb) {
    try {
      rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)
      return rgb && rgb.length === 4
        ? '#' +
            ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
        : ''
    } catch (e) {
      return '#ffffff'
    }
  },
}
export default colorFormat
