import { getServices } from '@/api/services'
import Store from '@/store'

export default {
  namespaced: true,
  state: {
    services: [],
    projections: [],
    firstLoad: true,
    sources: ['Sentinel2 L2A'],
    source: 'Sentinel2 L2A',
    cacheFields: [],
    currentField: undefined,
    frequency: 'daily',
    defaultData: undefined,
  },
  getters: {
    services(state) {
      return state.services
    },
    projections(state) {
      return state.projections
    },
    firstLoad(state) {
      return state.firstLoad
    },
    filterImage: state => (routeName, data) => {
      if (!state.defaultData || !data || data.length === 0) {
        return data[data.length - 1]
      }
      const isMatchingdefaultData = state.defaultData?.name === routeName
      const foundImage = data.find(image => image.date === state.defaultData?.result?.date)
      state.defaultData = undefined
      return isMatchingdefaultData && foundImage ? foundImage : data[data.length - 1] || null
    },
    filterAOI: state => routeName => {
      const isMatchingdefaultData = state.defaultData?.name === routeName
      const foundAOI = Store.state.AOI.AOIs.find(aoi => aoi.uuid === state.defaultData?.aoi?.uuid)
      return isMatchingdefaultData && foundAOI ? foundAOI : Store.state.AOI.AOIs[0] || null
    },
  },
  mutations: {
    SET_PROPERTY_VALUE(state, { property, value }) {
      state[property] = value
    },
    SET_SOURCES(state, sources) {
      state.sources = sources
      state.source = state.defaultData?.image_source ? state.defaultData?.image_source : sources[0]
    },
    SET_CACHE_FIELDS(state, cacheFields) {
      state.cacheFields = cacheFields
    },
  },
  actions: {
    async getListServices({ commit }, payload = {}) {
      try {
        const res = await getServices(payload)
        commit('SET_PROPERTY_VALUE', { property: 'services', value: res.data })
      } catch (e) {
        console.warn('Error when fetching services', e)
      }
    },
  },
}
