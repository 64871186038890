<template>
  <div class="handle-find">
    <SearchBarEarth
      v-model="search"
      append-inner-icon="mdi-magnify"
      density="compact"
      hide-details
      clearable
      label="Search by descriptions"
      placeholder="Search"
      variant="outlined"
      @update:modelValue="searchData"
      @click.stop
    >
      <template #append>
        <FilterNotification :listFilters="listFilters" @filterData="searchData" />
      </template>
    </SearchBarEarth>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import FilterNotification from './filter-notification.vue'
import FILTER_NOTIFICATIONS from '@/constants/filter-notification'
import SearchBarEarth from '../SearchBarEarth.vue'

export default {
  components: {
    FilterNotification,
    SearchBarEarth,
  },
  computed: {
    search: {
      get() {
        return this.$store.getters['notification/searchNotification']
      },
      set(value) {
        this.$store.commit('notification/SET_STATE_PROPERTY', {
          property: 'searchNotification',
          value,
        })
      },
    },
  },
  data: () => {
    return {
      listFilters: FILTER_NOTIFICATIONS,
    }
  },
  methods: {
    searchData: debounce(function () {
      this.$emit('searchData')
    }, 500),
  },
}
</script>

<style scoped lang="scss">
.handle-find {
  padding: 10px 2px;
}
</style>
