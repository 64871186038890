const DB_NAME = 'myAppDB'
const DB_VERSION = 1
let db = null

export const initDB = stores => {
  return new Promise((resolve, reject) => {
    let request = window.indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = event => {
      console.error('Database error: ', event.target.errorCode)
      reject(event.target.errorCode)
    }

    request.onsuccess = event => {
      db = event.target.result
      resolve(db)
    }

    request.onupgradeneeded = event => {
      db = event.target.result
      stores.forEach(storeName => {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'id' })
        }
      })
    }
  })
}

export const saveData = (storeName, data, key) => {
  return new Promise((resolve, reject) => {
    if (!db) return reject('Database is not initialized')
    let transaction = db.transaction([storeName], 'readwrite')
    let store = transaction.objectStore(storeName)

    let request = store.put({ id: key, value: JSON.parse(JSON.stringify(data)) })
    request.onsuccess = () => {
      console.log(`Data saved to ${storeName}:`, data)
    }

    request.onerror = event => {
      console.error(`Error saving data to ${storeName}:`, event)
      reject(event.target.errorCode)
    }
    transaction.oncomplete = () => {
      resolve(`All data saved successfully to ${storeName}.`)
    }

    transaction.onerror = event => {
      console.error('Transaction error:', event.target)
      reject(event.target.errorCode)
    }
  })
}

export const getAllData = storeName => {
  return new Promise((resolve, reject) => {
    if (!db) return reject('Database is not initialized')
    let transaction = db.transaction([storeName], 'readonly')
    let store = transaction.objectStore(storeName)
    let request = store.getAll()

    request.onsuccess = event => {
      resolve(event.target.result)
    }

    request.onerror = event => {
      console.error(`Error fetching data from ${storeName}:`, event)
      reject(event.target.errorCode)
    }
  })
}
export const getData = (storeName, key) => {
  return new Promise((resolve, reject) => {
    if (!db) return reject('Database is not initialized')

    let transaction = db.transaction([storeName], 'readonly')
    let store = transaction.objectStore(storeName)
    let request = store.get(key)

    request.onsuccess = event => {
      resolve(event.target.result)
    }

    request.onerror = event => {
      console.error(`Error fetching data from ${storeName} for key ${key}:`, event)
      reject(event)
    }
  })
}

export const checkItemExist = (storeName, id) => {
  return new Promise((resolve, reject) => {
    if (!db) return reject('Database is not initialized')
    let transaction = db.transaction([storeName], 'readonly')
    let store = transaction.objectStore(storeName)
    let request = store.get(id)

    request.onsuccess = event => {
      resolve(event.target.result)
    }

    request.onerror = event => {
      console.error(`Error checking item in ${storeName}:`, event.target.errorCode)
      reject(event.target.errorCode)
    }
  })
}
