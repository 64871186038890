export function checkGrammar(number, text) {
  if (isNaN(number)) return text
  if (number === 1) {
    return `${number} ${text}`
  } else {
    const exceptions = ['chef', 'roof', 'belief', 'chief']
    const specialCases = ['s', 'x', 'z', 'ch', 'sh']

    if (exceptions.includes(text)) {
      return `${number} ${text}s`
    } else if (specialCases.some(suffix => text.endsWith(suffix))) {
      return `${number} ${text}es`
    } else if (text.endsWith('f')) {
      return `${number} ${text.slice(0, -1)}ves`
    } else if (text.endsWith('fe')) {
      return `${number} ${text.slice(0, -2)}ves`
    } else {
      return `${number} ${text}s`
    }
  }
}

export function renameIncrease(text, listTexts) {
  if (!listTexts.includes(text)) return text
  let i = 1
  while (listTexts.includes(`${text} (${i})`)) {
    i++
  }
  return `${text} (${i})`
}
