import ax from 'axios'
import backendConfig from '@/config'
import ConvertDate from '@/utils/convertDate'

const END_POINT = backendConfig.backend + '/blacksky'
let API_KEY = ''

const config = {
  baseURL: END_POINT,
  timeout: 30000,
}
let axios = ax.create(config)

export class PlanetTaskingApi {
  constructor() {
    this.itemTypes = null

    // this.getItemTypes().then((types) => {
    //   this.itemTypes = types
    // })
  }

  setApikey(key) {
    API_KEY = key
  }

  getApikey() {
    return API_KEY
  }

  activeDownload(url) {
    let options = {}
    options.url = url
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  self(url) {
    let options = {}
    options.url = url
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  request(options, isData = true) {
    options.url = `${END_POINT}${isData ? '/data/v1' : ''}${options.url}`
    options.auth = {
      username: API_KEY,
      password: '',
    }

    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }

  async getImage(id) {
    const res = await this.request(
      {
        method: 'get',
        url: `/item-types/SkySatCollect/items/${id}`,
        params: {},
      },
      true,
    )

    return res.data
  }

  async getQuota() {
    const res = await this.request(
      {
        method: 'get',
        url: '/auth/v1/experimental/public/my/subscriptions',
        params: {},
      },
      false,
    )

    return res.data
  }

  order(data) {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'POST'
    options.data = {
      name: data.name ? data.name : 'Planet image' + '_' + ConvertDate.dateTimeFormatted(new Date()),
      products: [
        {
          item_ids: data.itemIds,
          item_type: data.type,
          product_bundle: data.productBundle,
        },
      ],
      tools: data.geometry
        ? [
            {
              clip: {
                aoi: data.geometry,
              },
            },
          ]
        : [],
    }
    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }

  getOffering() {
    let options = {}
    options.url = END_POINT + `/v1/order/offerings`
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
      Authorization: API_KEY,
    }
    return axios(options)
  }

  getTaskingOrders(url) {
    let options = {}
    options.url = backendConfig.planet + url
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getListOrder() {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getOrder(id) {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2/${id}`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getProduct() {
    let options = {}
    options.url =
      backendConfig.planet +
      `/tasking/v2/products?fields=name,id,allowed_geometries,available_order_types,scheduling_type,exclusivity_days,requested_area_width_meters,point_order_radius,&limit=1000`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getPreview(data) {
    let options = {}
    options.url = backendConfig.planet + `/tasking/v2/orders/preview`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'POST'
    options.data = data
    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }
}

const api = new PlanetTaskingApi()

export default api
