<template>
  <div class="d-flex" style="position: relative">
    <v-menu
      ref="menu"
      v-model="dataMenu"
      :close-on-content-click="!isSearch"
      class="select-menu"
      :offset-y="true"
      bottom
      style="width: 100%"
      transition="scale-transition"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props" @click="dataMenu = true" style="width: 100%">
          <v-select
            v-model="selectedData"
            :chips="chips"
            :clearable="clearable"
            :density="density"
            :disabled="disabled"
            :hide-details="$attrs['hide-details']"
            :item-title="itemTitle"
            :item-value="itemValue"
            :items="flatItems"
            :label="label"
            :variant="variant"
            :placeholder="$attrs['placeholder']"
            :prepend-inner-icon="$attrs['prepend-inner-icon']"
            :return-object="returnObject"
            :rules="rules"
            :small-chips="smallChips"
            readonly
          >
          </v-select>
        </div>
      </template>
      <v-card class="pt-2">
        <div style="border-bottom: 1px solid">
          <SearchBarEarth
            v-model="search"
            append-inner-icon="mdi-magnify"
            class="ma-2"
            density="compact"
            hide-details
            label="Search"
            variant="outlined"
            @blur="isSearch = false"
            @focus="isSearch = true"
            @input="searchData"
            placeholder="Search"
          >
          </SearchBarEarth>
        </div>
        <v-list class="px-2" density="compact" max-height="275" style="overflow-y: auto" width="100%">
          <v-list-item
            v-for="(item, i) in availableDataGroup"
            :key="i"
            class="py-1"
            @click="selectData(returnObject ? item : item[itemValue])"
            :class="{ 'selected-item': isSelected(item) }"
            :title="item[itemTitle]"
          >
            <template #append>
              <v-icon v-if="item.starred" color="#ffffff">mdi-star</v-icon>
            </template>
          </v-list-item>
        </v-list>
        <div class="d-flex justify-end mt-1" style="border-top: 1px solid">
          <v-btn
            width="140"
            text="Browse all"
            prepend-icon="mdi-ballot-outline"
            class="ma-2"
            color="primary"
            @click="$router.push('/projects')"
          ></v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import sleep from '@/utils/sleep'
import SearchBarEarth from '../SearchBarEarth.vue'

export default {
  components: { SearchBarEarth },
  props: {
    modelValue: {},
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    color: { type: String, default: '' },
    density: { type: String, default: 'default' },
    variant: { type: String, default: 'outlined' },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    itemTitle: { type: String, default: 'name' },
    itemValue: { type: String, default: 'name' },
    returnObject: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    modelValue(val) {
      if (val) this.$emit('change', this.modelValue)
      this.setFirstData()
    },
    dataMenu(val) {
      this.isSearch = false
      if (val) {
        this.search = ''
        this.availableData = [...this.items]
      }
    },
  },
  computed: {
    selectedData: {
      get() {
        return this.dataModel
      },
      set(val) {
        this.dataModel = val
        this.$emit('update:modelValue', this.dataModel)
      },
    },
    availableDataGroup() {
      return this.availableData.filter(item => !item.items)
    },
  },
  data() {
    return {
      isFirst: true,
      isSearch: true,
      flatItems: [],
      availableData: [],
      search: '',
      dataMenu: false,
      dataModel: undefined,
    }
  },

  created() {
    this.setFirstData()
  },
  methods: {
    isSelected(item) {
      return this.selectedData && this.selectedData[this.itemValue] === item[this.itemValue]
    },
    async setFirstData() {
      if (this.isFirst) {
        this.flatItems = []
        this.items.forEach(val => {
          if (!val.items) this.flatItems.push(val)
          else this.flatItems = this.flatItems.concat(val.items)
        })
      }
      await sleep(100)
      this.dataModel = this.modelValue
    },
    selectData(data) {
      if (this.selectedData === data && this.clearable) this.selectedData = undefined
      else this.selectedData = data
    },
    searchData() {
      if (!this.search) this.availableData = [...this.items]
      else {
        let availItems = []
        this.items.forEach(val => {
          if (
            !val.items &&
            val[this.itemTitle] &&
            val[this.itemTitle].toString().toUpperCase().includes(this.search.toUpperCase())
          ) {
            availItems.push({ ...val })
          } else if (val.items) {
            let parent = { ...val }
            parent.items = val.items.filter(
              child =>
                child[this.itemTitle] &&
                child[this.itemTitle].toString().toUpperCase().includes(this.search.toUpperCase()),
            )
            if (parent.items.length) availItems.push({ ...parent })
          }
        })
        this.availableData = [...availItems]
      }
    },
  },
}
</script>

<style scoped lang="scss">
:deep{
  .v-theme--dark.v-list {
    background-color: transparent !important;
  }
  .v-menu__content {
    margin-top: -25px;
  }
  .v-list-item {
    border-radius: 4px !important;
  }
}
.selected-item {
  background-color: rgb(var(--v-theme-primary));
  color: white !important;
}
</style>
