/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import router from '@/router'
import store from '@/store'
import antd from 'ant-design-vue'
import pinia from '@/store/pinia'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import * as Sentry from '@sentry/vue'

export function registerPlugins(app) {
  // Sentry.init({
  //   app,
  //   dsn: "http://a4b3e42a598747b0c5c8420da6268c82@192.168.4.204:9000/4",
  //   integrations: [
  //     Sentry.replayIntegration(),
  //     Sentry.browserTracingIntegration({ router }),
  //   ],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   // Tracing
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   // Profiling
  // });
  app
    .use(vuetify)
    .use(router)
    .use(store)
    .use(antd)
    .use(pinia)
    .use(VueMonacoEditorPlugin, {
      paths: {
        // The recommended CDN config
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
      },
    })


}
