import { getLegends } from '@/api/detection-api'
import { SERVICE_NAME_SHIP, SERVICE_NAME_AIRCRAFT } from '@/constants/detection'

export default {
  namespaced: true,
  state: {
    shipLegends: [],
    aircraftLegends: [],
    typeLegends: 'vessel',
  },
  getters: {
    customLegends(state) {
      const customLegends = {}
      const legends = state.typeLegends === SERVICE_NAME_SHIP ? state.shipLegends : state.aircraftLegends
      legends.forEach(legend => {
        customLegends[legend.value] = legend.color
      })
      return customLegends
    },
  },
  mutations: {
    SET_STATE_PROPERTY(state, { property, value }) {
      state[property] = value
    },
  },
  actions: {
    async getLegends({ commit }, params) {
      try {
        const res = await getLegends(params)
        if (params.service_name === SERVICE_NAME_SHIP) {
          commit('SET_STATE_PROPERTY', { property: 'shipLegends', value: res.data })
          commit('SET_STATE_PROPERTY', { property: 'typeLegends', value: SERVICE_NAME_SHIP })
        } else {
          commit('SET_STATE_PROPERTY', { property: 'aircraftLegends', value: res.data })
          commit('SET_STATE_PROPERTY', {
            property: 'typeLegends',
            value: SERVICE_NAME_AIRCRAFT,
          })
        }
      } catch (e) {
        console.warn('Error when get legends: ', e)
      }
    },
  },
}
