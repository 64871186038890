<template>
  <v-snackbar
    class="alert-bar"
    v-model="snackbar"
    :multi-line="multiLine"
    timeout="-1"
    location="top right"
    transition="scroll-x-transition"
    max-width="500"
  >
    <v-row>
      <v-col cols="1">
        <v-icon class="alert-icon mr-2">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="11" class="d-flex align-center">
        <span class="truncate-text-3" style="color: white; margin-left: 4px" :title="text">{{ text }}</span>
      </v-col>
    </v-row>
    <div>
      <audio ref="audioPlayer" controls style="display: none" @ended="onAudioEnded">
        <source :src="audioSource" type="audio/mpeg" />
      </audio>
    </div>
    <div class="details" @click="showDetail"><span>Show detail</span></div>
  </v-snackbar>
</template>

<script>
const audioSource = new URL(`@/assets/audio/alert3.mp3`, import.meta.url).href

export default {
  name: 'alert-bar',
  data: () => {
    return {
      snackbar: false,
      text: '',
      icon: 'mdi-alert-outline',
      multiLine: false,
      withAudio: false,
      audioSource,
    }
  },
  methods: {
    show(text = 'alert', withAudio = true) {
      this.text = text
      this.snackbar = true
      this.withAudio = withAudio
    },
    showDetail() {
      this.$emit('showDetail')
    },
    close() {
      this.snackbar = false
      this.stopAudio()
    },
    playAudio() {
      this.$nextTick(() => {
        this.$refs.audioPlayer.play()
      })
    },
    stopAudio() {
      if (!this.$refs.audioPlayer) return
      this.$refs.audioPlayer.pause()
      this.$refs.audioPlayer.currentTime = 0
    },
    // loop sound
    onAudioEnded() {
      this.$refs.audioPlayer.currentTime = 0
      this.$refs.audioPlayer.play()
    },
  },
  watch: {
    withAudio(val) {
      if (val) {
        this.playAudio()
        this.withAudio = false
      }
    },
    snackbar(val) {
      if (!val) {
        this.stopAudio()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.details {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
.icon-close {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
}

:deep(.v-snackbar__content) {
  color: white !important;
  background-color: #f75050 !important;
}
</style>
