<template>
  <div class="d-flex flex-grow-1" style="position: relative">
    <v-menu
      ref="menu"
      v-model="dataMenu"
      :close-on-content-click="!multiple && !isSearch"
      class="select-menu"
      bottom
      style="width: 100%"
      transition="scale-transition"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props" style="width: 100%">
          <v-select
            :class="multiple ? 'wrap-input' : ''"
            v-model="selectedData"
            :chips="chips"
            :clearable="clearable"
            :density="density"
            :disabled="disabled"
            :hide-details="$attrs['hide-details']"
            :item-title="itemTitle"
            :item-value="itemValue"
            :items="flatItems"
            :label="label"
            :closable-chips="true"
            :multiple="multiple"
            :variant="variant"
            :placeholder="$attrs['placeholder']"
            :prepend-inner-icon="$attrs['prepend-inner-icon']"
            :return-object="returnObject"
            :rules="rules"
            :small-chips="smallChips"
            readonly
          >
          </v-select>
        </div>
      </template>
      <v-card style="z-index: 9998; background-color: #34364a !important">
        <SearchBarEarth
          v-model="search"
          append-inner-icon="mdi-magnify"
          class="ma-2"
          density="compact"
          hide-details
          label="Search"
          variant="outlined"
          placeholder="Search"
          @blur="isSearch = false"
          @focus="isSearch = true"
          @update:modelValue="searchData"
        >
        </SearchBarEarth>
        <v-list v-if="items.length > 0" density="compact" max-height="275" style="overflow-y: auto" width="100%">
          <div v-for="(item, i) in availableData" :key="i" class="d-flex flex-column px-2">
            <v-list-item
              v-if="!item.items"
              class="py-1"
              @click="selectData(returnObject ? item : item[itemValue])"
              :active="isSelected(returnObject ? item : item[itemValue])"
            >
              <v-list-item-title v-text="item[itemTitle]"></v-list-item-title>
            </v-list-item>
            <div
              v-if="item.items"
              class="d-flex flex-column"
              style="border-bottom: 1px solid rgba(103, 103, 103, 0.6); border-top: 1px solid rgba(103, 103, 103, 0.6)"
            >
              <v-list-item-title class="px-2 py-1">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-folder-outline</v-icon>
                  <b style="font-size: 15px"> {{ item[itemTitle] }}</b>
                </div>
              </v-list-item-title>
              <v-list-item
                v-for="(child, i) in item.items"
                :key="'child' + i"
                class="px-7 py-1"
                @click="selectData(returnObject ? child : child[itemValue])"
                :active="isSelected(returnObject ? child : child[itemValue])"
              >
                <v-list-item-title v-if="!child.items" v-text="child[itemTitle]"></v-list-item-title>
              </v-list-item>
            </div>
          </div>
        </v-list>
        <div v-else style="text-align: center; padding-bottom: 4px">No data available.</div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import sleep from '@/utils/sleep'
import SearchBarEarth from '../SearchBarEarth.vue'

export default {
  components: { SearchBarEarth },
  props: {
    modelValue: {},
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    color: { type: String, default: '' },
    density: { type: String, default: 'default' },
    variant: { type: String, default: 'outlined' },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    itemTitle: { type: String, default: 'name' },
    itemValue: { type: String, default: 'name' },
    returnObject: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    modelValue(val) {
      if (!val && this.multiple) this.dataModel = []
      else this.setFirstData()
      if (val) this.$emit('change', this.modelValue)
    },
    dataMenu(val) {
      this.isSearch = false
      if (val) {
        this.search = ''
        this.availableData = [...this.items]
      }
    },
  },
  computed: {
    selectedData: {
      get() {
        return this.dataModel && Object.keys(this.dataModel).length > 0
          ? this.dataModel
          : this.multiple
            ? []
            : undefined
      },
      set(val) {
        this.dataModel = val
        this.$emit('update:modelValue', this.dataModel)
      },
    },
  },
  data() {
    return {
      isFirst: true,
      isSearch: true,
      flatItems: [],
      availableData: [],
      search: '',
      dataMenu: false,
      dataModel: undefined,
    }
  },

  created() {
    this.setFirstData()
  },
  methods: {
    async setFirstData() {
      if (this.isFirst) {
        this.flatItems = []
        this.items.forEach(val => {
          if (!val.items) this.flatItems.push(val)
          else this.flatItems = this.flatItems.concat(val.items)
        })
      }
      await sleep(100)
      this.dataModel = this.modelValue
    },
    selectData(data) {
      if (this.multiple) {
        let ind = this.selectedData.findIndex(val => val === data)
        let newData = [...this.selectedData]
        if (ind >= 0) newData.splice(ind, 1)
        else newData.push(data)
        this.selectedData = newData
      } else {
        if (this.selectedData === data && this.clearable) this.selectedData = undefined
        else this.selectedData = data
      }
    },
    isSelected(data) {
      return (
        JSON.stringify(this.selectedData) === JSON.stringify(data) ||
        (Array.isArray(this.selectedData) && this.selectedData.includes(data))
      )
    },
    searchData() {
      if (!this.search) this.availableData = [...this.items]
      else {
        let availItems = []
        this.items.forEach(val => {
          if (
            !val.items &&
            val[this.itemTitle] &&
            val[this.itemTitle].toString().toUpperCase().includes(this.search.toUpperCase())
          ) {
            availItems.push({ ...val })
          } else if (val.items) {
            let parent = { ...val }
            parent.items = val.items.filter(
              child =>
                child[this.itemTitle] &&
                child[this.itemTitle].toString().toUpperCase().includes(this.search.toUpperCase()),
            )
            if (parent.items.length) availItems.push({ ...parent })
          }
        })
        this.availableData = [...availItems]
      }
    },
  },
}
</script>

<style scoped>
.v-menu__content {
  margin-top: -25px;
  z-index: 10000 !important;
}
:deep(.v-input__details) {
  display: none;
}
</style>
