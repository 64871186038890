import { TODAY, PREVIOUS_7_DAYS, PREVIOUS_30_DAYS, OLDER } from '@/constants/categories-date'

const date = {
  dateFormatted(date) {
    if (!date) return ''
    let currentDate = new Date(date)
    let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
    let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    return year + '-' + month + '-' + day
  },
  getCurrentDate() {
    let currentDate = new Date()
    let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    return year + '-' + month + '-' + '01'
  },
  getBeforeDate() {
    let beforeDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3)
    let beforeMonth = beforeDate.getMonth() + 1 < 10 ? '0' + (beforeDate.getMonth() + 1) : beforeDate.getMonth() + 1
    let beforeYear = beforeDate.getFullYear()
    return beforeYear + '-' + beforeMonth + '-' + '01'
  },
  customDate(date) {
    if (!date) return ''
    const dateString = date.split('T')[0]
    if (/^\d{4}-\d{2}$/.test(dateString)) {
      const [year, month] = dateString.split('-')
      const customDate = new Date(year, month - 1)
      const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: 'numeric',
      })
      return formatter.format(customDate)
    } else if (/^\d{4}-Q[1-4]$/.test(dateString)) {
      const [year, quarter] = dateString.split('-')
      return `${quarter} ${year}`
    } else if (/^\d{4}$/.test(dateString)) {
      return dateString
    } else {
      const customDate = new Date(dateString)
      const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
      return formatter.format(customDate)
    }
  },
  dateTimeFormatted(date) {
    if (!date) return ''
    let currentDate = new Date(date)
    let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
    let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    let hours = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    return year + '-' + month + '-' + day + ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
  },
  yearMonthFormatted(date) {
    if (!date) return ''
    let currentDate = new Date(date)
    let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    return year + '-' + month
  },
  localDateString(date) {
    if (!date) return ''
    let currentDate = new Date(date)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    return currentDate.toLocaleDateString('vn-VN', options)
  },
  categorizeDates(dateStr) {
    const today = new Date()
    const date = new Date(dateStr)
    const diffTime = today - date
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays <= 1) {
      return TODAY
    } else if (diffDays <= 7) {
      return PREVIOUS_7_DAYS
    } else if (diffDays <= 30) {
      return PREVIOUS_30_DAYS
    }
    return OLDER
  },
}
export default date
