import ax from 'axios'
import { RateLimit } from '@/utils/planet/rateLimit'
import ConvertDate from '@/utils/convertDate'
import backendConfig from '@/config'

const rateLimit = new RateLimit(2, 500)
const END_POINT = backendConfig.planet
let API_KEY = '1221cfc2e35a4b8b838245a88aa96a50'
// let API_KEY = 'PLAK8107cc868f994213af0f299f0f8c79dc'

const config = {
  baseURL: END_POINT,
  timeout: 30000,
}
let axios = ax.create(config)

export class PlanetApi {
  constructor() {
    this.itemTypes = null

    // this.getItemTypes().then((types) => {
    //   this.itemTypes = types
    // })
  }

  setApikey(key) {
    API_KEY = key
  }

  activeDownload(url) {
    let options = {}
    options.url = url
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  self(url) {
    let options = {}
    options.url = url
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getOrder(id) {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2/${id}`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  getListOrder() {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'GET'
    options.headers = {
      'Content-Type': 'application/json',
    }
    return axios(options)
  }

  order(data) {
    let options = {}
    options.url = backendConfig.planet + `/compute/ops/orders/v2`
    options.auth = {
      username: API_KEY,
      password: '',
    }
    options.method = 'POST'
    options.data = {
      name: data.name ? data.name : 'Planet image' + '_' + ConvertDate.dateTimeFormatted(new Date()),
      products: [
        {
          item_ids: data.itemIds,
          item_type: data.type,
          product_bundle: data.productBundle,
        },
      ],
      tools: data.geometry
        ? [
            {
              clip: {
                aoi: data.geometry,
              },
            },
          ]
        : [],
    }
    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }

  request(options, isData = true) {
    options.url = `${END_POINT}${isData ? '/data/v1' : ''}${options.url}`
    options.auth = {
      username: API_KEY,
      password: '',
    }

    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }

  async getItemTypes() {
    const res = await this.request({
      method: 'GET',
      url: '/item-types',
    })
    // console.log(res)
    return res.data.item_types
  }

  /**
   * @public
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudPercent Cloud cover percentage [0..100]
   * @param {String} interval 'hour', 'day', 'month', 'year'
   */
  stats(geometry, startDate, endDate, cloudPercent, interval) {
    return new Promise((resolve, reject) => {
      rateLimit.run(() => {
        // console.log('STATS', new Date().toISOString())
        this.performStats(geometry, startDate, endDate, cloudPercent, interval).then(resolve).catch(reject)
      })

      rateLimit.run(() => {
        // Empty function call for OPTIONS request alongside real stats request
        // console.log('OPTIONS', new Date().toISOString())
      })
    })
  }

  /**
   * @private
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudPercent Cloud cover percentage [0..100]
   * @param {String} interval 'hour', 'day', 'month', 'year'
   */
  async performStats(geometry, startDate, endDate, cloudPercent, interval) {
    const data = await this.createRequestData(geometry, startDate, endDate, cloudPercent, interval)

    const res = await this.request({
      method: 'POST',
      url: '/stats',
      data: data,
    })

    return res.data
  }

  /**
   * @public
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudPercent Cloud cover percentage [0..100]
   */
  async quickSearch(filter) {
    let geometry = filter.geojson
    let startDate = new Date(filter.date_range[0])
    let endDate = new Date(filter.date_range[1])
    endDate.setHours(23, 59, 59, 999)
    let cloudPercent = filter.cloud ? filter.cloud : [0, 20]
    let types = filter.types
    const data = await this.createRequestData(geometry, startDate, endDate, cloudPercent, types)

    const res = await this.request({
      method: 'POST',
      url: '/quick-search',
      data: data,
    })

    return res.data.features.map(feature => {
      feature.display = false
      feature.thumbnail = feature._links.thumbnail + `?api_key=${API_KEY}`
      feature.properties.tile_urls = [
        `https://tiles0.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`,
        `https://tiles1.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`,
        `https://tiles2.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`,
        `https://tiles3.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`,
      ]
      feature.properties.subdomains = [0, 1, 2, 3]

      return feature
    })
  }

  async createRequestData(geometry, startDate, endDate, cloudPercent = [0, 20], types, interval = 'day') {
    if (!this.itemTypes) {
      this.itemTypes = await this.getItemTypes()
    }
    const data = {
      item_types: types.map(type => type.id),
      filter: {
        type: 'AndFilter',
        config: [
          {
            type: 'StringInFilter',
            field_name: 'quality_category',
            config: ['standard'],
          },
          {
            type: 'GeometryFilter',
            field_name: 'geometry',
            config: geometry,
          },
          {
            type: 'DateRangeFilter',
            field_name: 'acquired',
            config: {
              gte: startDate,
              lte: endDate,
            },
          },
          {
            type: 'RangeFilter',
            field_name: 'cloud_percent',
            config: {
              gte: cloudPercent[0],
              lte: cloudPercent[1],
            },
          },
        ],
      },
    }

    return data
  }

  async getQuota() {
    const res = await this.request(
      {
        method: 'get',
        url: '/auth/v1/experimental/public/my/subscriptions',
        params: {},
      },
      false,
    )

    return res.data
  }

  async getAssets(image) {
    const res = await this.request({
      method: 'GET',
      url: image._links.assets.replace('https://api.planet.com/data/v1', ''),
    })

    return res.data
  }

  async activateAsset(asset) {
    const res = await this.request({
      method: 'POST',
      url: asset._links.activate.replace('https://api.planet.com/data/v1', ''),
    })

    return res.data
  }

  async getBaseMapMosaics() {
    const res = await this.request({
      method: 'GET',
      url: '/basemaps/v1/mosaics',
      params: {
        public: true,
      },
    })

    return res.data.mosaics
  }
}

const api = new PlanetApi()

export default api
