import api from '@/api/api'
import config from '@/config'

export async function getListProjects(param) {
  return await api.get(`${config.backend}/projects`, param)
}

export async function getAllProjects(param) {
  return await api.get(`${config.backend}/system/projects`, param)
}

export async function createProject(data) {
  return await api.post(`${config.backend}/projects`, data)
}

export async function deleteProject(id) {
  return await api.delete(`${config.backend}/projects/${id}`)
}

export async function updateProject(form) {
  return await api.put(`${config.backend}/projects/${form.id}`, form.data)
}

export async function addShare(data) {
  return await api.post(`${config.backend}/projects/${data.project_id}/shares`, data)
}

export async function getShared(project) {
  return await api.get(`${config.backend}/projects/${project}/shares`)
}

export async function updateShared(data) {
  return await api.put(`${config.backend}/projects/${data.project_id}/shares/${data.id}`, data)
}

export async function deleteShared(data) {
  return await api.delete(`${config.backend}/projects/${data.project_id}/shares/${data.id}`)
}
export async function updateMenuProject(data) {
  return await api.put(`${config.backend}/projects/${data.project_id}/menus`, data.data)
}
export async function getMenuproject(id) {
  return await api.get(`${config.backend}/projects/${id}/menus`)
}
export async function getTags(param) {
  return await api.get(`${config.backend}/tags`, param)
}
export async function updateStarred(data) {
  return await api.put(`${config.backend}/projects/${data.project_id}/favorite`, data.payload)
}
