// import { point } from 'turf'
import { point } from '@turf/turf'

import { Base } from './PointMarker'

export function SymbolMarkerDraw(_draw, _feature = {}, { layer, layers } = {}) {
  if (layers && !layer) {
    layer = layers[0]
  }
  if (layer) {
    layer = _draw.addLayer(layer)
    _draw.onClear = () => {
      _draw.removeLayer(layer)
    }
  }
  let parent = new Base('SymbolMarkerDraw', _draw, createPoint, _feature)

  parent.start = () => {
    _draw.start({ max_point: 1 })
  }
  return parent
}

function createPoint(coordinates) {
  if (!coordinates || coordinates.length != 1) {
    return []
  }
  return [point(coordinates[0])]
}
