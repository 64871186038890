import { ArrowDoubleDraw } from './ArrowDoubleDraw'
import { ArrowSimpleDraw } from './ArrowSimpleDraw'
import { ArrowYWDraw } from './ArrowYWDraw'
import { PointMarker } from './PointMarker'
import { SymbolMarkerDraw } from './SymbolMarkerDraw'

export const MARKER_DRAW = {
  PointMarker: (map, feature, options) => new PointMarker(map, feature, options),
  SymbolMarkerDraw: (map, feature, options) => new SymbolMarkerDraw(map, feature, options),
  SimpleMarkerSelect: (map, options) => new SimpleSelect(map, options),
  ArrowSimpleDraw: (map, feature, options) => ArrowSimpleDraw(map, feature, options),
  ArrowYWDraw: (map, feature, options) => ArrowYWDraw(map, feature, options),
  ArrowDoubleDraw: (map, feature, options) => ArrowDoubleDraw(map, feature, options),
}

function SimpleSelect(map, { layer_ids = [] } = {}) {
  let _onMapClick = e => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: layer_ids,
    })
    if (features && features.length > 0 && features[0].properties.draw_type) {
      this.onSelect(features[0].properties.draw_type, features[0])
    }
  }
  this.onSelect = () => {}

  map.on('click', _onMapClick)
  this.destroy = () => {
    map.off('click', _onMapClick)
  }
}
