export default {
  namespaced: true,
  state: {
    legend: [],
  },
  getters: {
    legend(state) {
      return state.legend
    },
  },
  mutations: {
    SET_LEGEND(state, newValue) {
      state.legend = newValue
    },
  },
}
