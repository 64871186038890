export default {
  namespaced: true,
  state: {
    content: '',
    snackBar: false,
    color: '#fc0303',
    location: 'bottom center',
    timeout: 3000,
    type: 'notification',
    undoItem: {},
  },
  getters: {
    text(state) {
      return state.text
    },
    snackBar(state) {
      return state.snackBar
    },
    color(state) {
      return state.color
    },
  },
  mutations: {
    SET_STATE_PROPERTY(state, payload) {
      state[payload.key] = payload.value
    },
    SHOW_SUCCESS(state, newValue) {
      state.timeout = 3000
      state.type = 'notification'
      state.location = 'bottom center'
      state.color = '#04c40e'
      state.content = `<div style="background-color:${state.color}">${newValue}</div> `
      state.snackBar = true
    },
    SHOW_ERROR(state, newValue) {
      state.timeout = 3000
      state.type = 'notification'
      state.location = 'bottom center'
      state.color = '#fc0303'
      state.content = `<div style="background-color:${state.color}">${newValue}</div>`
      state.snackBar = true
    },
    SHOW_WARNING(state, newValue) {
      state.timeout = 3000
      state.type = 'notification'
      state.location = 'bottom center'
      state.color = '#ec942c'
      state.content = `<div style="background-color:${state.color}">${newValue}</div>`
      state.snackBar = true
    },
    SHOW_NOTI_WITH_BTN(
      state,
      {
        message,
        type = 'undo',
        callback,
        btnText,
        color = '#181b34',
        btnColor = '#fff',
        btnTextColor = '#000',
        prependIcon,
        appendIcon,
        innerIcon,
      },
    ) {
      state.timeout = 10000
      state.type = type
      state.location = 'bottom center'
      state.color = color

      state.content = `
        <div style="display: flex; align-items: center; background-color: ${state.color};  color: #fff;">
        ${prependIcon ? `<i class="mdi ${prependIcon}" style="margin-right: 10px;font-size: 24px;"></i>` : ''}
        <span style="flex: 1;margin-right:10px">${message}</span>
          <button id="button" style="border: none; color: ${btnTextColor}; padding: 5px 10px; margin-left: 10px; background-color: ${btnColor}; border-radius: 4px; cursor: pointer; display: flex; align-items: center;">
          ${btnText}
            ${innerIcon ? `<i class="mdi ${innerIcon}" style="margin-right: 5px;margin-left:5px"></i>` : ''}
          </button>
          ${appendIcon ? `<i class="mdi ${appendIcon}" style="margin-left: 10px;"></i>` : ''}
        </div>`

      setTimeout(() => {
        const button = document.getElementById('button')
        if (button) {
          button.addEventListener('click', () => {
            state.snackBar = false
            if (callback) {
              callback()
            }
          })
        }
      }, 0)

      state.snackBar = true
    },
  },
}
