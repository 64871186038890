import './overrides.scss'

const customDarkTheme = {
  dark: true,
  colors: {
    'text-primary': '#d5d8df',
    background: '#FFFFFF',
    'on-background': '#d5d8df',
    surface: '#181b34',
    'on-surface': '#d5d8df',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#ffffff',
    'on-surface-variant': '#323338',
    primary: '#1966F7',
    'primary-darken-1': '#1F5592',
    secondary: '#8a8d93',
    'secondary-darken-1': '#018786',
    accent: '#1174ff',
    error: '#ff3c42',
    info: '#2196F3',
    success: '#56CA00',
    warning: '#ffb400',
    gray3: '#828282',
  },
}

export default {
  theme: {
    defaultTheme: 'dark',
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      dark: customDarkTheme,
    },
  },
}
