import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as dragPan from '../utils/drag_pan'
import circle from '@turf/circle'
import turfLength from '@turf/length'
import * as turfHelpers from '@turf/helpers'

const { modes, constants } = MapboxDraw

const DragCircleMode = { ...modes.draw_polygon }

DragCircleMode.onSetup = function () {
  const polygon = this.newFeature({
    type: constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: [],
    },
    geometry: {
      type: constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  })

  this.addFeature(polygon)

  this.clearSelectedFeatures()
  this.map.doubleClickZoom.disable()
  dragPan.disable(this)
  this.updateUIClasses({ mouse: constants.cursors.ADD })
  this.activateUIButton(constants.types.POLYGON)
  this.setActionableState({
    trash: true,
  })

  return {
    polygon,
    currentVertexPosition: 0,
  }
}

DragCircleMode.onMouseDown = DragCircleMode.onTouchStart = function (state, e) {
  const currentCenter = state.polygon.properties.center
  if (currentCenter.length === 0) {
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]
  }
}

DragCircleMode.onDrag = DragCircleMode.onMouseMove = function (state, e) {
  const center = state.polygon.properties.center
  if (center.length > 0) {
    const distanceInKm = turfLength(turfHelpers.point(center), turfHelpers.point([e.lngLat.lng, e.lngLat.lat]), {
      units: 'kilometers',
    })
    const circleFeature = circle(center, distanceInKm)
    state.polygon.incomingCoords(circleFeature.geometry.coordinates)
    state.polygon.properties.radiusInKm = distanceInKm
  }
}

DragCircleMode.onMouseUp = DragCircleMode.onTouchEnd = function (state) {
  dragPan.enable(this)
  return this.changeMode(constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] })
}

DragCircleMode.onClick = DragCircleMode.onTap = function (state) {
  // don't draw the circle if its a tap or click event
  state.polygon.properties.center = []
}

DragCircleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActivePolygon = geojson.properties.id === state.polygon.id
  geojson.properties.active = isActivePolygon ? constants.activeStates.ACTIVE : constants.activeStates.INACTIVE
  return display(geojson)
}

export default DragCircleMode
