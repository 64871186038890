<template>
  <v-dialog v-model="dialog" width="400">
    <v-card :loading="loading" class="custom-card-bg-gradient">
      <DialogHeader :is-close="true" title="Style" @close="dialog = false" />
      <v-card-text>
        <div v-for="(item, key) in paint" v-if="show" :key="key" class="d-flex align-center py-1">
          <div class="text-capitalize">{{ key.replaceAll('-', ' ') }}</div>
          <v-spacer />
          <div v-if="checkArr(item) && key.includes('color')">
            <div v-for="(val, ind) in item" v-if="ind">
              <span v-if="ind % 2 !== 0 && ind < item.length - 1">{{ val[2] }}</span>
              <div v-else-if="ind === item.length - 1">
                Other
                <ColorIcon :color="val" :width="200" @change="color => updateColor(key, ind, val, color)"></ColorIcon>
              </div>
              <ColorIcon
                v-else
                :color="val"
                :width="200"
                @change="color => updateColor(key, ind, val, color)"
              ></ColorIcon>
            </div>
          </div>
          <ColorIcon
            v-else-if="key.includes('color')"
            v-model:color="paint[key]"
            :width="200"
            @change="color => (paint[key] = color)"
          ></ColorIcon>
          <div v-else-if="!key.includes('opacity')" style="width: 75px">
            <v-text-field
              v-model.number="paint[key]"
              density="compact"
              hide-details
              variant="outlined"
              type="number"
            ></v-text-field>
          </div>
          <v-slider
            v-else
            v-model.number="paint[key]"
            density="compact"
            hide-details
            max="1"
            min="0"
            step="0.1"
          ></v-slider>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" variant="flat" rounded @click="updateStyle">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import ColorIcon from '@/components/ColorIcon.vue'
import { isArray } from 'chart.js/helpers'

export default {
  components: { ColorIcon, DialogHeader },
  emits: ['updateStyle'],
  data() {
    return {
      dialog: false,
      loading: false,
      currentLayer: {},
      paint: {},
      show: true,
    }
  },
  methods: {
    async updateColor(key, ind, val) {
      this.paint[key][ind] = val
      this.show = false
      this.show = true
    },
    checkArr(item) {
      return isArray(item)
    },
    openDialog(layer) {
      this.currentLayer = layer
      this.paint = JSON.parse(JSON.stringify(layer.paint))
      this.dialog = true
    },
    updateStyle() {
      this.currentLayer.paint = this.paint
      this.$emit('updateStyle', this.currentLayer)
      this.dialog = false
    },
  },
}
</script>
