<template>
  <v-dialog v-model="config" fullscreen class="custom-card-bg-gradient">
    <v-overlay :model-value="loading" class="align-center justify-center" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <DialogHeader :is-close="true" :title="'Upload AOI'" @close="config = false" />
    <v-form ref="formData" v-model="valid" @submit.prevent="saveData" style="height: calc(100% - 60px)">
      <div class="d-flex px-4 pb-4" style="height: 100%">
        <div style="width: 400px; height: 100%">
          <v-card class="custom-card-bg-2 pa-2 elevation-2" height="100%" width="100%">
            <div class="d-flex flex-column fill-height overflow-y-hidden">
              <div
                class="d-flex flex-column"
                style="flex: 1; width: 100%; overflow-y: auto; height: calc(100% - 500px)"
              >
                <div v-for="(feature, index) in geojson.features" :key="index" class="py-1">
                  <div class="d-flex">
                    <div class="d-flex pr-1" style="flex: 5">
                      <v-text-field
                        v-model="feature.properties.name"
                        :rules="[rules.nameRequired]"
                        density="compact"
                        label="Name"
                        variant="outlined"
                        placeholder="Name"
                      ></v-text-field>
                    </div>
                    <div class="d-flex px-1" style="flex: 5">
                      <v-combobox
                        v-model="feature.properties.group"
                        :items="groups"
                        density="compact"
                        label="Select group"
                        variant="outlined"
                        placeholder="Select group"
                        small-chips
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip color="primary" small>
                            <div class="caption-light">{{ item.title }}</div>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <div class="d-flex pl-1" style="flex: 1">
                      <v-btn class="mt-1" icon="icon-gps" variant="text" size="small" @click="zoomTo(feature)"></v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%; flex: none; height: 50px">
                <v-btn
                  color="primary"
                  rounded
                  width="100%"
                  type="submit"
                  prepend-icon="mdi-content-save-check-outline"
                  text="Save"
                ></v-btn>
              </div>
            </div>
          </v-card>
        </div>
        <div v-if="config" class="d-flex flex-fill pl-4 card-map-v2">
          <MapV2 ref="mapView" />
        </div>
      </div>
    </v-form>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import randomColor from '@/utils/randomColor'
import { saveUploadAOI } from '@/api/aoi-api'
import MapV2 from '@/components/MapV2.vue'
import { mapState } from '@/store/ults'

export default {
  components: { MapV2, DialogHeader },
  emits: ['closeConfig', 'fetchAOI'],
  computed: {
    ...mapState('AOI', ['groups', 'currentGroup']),
  },
  data() {
    return {
      config: false,
      geojson: {},
      loading: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      valid: false,
    }
  },
  methods: {
    handleGroup() {
      this.geojson.features = this.geojson.features.map(feature => {
        if (!feature?.properties?.group) feature.properties.group = this.currentGroup.name
        return feature
      })
    },
    openDialog(geojson) {
      this.geojson = geojson
      if (Object.keys(this.currentGroup).length > 0) this.handleGroup()
      this.config = true
      this.addLayerToMap()
    },
    async saveData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        this.geojson.features.forEach(feature => {
          feature.name = feature.properties.name
          feature.group = feature.properties.group
        })
        await saveUploadAOI({
          projectId: this.$route.params.id,
          params: { features: this.geojson.features },
        })
        this.$emit('fetchAOI')
        this.$emit('closeConfig')
        this.config = false
      } catch (e) {
        console.warn('Error when save upload aoi: ', e)
      } finally {
        this.loading = false
      }
    },
    async addLayerToMap() {
      if (!this.$refs.mapView || !this.$refs.mapView.map) {
        await sleep(100)
        await this.addLayerToMap()
        return
      }
      await sleep(300)
      this.geojson.features.forEach((feature, index) => {
        this.$refs.mapView.addGeoJsonToMap(
          `layer-${index}`,
          feature.geometry,
          randomColor.getColor(index),
          `layer-${index}`,
          'line',
          false,
        )
      })
      this.$refs.mapView.submitZoom(
        bbox({
          type: 'FeatureCollection',
          features: this.geojson.features,
        }),
      )
    },
    zoomTo(feature) {
      this.$refs.mapView.submitZoom(bbox(feature.geometry))
    },
  },
}
</script>

<style scoped></style>
