import api from '@/api/api'
import config from '@/config'

export async function getHotspots(data) {
  return await api.get(`${config.backend}/map/forestfire`, data.payload)
}

export async function getListHotspots(data) {
  return await api.post(`${config.backend}/map/forest-fire`, data.params)
}

export async function getDetailHotspots(data) {
  return await api.get(`${config.backend}/map/forest-fire/detail`, data.payload)
}

export async function getEarthquake(data) {
  return await api.post(`${config.backend}/map/earthquake`, data.params)
}

export async function getDetailEarthquake(data) {
  return await api.get(`${config.backend}/map/earthquake/${data.id}`)
}
